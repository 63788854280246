// BUTI DINERS, INC. All right Reserved ©

import NewOrdersAlarm from "./NewOrdersAlarm";
import Printers from "./Printers";

const ShopInfoModules = {
  NewOrdersAlarm,
  Printers
};

export default ShopInfoModules;
