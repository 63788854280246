// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import _get from "lodash.get";

import Main from "main";
import { PageNotFound } from "views";

const ROUTES = (routeName = "", props = {}) => {
  switch (routeName) {
    case "home":
      return <Main {...props} showHomeScreen />;
    case "new-merchant-account":
    case "online-ordering-agreement":
      return (
        <Main
          {...props}
          formId={_get(props, "match.params.formId") || ""}
          showAgreementForm
        />
      );
    case "payments":
      return <Main {...props} activeNavbarItemId="paymentInfo" />;
    case "payouts":
      return (
        <Main {...props} activeNavbarItemId="payoutsForConnectedAccount" />
      );
    case "shopAdmin":
      return <Main {...props} />;
    case "stripeRedirect":
      return (
        <Main {...props} activeNavbarItemId="paymentInfo" stripeRedirect />
      );
    default:
      return () => <PageNotFound />;
  }
};

export default ROUTES;
