// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { GridIcon, ListIcon } from "assets/Icons";

const DISPLAY_OPTIONS = {
  grid: { label: <GridIcon />, name: "grid" },
  list: { label: <ListIcon />, name: "list" },
};

const FILTERS = [
  {
    title: "Rating",
    subOptions: ["Highest", "Lowest"],
  },
  {
    title: "Date",
    subOptions: ["Newest", "Oldest"],
  },
];

export { DISPLAY_OPTIONS, FILTERS };
