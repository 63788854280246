// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";

import {
  ADDON_HARDWARE_OPTIONS,
  SERVICE_PACKAGE_OPTIONS,
  SERVICE_PAYMENT_OPTIONS,
} from "./HelperFunctions";

// Style
import FormStyle from "../style.module.scss";

// Components
import { CheckBoxGroup, RadioGroup } from "components";
import { LOCATION_INFO } from "components/NewMerchantAccountModules/AgreementForm/HelperFunctions";

// Fields
import { Box, Button, ExpansionPanel, QuantityCalculator, Input } from "fields";

const DEFAULT_COMMISSION_FEE = 10;
const MIN_COMMISSION_FEE = 5;

class Location extends React.Component {
  componentDidMount = () => {
    const { locationInfo = {} } = this.props;
    const { packagePlan = {} } = locationInfo;
    if (!packagePlan["optionId"]) this.onSelectPackage("commissionOnly");
  };

  componentDidUpdate = () => {
    const { locationInfo = {} } = this.props;
    const { paymentPlan = {} } = locationInfo;
    if (!paymentPlan["optionId"]) this.onSelectPaymentOption("annual");
  };

  onChangeCommissionFee = ({ commissionFee, increment }) => {
    const newCommissionFee = commissionFee + increment;
    this.props.onChange(
      set(
        this.props.locationInfo,
        "packagePlan.commissionFee",
        newCommissionFee < MIN_COMMISSION_FEE
          ? MIN_COMMISSION_FEE
          : newCommissionFee
      )
    );
  };

  onChangeInputField = ({ sectionId, fieldId, fieldValue }) =>
    this.props.onChange(
      set(this.props.locationInfo, `${sectionId}.${fieldId}`, fieldValue)
    );

  onSelectAddOnHardware = (optionId) => {
    const { locationInfo = {} } = this.props;
    const { addOnHardware = {} } = locationInfo;
    this.props.onChange(
      set(
        this.props.locationInfo,
        "addOnHardware",
        addOnHardware[optionId]
          ? del(addOnHardware, optionId)
          : set(addOnHardware, optionId, ADDON_HARDWARE_OPTIONS[optionId])
      )
    );
  };

  onSelectPackage = (optionId) =>
    this.props.onChange(
      set(this.props.locationInfo, "packagePlan", {
        commissionFee:
          optionId !== "commissionOnly" ? 0 : DEFAULT_COMMISSION_FEE,
        optionId,
        optionInfo: SERVICE_PACKAGE_OPTIONS[optionId],
      })
    );

  onSelectPaymentOption = (optionId) =>
    this.props.onChange(
      set(this.props.locationInfo, "paymentPlan", {
        optionId,
        optionInfo: SERVICE_PAYMENT_OPTIONS[optionId],
      })
    );

  renderFields = ({ fields = {}, sectionId }) => {
    const { locationInfo = {}, readOnly = false } = this.props;
    const sectionInfo = locationInfo[sectionId] || {};
    return Object.keys(fields).map((fieldId) => {
      const field = fields[fieldId];
      const { label, type = "text" } = field;
      if (type === "radio") {
        const { options = {}, required } = field;
        const { optionId: selectedOptionId = "" } = sectionInfo[fieldId] || {};
        return (
          <div key={fieldId}>
            <RadioGroup
              heading={label}
              onClick={(optionId) =>
                this.onChangeInputField({
                  sectionId,
                  fieldId,
                  fieldValue: {
                    optionId,
                    optionInfo: options[optionId],
                  },
                })
              }
              optionClassName={FormStyle.option}
              options={options}
              readOnly={readOnly}
              required={required}
              selectedOptionId={selectedOptionId}
            />
          </div>
        );
      }
      return (
        <div className={FormStyle.field} key={fieldId}>
          <Input.TextInput
            {...field}
            name={label}
            onChange={(fieldValue) =>
              this.onChangeInputField({ sectionId, fieldId, fieldValue })
            }
            readOnly={readOnly}
            value={sectionInfo[fieldId] || ""}
          />
        </div>
      );
    });
  };

  renderAddOnHardware = () => {
    const { locationInfo = {}, readOnly = false } = this.props;
    const { addOnHardware = {} } = locationInfo;
    return (
      <ExpansionPanel moduleTitle="Hardware Add-Ons" showExpandableContent>
        <CheckBoxGroup
          heading="Select any hardware you need"
          onClick={this.onSelectAddOnHardware}
          options={ADDON_HARDWARE_OPTIONS}
          readOnly={readOnly}
          selectedOptionIds={Object.keys(addOnHardware)}
        />
      </ExpansionPanel>
    );
  };

  renderCommissionInput = () => {
    const { locationInfo = {}, readOnly = false } = this.props;
    const { packagePlan = {} } = locationInfo;
    const { commissionFee } = packagePlan;
    return (
      <div className={FormStyle.commissionInput}>
        <div style={{ marginRight: "2rem" }}>Commission Fee (%)</div>
        <QuantityCalculator
          currentQuantity={commissionFee}
          disableMinusButton={readOnly}
          disablePlusButton={readOnly}
          onButtonClick={(increment) => {
            if (!readOnly)
              this.onChangeCommissionFee({ commissionFee, increment });
          }}
        />
      </div>
    );
  };

  renderInputForm = () =>
    Object.keys(LOCATION_INFO).map((sectionId) => {
      const { label, fields = {} } = LOCATION_INFO[sectionId];
      return (
        <div key={sectionId}>
          <ExpansionPanel moduleTitle={label} showExpandableContent>
            <div className={FormStyle.form}>
              {this.renderFields({ fields, sectionId })}
            </div>
          </ExpansionPanel>
        </div>
      );
    });

  renderNotice = () => (
    <p className={FormStyle.notice}>
      Please enter your bank account information for direct deposit. This is a
      secure form. Bank account details are sent and stored securely.
    </p>
  );

  renderRemoveLocationButton = () =>
    this.props.locationNumber > 1 && (
      <Button
        color="danger"
        name="remove location"
        onClick={() => this.props.onRemove(this.props.locationId)}
      >
        Remove Location
      </Button>
    );

  renderSelectServicePackage = () => {
    const { locationInfo = {}, readOnly = false } = this.props;
    const { packagePlan = {} } = locationInfo;
    const { optionId = "" } = packagePlan;
    return (
      <ExpansionPanel moduleTitle="Features Package" showExpandableContent>
        <RadioGroup
          heading="Select your preferred package"
          onClick={this.onSelectPackage}
          optionClassName={FormStyle.option}
          options={SERVICE_PACKAGE_OPTIONS}
          readOnly={readOnly}
          required
          selectedOptionId={optionId}
        />
      </ExpansionPanel>
    );
  };

  renderSelectServicePaymentPlan = () => {
    const { locationInfo = {}, readOnly = false } = this.props;
    const { paymentPlan = {} } = locationInfo;
    const { optionId = "" } = paymentPlan;
    return (
      <ExpansionPanel moduleTitle="Payment Plan" showExpandableContent>
        <RadioGroup
          heading="Select your preferred payment plan"
          onClick={this.onSelectPaymentOption}
          options={SERVICE_PAYMENT_OPTIONS}
          readOnly={readOnly}
          required
          selectedOptionId={optionId}
        />
      </ExpansionPanel>
    );
  };

  render() {
    const { locationInfo = {} } = this.props;
    const { packagePlan = {} } = locationInfo;
    const isCommissionOnly = packagePlan.optionId === "commissionOnly";
    return (
      <Box
        class_names={{ header: FormStyle.box_header }}
        header="Business Information"
      >
        {this.renderNotice()}
        {this.renderInputForm()}
        {this.renderSelectServicePackage()}
        {isCommissionOnly && this.renderCommissionInput()}
        {!isCommissionOnly && this.renderSelectServicePaymentPlan()}
        {/* {this.renderAddOnHardware()} */}
        {this.renderRemoveLocationButton()}
      </Box>
    );
  }
}

Location.propTypes = {
  locationId: PropTypes.string.isRequired,
  locationInfo: PropTypes.shape({
    basicInfo: PropTypes.object,
    legalInfo: PropTypes.object,
    billingInfo: PropTypes.object,
  }),
  locationNumber: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

Location.defaultProps = {
  readOnly: false,
};

export default Location;
