// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import FormStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { OWNER_INFO_FIELDS } from "components/NewMerchantAccountModules/AgreementForm/HelperFunctions";

// Fields
import { Box, ExpansionPanel, Input } from "fields";

class ContactInfo extends React.Component {
  onInputChange = ({ fieldId, fieldValue }) =>
    this.props.onChange(set(this.props.ownerInfo, fieldId, fieldValue));

  renderFields = () => {
    const { ownerInfo = {}, readOnly = false } = this.props;
    const fields = OWNER_INFO_FIELDS({ ownerInfo });
    return Object.keys(fields).map((fieldId) => {
      const field = fields[fieldId];
      const { label } = field;
      return (
        <div className={FormStyle.field} key={fieldId}>
          <Input.TextInput
            {...field}
            name={label}
            onChange={(value) =>
              this.onInputChange({ fieldId, fieldValue: value })
            }
            readOnly={readOnly}
            value={ownerInfo[fieldId] || ""}
          />
        </div>
      );
    });
  };

  renderPaymentPlanOptions = () => (
    <div className={Style.paymentPlanOptions}>
      <p>
        <strong>Monthly Plan:</strong> Billed monthly. Your setup fee and first
        month's service payment will be processed within 48 hours, and your
        monthly recurring billing will begin 30 days after submitting this form.
      </p>
      <p>
        <strong>Annual Plan:</strong> 12 months paid up-front. Your payment will
        be processed within 48 hours, and your 12-month term will begin 30 days
        after submitting this form.
      </p>
      <p>
        <strong>Two-Year Plan:</strong> 24 months paid up-front. Your payment
        will be processed within 48 hours, and your 24-month term will begin 30
        days after submitting this form.
      </p>
    </div>
  );

  renderPurposeOfForm = () => (
    <div className={Style.purposeOfForm}>
      <h2>Purpose of Form</h2>
      <p>
        An individual or entity (Form W-9 requester) who is required to file an
        information return with the IRS must obtain your correct taxpayer
        identification number (TIN) which may be your social security number
        (SSN), individual taxpayer identification number (ITIN), adoption
        taxpayer identification number (ATIN), or employer identification number
        (EIN), to report on an information return the amount paid to you, or
        other amount reportable on an information return.
      </p>
    </div>
  );

  renderServicePackages = () => (
    <div className={Style.paymentPlanOptions}>
      <p>
        <strong>Plus:</strong> Get paid weekly. Zero commission fee for paid
        delivery and takeout orders. And more.
      </p>
      <p>
        <strong>Premium:</strong> All features of Business Plus. Get paid daily.
        Send orders to bluetooth + wifi printers. And more.
      </p>
      <p>
        <strong>Unlimited:</strong> All features of Business Premium. Combine
        all 3rd parties into one easily managable tablet. Customer data and
        more.
      </p>
    </div>
  );

  render() {
    return (
      <Box
        class_names={{ header: FormStyle.box_header }}
        header="Tax Information"
      >
        {this.renderPurposeOfForm()}
        <ExpansionPanel
          moduleTitle="Business Presentative"
          showExpandableContent
        >
          <div className={FormStyle.form}>{this.renderFields()}</div>
        </ExpansionPanel>
        {/* <ExpansionPanel moduleTitle="Service Packages" showExpandableContent>
          {this.renderServicePackages()}
        </ExpansionPanel> */}
        {/* <ExpansionPanel
          moduleTitle="Service Payment Plan"
          showExpandableContent
        >
          {this.renderPaymentPlanOptions()}
        </ExpansionPanel> */}
      </Box>
    );
  }
}

ContactInfo.propTypes = {
  onChange: PropTypes.func.isRequired,
  ownerInfo: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
};

ContactInfo.defaultProps = {
  readOnly: false,
};

export default ContactInfo;
