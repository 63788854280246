// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import { withRouter } from "react-router-dom";

// Utils
import { _scrollTo } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Button, PageMsg } from "fields";

// Icons
import { SendEmailIcon } from "assets/Icons";

class SubmittedFormSuccess extends React.Component {
  refs = {};

  componentDidMount = () => _scrollTo({ ref: this.refs["topOfForm"] });

  onReviewAgreement = () => {
    const { submittedFormId = "" } = this.props;
    const { pathname = "" } = this.props.location;
    if (!pathname.includes(submittedFormId)) {
      this.props.history.push(
        `${
          pathname[pathname.length - 1] === "/"
            ? pathname.slice(0, -1)
            : pathname
        }/${submittedFormId}`
      );
    }
    window.location.reload();
  };

  render() {
    const { submittedFormId } = this.props;
    return (
      <div ref={(ref) => (this.refs = set(this.refs, "topOfForm", ref))}>
        <PageMsg>
          <img
            alt=""
            className={Style.img}
            src="https://i.imgur.com/YJ9dEmM.png"
          />
          <h2>Congratulations!</h2>
          <p style={{ marginBottom: "2rem" }}>
            We received your application and sent a confirmation via email.
            <br />
            Our staff will reach out to you soon.
          </p>
          <Box className={Style.box}>
            <h3>Your Form Number</h3>
            <p>{submittedFormId}</p>
          </Box>
          <Button
            className={Style.button}
            hasShadow
            name="review agreement"
            onClick={this.onReviewAgreement}
          >
            Review Agreement
          </Button>
          <Button
            className={Style.button}
            color="white"
            hasShadow
            name="try again"
            onClick={() =>
              this.props.onEmailFormToMerchant({ formId: submittedFormId })
            }
          >
            <SendEmailIcon className={Style.sendEmailIcon} />
            Email Me Again
          </Button>
        </PageMsg>
      </div>
    );
  }
}

SubmittedFormSuccess.propTypes = {
  onEmailFormToMerchant: PropTypes.func.isRequired,
  submittedFormId: PropTypes.string.isRequired,
};

export default withRouter(SubmittedFormSuccess);
