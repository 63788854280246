// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { useHistory } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, PageMsg } from "fields";

// Icons
import { EmbarrassedDinosaurIcon } from "assets/Icons";

const FailedToFindForm = () => {
  const history = useHistory();

  return (
    <div>
      <PageMsg>
        <EmbarrassedDinosaurIcon className={Style.icon} />
        <h2>Sorry, no form found!</h2>
        <p style={{ marginBottom: "2rem" }}>
          The form you searched was unfortunately not found or doesn't exist.
        </p>
        <Button
          className={Style.button}
          color="white"
          hasShadow
          name="try again"
          onClick={() => window.location.reload()}
        >
          Try Again
        </Button>
        <Button
          className={Style.button}
          hasShadow
          name="create new form"
          onClick={() => {
            history.push("/new-merchant-account");
            window.location.reload();
          }}
        >
          Create New Form
        </Button>
      </PageMsg>
    </div>
  );
};

export default FailedToFindForm;
