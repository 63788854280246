// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Components
import { Printer } from "components";

// Fields
import { Button, Input, Tabs } from "fields";

const SEARCH_OPTIONS = {
  searchAll: { label: "Nearby Printers" },
  searchOne: { label: "Search Printer" },
};

class SearchPrinters extends React.Component {
  state = {
    allPrinters: {},
    searchTerm: "",
    selectedSearchOptionID: "searchAll",
  };

  componentDidMount = () => this.onSearchAllPrinters();

  onGetSearchBtnStatus = () => {
    if (this.state.isFindingOnePrinter) return "loading";
    return this.state.searchTerm ? "active" : "inactive";
  };

  onSearchAllPrinters = () => {
    this.setState({ isFindingAllPrinters: true });
    window.qz.printers
      .find()
      .then((data = []) =>
        this.setState({
          allPrinters: data.reduce(
            (result, printerName) => immutable.set(result, printerName, ""),
            {}
          ),
        })
      )
      .catch(() => this.setState({ allPrinters: {} }))
      .finally(() => this.setState({ isFindingAllPrinters: false }));
  };

  onSearchOnePrinter = () => {
    this.setState({ isFindingOnePrinter: true });
    window.qz.printers
      .find(this.state.searchTerm)
      .then((printerName) =>
        this.setState({ searchResultPrinterName: printerName })
      )
      .catch(() => this.setState({ searchResultPrinterName: "notFound" }))
      .finally(() => this.setState({ isFindingOnePrinter: false }));
  };

  renderAllPrinters = () => {
    const { allPrinters = {} } = this.state;
    const printerNames = Object.keys(allPrinters);
    if (printerNames.length === 0) return <p>No printer found</p>;
    return printerNames.map((name) => (
      <div className={Style.printerContainer} key={name}>
        <Printer
          isAddedToShop={false}
          onAddPrinter={this.props.onAddPrinter}
          printerName={name}
        />
      </div>
    ));
  };

  renderSearchAllPrinters = () => (
    <React.Fragment>
      {this.state.isFindingAllPrinters ? (
        <p>Finding local printers...</p>
      ) : (
        <div className={Style.printers}>{this.renderAllPrinters()}</div>
      )}
    </React.Fragment>
  );

  renderSearchOnePrinter = () => (
    <div>
      <div className={Style.printerNameInput}>
        <Input.TextInput
          id="Printer Name"
          name="Printer Name"
          label="Printer Name"
          onChange={(searchTerm) => this.setState({ searchTerm })}
          value={this.state.searchTerm}
          required
        />
      </div>
      {this.renderSearchResult()}
    </div>
  );

  renderSearchResult = () => {
    const { searchResultPrinterName } = this.state;
    if (!searchResultPrinterName) return;
    else if (searchResultPrinterName === "notFound")
      return <p>No printer name "{this.state.searchTerm}" found.</p>;
    return (
      <div style={{ marginBottom: "20px" }}>
        <p>Search Result</p>
        <Printer
          isAddedToShop={false}
          onAddPrinter={this.props.onAddPrinter}
          printerName={searchResultPrinterName}
        />
      </div>
    );
  };

  render() {
    const { selectedSearchOptionID } = this.state;
    return (
      <div style={{ position: "relative" }}>
        <Tabs
          classNames={{ container: Style.tabs }}
          onClick={(id) => this.setState({ selectedSearchOptionID: id })}
          options={SEARCH_OPTIONS}
          selectedOptionId={selectedSearchOptionID}
        />
        {selectedSearchOptionID === "searchAll"
          ? this.renderSearchAllPrinters()
          : this.renderSearchOnePrinter()}
        <div className={Style.buttons}>
          {selectedSearchOptionID === "searchOne" && (
            <Button
              className={Style.searchOnePrinterBtn}
              name="Search One Printer"
              onClick={this.onSearchOnePrinter}
              status={this.onGetSearchBtnStatus()}
              loadingText="Searching"
            >
              Search
            </Button>
          )}
          <Button
            className={Style.button}
            color="dark"
            hasShadow
            name="hide search printers"
            onClick={this.props.onHideAddPrinterBox}
          >
            Hide Search
          </Button>
        </div>
      </div>
    );
  }
}

SearchPrinters.propTypes = {
  onAddPrinter: PropTypes.func.isRequired,
  onHideAddPrinterBox: PropTypes.func.isRequired,
};

export default SearchPrinters;
