// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { set } from "object-path-immutable";

import { GUEST_REVIEWS_MODULES } from "./constants";
import { _sortReviews } from "./functions";

// Utils
import { _scrollTo } from "utils";

// Style
import MerchantViewStyle from "views/style.module.scss";
import Style from "./style.module.scss";

// Components
import { GuestReviewsModules } from "components";
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { Dropdown, NotViewableOnMobile, SubNavbarContainer } from "fields";
import { Services } from "lib";

// Assets
import { ArrowIcon } from "assets/Icons";

class GuestReviews extends React.Component {
  state = {};

  onSelectDropdownOption = ({ id }) => _scrollTo({ ref: this.refs[id] });
  componentDidMount = () => this.onGetGuestReviews();

  onUpdateRefs = ({ ref_id = "", ref }) => {
    if (ref_id) this.refs = set(this.refs, ref_id, ref);
  };

  onGetGuestReviews = async () => {
    const { Merchants } = Services;
    const { GetGuestReviews } = Merchants.GetRequests;
    const { context = {} } = this.props;
    const { shop_id } = context;
    try {
      const { guest_reviews = {} } = await GetGuestReviews({ shop_id });
      this.setState({
        guest_reviews,
        sorted_review_id_list: _sortReviews({ guest_reviews }),
      });
    } catch {
      this.setState({ guest_reviews: {}, sorted_review_id_list: [] });
    }
  };

  renderDropdownOption = ({ name }) => (
    <div className={Style.dropdown_option}>
      <div>{name}</div>
      <div className={Style.option_arrow_icon}>
        <ArrowIcon />
      </div>
    </div>
  );

  renderSubNavbar = () => (
    <SubNavbarContainer>
      <div className={Style.subNavbarLeftColumn}>
        <Dropdown
          class_names={{ placeholder: Style.dropdown_placeholder }}
          modal_props={{ headerProps: { header: "Guest Reviews" } }}
          onSelectOption={this.onSelectDropdownOption}
          options={GUEST_REVIEWS_MODULES}
          placeholder="Guest Reviews"
          renderOption={this.renderDropdownOption}
        />
      </div>
    </SubNavbarContainer>
  );

  render() {
    const { guest_reviews, sorted_review_id_list } = this.state;
    const { scroll_y } = this.props;
    return (
      <div>
        <NotViewableOnMobile />
        <div className={Style.reviews_container}>
          {this.renderSubNavbar()}
          <div className={MerchantViewStyle.extraPaddingTop}>
            <div
              ref={(ref) =>
                this.onUpdateRefs({ ref_id: "review_statistic", ref })
              }
            >
              <GuestReviewsModules.ReviewLink />
            </div>
            <div
              ref={(ref) =>
                this.onUpdateRefs({ ref_id: "review_statistic", ref })
              }
            >
              <GuestReviewsModules.ReviewStatistics
                guest_reviews={guest_reviews}
                sorted_review_id_list={sorted_review_id_list}
              />
            </div>
            <div ref={(ref) => this.onUpdateRefs({ ref_id: "reviews", ref })}>
              <GuestReviewsModules.Reviews
                guest_reviews={guest_reviews}
                scroll_y={scroll_y}
                sorted_review_id_list={sorted_review_id_list}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(MerchantInterfaceConsumer)(GuestReviews);
