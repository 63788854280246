// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import _get from "lodash.get";
import cx from "classnames";

import { _countNumberOfSelectedFilters } from "./functions";

// Style
import Parent_Style from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { RadioGroup } from "components";

// Fields
import { Input, ResponsiveModal } from "fields";

// Assets
import { MagnifyingGlassIcon } from "assets/Icons";

class FilterButton extends React.Component {
  state = {};

  onGetResultsCount = () => {
    return `Results `;
  };

  onGetInputValue = ({ criteria_id, input_type }) => {
    const { selected_filter_values = {} } = this.props;
    switch (input_type) {
      case "radio":
      case "text":
        return _get(selected_filter_values, criteria_id) || "";
      default:
        return "";
    }
  };

  onUpdateSelectedFilterValues = ({ criteria_id, input_type, new_value }) => {
    const { selected_filter_values = {} } = this.props;
    switch (input_type) {
      case "radio":
      case "text":
        return this.props.onUpdateSelectedFilterValues(
          set(selected_filter_values, criteria_id, new_value)
        );
      default:
        return;
    }
  };

  renderFilterCriterias = () => {
    const { filter_criterias = {} } = this.props;
    return Object.keys(filter_criterias).map((criteria_id) => {
      let result;
      const criteria_info = filter_criterias[criteria_id];
      const { input = {} } = criteria_info;
      const { type } = input;
      if (type === "radio")
        result = this.renderRadioInput({ criteria_id, criteria_info });
      else if (type === "text")
        result = this.renderTextInput({ criteria_id, criteria_info });
      return (
        <div className={Style.filter_criteria_container} key={criteria_id}>
          {result}
        </div>
      );
    });
  };

  renderFilterModal = () => {
    const { results_count } = this.props;
    const view_filter_results_button_text = `Show ${results_count} result${
      results_count > 1 ? "s" : ""
    }`;
    return (
      <ResponsiveModal
        classNames={{
          modal: Style.modal,
          footer_cancel_button: Style.footer_cancel_button,
          footer_submit_button: Style.footer_submit_button,
        }}
        contentLabel="Filter"
        footerProps={{
          showFooter: true,
          submitButtonProps: { activeText: view_filter_results_button_text },
        }}
        headerProps={{ header: "Filter" }}
        onCloseModal={() => this.setState({ show_filter_modal: false })}
      >
        {this.renderFilterCriterias()}
      </ResponsiveModal>
    );
  };

  renderRadioInput = ({ criteria_id, criteria_info }) => {
    const { class_names = {}, input = {}, required } = criteria_info;
    const { header, options, type } = input;
    return (
      <RadioGroup
        heading={header}
        onClick={(option_id) =>
          this.onUpdateSelectedFilterValues({
            criteria_id,
            input_type: type,
            new_value: option_id,
          })
        }
        options={options}
        optionClassName={class_names.option}
        required={required}
        selectedOptionId={this.onGetInputValue({
          criteria_id,
          input_type: type,
        })}
      />
    );
  };

  renderTextInput = ({ criteria_id, criteria_info }) => {
    const { input = {}, required } = criteria_info;
    const { label, type } = input;
    return (
      <Input.TextInput
        label={label}
        name={label}
        onChange={(new_value) =>
          this.onUpdateSelectedFilterValues({
            criteria_id,
            input_type: type,
            new_value,
          })
        }
        required={required}
        value={this.onGetInputValue({
          criteria_id,
          input_type: type,
        })}
      />
    );
  };

  render() {
    const selected_filters_count = _countNumberOfSelectedFilters({
      selected_filter_values: this.props.selected_filter_values,
    });
    return (
      <React.Fragment>
        {this.state.show_filter_modal && this.renderFilterModal()}
        <button
          className={cx(
            Parent_Style.button,
            selected_filters_count > 0 && Style.filters_applied
          )}
          onClick={() => this.setState({ show_filter_modal: true })}
        >
          <div
            className={cx(
              Style.filter_icon,
              selected_filters_count > 0 && Style.filters_applied
            )}
          >
            <MagnifyingGlassIcon />
          </div>
          <div className={Parent_Style.label_filter}>Filter</div>
          {selected_filters_count > 0 && (
            <div className={Style.filters_count}>{selected_filters_count}</div>
          )}
        </button>
      </React.Fragment>
    );
  }
}

FilterButton.propTypes = {
  filter_criterias: PropTypes.object,
  onUpdateSelectedFilterValues: PropTypes.func.isRequired,
  results_count: PropTypes.number.isRequired,
  selected_filter_values: PropTypes.object,
};

export default FilterButton;
