// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import ModuleStyle from "../style.module.scss";

// Components
import { MenusListOfElements } from "components";

// Fields
import { Box, Button } from "fields";

// Icons
import { CloseIcon, PlusIcon } from "assets/Icons";

// Lib
import { Functions } from "lib";

class AddedItemsModule extends React.Component {
  state = { fullyLoadedItems: {} };

  componentDidMount = () => this.onGetInfoForAddedItems(this.props.items);

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    const oldItemsCount = Object.keys(prevProps.items).length;
    const newItemsCount = Object.keys(items).length;
    if (newItemsCount > oldItemsCount) this.onGetInfoForAddedItems(items);
    else if (newItemsCount < oldItemsCount)
      this.setState({
        fullyLoadedItems: _pick(
          this.state.fullyLoadedItems,
          Object.keys(items)
        ),
      });
  }

  onGetInfoForAddedItems = async (items) => {
    this.setState({ isLoadingInfo: true });
    try {
      const { GetInformationForItems } = Functions.FoodMenuFuncs;
      const params = { items, shopID: this.props.context.shopID };
      const fullyLoadedItems = await GetInformationForItems(params);
      this.setState({ fullyLoadedItems });
    } catch {
      this.setState({ fullyLoadedItems: {} });
    } finally {
      this.setState({ isLoadingInfo: false });
    }
  };

  renderAddedItems = () => {
    const { allowChangeItemPhoto, classNames = {} } = this.props;
    const { fullyLoadedItems, isLoadingInfo = false } = this.state;
    if (isLoadingInfo) return <p>Finding items...</p>;
    return (
      Object.keys(fullyLoadedItems).length > 0 && (
        <MenusListOfElements.ListOfMenuItems
          classNames={{ itemContainer: classNames.itemContainer }}
          itemHelperButton={(itemID) => (
            <CloseIcon
              className={ModuleStyle.removeButton}
              onClick={() => this.props.onRemoveItem(itemID)}
            />
          )}
          itemProps={{ allowChangeItemPhoto, isInEditMode: true }}
          items={fullyLoadedItems}
        />
      )
    );
  };

  render() {
    const { items = {} } = this.props;
    return (
      <Box
        description="Add item to a category to allow customers to add and purchase items"
        header={`Items (${Object.keys(items).length})`}
      >
        <Button
          className={ModuleStyle.addButton}
          color="info"
          name="add items"
          onClick={this.props.onClickAddButton}
        >
          <PlusIcon />
          Add Items
        </Button>
        {this.renderAddedItems()}
      </Box>
    );
  }
}

AddedItemsModule.propTypes = {
  allowChangeItemPhoto: PropTypes.bool,
  classNames: PropTypes.exact({
    itemContainer: PropTypes.string,
  }),
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  isExpandable: PropTypes.bool,
  items: PropTypes.object,
  onClickAddButton: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

AddedItemsModule.defaultProps = {
  allowChangeItemPhoto: true,
  classNames: {},
  isExpandable: true,
  items: {},
  onClickAddButton: () => {},
  onRemoveItem: () => {},
};

export default withContext(MerchantInterfaceConsumer)(AddedItemsModule);
