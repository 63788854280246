// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

const COMBINE_ORDERS_IMG_URL = "https://i.imgur.com/m7vP3lw.png";

const HOW_IT_WORKS = [
  {
    description: "Menu and hours import from your DoorDash listing on our app",
    img_url: "https://i.imgur.com/2UecJDw.png",
  },
  {
    description: "Receive orders the same way you currently do for Skipli",
    img_url: "https://i.imgur.com/2UecJDw.png",
  },
  {
    description: "DoorDash, Postmates, or Uber Eats complete your deliveries",
    img_url: "https://i.imgur.com/2UecJDw.png",
  },
  {
    description: "Payments sent to you by the third-party on your set schedule",
    img_url: "https://i.imgur.com/2UecJDw.png",
  },
];

class WelcomePage extends React.Component {
  renderHeroImage = () => (
    <div className={Style.heroImageContainer}>
      <div className={Style.heroImageContent}>
        <div className={Style.heroImageText}>
          <h1>Combine third-party orders into one tablet.</h1>
          <p>
            Set up your branded online ordering store in minutes and enable
            pickup and delivery directly from your website.
          </p>
          <Button
            className={Style.getStartedButton}
            hasShadow
            name="get started"
            onClick={() => this.props.onChangePage({ pageId: "setup" })}
          >
            Get Started
          </Button>
        </div>
        <img alt="" className={Style.heroImg} src={COMBINE_ORDERS_IMG_URL} />
      </div>
    </div>
  );

  renderHowItWorks = () => {
    return (
      <div style={{ background: "#fff" }}>
        <div className={Style.howItWorksContainer}>
          <h2>How does Combine Orders work?</h2>
          <p>
            No training is required to use Combine Orders. <br />
            You can set up in minutes to begin combining orders today.
          </p>
          <div>
            {HOW_IT_WORKS.map((item, index) => {
              const { description, img_url } = item;
              return (
                <div className={Style.howItWorksItem} key={index}>
                  <img alt="" src={img_url} />
                  <p>{description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderHeroImage()}
        {this.renderHowItWorks()}
      </div>
    );
  }
}

WelcomePage.propTypes = {
  onChangePage: PropTypes.func.isRequired,
};

export default WelcomePage;
