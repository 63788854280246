// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import dayjs from "dayjs";

import { _createEmailBody, _sortSpecialHours,_dayjs } from "./functions";

import SpecialHoursEditModal from "./SpecialHoursEditModal";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

class SpecialHours extends React.Component {
  state = {};
  
  componentDidMount() {
    this.handleGetTodayDay();
  }

  handleGetTodayDay = async () => {
    const { DateTime } = Functions;
    const { GetTodayDay } = DateTime;
    const { date: today_date = "" } = await GetTodayDay();
    this.setState({ today_date });
  };

  onNotifySkipliSupport = async ({ special_hours }) => {
    const { BUTI } = Services;
    const { SendEmail } = BUTI.PostRequests;

    const { shopBasicInfo, shopID } = this.props.context;
    const { name } = shopBasicInfo;
    const body = await _createEmailBody({ special_hours, shop_id: shopID });
    await SendEmail({
      addresses: ["accounts@skiplinow.com", "support@skiplinow.com"],
      body,
      subject: `${name} updated special hours`,
    });
  };

  onSaveNewSpecialHours = async ({ new_special_hours: special_hours = {} }) => {
    const { Merchants } = Services;
    const { ShowConfirmNotif } = Functions;
    const { SaveSpecialHours } = Merchants.PostRequests;
    const { onUpdateShopBasicInfo, shopBasicInfo, shopID } = this.props.context;
    try {
      await SaveSpecialHours({ shop_id: shopID, special_hours });
      ShowConfirmNotif({ message: "Updated special hours", type: "success" });
      onUpdateShopBasicInfo(set(shopBasicInfo, "special_hours", special_hours));
      // await this.onNotifySkipliSupport({ special_hours });
    } catch {
      ShowConfirmNotif({
        message: "Failed to update open hours. Try again or refresh page.",
        type: "error",
      });
    }
  };

  renderContent = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { today_date = "" } = this.state;
    const { special_hours = {} } = shopBasicInfo;
  
    if (Object.keys(special_hours).length === 0) return;

    return _sortSpecialHours({ special_hours }).map((id) => {
      const { date, end_at, is_closed = true, start_at } = special_hours[id];
      
      if (_dayjs(date).isBefore(_dayjs(today_date), "day")) return null;

      return (
        <div className={Style.special_hour_container} key={id}>
          <div className={Style.special_hour_date}>{date}</div>
          <div>
            {is_closed
              ? "Closed"
              : this.renderTimePickers({ end_at, start_at })}
          </div>
        </div>
      );
    });
  };

  renderModuleTitle = () => (
    <div className={CommonStyle.module_title}>
      Special Hours
      <div className={CommonStyle.module_title_icon}>
        <PencilIcon />
      </div>
    </div>
  );

  renderSpecialHoursEditModal = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { special_hours = {} } = shopBasicInfo;
    const { today_date = "" } = this.state;
    return (
      <SpecialHoursEditModal
        onCloseModal={() =>
          this.setState({ show_special_hours_edit_modal: false })
        }
        onSaveNewSpecialHours={this.onSaveNewSpecialHours}
        special_hours={special_hours}
        today={today_date}
      />
    );
  };

  renderTimePickers = ({ end_at, start_at }) => (
    <div className={Style.time_pickers_group}>
      <div>{dayjs("1/1/1 " + start_at).format("h:mm A")}</div>
      <div style={{ margin: "0 4px" }}>-</div>
      <div>{dayjs("1/1/1 " + end_at).format("h:mm A")}</div>
    </div>
  );

  render() {
    return (
      <>
        {this.state.show_special_hours_edit_modal &&
          this.renderSpecialHoursEditModal()}
        <div
          onClick={() => this.setState({ show_special_hours_edit_modal: true })}
          style={{ cursor: "pointer" }}
        >
          <div>{this.renderContent()}</div>
          <Button
            className={Style.edit_special_hours_button}
            name="edit special hours"
          >
            <PencilIcon /> Edit special hours
          </Button>
        </div>
      </>
    );
  }
}

SpecialHours.propTypes = {
  context: PropTypes.shape({
    onUpdateShopBasicInfo: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.shape({
      special_hours: PropTypes.object,
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(SpecialHours);
