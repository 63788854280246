// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import _isEmpty from "lodash.isempty";
import cx from "classnames";

import {
  DEFAULT_FILTER_VALUES,
  FILTER_CATEGORIES_CRITERIAS,
} from "./constants";

import AddButton from "../AddButton";
import FilterButton from "../FilterButton";
import { _filter } from "../functions";

// Style
import Parent_Style from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { MenusListOfElements, Modals } from "components";

// Fields
import { LoadingSpinner, PageMsg } from "fields";

// Assets
import { SortIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

const { SortMenuCategories } = Functions.FoodMenuFuncs;
const { Merchants } = Services;
const { GetShopAllGroups } = Merchants.GetRequests;
const { SaveNewAllGroups } = Merchants.PostRequests;

class AllGroups extends Component {
  state = { allGroups: {}, selected_filter_values: DEFAULT_FILTER_VALUES };

  componentDidMount = () =>
    this.setState({ isGettingAllGroups: true }, this.onGetAllGroups);

  componentWillUnmount = () => this.setState({ isGettingAllGroups: false });

  onGetAllGroups = async () => {
    const { allGroups = {} } = await GetShopAllGroups({
      shopID: this.props.shopID,
    });
    this.setState(
      { allGroups, isGettingAllGroups: false },
      this.onUpdateFilterResults
    );
  };

  onSaveSortResult = async (sortedCategories) => {
    try {
      await SaveNewAllGroups({
        allGroups: sortedCategories,
        shopID: this.props.shopID,
      });
      this.setState(
        { allGroups: sortedCategories },
        this.onUpdateFilterResults
      );
    } catch (e) {
      throw e;
    }
  };

  onUpdateAllCategories = ({ category_id = "", category_info = {} }) => {
    const { allGroups = {} } = this.state;
    if (!category_id) return;
    else if (_isEmpty(category_info))
      this.setState(
        { allGroups: del(allGroups, category_id) },
        this.onUpdateFilterResults
      );
    else {
      this.setState(
        { allGroups: set(allGroups, category_id, category_info) },
        this.onUpdateFilterResults
      );
    }
  };

  onUpdateSelectedFilterValues = (selected_filter_values) =>
    this.setState({ selected_filter_values }, this.onUpdateFilterResults);

  onUpdateFilterResults = () =>
    this.setState({
      filter_results: _filter({
        list: this.state.allGroups,
        filter_criterias: FILTER_CATEGORIES_CRITERIAS,
        selected_filter_values: this.state.selected_filter_values,
      }),
    });

  renderCategories = () => {
    const { filter_results = {} } = this.state;
    return (
      <MenusListOfElements.ListOfMenuGroups
        groupProps={{ isInEditMode: true }}
        groups={filter_results}
        onUpdateAllCategories={this.onUpdateAllCategories}
        sortedGroupIDs={SortMenuCategories(filter_results)}
      />
    );
  };

  renderCreateModal = () => (
    <Modals.MenuGroupModal
      onCloseModal={() => this.setState({ showCreateGroupModal: false })}
      onUpdateAfterGroupCreated={this.onUpdateAllCategories}
    />
  );

  renderContent = () => {
    const { allGroups = {}, filter_results = {} } = this.state;
    const { scroll_y } = this.props;
    const count = Object.keys(filter_results).length;

    return (
      <div>
        <div
          className={cx(Parent_Style.headingContainer, {
            [Parent_Style.headingContainerEnd]: scroll_y > 50,
          })}
        >
          <h2 className={Parent_Style.heading}>
            {count} {count > 1 ? "Categories" : "Category"}
          </h2>
          <div
            className={cx(Parent_Style.buttons_group, {
              [Parent_Style.buttons_group_update]: scroll_y > 50,
            })}
          >
            <AddButton
              onClick={() => this.setState({ showCreateGroupModal: true })}
            />
            {Object.keys(allGroups).length > 0 && (
              <button
                className={Parent_Style.button}
                name="sort categories"
                onClick={() => this.setState({ showSortCategoriesModal: true })}
              >
                <div
                  className={cx(Parent_Style.button_icon, Style.button_icon)}
                >
                  <SortIcon />
                </div>
                <div className={Parent_Style.button_label}>Sort</div>
              </button>
            )}
            <FilterButton
              filter_criterias={FILTER_CATEGORIES_CRITERIAS}
              onUpdateSelectedFilterValues={this.onUpdateSelectedFilterValues}
              results_count={count}
              selected_filter_values={this.state.selected_filter_values}
            />
          </div>
        </div>
        {this.state.showCreateGroupModal && this.renderCreateModal()}
        {this.renderCategories()}
      </div>
    );
  };

  renderSortCategoriesModal = () => {
    const { allGroups = {} } = this.state;
    return (
      <Modals.SortCategoriesModal
        categories={allGroups}
        onCloseModal={() => this.setState({ showSortCategoriesModal: false })}
        onSaveSortResult={this.onSaveSortResult}
      />
    );
  };

  render() {
    if (this.state.isGettingAllGroups)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for categories" />
        </PageMsg>
      );
    return (
      <React.Fragment>
        {this.state.showSortCategoriesModal && this.renderSortCategoriesModal()}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

AllGroups.propTypes = {
  shopID: PropTypes.string.isRequired,
};

export default AllGroups;
