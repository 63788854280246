// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import Style from "./style.module.scss";
import cx from "classnames";

class DropdownSelect extends Component {
  state = {
    showOptions: false,
    showSubOptions: false,
    selectedOption: "",
  };

  toggleOptions = () => {
    this.setState((prevState) => ({
      showOptions: !prevState.showOptions,
      showSubOptions: false,
    }));
  };
  handleSubOptionClick = (subOption) => {
    const { onSortChange } = this.props;

    if (subOption === "Lowest" || subOption === "Highest") {
      onSortChange(subOption === "Lowest", "rating");
    } else if (subOption === "Oldest" || subOption === "Newest") {
      onSortChange(subOption === "Oldest", "date");
    }
    this.setState({
      selectedSubOption: subOption,
      showSubOptions: false,
      showOptions: false,
    });
  };

  toggleSubOptions = () => {
    this.setState((prevState) => ({
      showSubOptions: !prevState.showSubOptions,
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      showOptions: false,
      showSubOptions: true,
    });
  };
  render() {
    const { options, title, className } = this.props;

    return (
      <div>
        <button className={Style.dropdownButton} onClick={this.toggleOptions}>
          {title}
        </button>
        {this.state.showOptions && (
          <div className={Style.optionsContainer}>
            {options.map((option, index) => (
              <div
                key={index}
                className={cx(Style.option, className)}
                onClick={() => this.handleOptionClick(option)}
              >
                {option.title}
              </div>
            ))}
          </div>
        )}

        {this.state.showSubOptions && this.state.selectedOption && (
          <div className={Style.optionsContainer}>
            {this.state.selectedOption.subOptions.map((subOption, index) => (
              <div
                key={index}
                className={cx(Style.option, className)}
                onClick={() => this.handleSubOptionClick(subOption)}
              >
                {subOption}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default DropdownSelect;
