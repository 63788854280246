// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";
import _get from "lodash.get";

import {
  _getSubmitBtnStatus,
  _validateInputValue,
  _vetModifierInfoBeforeSubmit,
} from "./HelperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Utils
import { _removeAccents } from "utils";

// Style
import FieldStyle from "components/Modals/style.module.scss";

// Fields
import { Button, Input } from "fields";

// Lib
import { Constants } from "lib";

class ModifierFormFields extends Component {
  state = { inputErrors: {}, modifierInfo: {} };

  componentDidMount = () =>
    this.setState({ modifierInfo: this.props.modifierInfo });

  onChangeTextInputValue = (fieldID, value) =>
    this.setState(
      {
        modifierInfo: immutable.set(
          this.state.modifierInfo,
          fieldID,
          _removeAccents({ text: value })
        ),
      },
      () =>
        this.setState({
          inputErrors: _validateInputValue({
            inputErrors: this.state.inputErrors,
            fieldID,
            value: _removeAccents({ text: value }),
          }),
        })
    );

  onGetReadOnlyStatus = (fieldID) => {
    if (this.props.isReadOnly) return true;
    else if (this.props.isInEditMode) {
      const role = _get(this.props.context, "personnel.role");
      if (role === "owner") return false;
      else {
        if (["modifierName", "modifierPrice"].includes(fieldID)) return true;
        return false;
      }
    }
    return false;
  };

  onSubmitForm = () => {
    this.props.onSubmit(
      _vetModifierInfoBeforeSubmit({ modifierInfo: this.state.modifierInfo })
    );
  };

  renderField = (field = {}) => {
    const { inputErrors = {}, modifierInfo = {} } = this.state;
    const { fieldKind, id } = field;
    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div className={FieldStyle.formField} key={id}>
            <Input.TextInput
              {...field}
              errortext={inputErrors[id]}
              hasMultipleLines={fieldKind === "textarea" ? true : false}
              name={id}
              onChange={(value) => this.onChangeTextInputValue(id, value)}
              readOnly={this.onGetReadOnlyStatus(id)}
              showError
              value={modifierInfo[id] || ""}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderSubmitButton = () => {
    const submitBtnLabel = this.props.isInEditMode ? "Save" : "Create";
    return (
      <Button
        className={FieldStyle.saveButton}
        color="info"
        name={`${submitBtnLabel} modifier`}
        onClick={this.onSubmitForm}
        status={_getSubmitBtnStatus(this.state)}
        type="submit"
      >
        {submitBtnLabel}
      </Button>
    );
  };

  render() {
    const { NEW_MODIFIER_FIELDS } = Constants;
    return (
      <div>
        {NEW_MODIFIER_FIELDS.map(this.renderField)}
        {!this.props.isReadOnly && this.renderSubmitButton()}
      </div>
    );
  }
}

ModifierFormFields.propTypes = {
  context: PropTypes.shape({
    personnel: PropTypes.object.isRequired,
  }).isRequired,
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  modifierID: PropTypes.string,
  modifierInfo: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

ModifierFormFields.defaultProps = {
  isInEditMode: false,
  isReadOnly: false,
  modifierInfo: {},
};

export default withContext(MerchantInterfaceConsumer)(ModifierFormFields);
