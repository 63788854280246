// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import dayjs from "dayjs";

// Style
import Style from "./style.module.scss";

var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const _convertDate = ({ date, timeZone }) =>
  dayjs
    .unix(date)
    .tz(timeZone)
    .format("MMM-DD-YYYY");

const _createTransactionsTableColumns = () => [
  {
    accessor: "type",
    header: "Type",
  },
  {
    accessor: "net",
    header: "Net",
  },
  {
    accessor: "currency",
    header: "Currency",
  },
  {
    accessor: "created",
    header: "Created",
  },
];

const _sanitizeDataForTable = ({
  data = [],
  timeZone = "America/New_York",
}) => {
  return data.map((infoObject = {}) => {
    const { created, currency, net = 0, type } = infoObject;
    return {
      ...infoObject,
      created: _convertDate({ date: created, timeZone }),
      currency: <div className={Style.currency}>{currency}</div>,
      net: <div>${parseFloat(`${net}e-2`).toFixed(2)}</div>,
      type: (
        <div className={Style.transactionType}>{type.split("_").join(" ")}</div>
      ),
    };
  });
};

//More information about reporting categories can be found here
// https://stripe.com/docs/reports/reporting-categories

const _sanitizeTransactions = (transactions = []) =>
  transactions.filter(({ type }) => type !== "payout");

export {
  _createTransactionsTableColumns,
  _sanitizeDataForTable,
  _sanitizeTransactions,
};
