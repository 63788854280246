// BUTI DINERS, INC. All right Reserved ©

const ADDON_HARDWARE_OPTIONS = {
  lteTablet: {
    description:
      "Select this add-on if this location does not have wifi, or if the wifi connection is unreliable",
    label: "LTE-Enabled Tablet ($300 + $20/month)",
  },
  regularTablet: {
    description: "Select this add-on if this location does have reliable wifi",
    label: "Wifi Tablet ($300)",
  },
  wirelessPrinter: {
    description:
      "Select this add-on if this location processes a high volume of to-go orders",
    label: "Wireless Receipt Printer ($400)",
  },
};

const SERVICE_PACKAGE_OPTIONS = {
  basic: { label: "Plus" },
  unlimited: { label: "Premium" },
  vip: { label: "Unlimited" },
  commissionOnly: { label: "Commission Only" },
};

const SERVICE_PAYMENT_OPTIONS = {
  monthly: { label: "Monthly Plan" },
  annual: { label: "Annual Plan" },
  twoYear: { label: "Two-Year Plan" },
};

export {
  ADDON_HARDWARE_OPTIONS,
  SERVICE_PACKAGE_OPTIONS,
  SERVICE_PAYMENT_OPTIONS,
};
