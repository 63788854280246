// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import Style from "./style.module.scss";
import WebsiteBuilderEditStyle from "../style.module.scss";

// Fields
import { Box, ColorPicker, ResponsiveModal } from "fields";

const GENERAL_FIELDS = {
  backgroundColor: {
    heading: "Background Color",
  },
  textColor: {
    heading: "Text Color",
  },
  textHoverColor: {
    heading: "Text Hover Color",
  },
};

class ShopHeaderModal extends React.Component {
  onChange = ({ fieldId = "", fieldValue }) => {
    if (!fieldId) return;
    const { header = {} } = this.props;
    this.props.onChange(set(header, fieldId, fieldValue));
  };

  renderGeneralFields = () =>
    Object.keys(GENERAL_FIELDS).map((fieldId) => {
      const { header = {} } = this.props;
      const { heading } = GENERAL_FIELDS[fieldId];
      return (
        <div className={WebsiteBuilderEditStyle.fieldContainer} key={fieldId}>
          <ColorPicker
            class_names={{
              heading_container: Style.color_picker_heading_container,
            }}
            currentHexColor={header[fieldId]}
            heading={heading}
            onChange={(hexColor) =>
              this.onChange({ fieldId, fieldValue: hexColor })
            }
          />
        </div>
      );
    });

  renderModalContent = () => (
    <div className={WebsiteBuilderEditStyle.modalContent}>
      <Box header="Colors">{this.renderGeneralFields()}</Box>
    </div>
  );

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: WebsiteBuilderEditStyle.modal }}
        contentLabel="Edit website header modal"
        headerProps={{ header: "Navigation Bar" }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderModalContent()}
      </ResponsiveModal>
    );
  }
}

ShopHeaderModal.propTypes = {
  header: PropTypes.exact({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    textHoverColor: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ShopHeaderModal;
