// BUTI DINERS, INC. All right Reserved ©

import ListOfMenuGroups from "./ListOfMenuGroups";
import ListOfMenuItems from "./ListOfMenuItems";
import ListOfModifierGroups from "./ListOfModifierGroups";
import ListOfModifiers from "./ListOfModifiers";

const MenusListOfElements = {
  ListOfMenuGroups,
  ListOfMenuItems,
  ListOfModifierGroups,
  ListOfModifiers,
};

export default MenusListOfElements;
