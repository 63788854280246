// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import AverageTimeModule from "./AverageTimeModule";
import PickUpHoursModule from "./PickUpHoursModule";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { Modals } from "components";

// Style
import ViewStyle from "views/style.module.scss";

// Fields
import { Box, FloatingButton } from "fields";

// Lib
import { Functions, Services } from "lib";

class PickUp extends React.Component {
  state = { pickUpInfo: {} };

  componentDidMount() {
    const { pickUpInfo = {} } = this.props.context.shopBasicInfo;
    this.setState({ pickUpInfo });
  }

  onChangePickUpInfo = (pickUpInfo) => this.setState({ pickUpInfo });

  onSaveInfo = async () => {
    this.setState({ isLoading: true });
    const { Merchants } = Services;
    const { ShowConfirmNotif } = Functions;
    const { SaveChangedPickUpInfo } = Merchants.PostRequests;
    try {
      const {
        onUpdateShopBasicInfo,
        shopBasicInfo,
        shopID,
      } = this.props.context;
      const { pickUpInfo } = this.state;
      const { success } = await SaveChangedPickUpInfo({
        pickUpInfo,
        shopID,
      });
      this.confirmNotif = ShowConfirmNotif({
        message: success
          ? "Updated pickup settings"
          : "Failed to update pickup settings. Try again or refresh page.",
        type: success ? "success" : "error",
      });
      if (success)
        onUpdateShopBasicInfo(set(shopBasicInfo, "pickUpInfo", pickUpInfo));
    } catch {
      this.confirmNotif = ShowConfirmNotif({
        message: "Failed to update pickup settings. Try again or refresh page.",
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { openHours = {}, timeZone } = this.props.context.shopBasicInfo;
    const { pickUpInfo = {} } = this.state;
    return (
      <div>
        {this.state.isLoading && <Modals.LoadingModal message="Saving..." />}
        <Box>
          <AverageTimeModule
            onChangePickUpInfo={this.onChangePickUpInfo}
            pickUpInfo={pickUpInfo}
          />
        </Box>
        <Box>
          <PickUpHoursModule
            onChangePickUpInfo={this.onChangePickUpInfo}
            openHours={openHours}
            pickUpInfo={pickUpInfo}
            timeZone={timeZone}
          />
        </Box>
        <FloatingButton
          buttonClassname={ViewStyle.saveButton}
          onClick={this.onSaveInfo}
        >
          Save
        </FloatingButton>
      </div>
    );
  }
}

PickUp.propTypes = {
  context: PropTypes.shape({
    onUpdateShopBasicInfo: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.shape({
      pickUpInfo: PropTypes.object,
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(PickUp);
