import dayjs from "dayjs";

const _convertDate = ({ date, timeZone= "America/New_York" }) =>
  dayjs
    .unix(date)
    .add(1, "day")
    .tz(timeZone)
    .format("MMM-DD-YYYY");

const _sanitizeData = ({
  payouts_info = [],
  timeZone = "America/New_York",
}) =>
  payouts_info.map((infoObject = {}) => {
    const { amount = 0, arrival_date, created, description = "" } = infoObject;

    return {
      ...infoObject,
      amount: (parseFloat(amount) * 1e-2).toFixed(2),
      arrival_date: _convertDate({ date: arrival_date, timeZone }),
      created: _convertDate({ date: created, timeZone }),
      description: (description || "").toLowerCase(),
    };
  });

  const generateEmailContent = (name="", id="", address="", select_year="", select_month=[]) => {
    const months_string = select_month.join(", ");
    const subject = `Excel Payout Report for ${name} (ID: ${id}) - Year: ${select_year}, Month(s): ${months_string}`;
    
    const body = `
      Excel payout report for your restaurant (ID: ${id}).
      
      Restaurant Name: ${name}
      Address: ${address}
      Year: ${select_year}
      Month(s): ${months_string}
  
      The report contains detailed information on your payouts and balance transactions.
  
    `;
    
    return { subject, body };
  };
  
export { _sanitizeData ,generateEmailContent};
