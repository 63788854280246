// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import Personnel from "./Personnel";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Box } from "fields";

// Lib
import { Services } from "lib";
import { del, set } from "object-path-immutable";

const { Merchants } = Services;
const { GetShopAllPersonnels } = Merchants.GetRequests;

class PersonnelProfile extends Component {
  state = {
    showCreateUserModal: false,
  };

  componentDidMount = () => {
    const { role = "" } = this.props.personnel;
    if (role === "owner") this.onGetStaff();
  };

  onCreateUserSuccess = ({ personnelInfo, personnelPin }) =>
    this.setState({
      allPersonnels: set(this.state.allPersonnels, personnelPin, personnelInfo),
      staff: set(this.state.staff, personnelPin, personnelInfo),
    });

  onGetStaff = async () => {
    this.setState({ isFindingStaff: true });
    const { shopID } = this.props;
    const { allPersonnels } = await GetShopAllPersonnels({ shopID });
    const staff = Object.keys(allPersonnels).reduce((result, pin) => {
      const { role } = allPersonnels[pin];
      if (role !== "owner") result[pin] = allPersonnels[pin];
      return result;
    }, {});
    this.setState({ allPersonnels, isFindingStaff: false, staff });
  };

  onRemoveUserSuccess = ({ personnelPin }) =>
    this.setState({
      allPersonnels: del(this.state.allPersonnels, personnelPin),
      staff: del(this.state.staff, personnelPin),
    });

  renderAddUserSection = () => {
    return this.state.showCreateUserModal ? (
      <Modals.CreateUserModal
        allPersonnels={this.state.allPersonnels}
        onCreateUserSuccess={this.onCreateUserSuccess}
        onCloseModal={() => this.setState({ showCreateUserModal: false })}
        shopID={this.props.shopID}
      />
    ) : (
      <button
        className={Style.addUserBtn}
        name="Add New Personnel"
        onClick={() => this.setState({ showCreateUserModal: true })}
      >
        + Add User
      </button>
    );
  };

  renderPersonnelInfo = () => (
    <Box header="Your Profile">
      <Personnel
        allPersonnels={this.state.allPersonnels}
        personnelInfo={this.props.personnel}
        shopID={this.props.shopID}
        showRemoveButton={false}
      />
      <button
        className={Style.signOutBtn}
        name="sign out of personnel"
        onClick={this.props.onRemovePersonnelInfo}
      >
        Log Out
      </button>
    </Box>
  );

  renderStaffList = () => {
    const { allPersonnels = {}, staff = {} } = this.state;
    return Object.keys(staff).map((personnelPin) => (
      <div key={personnelPin} style={{ marginBottom: "25px" }}>
        <Personnel
          allPersonnels={allPersonnels}
          onRemoveUserSuccess={this.onRemoveUserSuccess}
          personnelInfo={staff[personnelPin]}
          personnelPin={personnelPin}
          shopID={this.props.shopID}
          showPersonnelPin
        />
      </div>
    ));
  };

  renderStaffModule = () => {
    const { role = "" } = this.props.personnel;
    return (
      role === "owner" && (
        <Box header="Staff">
          {this.state.isFindingStaff ? (
            "Finding your staff"
          ) : (
            <React.Fragment>
              <div>{this.renderStaffList()}</div>
              {this.renderAddUserSection()}
            </React.Fragment>
          )}
        </Box>
      )
    );
  };

  render() {
    return (
      <div>
        {this.renderPersonnelInfo()}
        {this.renderStaffModule()}
      </div>
    );
  }
}

PersonnelProfile.propTypes = {
  onRemovePersonnelInfo: PropTypes.func.isRequired,
  personnel: PropTypes.shape({
    personnelID: PropTypes.string.isRequired,
    personnelName: PropTypes.string.isRequired,
    role: PropTypes.oneOf(["owner", "staff"]),
  }).isRequired,
  shopID: PropTypes.string.isRequired,
};

export default PersonnelProfile;
