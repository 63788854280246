// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import {
  _convertDateRangeToUtc,
  FindMatchingOrders,
  SEARCH_CATEGORIES,
} from "./HelperFunctions";

// Style
import Style from "./style.module.scss";

// Components
import { Modals, Orders, ShopOrdersToolbar } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import {
  LoadingSpinner,
  PageMsg,
  SearchBar,
  SubNavbarContainer,
  ScreenSize,
} from "fields";

// Icons
import { DollarSymbolIcon, MagnifyingGlassIcon } from "assets/Icons";

// Lib
import { Services, Functions } from "lib";
import { set } from "object-path-immutable";
const { OrdersManagement } = Functions;
const { RankPastOrders } = OrdersManagement;
const { Merchants } = Services;

const { GetShopOrders } = Merchants.GetRequests;

class ShopOrders extends Component {
  state = {
    numOrdersPerRow: "1",
    pastOrders: {},
    selectedTimeRangeID: "today",
    selectedSearchCategory: "customerName",
    showSearchBar: false,
    is_close_all_order: false,
    sendAllOrderReadyText: false,
  };

  componentDidMount = () => {
    const screenWidth = window.screen.width;
    let maxNumOrdersPerRow = 1,
      numOrdersPerRow = 1;
    if (screenWidth > 1100) maxNumOrdersPerRow = 6;
    this.setState(
      { maxNumOrdersPerRow, numOrdersPerRow },
      () => this.props.orderType === "pastOrders" && this.onGetPastOrders()
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { endAt, startAt } = this.state;
    if (prevState.endAt !== endAt || prevState.startAt !== startAt)
      this.onGetPastOrders();
  };

  getOrdersType = () => {
    switch (this.props.orderType) {
      case "activeOrders":
        return "active";
      case "pastOrders":
        return "past";
      default:
        return "";
    }
  };

  handleSendAllOrderReadyTextChange = (value) => {
    this.setState({ sendAllOrderReadyText: value });
  };

  onAddRefundRequestToPastOrder = ({
    orderId = "",
    refund_request_id = "",
    request_details = {},
  }) => {
    if (
      !orderId ||
      !refund_request_id ||
      Object.keys(request_details).length === 0
    )
      return;
    this.setState({
      pastOrders: set(
        this.state.pastOrders,
        `${orderId}.refundRequests.${refund_request_id}`,
        request_details
      ),
    });
  };

  onChangeDateRange = (selectedDayRange) => {
    const timeZone = _get(this.props, "context.shopBasicInfo.timeZone");
    this.setState({ selectedDayRange }, () => {
      const { from_utc, to_utc } = _convertDateRangeToUtc({
        selectedDayRange,
        timeZone,
      });
      this.setState({
        endAt: to_utc,
        selectedTimeRangeID: "",
        startAt: from_utc,
      });
    });
  };

  onChangeNumOrdersPerRow = (numOrdersPerRow) => {
    const { maxNumOrdersPerRow } = this.state;
    let result = parseInt(numOrdersPerRow);
    if (result < 1) result = 1;
    else if (result > maxNumOrdersPerRow) result = maxNumOrdersPerRow;
    this.setState({ numOrdersPerRow: result });
  };

  onChangeSearchText = (searchText) =>
    this.setState({ searchText }, this.onFindMatchingOrders);

  onChangeCloseAllActiveOrder = () => {
    this.setState({ is_close_all_order: false });
  };

  onChangeTimeRange = ({ endAt, selectedTimeRangeID, startAt }) =>
    this.setState(
      {
        endAt,
        selectedDayRange: { from: null, to: null },
        selectedTimeRangeID,
        startAt,
      },
      this.onFindMatchingOrders
    );

  onExpandAllOrders = () =>
    this.setState({ expandAllOrders: !this.state.expandAllOrders });

  onFindMatchingOrders = () => {
    const { pastOrders = {}, searchText } = this.state;
    const { activeOrders = {} } = this.props.context;
    this.setState({
      matchingOrders: !searchText
        ? null
        : FindMatchingOrders({
            filterBy: this.state.selectedSearchCategory,
            orders:
              this.props.orderType === "activeOrders"
                ? activeOrders
                : pastOrders,
            searchText: this.state.searchText,
          }),
    });
  };

  onGetPastOrders = async () => {
    this.setState({ isFetchingOrders: true });
    const { endAt = "", startAt = "" } = this.state;
    const { orders = {} } = await GetShopOrders({
      endAt,
      ordersType: "past",
      shopID: this.props.context.shopID,
      startAt,
    }).finally(() => this.setState({ isFetchingOrders: false }));
    this.setState(
      { pastOrders: RankPastOrders(orders) },
      this.onFindMatchingOrders
    );
  };

  onGetOrdersCount = () => {
    const { matchingOrders, pastOrders = {} } = this.state;
    const { activeOrders = {} } = this.props.context;
    if (matchingOrders) return Object.keys(matchingOrders).length;
    return this.props.orderType === "pastOrders"
      ? Object.keys(pastOrders).length
      : Object.keys(activeOrders).length;
  };

  onSelectSearchCategory = (selectedSearchCategory) =>
    this.setState({ selectedSearchCategory }, () =>
      this.onChangeSearchText(this.state.searchText)
    );

  renderContent = () => {
    const { orderType } = this.props;
    const { matchingOrders } = this.state;
    const ordersCount = this.onGetOrdersCount();
    let ordersList;
    if (matchingOrders) ordersList = matchingOrders;
    else
      ordersList =
        this.props.orderType === "activeOrders"
          ? this.props.context.activeOrders
          : this.state.pastOrders;

    const confirmed_orders = Object.keys(ordersList).filter(
      (orderID) => ordersList[orderID].status === "confirmed"
    );
    return (
      <div>
        <ShopOrdersToolbar
          confirmed_orders={confirmed_orders}
          expandAllOrders={this.state.expandAllOrders}
          numOrdersPerRow={parseInt(this.state.numOrdersPerRow)}
          onChangeNumOrdersPerRow={this.onChangeNumOrdersPerRow}
          onChangeDateRange={this.onChangeDateRange}
          onChangeTimeRange={this.onChangeTimeRange}
          onExpandAllOrders={this.onExpandAllOrders}
          onShowQuickReport={() => this.setState({ showQuickReport: true })}
          ordersCount={ordersCount}
          orderType={orderType}
          selectedDayRange={this.state.selectedDayRange}
          selectedTimeRangeID={this.state.selectedTimeRangeID}
          onShowConfirmAllCloseOrder={() =>
            this.setState({ show_confirm_all_close_order: true })
          }
        />
        {this.renderOrdersList()}
      </div>
    );
  };

  renderHelperButtons = (device = "desktop") => (
    <div className={Style.secondColumn}>
      <button
        className={Style.helperButton}
        onClick={() => this.setState({ showSearchBar: true })}
      >
        <MagnifyingGlassIcon /> {device === "desktop" && "Search"}
      </button>
      {this.props.orderType === "pastOrders" && (
        <button
          className={Style.helperButton}
          onClick={() => this.setState({ showQuickReport: true })}
        >
          <DollarSymbolIcon />
          {device === "desktop" && "Report"}
        </button>
      )}
    </div>
  );

  renderOrdersList = () => {
    const { matchingOrders, is_close_all_order ,sendAllOrderReadyText} = this.state;
    if (this.state.isFetchingOrders)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for orders" />
        </PageMsg>
      );
    const { ShopOrders } = Orders;
    const { ShopOrdersList } = ShopOrders;
    let ordersList;
    if (matchingOrders) ordersList = matchingOrders;
    else
      ordersList =
        this.props.orderType === "activeOrders"
          ? this.props.context.activeOrders
          : this.state.pastOrders;
    return (
      <div style={{ marginTop: "30px" }}>
        <ShopOrdersList
          expandAllOrders={this.state.expandAllOrders}
          handleSendAllOrderReadyTextChange={this.handleSendAllOrderReadyTextChange}
          is_close_all_order={is_close_all_order}
          sendAllOrderReadyText={sendAllOrderReadyText}
          numOrdersPerRow={String(this.state.numOrdersPerRow)}
          onAddRefundRequestToPastOrder={this.onAddRefundRequestToPastOrder}
          onChangeCloseAllActiveOrder={this.onChangeCloseAllActiveOrder}
          ordersList={ordersList}
          ordersType={this.getOrdersType()}
        />
      </div>
    );
  };

  renderPastOrdersQuickReportModal = () => (
    <Modals.PastOrdersQuickReportModal
      dateRange={{
        endAt: this.state.endAt,
        startAt: this.state.startAt,
      }}
      onCloseModal={() => this.setState({ showQuickReport: false })}
      pastOrders={this.state.pastOrders}
    />
  );

  renderSubNavbar = () => (
    <SubNavbarContainer>
      {/* <div className={Style.subNavbarColumn}>
        <Navbars.SubNavbar
          orderType={this.props.orderType}
          navItems={ORDERS_NAVBAR_ITEMS}
          onNavItemClick={this.onChangeNavItemId}
        />
      </div> */}
      <React.Fragment>
        <ScreenSize deviceType="mobile">
          {this.renderHelperButtons("mobile")}
        </ScreenSize>
        <ScreenSize deviceType="tablet">
          {this.renderHelperButtons("mobile")}
        </ScreenSize>
        <ScreenSize deviceType="desktop">
          {this.renderHelperButtons("desktop")}
        </ScreenSize>
      </React.Fragment>
    </SubNavbarContainer>
  );

  renderSearchBar = () => {
    const { selectedSearchCategory } = this.state;
    const { label = "" } = SEARCH_CATEGORIES.find(
      ({ id }) => id === selectedSearchCategory
    );
    return (
      <SearchBar
        inputPlaceholder={`Search by ${label}`}
        onChangeSearchText={this.onChangeSearchText}
        onCloseSearchBar={() =>
          this.setState({ showSearchBar: false }, () =>
            this.onChangeSearchText("")
          )
        }
        onSelectSearchCategory={this.onSelectSearchCategory}
        searchText={this.state.searchText}
        searchCategories={SEARCH_CATEGORIES}
        selectedSearchCategory={selectedSearchCategory}
      />
    );
  };
 
  render() {
    const { matchingOrders } = this.state;

    let ordersList;
    if (matchingOrders) ordersList = matchingOrders;
    else
      ordersList =
        this.props.orderType === "activeOrders"
          ? this.props.context.activeOrders
          : this.state.pastOrders;

    const confirmed_orders = Object.keys(ordersList).filter(
      (orderID) => ordersList[orderID].status === "confirmed"
    );
    return (
      <div>
        {this.state.showSearchBar && this.renderSearchBar()}
        {this.state.showQuickReport && this.renderPastOrdersQuickReportModal()}
        {this.state.show_confirm_all_close_order && (
          <Modals.ConfirmCloseAllOrderModal
            onCloseModal={() =>
              this.setState({ show_confirm_all_close_order: false })
            }
            onCloseAllActiveOrder={() =>
              this.setState({ is_close_all_order: true })
            }
            confirmed_orders={confirmed_orders}
            onSendAllOrderReadyTextChange={this.handleSendAllOrderReadyTextChange}
            sendAllOrderReadyText={this.state.sendAllOrderReadyText}

          />
        )}
        {/* {this.renderSubNavbar()} */}
        <div>{this.renderContent()}</div>
      </div>
    );
  }
}

ShopOrders.propTypes = {
  context: PropTypes.shape({
    activeOrders: PropTypes.object,
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
};

ShopOrders.defaultProps = {
  context: { activeOrders: {} },
};

export default withContext(MerchantInterfaceConsumer)(ShopOrders);
