// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Link } from "fields";

// Lib
import { Services } from "lib";

class ViewReceiptButton extends React.Component {
  _isMounted = false;
  state = { pdfData: null };

  componentDidMount = () => {
    this._isMounted = true;
    this.onGenerateStripeReceiptURL();
    // this.onGenerateReceiptPDF();
  };

  componentWillUnmount = () => (this._isMounted = false);

  onGenerateStripeReceiptURL = async () => {
    const { BUTI } = Services;
    const { GetCustomerStripeReceipt } = BUTI.GetRequests;
    this.setState({ isLoadingReceiptURL: true });
    const { paymentIntentID = "" } = this.props;
    const { receiptURL = "" } = await GetCustomerStripeReceipt({
      paymentIntentID
    });
    this.setState({ isLoadingReceiptURL: false, receiptURL });
  };
  

  onGenerateReceiptPDF = async () => {
    this.setState({ isLoadingReceiptURL: true });
    try {
      const { BUTI } = Services;
      const { GetCustomerItemizedReceipt } = BUTI.PostRequests;
      const { pdfData } = await GetCustomerItemizedReceipt(this.props);
      this.setState({ isLoadingReceiptURL: false }, () => {
        if (pdfData) this.setState({ pdfData });
      });
    } catch {
      this.setState({ isLoadingReceiptURL: false });
    }
  };

  onShowFile = pdfData => {
    const newBlob = new Blob([pdfData], { type: "application/pdf" });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    let tab = window.open();
    tab.location.href = data;

    setTimeout(function() {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };

  // render() {
  //   const { isLoadingReceiptURL, pdfData } = this.state;
  //   if (isLoadingReceiptURL) return <p>Finding receipt...</p>;
  //   else if (!pdfData) return <p>No receipt found.</p>;
  //   return (
  //     <button
  //       className={Style.viewReceiptButton}
  //       onClick={() => this.onShowFile(pdfData)}
  //     >
  //       View Receipt
  //     </button>
  //   );
  // }

  render() {
    const { isLoadingReceiptURL, receiptURL } = this.state;
    if (isLoadingReceiptURL) return <p>Finding receipt...</p>;
    else if (!receiptURL) return <p>No receipt found.</p>;
    return (
      <div style={{ marginTop: "15px" }}>
        <Link className={Style.viewReceiptButton} href={receiptURL}>
          View Receipt
        </Link>
      </div>
    );
  }
}

ViewReceiptButton.propTypes = {
  paymentIntentID: PropTypes.string.isRequired,
  orderDetails: PropTypes.object,
  orderID: PropTypes.string,
  shopAddress: PropTypes.string,
  shopName: PropTypes.string,
  timeZone: PropTypes.string
};

export default ViewReceiptButton;
