// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import SkipliHeader from "./SkipliHeader";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { Modals, WebsiteBuilder } from "components";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, FullPageModal, Input, Link, ResponsiveModal } from "fields";

// Icons
import { WebsiteSavedIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

class WebPageEditWindow extends React.Component {
  state = {};

  componentDidMount = () => {
    const { website = {} } = this.props;
    const { latestVersion } = website;
    this.setState({ latestVersion });
  };

  onChangeLatestVersion = (latestVersion) =>
    this.setState({ hasUnsavedChanges: true, latestVersion });

  onExitEditMode = () => {
    if (this.state.hasUnsavedChanges)
      this.setState({ showExitConfirmModal: true });
    else this.props.onCloseEditWindow();
  };

  onGetSiteURL = () => {
    const { CUSTOMER_INTERFACE_BASE_URL } = Services;
    const { shopID } = this.props.context;
    return `${CUSTOMER_INTERFACE_BASE_URL}/shop/${shopID}/site`;
  };

  onPublishNow = () =>
    this.setState({ showSaveConfirmModal: false }, this.onPublishLatestVersion);

  onPublishLatestVersion = async () => {
    const { latestVersion = {} } = this.state;
    const { success = true } = await this.onSaveWebsite({
      ...this.props.website,
      latestVersion,
      publishedVersion: latestVersion,
    });
    if (success)
      this.setState({
        hasUnsavedChanges: false,
        showPublishConfirmModal: true,
      });
  };

  onSaveAndExit = async () => {
    const { latestVersion = {} } = this.state;
    const { success = true } = await this.onSaveWebsite({
      ...this.props.website,
      latestVersion,
    });
    if (success)
      this.setState(
        { hasUnsavedChanges: false, showExitConfirmModal: false },
        this.props.onCloseEditWindow
      );
  };

  onSaveLatestVersion = async () => {
    const { latestVersion = {} } = this.state;
    const { success = true } = await this.onSaveWebsite({
      ...this.props.website,
      latestVersion,
    });
    if (success)
      this.setState({ hasUnsavedChanges: false, showSaveConfirmModal: true });
  };

  onSaveWebsite = async (website = {}) => {
    this.setState({ showLoadingModal: true });
    const { shopID = "" } = this.props.context;
    const { Merchants } = Services;
    const { SaveWebsite } = Merchants.PostRequests;
    try {
      await SaveWebsite({ shopID, website });
      this.props.onUpdateWebsite(website);
      return { success: true };
    } catch {
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: "An error occured! Try again.",
        type: "error",
      });
      return { success: false };
    } finally {
      this.setState({ showLoadingModal: false });
    }
  };

  renderExitConfirmModal = () => (
    <ResponsiveModal
      classNames={{ modal: Style.warningModalModalBox }}
      contentLabel="Potential unsaved changes confirm modal"
      headerProps={{ header: "Unsaved changes!" }}
      onCloseModal={() => this.setState({ showExitConfirmModal: false })}
    >
      <p style={{ margin: "0 0 30px 0" }}>
        Your latest changes were not saved and may be lost.
      </p>
      <div className={Style.warningButtons}>
        <Button
          className={Style.saveAndExitBtn}
          name="save and exit"
          onClick={this.onSaveAndExit}
        >
          Save & Exit
        </Button>
        <Button
          className={Style.exitBtn}
          color="danger"
          name="exit without saving"
          onClick={this.props.onCloseEditWindow}
        >
          Exit anyway
        </Button>
      </div>
    </ResponsiveModal>
  );

  renderPublishConfirmModal = () => {
    const siteURL = this.onGetSiteURL();
    return (
      <ResponsiveModal
        classNames={{ modal: Style.warningModalModalBox }}
        contentLabel="Publish latest version confirm modal"
        headerProps={{ header: "Congratulations" }}
        onCloseModal={() => this.setState({ showPublishConfirmModal: false })}
      >
        <p>Your site is published and live online at the following domain:</p>
        <div className={Style.domainContainer}>
          <Input.TextInput name="website url" readOnly value={siteURL} />
        </div>
        <div className={Style.whatsnext}>What's Next</div>
        <div className={Style.publishWebsiteSection}>
          <WebsiteSavedIcon />
          <div>
            <h2 className={Style.heading}>See your website online</h2>
            <Link className={Style.publishNowBtn} href={siteURL}>
              View Site
            </Link>
          </div>
        </div>
      </ResponsiveModal>
    );
  };

  renderSaveConfirmModal = () => (
    <ResponsiveModal
      classNames={{ modal: Style.warningModalModalBox }}
      contentLabel="Save latest version confirm modal"
      headerProps={{ header: "Your changes are saved" }}
      onCloseModal={() => this.setState({ showSaveConfirmModal: false })}
    >
      <p>Publish to see your changes live at the following domain:</p>
      <div className={Style.domainContainer}>
        <Input.TextInput
          name="website url"
          readOnly
          value={this.onGetSiteURL()}
        />
      </div>
      <div className={Style.whatsnext}>What's Next</div>
      <div className={Style.publishWebsiteSection}>
        <WebsiteSavedIcon />
        <div>
          <h2 className={Style.heading}>
            Publish your site so people can see it
          </h2>
          <Button
            className={Style.publishNowBtn}
            name="publish latest version"
            onClick={this.onPublishNow}
          >
            Publish Now
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );

  render() {
    const { context = {}, website = {} } = this.props;
    const { shopBasicInfo = {}, shopID = "" } = context;
    const { general = {}, seo = {} } = website;
    return (
      <React.Fragment>
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        <FullPageModal
          contentLabel="web page canvas"
          contentContainerClassname={Style.contentContainer}
          modalClassname={Style.modalClassname}
          onCloseModal={() => {}}
          overlayClassname={Style.overlay}
          showCloseButton={false}
        >
          {this.state.showPublishConfirmModal &&
            this.renderPublishConfirmModal()}
          {this.state.showSaveConfirmModal && this.renderSaveConfirmModal()}
          {this.state.showExitConfirmModal && this.renderExitConfirmModal()}
          <SkipliHeader
            onExitEditMode={this.onExitEditMode}
            onPublish={this.onPublishLatestVersion}
            onSave={this.onSaveLatestVersion}
          />
          <WebsiteBuilder
            general={general}
            latestVersion={this.state.latestVersion}
            onChangeLatestVersion={this.onChangeLatestVersion}
            seo={seo}
            shopBasicInfo={shopBasicInfo}
            shopID={shopID}
          />
        </FullPageModal>
      </React.Fragment>
    );
  }
}

WebPageEditWindow.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  onCloseEditWindow: PropTypes.func.isRequired,
  onUpdateWebsite: PropTypes.func.isRequired,
  website: PropTypes.shape({
    latestVersion: PropTypes.shape({
      components: PropTypes.object,
    }),
    publishedVersion: PropTypes.shape({
      components: PropTypes.object,
    }),
  }).isRequired,
};

WebPageEditWindow.defaultProps = {};

export default withContext(MerchantInterfaceConsumer)(WebPageEditWindow);
