// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { Box, Input } from "fields";

class AfterGuestCheckin extends React.Component {
  renderAutomatedTextAfterCheckin = ({ automated_text_after_checkin = "" }) => (
    <Input.TextInput
      hasMultipleLines
      label="Automated text message"
      max_character_count={200}
      name="Automated text message after check-in"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "after_checkin.automated_text_after_checkin",
          value,
        })
      }
      rows={3}
      value={automated_text_after_checkin}
    />
  );

  renderCheckInInstruction = ({ instruction_after_checkin = "" }) => (
    <Input.TextInput
      hasMultipleLines
      label="Instruction for guest"
      max_character_count={100}
      name="Instruction for guest after check-in"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "after_checkin.instruction_after_checkin",
          value,
        })
      }
      required
      value={instruction_after_checkin}
      rows={3}
    />
  );

  render() {
    const { after_checkin = {} } = this.props;
    const {
      automated_text_after_checkin = "",
      instruction_after_checkin = "",
    } = after_checkin;

    return (
      <Box header="After check-in">
        {this.renderCheckInInstruction({ instruction_after_checkin })}
        {this.renderAutomatedTextAfterCheckin({ automated_text_after_checkin })}
      </Box>
    );
  }
}

AfterGuestCheckin.propTypes = {
  after_checkin: PropTypes.shape({
    automated_text_after_checkin: PropTypes.string.isRequired,
    instruction_after_checkin: PropTypes.string.isRequired,
  }).isRequired,
  onChangeGuestCheckin: PropTypes.func.isRequired,
};

AfterGuestCheckin.defaultProps = {
  after_checkin: {
    automated_text_after_checkin: "",
    instruction_after_checkin: "",
  },
};

export default AfterGuestCheckin;
