// BUTI DINERS, INC. All right Reserved ©

const REFUND_REASONS = {
  missingItem: { label: "Missing item(s)" },
  outOfStock: { label: "Out of stock" },
  kitchenClosed: { label: "Kitchen closed" },
  paidAtCounter: { label: "Paid at counter" },
  failed_delivery: { label: "Delivery failed" },
  other: { label: "Other" },
};

export { REFUND_REASONS };
