// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Box } from "fields";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Icon
import { CheckIcon } from "assets/Icons";

// Lib
import { Services } from "lib";

const MAX_CLICK_COUNT = 7;

const stripeConnectButton = require("assets/Images/stripe-connect-button.png");

class StripeConnect extends React.Component {
  state = {
    moduleClickCount: 0,
    showStripeConnectBtn: false,
    stripeConnectOnboardingUrl: "",
  };

  componentDidMount() {
    const {
      shopBasicInfo,
      stripeConnectAuthCode,
      stripeConnectToken,
    } = this.props.context;
    const { stripeConnectInfo } = shopBasicInfo;
    if (
      !stripeConnectInfo ||
      stripeConnectInfo.applicationStatus === "pending"
    ) {
      if (stripeConnectAuthCode && stripeConnectToken)
        this.onSaveAuthCode({
          shopBasicInfo,
          stripeConnectAuthCode,
          stripeConnectToken,
        });
      else this.onGetOAuthUrl();
    }
  }

  onSaveAuthCode = ({
    shopBasicInfo,
    stripeConnectAuthCode,
    stripeConnectToken,
  }) => {
    const { shopID } = this.props.context;
    if (stripeConnectToken === shopBasicInfo.stripeConnectInfo.token)
      this.onSaveMerchantStripeAccountID({ stripeConnectAuthCode, shopID });
    else this.onGetOAuthUrl();
  };

  onSaveMerchantStripeAccountID = async ({ stripeConnectAuthCode, shopID }) => {
    const { SaveMerchantStripeAccountID } = Services.BUTI.PostRequests;
    const { success } = await SaveMerchantStripeAccountID({
      stripeConnectAuthCode,
      shopID,
    });
    if (success) {
      const { FRONT_END_BASE_URL } = Services;
      window.location.href = `${FRONT_END_BASE_URL}/${shopID}/payments`;
    }
  };

  onGetOAuthUrl = async () => {
    const { Merchants } = Services;
    const { CreateStripeConnectAccount } = Merchants.GetRequests;
    const { shopID } = this.props.context;
    const { stripeConnectOnboardingUrl } = await CreateStripeConnectAccount({
      shopID,
    });
    this.setState({ stripeConnectOnboardingUrl });
  };

  onShowStripeConnectButton = () => {
    if (!this.props.isStripeConnectComplete) {
      const newCount = this.state.moduleClickCount + 1;
      this.setState({
        moduleClickCount: newCount,
        showStripeConnectBtn: newCount >= MAX_CLICK_COUNT,
      });
    }
  };

  renderModuleClickCount = () => {
    const { moduleClickCount = 0 } = this.state;
    const stepsAway = MAX_CLICK_COUNT - moduleClickCount;
    if (moduleClickCount < 4) return;
    else if (stepsAway > 0)
      return (
        <div className={Style.moduleClickCount}>
          You are {stepsAway} step{stepsAway === 1 ? "" : "s"} away
        </div>
      );
    return (
      <div className={Style.moduleClickCount}>Developer mode is enabled</div>
    );
  };

  renderModuleTitle = () => (
    <div className={Style.successConnect}>
      Payout
      {this.props.isStripeConnectComplete && (
        <div className={Style.checkIcon}>
          <CheckIcon />
        </div>
      )}
    </div>
  );

  renderStripeConnectButton = () =>
    this.state.showStripeConnectBtn && (
      <a href={this.state.stripeConnectOnboardingUrl}>
        <img
          alt="Connect with Stripe"
          style={{ height: "35px" }}
          src={stripeConnectButton}
        />
      </a>
    );

  render() {
    const box_escription = !this.props.isStripeConnectComplete
      ? "Your account is not connected to a checking account. To receive payments from your customers, please submit the information required in 'Get Paid' below."
      : "Your account is connected with a checking account and ready for payouts.";

    return (
      <div onClick={this.onShowStripeConnectButton}>
        <Box description={box_escription} header={this.renderModuleTitle()}>
          {this.renderModuleClickCount()}
          {this.renderStripeConnectButton()}
        </Box>
      </div>
    );
  }
}

StripeConnect.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
    stripeConnectAuthCode: PropTypes.string,
    stripeConnectToken: PropTypes.string,
  }).isRequired,
  isStripeConnectComplete: PropTypes.bool,
};

StripeConnect.defaultProps = {
  isStripeConnectComplete: false,
};

export default withContext(MerchantInterfaceConsumer)(StripeConnect);
