// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _omit from "lodash.omit";

// Style
import CommonStyle from "../style.module.scss";
import AddButtonStyle from "../style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { MenusListOfElements } from "components";

// Fields
import { LoadingSpinner, PageMsg, ResponsiveModal } from "fields";

// Lib
import { Services } from "lib";

class AddModifierGroupModal extends React.Component {
  state = { isFindingModifierGroups: false };

  componentDidMount = () => this.onGetAllModifierGroups();

  onClickAddButton = (modifierGroupID) =>
    this.props.onAddModifierGroup(modifierGroupID);

  onGetAllModifierGroups = async () => {
    const { Merchants } = Services;
    const { GetAllModifierGroups } = Merchants.GetRequests;
    this.setState({ isFindingModifierGroups: true });
    const { allModifierGroups } = await GetAllModifierGroups({
      shopID: this.props.context.shopID,
    }).finally(() => this.setState({ isFindingModifierGroups: false }));
    this.setState({ allModifierGroups: allModifierGroups || {} });
  };

  renderContent = () => {
    if (this.state.isFindingModifierGroups)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for modifier groups" />
        </PageMsg>
      );
    return (
      <MenusListOfElements.ListOfModifierGroups
        emptyListMessage="All modifier groups is added."
        modifierGroupHelperButton={this.renderModifierGroupHelperButton}
        modifierGroups={_omit(
          this.state.allModifierGroups,
          Object.keys(this.props.addedModifierGroups)
        )}
      />
    );
  };

  renderModifierGroupHelperButton = (modifierGroupID) => (
    <div
      className={AddButtonStyle.addButton}
      onClick={() => this.onClickAddButton(modifierGroupID)}
    >
      +
    </div>
  );

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentDescriptionProps={{
          contentDescription: "Click on modifier group for details",
          showContentDescription: true,
        }}
        contentLabel="Add modifier groups modal"
        headerProps={{
          close_button_icon: "arrow",
          header: "Add modifier group",
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

AddModifierGroupModal.propTypes = {
  addedModifierGroups: PropTypes.object,
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  destinationName: PropTypes.string.isRequired,
  onAddModifierGroup: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

AddModifierGroupModal.defaultProps = {
  addedModifierGroups: {},
};

export default withContext(MerchantInterfaceConsumer)(AddModifierGroupModal);
