// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import _isEmpty from "lodash.isempty";

// Style
import Style from "./style.module.scss";

const DeliveryAddress = ({ deliveryDetails }) => {
  const { deliveryAddress = {} } = deliveryDetails;
  if (_isEmpty(deliveryAddress)) return null;
  const { streetAddress1, streetAddress2, city, state, zip } = deliveryAddress;
  return (
    <div className={cx(Style.infoCol, Style.deliveryAddressCol)}>
      <div className={Style.detailHeading}>delivery address</div>
      <div>{streetAddress1}</div>
      {streetAddress2 && <div>{streetAddress2}</div>}
      <div>
        {city}, {state}
      </div>
      {zip && <div>{zip}</div>}
    </div>
  );
};

DeliveryAddress.propTypes = {
  deliveryDetails: PropTypes.shape({
    deliveryAddress: PropTypes.shape({
      streetAddress1: PropTypes.string,
      streetAddress2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
  }),
};

export default DeliveryAddress;
