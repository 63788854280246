// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

class ResponsiveModalFooter extends React.Component {
  renderSubmitButton = (submitButtonProps = {}) => {
    const { classNames = {} } = this.props;
    const {
      activeText = "Submit",
      loadingText = "Loading",
      status = "active",
    } = submitButtonProps;
    const { onSubmit = () => {} } = this.props;
    return (
      <Button
        className={cx(Style.submitButton, classNames.footer_submit_button)}
        color="info"
        loadingText={loadingText}
        name="submit button"
        onClick={onSubmit}
        status={this.props.isSubmitting ? "loading" : status}
      >
        {activeText}
      </Button>
    );
  };

  render() {
    const {
      classNames = {},
      footerProps = {},
      isSubmitting = false,
    } = this.props;
    const {
      customFooter,
      showFooter = false,
      submitButtonProps = {},
    } = footerProps;
    if (!showFooter) return null;
    else if (customFooter)
      return (
        <div
          className={cx(Style.footer_container, classNames.footer_container)}
        >
          {customFooter}
        </div>
      );
    return (
      <div className={cx(Style.footer_container, classNames.footer_container)}>
        <Button
          className={cx(Style.cancelButton, classNames.footer_cancel_button)}
          name="cancel button"
          onClick={this.props.onCloseModal}
          status={isSubmitting ? "inactive" : "active"}
        >
          Cancel
        </Button>
        {this.renderSubmitButton(submitButtonProps)}
      </div>
    );
  }
}

ResponsiveModalFooter.propTypes = {
  classNames: PropTypes.shape({
    footer_container: PropTypes.string,
    footer_cancel_button: PropTypes.string,
    footer_submit_button: PropTypes.string,
  }),
  footerProps: PropTypes.shape({
    customFooter: PropTypes.any,
    showFooter: PropTypes.bool,
    submitButtonProps: PropTypes.shape({
      activeText: PropTypes.any,
      loadingText: PropTypes.string,
      status: PropTypes.oneOf(["active", "inactive"]),
    }),
  }),
  isSubmitting: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

ResponsiveModalFooter.defaultProps = {
  footerProps: {
    customFooter: null,
    showFooter: false,
    submitButtonProps: {
      activeText: "Submit",
      loadingText: "Loading",
      status: "active",
    },
  },
  isSubmitting: false,
  onSubmit: () => {},
};

export default ResponsiveModalFooter;
