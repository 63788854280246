// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

const Link = props => {
  const { target } = props;
  const rel = target === "_blank" ? "noopener noreferrer" : props.rel;
  return (
    <a className={props.className} href={props.href} rel={rel} target={target}>
      {props.children}
    </a>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.oneOf(["_blank", "_parent", "_self", "_top"])
};

Link.defaultProps = {
  rel: "external",
  target: "_blank"
};

export default Link;
