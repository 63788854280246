// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import NavigationMenuContainer from "./NavigationMenuContainer";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Link } from "fields";

// Icons
import { HomeIcon, OrderFoodOnlineIcon } from "assets/Icons";

// Icons
import { MenuIcon, SkipliLogoWithTextWhiteIcon } from "assets/Icons";

class AdminNavbar extends Component {
  state = { showMenu: false };

  onChangeGlobalNavItem = (navItemID) =>
    this.setState({ showMenu: false }, () =>
      this.props.context.onChangeGlobalNavItem(navItemID)
    );

  renderFirstCol = () => (
    <div>
      {this.renderMenuIcon()}
      <button
        className={Style.skipliLogo}
        onClick={() => this.onChangeGlobalNavItem("orders")}
      >
        <SkipliLogoWithTextWhiteIcon />
      </button>
    </div>
  );

  renderMenu = () => (
    <div
      className={cx(Style.menuContainer, this.state.showMenu && Style.showMenu)}
    >
      <NavigationMenuContainer
        activeNavbarItemId={this.props.activeNavbarItemId}
        navItems={this.props.navItems}
        onChangeGlobalNavItem={this.onChangeGlobalNavItem}
        personnel={this.props.personnel}
      />
    </div>
  );

  renderMenuIcon = () => {
    // const { MOBILE_NAVBAR_ITEMS } = Constants;
    // const { label = "" } = MOBILE_NAVBAR_ITEMS.find(
    //   ({ id }) => id === this.props.activeNavbarItemId
    // );
    return (
      <button
        className={Style.menuIconContainer}
        onClick={() => this.setState({ showMenu: !this.state.showMenu })}
      >
        <MenuIcon />
        Menu
      </button>
    );
  };

  renderSecondCol = () => {
    const { shopBasicInfo = {}, shopID } = this.props.context;
    const { name = "" } = shopBasicInfo;
    return (
      <div className={Style.secondColContent}>
        {/* <Button
          className={Style.getUpdatesButton}
          name="get updates"
          onClick={() => window.location.reload()}
        >
          <div className={Style.secondColIcon}>
            <RefreshIcon />
          </div>
          Get Updates
        </Button> */}
        <Link
          className={Style.visitOnlineMenuButton}
          href={`https://www.skiplinow.com/shop/${shopID}`}
        >
          <div className={Style.secondColIcon}>
            <OrderFoodOnlineIcon />
          </div>
          View Live Menu
        </Link>
        <Button
          className={Style.shopInfoButton}
          name="store info"
          onClick={() => this.props.context.onChangeGlobalNavItem("shopInfo")}
        >
          <div className={Style.secondColIcon}>
            <HomeIcon />
          </div>
          <div className={Style.shopName}>{name}</div>
        </Button>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderMenu()}
        <div className={Style.adminHeaderContainer}>
          <div className={Style.adminHeaderContent}>
            <div className={Style.firstCol}>{this.renderFirstCol()}</div>
            <div className={Style.secondCol}>{this.renderSecondCol()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AdminNavbar.propTypes = {
  activeNavbarItemId: PropTypes.string.isRequired,
  context: PropTypes.shape({
    onChangeGlobalNavItem: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  navItems: PropTypes.array.isRequired,
  personnel: PropTypes.object,
};

export default withContext(MerchantInterfaceConsumer)(AdminNavbar);
