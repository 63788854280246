// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import ShopOrderPill from "../ShopOrderPill";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, PageMsg } from "fields";

// Icons
import { AllOrdersIcon, BagIcon } from "assets/Icons";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

const ShopOrdersList = ({
  context,
  expandAllOrders,
  handleSendAllOrderReadyTextChange,
  is_close_all_order,
  numOrdersPerRow,
  onAddRefundRequestToPastOrder,
  onChangeCloseAllActiveOrder,
  ordersList,
  ordersType,
  sendAllOrderReadyText,
}) => {
  const orderWidth =
    parseInt(numOrdersPerRow) === 1
      ? "100"
      : 100 / parseInt(numOrdersPerRow) - 2;

  const renderList = () =>
    Object.keys(ordersList).map(
      (orderID) =>
        ordersList[orderID] && (
          <div
            className={Style.orderPill}
            style={{ width: `${orderWidth}%` }}
            key={orderID}
          >
            <ShopOrderPill
              handleSendAllOrderReadyTextChange={
                handleSendAllOrderReadyTextChange
              }
              is_close_all_order={is_close_all_order}
              onAddRefundRequestToPastOrder={onAddRefundRequestToPastOrder}
              onChangeCloseAllActiveOrder={onChangeCloseAllActiveOrder}
              orderID={orderID}
              ordersList={ordersList}
              orderInfo={ordersList[orderID]}
              orderType={ordersType}
              showExpandedInfo={expandAllOrders}
              sendAllOrderReadyText={sendAllOrderReadyText}
            />
          </div>
        )
    );

  const renderViewPastOrdersButton = () => {
    const { onChangeGlobalNavItem } = context;
    return (
      <Button
        className={Style.viewPastOrdersButton}
        color="info"
        name="order history"
        onClick={() =>
          onChangeGlobalNavItem && onChangeGlobalNavItem("pastOrders")
        }
      >
        <AllOrdersIcon /> View Order History
      </Button>
    );
  };

  return Object.keys(ordersList).length === 0 ? (
    <PageMsg>
      <div className={Style.bagIcon}>
        <BagIcon />
      </div>
      <h3 className={Style.title}>
        No {ordersType === "active" ? "active" : "completed"} orders
      </h3>
      <p className={Style.message}>
        {ordersType === "active" ? "Incoming" : "Fulfilled and canceled"} orders
        will appear here.
      </p>
      {ordersType === "active" && renderViewPastOrdersButton()}
    </PageMsg>
  ) : (
    <div className={Style.container}>{renderList()}</div>
  );
};

ShopOrdersList.propTypes = {
  context: PropTypes.shape({
    onChangeGlobalNavItem: PropTypes.func,
  }).isRequired,
  onChangeCloseAllActiveOrder: PropTypes.func,
  expandAllOrders: PropTypes.bool,
  is_close_all_order: PropTypes.bool,
  onAddRefundRequestToPastOrder: PropTypes.func.isRequired,
  ordersList: PropTypes.object,
  numOrdersPerRow: PropTypes.string,
  ordersType: PropTypes.oneOf(["active", "past"]).isRequired,
};

ShopOrdersList.defaultProps = {
  expandAllOrders: false,
  numOrdersPerRow: "1",
  ordersList: {},
};

export default withContext(MerchantInterfaceConsumer)(ShopOrdersList);
