// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { set } from "object-path-immutable";

import AverageTimeModule from "./AverageTimeModule";
import DeliverHoursModule from "./DeliverHoursModule";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import ViewStyle from "views/style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Box, FloatingButton } from "fields";

// Lib
import { Functions, Services } from "lib";

class Deliver extends React.Component {
  state = { deliverInfo: {} };

  componentDidMount() {
    const { deliverInfo = {} } = this.props.context.shopBasicInfo;
    this.setState({ deliverInfo });
  }

  onChangeDeliverInfo = (deliverInfo) => this.setState({ deliverInfo });

  onClickRadioButton = (optionID) =>
    this.setState({
      deliverInfo: set(this.state.deliverInfo, "isDeliveryEnabled", optionID),
    });

  onSaveDeliverInfo = async () => {
    this.setState({ isLoading: true });
    const { Merchants } = Services;
    const { ShowConfirmNotif } = Functions;
    const { SaveChangedDeliverInfo } = Merchants.PostRequests;
    try {
      const {
        onUpdateShopBasicInfo,
        shopBasicInfo,
        shopID,
      } = this.props.context;
      const { deliverInfo } = this.state;
      const { success } = await SaveChangedDeliverInfo({
        deliverInfo,
        shopID,
      });
      this.confirmNotif = ShowConfirmNotif({
        message: success
          ? "Updated deliver settings"
          : "Failed to update deliver settings. Try again or refresh page.",
        type: success ? "success" : "error",
      });
      if (success)
        onUpdateShopBasicInfo(set(shopBasicInfo, "deliverInfo", deliverInfo));
    } catch {
      this.confirmNotif = ShowConfirmNotif({
        message:
          "Failed to update deliver settings. Try again or refresh page.",
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { openHours = {}, timeZone } = this.props.context.shopBasicInfo;
    const { deliverInfo = {} } = this.state;
    return (
      <div>
        {this.state.isLoading && <Modals.LoadingModal message="Saving..." />}
        <Box>
          <AverageTimeModule
            deliverInfo={deliverInfo}
            onChangeDeliverInfo={this.onChangeDeliverInfo}
          />
        </Box>
        <Box>
          <DeliverHoursModule
            deliverInfo={deliverInfo}
            onChangeDeliverInfo={this.onChangeDeliverInfo}
            openHours={openHours}
            timeZone={timeZone}
          />
        </Box>
        {/* {isDeliveryEnabled === "true" && (
          <React.Fragment>
            <Box>
              <AverageTimeModule
                deliverInfo={deliverInfo}
                onChangeDeliverInfo={this.onChangeDeliverInfo}
              />
            </Box>
            <Box>
              <DeliverFee
                deliverInfo={deliverInfo}
                onChangeDeliverInfo={this.onChangeDeliverInfo}
              />
            </Box>
            <Box>
              <DeliverHoursModule
                deliverInfo={deliverInfo}
                onChangeDeliverInfo={this.onChangeDeliverInfo}
                openHours={openHours}
                timeZone={timeZone}
              />
            </Box>
          </React.Fragment>
        )} */}
        <FloatingButton
          buttonClassname={ViewStyle.saveButton}
          onClick={this.onSaveDeliverInfo}
        >
          Save
        </FloatingButton>
      </div>
    );
  }
}

export default withContext(MerchantInterfaceConsumer)(Deliver);
