// BUTI DINERS, INC. All right Reserved ©

const BASIC_INFO = {
  customerName: "Emily Miller",
  feesForBUTI: {
    convenience: 0.6,
    technologyFee: 1,
  },
  orderDeliveryTypeID: "deliver",
  orderItems: {
    yJj9bECAU: {
      customerInstruction: "Extra Bowl",
      itemID: "-LmGbiZyvDbxiPLMDwwC",
      itemSimpleDescription: {
        itemName: "Lemon Chicken Soup",
        itemPrice: "7.50",
      },
      quantity: 2,
    },
    j5pgnbLtg: {
      customerInstruction: "Not too spicy please",
      itemID: "-LmGc9TJ2a8_nHBJwKs7",
      itemSimpleDescription: {
        itemName: "Veggie Soup",
        itemPrice: "8.00",
      },
      quantity: 1,
    },
    TD0jow8hz: {
      customerInstruction:
        "I like my steak medium rare. If possible, please cut the steak into cube pieces for me. Thank you.",
      itemID: "-LmGab2Tr4TmZW8fqMwr",
      itemSimpleDescription: {
        itemName: "Steak Sandwich",
        itemPrice: "9.00",
      },
      modifierGroups: {
        "-LmGapSgFweA5Uw8yENU": {
          modifiers: {
            "-LmGasGrumqd7QTjfdNg": {
              modifierName: "Potato Chips",
              modifierPrice: "0",
            },
            "-LmGauzpyO5oRhJVSeXw": {
              modifierName: "Tomato Soup",
              modifierPrice: "0",
            },
            "-LmGayRn0TOI0B8SbA7L": {
              modifierName: "Salads",
              modifierPrice: "0",
            },
          },
        },
      },
      quantity: 1,
    },
  },
  paymentIntentID: "pi_1FCppPKsEylVTkPfJxaaem2W",
  phoneNumber: "8727603558",
  pickUpTime: "ASAP",
  status: "active",
  timeStamp: "2020-01-12T15:47:01.065Z",
  tipAmount: 0,
  totalPriceAfterTax: 28.28,
  uuid: "-LkjglTdl7OuHczg2_9M",
};

const SAMPLE_DELIVERY_ORDER = {
  ...BASIC_INFO,
  deliveryDetails: { courier: "postmates" },
  orderDeliveryTypeID: "deliver",
};
const SAMPLE_PICKUP_ORDER = {
  ...BASIC_INFO,
  orderDeliveryTypeID: "pickUp",
  pickUpTime: "ASAP",
};

export { SAMPLE_DELIVERY_ORDER, SAMPLE_PICKUP_ORDER };
