// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _isempty from "lodash.isempty";
import _get from "lodash.get";
import { customAlphabet } from "nanoid";

import SelectDateScreen from "./SelectDateScreen";
import SelectHoursScreen from "./SelectHoursScreen";

import { DEFAULT_DATE_FORMAT } from "utils/dayjs/constants";

// Style
import ModalStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions } from "lib";

// Assets
import { ArrowIcon } from "assets/Icons";

const nanoid = customAlphabet("1234567890abcdef", 10);

class AddNewDateModal extends React.Component {
  constructor(props) {
    super(props);
    const { selected_date_id_to_edit = "", special_hours = {} } = props;
    if (selected_date_id_to_edit) {
      const {
        date,
        is_closed = true,
        end_at = "",
        start_at = "",
      } = special_hours[selected_date_id_to_edit];
      this.state = {
        closed_all_day: is_closed,
        screen_id: "select_hours",
        selected_date: date,
        selected_hours: { end_at, start_at },
      };
    } else {
      this.state = {
        closed_all_day: true,
        screen_id: "select_date",
        selected_date: "",
        selected_hours: {},
      };
    }
  }

  componentDidMount = () => this.onGetTodayDate();

  onGetTodayDate = async () => {
    const { selected_date_id_to_edit = "" } = this.props;
    try {
      this.setState({ is_getting_today_date: true });
      const { DateTime } = Functions;
      const { GetTodayDay } = DateTime;
      const { date: today_date = "" } = await GetTodayDay();
      this.setState({
        selected_date: selected_date_id_to_edit
          ? this.state.selected_date
          : today_date,
        today_date,
      });
    } catch {
    } finally {
      this.setState({ is_getting_today_date: false });
    }
  };

  onClickModalSubmit = () => {
    const { special_hours = {} } = this.props;
    const { screen_id } = this.state;
    if (screen_id === "select_date") {
      this.setState({ screen_id: "select_hours" });
    } else if (screen_id === "select_hours") {
      const { selected_date_id_to_edit = "" } = this.props;
      if (selected_date_id_to_edit)
        this.onSaveNewSpecialHours({ key: selected_date_id_to_edit });
      else {
        const matched_id = Object.keys(special_hours).find(
          (key) =>
            this.state.selected_date === _get(special_hours, `${key}.date`)
        );
        this.onSaveNewSpecialHours({ key: matched_id || nanoid(6) });
      }
    }
  };

  onCloseModal = () => {
    switch (this.state.screen_id) {
      case "select_hours": {
        const { selected_date_id_to_edit = "" } = this.props;
        if (selected_date_id_to_edit) return this.props.onCloseModal();
        else return this.setState({ screen_id: "select_date" });
      }
      case "select_date":
      default:
        return this.props.onCloseModal();
    }
  };

  onGetSubmitButtonStatus = () => {
    const { selected_date = "" } = this.state;
    switch (this.state.screen_id) {
      case "select_date":
        return _isempty(selected_date) ? "inactive" : "active";
      case "select_hours": {
        if (this.state.closed_all_day) return "active";
        const { end_at = "", start_at = "" } = this.state.selected_hours;
        return _isempty(end_at) || _isempty(start_at) ? "inactive" : "active";
      }
      default:
        return "inactive";
    }
  };

  onSaveNewSpecialHours = ({ key = "" }) => {
    const { end_at = "", start_at = "" } = this.state.selected_hours;
    this.props.onSave({
      key: key || nanoid(6),
      value: {
        date: this.state.selected_date,
        date_format: DEFAULT_DATE_FORMAT,
        is_closed: this.state.closed_all_day,
        end_at,
        start_at,
        time_format: "HH:MM",
      },
    });
    this.props.onCloseModal();
  };

  renderModalContent = () => {
    const { selected_date } = this.state;
    if (this.state.screen_id === "select_date") {
      return (
        <SelectDateScreen
          onSelectDate={(new_date = "") =>
            this.setState({ selected_date: new_date })
          }
          selected_date={selected_date}
          today_date={this.state.today_date}
        />
      );
    }
    return (
      <SelectHoursScreen
        closed_all_day={this.state.closed_all_day}
        onChangeCustomHours={(new_hours) =>
          this.setState({ selected_hours: new_hours })
        }
        onCloseAllDay={(closed_all_day) => this.setState({ closed_all_day })}
        selected_date={selected_date}
        selected_hours={this.state.selected_hours}
      />
    );
  };

  renderModalSubmitButtonLabel = () => {
    switch (this.state.screen_id) {
      case "select_date":
        return (
          <>
            Select hours
            <div className={Style.arrow_icon}>
              <ArrowIcon />
            </div>
          </>
        );
      case "select_hours":
        return "Save";
      default:
        return;
    }
  };

  render() {
    const { is_getting_today_date = "" } = this.state;
    if (is_getting_today_date) return <div />;
    return (
      <ResponsiveModal
        classNames={{ modal: ModalStyle.modal }}
        closeModalAfterSuccessSubmit={false}
        contentLabel="Add a new date to special hours"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: this.renderModalSubmitButtonLabel(),
            status: this.onGetSubmitButtonStatus(),
          },
        }}
        headerProps={{ close_button_icon: "arrow" }}
        onCloseModal={this.onCloseModal}
        onSubmit={this.onClickModalSubmit}
      >
        {this.renderModalContent()}
      </ResponsiveModal>
    );
  }
}

AddNewDateModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selected_date_id_to_edit: PropTypes.string,
  special_hours: PropTypes.object,
};

AddNewDateModal.defaultProps = {
  selected_date_id_to_edit: "",
  special_hours: {},
};

export default AddNewDateModal;
