// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";

// Style
import ModuleStyle from "../style.module.scss";

// Icons
import { ArrowIcon } from "assets/Icons";

// Fields
import { ComingSoonButton } from "fields";

class ImageEffectsModule extends React.Component {
  state = {};

  onToggleModuleContentVisibility = () =>
    this.setState({ showModuleContent: !this.state.showModuleContent });

  renderModuleTitle = () => (
    <div className={ModuleStyle.moduleTitleContainer}>
      <h3 className={ModuleStyle.moduleTitle}>Image Effects</h3>
      <ArrowIcon
        className={cx(
          ModuleStyle.arrowIcon,
          this.state.showModuleContent && ModuleStyle.flip
        )}
      />
    </div>
  );

  render() {
    return (
      <div className={ModuleStyle.moduleContainer}>
        <button onClick={this.onToggleModuleContentVisibility}>
          {this.renderModuleTitle()}
          <p className={ModuleStyle.moduleDescription}>
            Add an effect to jazz up your already awesome item.
          </p>
        </button>
        {this.state.showModuleContent && <ComingSoonButton />}
      </div>
    );
  }
}

export default ImageEffectsModule;
