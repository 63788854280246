// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _omit from "lodash.omit";

// Style
import CommonStyle from "../style.module.scss";
import AddButtonStyle from "../style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { MenusListOfElements } from "components";

// Fields
import { LoadingSpinner, PageMsg, ResponsiveModal } from "fields";

// Lib
import { Services } from "lib";

class AddModifierModal extends React.Component {
  state = { isFindingModifiers: false };

  componentDidMount = () => this.onGetAllModifiers();

  onClickAddButton = (modifierID) => this.props.onAddModifier(modifierID);

  onGetAllModifiers = async () => {
    const { Merchants } = Services;
    const { GetAllModifiers } = Merchants.GetRequests;
    this.setState({ isFindingModifiers: true });
    const { allModifiers } = await GetAllModifiers({
      shopID: this.props.context.shopID,
    }).finally(() => this.setState({ isFindingModifiers: false }));
    this.setState({ allModifiers: allModifiers || {} });
  };

  renderContent = () => {
    if (this.state.isFindingModifiers)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for modifiers" />
        </PageMsg>
      );
    return (
      <MenusListOfElements.ListOfModifiers
        emptyListMessage="All modifiers is added."
        modifierHelperButton={(modifier_id) => (
          <div
            className={AddButtonStyle.addButton}
            onClick={() => this.onClickAddButton(modifier_id)}
          >
            +
          </div>
        )}
        modifiers={_omit(
          this.state.allModifiers,
          Object.keys(this.props.addedModifiers)
        )}
      />
    );
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentDescriptionProps={{
          contentDescription: "Click on modifier for details",
          showContentDescription: true,
        }}
        contentLabel="Add modifiers modal"
        headerProps={{
          close_button_icon: "arrow",
          header: "Add modifier",
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

AddModifierModal.propTypes = {
  addedModifiers: PropTypes.object,
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  destinationName: PropTypes.string.isRequired,
  onAddModifier: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

AddModifierModal.defaultProps = {
  addedModifiers: {},
};

export default withContext(MerchantInterfaceConsumer)(AddModifierModal);
