// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { MenusManagement } from "components";

// Fields
import { PageMsg } from "fields";

// Assets
import { MenusIcon } from "assets/Icons";

class ListOfMenuGroups extends Component {
  renderGroups = (groups = {}) =>
    (this.props.sortedGroupIDs || Object.keys(groups)).map((groupID) => (
      <div className={CommonStyle.elementContainer} key={groupID}>
        <MenusManagement.Group
          {...this.props.groupProps}
          groupHelperButton={this.props.groupHelperButton}
          groupID={groupID}
          groupInfo={groups[groupID]}
          onUpdateAllCategories={this.props.onUpdateAllCategories}
        />
      </div>
    ));

  render() {
    const { groups = {} } = this.props;
    if (Object.keys(groups).length === 0)
      return (
        <PageMsg>
          <div className={CommonStyle.menus_icon}>
            <MenusIcon />
          </div>
          {this.props.emptyListMessage}
        </PageMsg>
      );
    return (
      <div
        className={cx(
          CommonStyle.listContainer,
          this.props.listContainerClassname
        )}
      >
        {this.renderGroups(groups)}
      </div>
    );
  }
}

ListOfMenuGroups.propTypes = {
  emptyListMessage: PropTypes.string,
  groupProps: PropTypes.object,
  groups: PropTypes.object,
  groupHelperButton: PropTypes.func,
  listContainerClassname: PropTypes.string,
  onUpdateAllCategories: PropTypes.func,
  sortedGroupIDs: PropTypes.array,
};

ListOfMenuGroups.defaultProps = {
  emptyListMessage: "No categories found.",
  groupProps: {},
  groups: {},
  onUpdateAllCategories: () => {},
  sortedGroupIDs: null,
};

export default ListOfMenuGroups;
