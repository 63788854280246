// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import _get from "lodash.get";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import LargeCenterModalStyle from "components/Modals/style.module.scss";
import Style from "./style.module.scss";

// Components
import { RadioGroup } from "components";

// Fields
import { Box, CenterModal } from "fields";

// Icons
import { CloseIcon, PrinterIcon } from "assets/Icons";

// Images
const EpsonLogo = require("assets/Images/epson-logo.png");
const StarLogo = require("assets/Images/star-micronics-logo.png");

const PRINTER_BRANDS = {
  epson: {
    label: <img alt="" className={Style.epsonLogo} src={EpsonLogo} />,
  },
  star: {
    label: <img alt="" className={Style.starLogo} src={StarLogo} />,
  },
};

// const SELECT_CATEGORIES = {
//   all: { label: "All" },
//   custom: { label: "Custom" },
// };

class PrinterInfoModal extends React.Component {
  state = {};

  onChangeCategoriesToPrint = (choiceID = "all") => {
    const categoriesToPrint = choiceID === "all" ? "all" : [];
    this.props.onChangeCategoriesToPrint({
      categoriesToPrint,
      printerName: this.props.printerName,
    });
  };

  onSelectBrand = (optionId) => {
    this.props.onChangePrinterBrand({
      printerBrand: optionId,
      printerName: this.props.printerName,
    });
  };

  renderBadges = () => {
    const { isConnected } = this.props;
    return (
      <div className={Style.badges}>
        {isConnected ? (
          <div className={Style.onlineBadge}>Online</div>
        ) : (
          <div className={Style.offlineBadge}>Offline</div>
        )}
      </div>
    );
  };

  renderBrandSelector = () => (
    <div style={{ marginTop: "1.4rem" }}>
      <RadioGroup
        heading="Select the printer brand"
        onClick={this.onSelectBrand}
        options={PRINTER_BRANDS}
        required
        selectedOptionId={this.props.printerBrand}
      />
    </div>
  );

  // renderCategoriesToPrint = () => {
  //   const { categoriesToPrint } = this.props;
  //   return Object.keys(SELECT_CATEGORIES).map((choiceID) => {
  //     const { label } = SELECT_CATEGORIES[choiceID];
  //     let checked = false;
  //     if (choiceID === "all") checked = categoriesToPrint === "all";
  //     else checked = categoriesToPrint !== "all";
  //     return (
  //       <React.Fragment key={choiceID}>
  //         <RadioButton
  //           className={Style.radioBtn}
  //           checked={checked}
  //           id={choiceID}
  //           label={label}
  //           onClick={() => this.onChangeCategoriesToPrint(choiceID)}
  //         />
  //       </React.Fragment>
  //     );
  //   });
  // };

  renderHelperButtons = () => {
    const { context, isConnected, printerBrand, printerName } = this.props;
    const personnelRole = _get(context, "personnel.role");
    const allowRemove = personnelRole === "owner";
    return (
      <div className={Style.helperButtons}>
        <button
          className={cx(
            Style.printTestOrderBtn,
            !allowRemove && Style.fullWidth
          )}
          onClick={() =>
            this.props.onTestPrinterConnection({
              isPrintingTestOrder: true,
              printerBrand,
              printerName,
            })
          }
        >
          {isConnected ? "Print Test order" : "Reconnect"}
        </button>
        {allowRemove && (
          <button
            className={Style.removeBtn}
            onClick={() => this.props.onRemovePrinter(printerName)}
          >
            Remove
          </button>
        )}
      </div>
    );
  };

  render() {
    return (
      <CenterModal
        closeModalButtonLabel={<CloseIcon className={Style.closeModalIcon} />}
        contentLabel="Printer info"
        modalBoxClassname={cx(
          LargeCenterModalStyle.largeCenterModalBox,
          Style.modalBoxClassname
        )}
        contentContainerClassname={
          LargeCenterModalStyle.largeCenterModalContainer
        }
        onCloseModal={this.props.onCloseModal}
        shouldCloseOnOverlayClick={true}
      >
        <Box className={Style.box}>
          <div className={Style.printerName}>
            <PrinterIcon />
            {this.props.printerName}
          </div>
          {this.renderBadges()}
          {this.renderBrandSelector()}
        </Box>
        {this.renderHelperButtons()}
      </CenterModal>
    );
  }
}

PrinterInfoModal.propTypes = {
  categoriesToPrint: PropTypes.any,
  context: PropTypes.shape({
    personnel: PropTypes.shape({ role: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
  isAddedToShop: PropTypes.bool,
  isConnected: PropTypes.bool,
  onAddPrinter: PropTypes.func,
  onChangePrinterBrand: PropTypes.func,
  onCloseModal: PropTypes.func.isRequired,
  onRemovePrinter: PropTypes.func,
  onSetPrinterAsPrimary: PropTypes.func,
  onTestPrinterConnection: PropTypes.func,
  printerBrand: PropTypes.string,
  printerName: PropTypes.string.isRequired,
};

PrinterInfoModal.defaultProps = {
  isAddedToShop: false,
  isConnected: false,
  onAddPrinter: () => {},
  onChangePrinterBrand: () => {},
  onRemovePrinter: () => {},
  onSetPrinterAsPrimary: () => {},
  onTestPrinterConnection: () => {},
};

export default withContext(MerchantInterfaceConsumer)(PrinterInfoModal);
