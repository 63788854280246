// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, CenterModal } from "fields";

class NewOrdersAlarmModal extends Component {
  render() {
    return (
      <CenterModal
        contentLabel="New orders alarm reactivation modal"
        onCloseModal={() => {}}
        modalBoxClassname={Style.modalBox}
        contentContainerClassname={Style.modalContainer}
        showCloseButton={false}
      >
        <p className={Style.dialogDescription}>
          The alarm for new orders is <strong>disabled</strong>
        </p>
        <Button
          className={Style.activateAlarmBtn}
          onClick={this.props.onActivateAlarm}
          name="Dismiss confirm order button"
        >
          Activate Alarm
        </Button>
      </CenterModal>
    );
  }
}

NewOrdersAlarmModal.propTypes = {
  onActivateAlarm: PropTypes.func.isRequired
};

export default NewOrdersAlarmModal;
