// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const Notices = (props) => {
  const renderRefundRequests = () => {
    const { refundRequests = {} } = props;
    const requests_count = Object.keys(refundRequests).length;
    return (
      requests_count > 0 && (
        <div className={Style.refundRequests} onClick={props.onShowRefundModal}>
          <div>Refund Requests</div>
          <div className={Style.requestCount}>{requests_count}</div>
        </div>
      )
    );
  };

  return (
    <div className={Style.container}>
      {/* <div className={Style.paidOrderMessage}>Order is paid by customer</div> */}
      {renderRefundRequests()}
    </div>
  );
};

Notices.propTypes = {
  refundRequests: PropTypes.object,
};

Notices.defaultProps = {
  refundRequests: {},
};

export default Notices;
