// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import Style from "../style.module.scss";

// Fields
import { Button } from "fields";

class CouponSubmitButton extends React.Component {
  onGetCreateCouponStatus = () => {
    const { couponInfo, isSubmitting } = this.props;
    const { name = "", selections = {} } = couponInfo;
    if (isSubmitting) return "loading";
    else if (!name) return "inactive";
    switch (this.props.couponType) {
      case "BUY_ONE_GET_ONE": {
        const { buyCategoryID, getCategoryID } = selections;
        if (!buyCategoryID || !getCategoryID) return "inactive";
        return "active";
      }
      case "DISCOUNT_ON_ORDERS_ABOVE_X": {
        const { amountOff, minimumTotal } = selections;
        if (
          parseFloat(amountOff || "0") === 0 ||
          parseFloat(minimumTotal || "0") === 0
        )
          return "inactive";
        return "active";
      }
      default:
        return "inactive";
    }
  };

  render() {
    const { editOnly } = this.props;
    return (
      <Button
        className={Style.createCouponButton}
        loadingText={editOnly ? "Saving" : "Creating"}
        name={`${editOnly ? "Edit" : "Create"} Coupon`}
        status={this.onGetCreateCouponStatus()}
        onClick={this.props.onClick}
      >
        {editOnly ? "Save Changes" : "Create Coupon"}
      </Button>
    );
  }
}

CouponSubmitButton.propTypes = {
  couponInfo: PropTypes.object,
  couponType: PropTypes.any.isRequired,
  editOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

CouponSubmitButton.defaultProps = {
  editOnly: false,
  isSubmitting: false
};

export default CouponSubmitButton;
