// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Utils
import { _localizeTime } from "utils";

// Style
import Parent_Style from "../style.module.scss";
import Style from "./style.module.scss";

// Icons
import {
  ClockIcon,
  DoordashIcon,
  PostmatesIcon,
  TrackIcon,
  WalkingPersonIcon,
} from "assets/Icons";

// Lib
import { Services } from "lib";

class DeliveryInfo extends React.Component {
  state = { courierClicksCount: 1, doordash_delivery_details: {} };

  componentDidMount = () => {
    const { orderInfo = {} } = this.props;
    const { orderDeliveryTypeID } = orderInfo;
    if (orderDeliveryTypeID === "deliver") {
      const { deliveryDetails = {} } = orderInfo;
      const { courier = "", courierConfirmation = {} } = deliveryDetails;
      if (courier === "doordash") {
        const { id } = courierConfirmation;
        this.on_get_doordash_delivery_details({ delivery_id: id });
      }
    }
  };

  onClickCourier = () => {
    const newClicksCount = this.state.courierClicksCount + 1;
    this.setState(
      { courierClicksCount: newClicksCount },
      () => newClicksCount > 3 && this.props.onShowDeliveryAddress()
    );
  };

  on_get_doordash_delivery_details = async ({ delivery_id }) => {
    const { GetDoordashDeliveryDetails } = Services.BUTI.GetRequests;
    try {
      const data = await GetDoordashDeliveryDetails({ delivery_id });
      const { estimated_pickup_time } = data;
      this.setState({
        doordash_delivery_details: { estimated_pickup_time },
      });
    } catch {}
  };

  on_get_estimated_pickup_time = () => {
    const { context = {}, orderInfo = {} } = this.props;
    const { doordash_delivery_details = {} } = this.state;
    const { pickUpTime = "ASAP" } = orderInfo;
    const { estimated_pickup_time } = doordash_delivery_details;
    const { shopBasicInfo = {} } = context;
    const { timeZone: time_zone } = shopBasicInfo;
    return estimated_pickup_time
      ? _localizeTime({ time: estimated_pickup_time, time_zone })
      : pickUpTime;
  };

  onGenerateTrackingLink = () => {
    const { CUSTOMER_INTERFACE_BASE_URL } = Services;
    const { order_id, shop_id } = this.props;
    return `${CUSTOMER_INTERFACE_BASE_URL}/shop/${shop_id}/order/${order_id}`;
  };

  render() {
    const { orderInfo = {} } = this.props;
    const {
      customerName,
      deliveryDetails = {},
      orderDeliveryTypeID,
    } = orderInfo;
    let courier = "";
    // const { courierConfirmation = {} } = deliveryDetails;
    // const { id: delivery_id } = courierConfirmation;

    if (orderDeliveryTypeID === "pickUp") courier = customerName;
    else {
      const { courier: deliveryCourier } = deliveryDetails;
      courier =
        deliveryCourier === "postmates" ? (
          <div className={Style.deliveryCourierIcon}>
            <PostmatesIcon />
          </div>
        ) : (
          <div className={Style.deliveryCourierIcon}>
            <DoordashIcon />
          </div>
        );
    }

    return (
      <div className={Parent_Style.infoCol}>
        <div className={Parent_Style.detailHeading}>delivery info</div>
        <div className={Parent_Style.info_cell}>
          <div className={Parent_Style.icon}>
            <ClockIcon />
          </div>
          <div className={Parent_Style.flex_wrap}>
            <strong>{this.on_get_estimated_pickup_time()}</strong>
            <div className={Parent_Style.dot_icon}>•</div>
            <div className={Style.label}>Estimated Pickup Time</div>
          </div>
        </div>
        <div className={Parent_Style.info_cell}>
          <div className={Parent_Style.icon}>
            <WalkingPersonIcon />
          </div>
          <div className={Parent_Style.flex_wrap}>
            <strong>{courier}</strong>
            <div className={Parent_Style.dot_icon}>•</div>
            <div className={Style.label}>Pickup By</div>
          </div>
        </div>
        <div className={Parent_Style.info_cell} style={{ marginTop: "20px" }}>
          <div className={Parent_Style.icon}>
            <TrackIcon />
          </div>
          <div className={Parent_Style.flex_wrap}>
            <strong
              className={Style.track_order_link}
              onClick={() => window.open(this.onGenerateTrackingLink())}
            >
              Track Order
            </strong>
            <button className={Style.share_tracking_button}>Share</button>
          </div>
        </div>
        {/* <div onClick={this.onClickCourier}>
          <div style={{ opacity: "0.8" }}>Pickup By</div>
          <strong>{courier}</strong>
        </div>
        {orderDeliveryTypeID === "deliver" && (
          <div onClick={() => window.open(tracking_url)}>
            <div style={{ opacity: "0.8" }}>Delivery ID</div>
            <strong className={Style.delivery_id}>{delivery_id}</strong>
          </div>
        )} */}
      </div>
    );
  }
}

DeliveryInfo.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  order_id: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    deliveryDetails: PropTypes.shape({
      courier: PropTypes.oneOf(["doordash", "postmates"]).isRequired,
      deliveryAddress: PropTypes.shape({
        streetAddress1: PropTypes.string,
        streetAddress2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
      }).isRequired,
    }),
    orderDeliveryTypeID: PropTypes.oneOf(["inStore", "deliver", "pickUp"])
      .isRequired,
    pickUpTime: PropTypes.string,
  }).isRequired,
  shop_id: PropTypes.string.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(DeliveryInfo);
