// BUTI DINERS, INC. All right Reserved ©

import Group from "./Group";
import Item from "./Item";
import Menu from "./Menu";
import Modifier from "./Modifier";
import ModifierGroup from "./ModifierGroup";

const MenusManagement = {
  Group,
  Item,
  Menu,
  Modifier,
  ModifierGroup
};

export default MenusManagement;
