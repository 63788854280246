import React from "react";
import PropTypes from "prop-types";
import LargeCenterModalStyle from "components/Modals/style.module.scss";

// Style
import TimePicker from "react-time-picker";
import { ArrowIcon } from "assets/Icons";

import { Box } from "fields";

// Fields
import { CheckBoxGroup, RadioGroup } from "components";

// Lib
import { Constants } from "lib";
class Availability extends React.Component {
  handleStartDateChange = (start_time = "") => {
    this.props.handleTimeChange("start_time", start_time);
  };
  handleEndDateChange = (end_time = "") => {
    this.props.handleTimeChange("end_time", end_time);
  };
  onClickRadioButton = (fieldID = "", optionID = "") => {
    this.props.updateTimeSetting(fieldID, optionID);
  };

  onClickRadioButtonDate = (fieldID = "", optionID = "") => {
    this.props.updateTimeSetting(fieldID, optionID, true);
  };

  renderAvailability = () => {
    const { NEW_GROUP_AVAILABILITY } = Constants;
    return NEW_GROUP_AVAILABILITY.map((field) => {
      const { fieldKind = "", id = "" } = field;
      return (
        fieldKind === "radio" && (
          <div className={LargeCenterModalStyle.radioForm} key={id}>
            <RadioGroup
              heading={field.label || ""}
              onClick={(optionID) => this.onClickRadioButtonDate(id, optionID)}
              options={field.options || {}}
              required={field.required || false}
              option_props={{
                has_border: true,
                has_border_style: LargeCenterModalStyle.has_border_style,
              }}
              selectedOptionId={
                this.props.groupInfo?.time_setting?.[id] || "every_day"
              }
            />
          </div>
        )
      );
    });
  };

  renderSelectionDate = ({ date_selection, selected_date_options }) => {
    return date_selection === "custom_date" ? (
      <CheckBoxGroup
        heading="Select date"
        selectedOptionIds={selected_date_options}
        onClick={this.props.handleOptionClick}
        options={{
          monday: { label: "Monday" },
          tuesday: { label: "Tuesday" },
          wednesday: { label: "Wednesday" },
          thursday: { label: "Thursday" },
          friday: { label: "Friday" },
          saturday: { label: "Saturday" },
          sunday: { label: "Sunday" },
        }}
        option_props={{
          has_border: true,
          has_border_style: LargeCenterModalStyle.has_border_style,
        }}
        required
      />
    ) : null;
  };

  renderTimeSelection = ({
    date_selection,
    selected_date_options,
    time_selection,
  }) => {
    return (
      (selected_date_options.length > 0 || date_selection === "every_day") && (
        <RadioGroup
          heading="Time Selection"
          onClick={(option_id) =>
            this.onClickRadioButton("time_selection", option_id)
          }
          options={{
            all_day: { label: `All Day` },
            custom_time: { label: `Custom Time` },
          }}
          option_props={{
            has_border: true,
            has_border_style: LargeCenterModalStyle.has_border_style,
          }}
          required
          selectedOptionId={time_selection || "all_day"}
        />
      )
    );
  };

  renderCustomTimePicker = ({ time_selection, end_time, start_time }) => {
    return (
      time_selection === "custom_time" && (
        <div className={LargeCenterModalStyle.heading}>
          <div className={LargeCenterModalStyle.headingText}>Select Time</div>
          <div className={LargeCenterModalStyle.time_pickers_group}>
            <TimePicker
              className={LargeCenterModalStyle.time_picker}
              clearIcon={null}
              disableClock
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={this.handleStartDateChange}
              value={start_time}
            />
            <div className={LargeCenterModalStyle.arrow_icon_time}>
              <ArrowIcon />
            </div>
            <TimePicker
              className={LargeCenterModalStyle.time_picker}
              clearIcon={null}
              disableClock
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={this.handleEndDateChange}
              value={end_time}
            />
          </div>
        </div>
      )
    );
  };

  renderAvailabilityBox = () => {
    const { groupInfo = {} } = this.props;
    const { time_setting = {} } = groupInfo;
    const {
      date_selection = "every_day",
      end_time = "",
      selected_date_options = [],
      start_time = "",
      time_selection = "all_day",
    } = time_setting;

    return (
      <Box header="Availability">
        {this.renderAvailability()}
        <div>
          {this.renderSelectionDate({ date_selection, selected_date_options })}
          {this.renderTimeSelection({
            date_selection,
            selected_date_options,
            time_selection,
          })}
          {this.renderCustomTimePicker({
            time_selection,
            end_time,
            start_time,
          })}
        </div>
      </Box>
    );
  };

  render() {
    return this.renderAvailabilityBox();
  }
}

Availability.propTypes = {
  groupInfo: PropTypes.shape({
    time_setting: PropTypes.object,
  }).isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  updateTimeSetting: PropTypes.func.isRequired,
};
Availability.defaultProps = {
  groupInfo: {},
};
export default Availability;
