// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { PRESET_COLORS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input, ResponsiveModal } from "fields";

class ColorPicker extends React.Component {
  state = { showColorTable: false };

  onGetInputValue = () => {
    const { currentHexColor = "#fff" } = this.props;
    return PRESET_COLORS.includes(currentHexColor)
      ? ""
      : currentHexColor.replace("#", "");
  };

  onInputChange = (hexColor) =>
    this.props.onChange(hexColor.includes("#") ? hexColor : `#${hexColor}`);

  renderPresetColors = () => {
    const { currentHexColor = "#fff" } = this.props;
    return PRESET_COLORS.map((hexColor) => (
      <div
        className={cx(
          Style.preset_color_block,
          hexColor === currentHexColor && Style.selected,
          hexColor === "#ffffff" && Style.highlight_white_block
        )}
        key={hexColor}
        onClick={() => this.props.onChange(hexColor)}
        style={{ background: hexColor }}
      />
    ));
  };

  renderColorTable = () => (
    <ResponsiveModal
      classNames={{ modal: Style.modal }}
      contentLabel="Change website theme color"
      headerProps={{ header: "Color Picker" }}
      onCloseModal={() => this.setState({ showColorTable: false })}
    >
      <div className={Style.selectNewColor}>Select a new color</div>
      <div className={Style.presetColors}>{this.renderPresetColors()}</div>
      <div className={Style.selectNewColor} style={{ marginTop: "30px" }}>
        Or Enter your theme color (HEX)
      </div>
      <div className={Style.hexInput}>
        <div className={Style.hexSymbol}>#</div>
        <Input.TextInput
          name="hex color input"
          onChange={this.onInputChange}
          value={this.onGetInputValue()}
        />
      </div>
    </ResponsiveModal>
  );

  render() {
    const {
      class_names = {},
      currentHexColor = "#fff",
      heading = "Your current color",
    } = this.props;

    return (
      <div>
        <div
          className={cx(Style.headingContainer, class_names.heading_container)}
        >
          <div className={cx(Style.heading, class_names.heading)}>
            {heading}
          </div>
          <Button
            className={Style.changeColorBtn}
            name="change color"
            onClick={() => this.setState({ showColorTable: true })}
          >
            <div
              className={Style.current_color_block}
              style={{ background: currentHexColor, marginBottom: "0" }}
            />
            <div className={Style.current_hex_color}>{currentHexColor}</div>
          </Button>
        </div>
        {this.state.showColorTable && this.renderColorTable()}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  class_names: PropTypes.shape({
    heading_container: PropTypes.string,
    heading: PropTypes.string,
  }),
  currentHexColor: PropTypes.string,
  heading: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
