// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import ArchiveButton from "views/MenusManagement/ArchiveButton";
import ModifierGroupFormFields from "./ModifierGroupFormFields";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions, Services } from "lib";

class ModifierGroupModal extends Component {
  confirmNotif = null;
  state = { showLoadingModal: false };

  componentWillUnmount = () => this.setState({ showLoadingModal: false });

  onArchiveModifierGroup = ({ shouldArchiveModifierGroup = false }) => {
    const { Merchants } = Services;
    const { ArchiveModifierGroup } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { context, modifierGroupID } = this.props;
    const { shopID } = context;
    const params = { modifierGroupID, shouldArchiveModifierGroup, shopID };
    ArchiveModifierGroup(params)
      .then(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: `${shouldArchiveModifierGroup ? "Archived" : "Restored"} ${
            this.props.modifierGroupInfo.modifierGroupName
          }`,
          type: "success",
        });
        this.props.onCloseModal();
        this.props.onUpdateAfterModifierGroupArchived({
          shouldArchiveModifierGroup,
        });
      })
      .catch(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: "An error has occured. Please try again.",
          type: "error",
        });
      });
  };

  onCreateModifierGroup = async (modifierGroupInfo) => {
    const { Merchants } = Services;
    const { CreateNewModifierGroup } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const params = { modifierGroupInfo, shopID: this.props.context.shopID };
    const { modifierGroupID } = await CreateNewModifierGroup(params);
    if (!modifierGroupID) return false;
    this.props.onUpdateModifierGroupsAfterCreate({
      modifierGroupID,
      modifierGroupInfo,
    });
    return true;
  };

  onSaveModifierGroup = async (modifierGroupInfo) => {
    const { Merchants } = Services;
    const { SaveChangedModifierGroupInfo } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { modifierGroupID } = this.props;
    const params = {
      modifierGroupID,
      modifierGroupInfo,
      shopID: this.props.context.shopID,
    };
    if (await SaveChangedModifierGroupInfo(params)) {
      this.props.onUpdateAfterModifierGroupSaved({
        modifierGroupInfo,
      });
      return true;
    }
    return false;
  };

  onSubmitModifierGroupInfo = async (modifierGroupInfo) => {
    const { ShowConfirmNotif } = Functions;
    const { isInEditMode } = this.props;
    const res = await (isInEditMode
      ? this.onSaveModifierGroup(modifierGroupInfo)
      : this.onCreateModifierGroup(modifierGroupInfo));
    if (res) {
      this.confirmNotif = ShowConfirmNotif({
        message: `${isInEditMode ? "Saved" : "Created"} ${
          modifierGroupInfo.modifierGroupName
        }`,
        type: "success",
      });
      this.props.onCloseModal();
    } else {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error",
      });
    }
  };

  onHideConfirmArchiveModal = (e) =>
    this.setState({ showConfirmArchiveModal: false });

  onShowConfirmArchiveModal = () =>
    this.setState({ showConfirmArchiveModal: true });

  renderArchiveModifierGroupButton = () => {
    const { modifierGroupIsArchived } = this.props.modifierGroupInfo;
    return (
      <ArchiveButton
        isInEditMode={this.props.isInEditMode}
        isObjectArchived={modifierGroupIsArchived}
        isReadOnly={this.props.isReadOnly}
        name="Archive Modifier Group"
        onClick={() => {
          modifierGroupIsArchived
            ? this.onArchiveModifierGroup({ shouldArchiveModifierGroup: false })
            : this.onShowConfirmArchiveModal();
        }}
      />
    );
  };

  renderModalTitle = () => {
    if (this.props.isReadOnly) return "Modifier group info";
    return `${this.props.isInEditMode ? "Edit" : "New"} modifier group`;
  };

  render() {
    const { isInEditMode, isReadOnly, modifierGroupInfo } = this.props;
    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentLabel="Create or edit modifier group modal"
        headerProps={{
          close_button_icon: "arrow",
          header: this.renderModalTitle(),
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.state.showConfirmArchiveModal && (
          <Modals.ConfirmArchiveModifierGroupModal
            modifierGroupName={modifierGroupInfo.modifierGroupName}
            onArchiveModifierGroup={() =>
              this.onArchiveModifierGroup({ shouldArchiveModifierGroup: true })
            }
            onCloseModal={this.onHideConfirmArchiveModal}
          />
        )}
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        <ModifierGroupFormFields
          isInEditMode={isInEditMode}
          isReadOnly={isReadOnly}
          modifierGroupID={this.props.modifierGroupID}
          modifierGroupInfo={modifierGroupInfo}
          onSubmit={this.onSubmitModifierGroupInfo}
          shopID={this.props.context.shopID}
        />
        {this.renderArchiveModifierGroupButton()}
      </ResponsiveModal>
    );
  }
}

ModifierGroupModal.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  modifierGroupID: PropTypes.string,
  modifierGroupInfo: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
  onUpdateModifierGroupsAfterCreate: PropTypes.func,
  onUpdateAfterModifierGroupArchived: PropTypes.func,
  onUpdateAfterModifierGroupSaved: PropTypes.func,
};

ModifierGroupModal.defaultProps = {
  isInEditMode: false,
  isReadOnly: false,
  modifierGroupInfo: {},
  onUpdateModifierGroupsAfterCreate: () => {},
  onUpdateAfterModifierGroupArchived: () => {},
  onUpdateAfterModifierGroupSaved: () => {},
};

export default withContext(MerchantInterfaceConsumer)(ModifierGroupModal);
