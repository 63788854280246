// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ReactModal from "react-modal";

// Views
import { PageNotFound } from "views";

// Style
import "./index.css";

// Constants
import { Constants } from "lib";
const { ROUTES } = Constants;

window.AudioContext = window.AudioContext || window.webkitAudioContext || false;

ReactModal.setAppElement("#root");

const Routing = () => {
  if (window.location.protocol !== "https:") {
    const secure = document.URL.replace(/^http:/, "https:");
    window.location.replace(secure);
  } else {
    return (
      <Router>
        <div style={{ height: "100%" }}>
          <Switch>
            <Route exact path="/" render={(props) => ROUTES("home", props)} />
            <Route
              exact
              path="/stripeRedirect"
              render={(props) => ROUTES("stripeRedirect", props)}
            />
            {/* Must place stripeRedirect before :shopID otherwise stripeRedirect will be treated as a shop and breaks merchant account creation */}
            <Route
              exact
              path="/new-merchant-account"
              render={(props) => ROUTES("online-ordering-agreement", props)}
            />
            <Route
              exact
              path="/new-merchant-account/:formId"
              render={(props) => ROUTES("online-ordering-agreement", props)}
            />
            <Route
              exact
              path="/online-ordering-agreement"
              render={(props) => ROUTES("online-ordering-agreement", props)}
            />
            <Route
              exact
              path="/online-ordering-agreement/:formId"
              render={(props) => ROUTES("online-ordering-agreement", props)}
            />
            <Route
              exact
              path="/:shopID"
              render={(props) => ROUTES("shopAdmin", props)}
            />
            <Route
              exact
              path="/:shopID/signin/:personnelPin"
              render={(props) => ROUTES("shopAdmin", props)}
            />
            <Route
              exact
              path="/:shopID/payments"
              render={(props) => ROUTES("payments", props)}
            />
            <Route
              exact
              path="/:shopID/payouts"
              render={(props) => ROUTES("payouts", props)}
            />
            <Route component={PageNotFound} />
          </Switch>
          <ToastContainer transition={Flip} />
        </div>
      </Router>
    );
  }
};

ReactDOM.render(Routing(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
