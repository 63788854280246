// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import ModuleStyle from "../style.module.scss";

// Fields
import { Input } from "fields";

class ShopMembership extends React.Component {
  render() {
    const { status } = this.props.membership;
    return (
      <div className={ModuleStyle.field}>
        <Input.TextInput
          label="Status"
          name="Membership status"
          value={status}
          readOnly
        />
      </div>
    );
  }
}

ShopMembership.propTypes = {
  membership: PropTypes.object,
};

ShopMembership.defaultProps = {
  membership: {},
};

export default ShopMembership;
