/* eslint-disable jsx-a11y/anchor-has-content */
// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Compressor from "compressorjs";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input, ResponsiveModal } from "fields";

// Icons
import { ArrowIcon, CloseIcon } from "assets/Icons";

class ImageUpload extends React.Component {
  state = { selectedFile: {} };

  onGetImageQuality = () => {
    const { imageQuality = 0.8 } = this.props;
    const quality = parseFloat(imageQuality);
    if (isNaN(quality) || quality < 0) return 0.8;
    else if (quality > 1) return 1;
    return quality;
  };

  onChangeInstagramURL = (instagramURL) =>
    this.setState({ instagramURL }, this.onGetInstagramPostImage);

  onGetInstagramPostImage = () => {
    axios.get(`${this.state.instagramURL}media/?size=l`).then(({ request }) => {
      if (request && request.responseURL) {
        this.props.onChangeImageURL(request.responseURL || "");
      }
    });
  };

  onHideSelectFileSection = () =>
    this.setState({ selectedFile: {}, showUpdatePhotoSection: false }, () =>
      this.props.onChangeImageFile(null)
    );

  onShowSelectFileSection = () =>
    this.setState({ showUpdatePhotoSection: true });

  onSelectFile = (event) => {
    const {
      onChangeImageFile,
      uploadMaxHeight = 600,
      uploadMaxWidth = 600,
    } = this.props;
    const files = event.target.files;
    if (files[0]) {
      const selectedFile = files[0];
      this.setState({ selectedFile }, () => {
        new Compressor(selectedFile, {
          quality: this.onGetImageQuality(),
          maxHeight: uploadMaxHeight,
          maxWidth: uploadMaxWidth,
          mimeType: "JPEG",
          success(result) {
            var reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onloadend = () => onChangeImageFile(reader.result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    }
  };

  renderConfirmRemoveModal = () => (
    <ResponsiveModal
      contentDescriptionProps={{
        contentDescription:
          "Once you remove the photo, it is deleted permenantly.",
        showContentDescription: true,
      }}
      contentLabel="Confirm Remove Photo Modal"
      headerProps={{ header: "Remove Photo?" }}
      onCloseModal={() => this.setState({ show_confirm_remove_modal: false })}
    >
      <Button
        className={Style.confirm_remove_button}
        name="confirm delete"
        onClick={this.props.onRemovePhoto}
      >
        Yes, remove
      </Button>
      <Button
        className={Style.cancel_remove_button}
        name="close confirm remove modal"
        onClick={() => this.setState({ show_confirm_remove_modal: false })}
      >
        Keep Photo
      </Button>
    </ResponsiveModal>
  );

  renderInstagramUrlInput = () => (
    <Input.TextInput
      hasMultipleLines={true}
      label="Instagram Post URL"
      name="Instagram URL Input"
      placeholder="Paste the instagram post url here"
      onChange={this.onChangeInstagramURL}
      rows={3}
      value={this.state.instagramURL || ""}
    />
  );

  renderUpdatePhotoSection = () => {
    let selectedFileName = "";
    const { selectedFile = {} } = this.state;
    if (selectedFile) {
      const { name } = selectedFile;
      selectedFileName = name;
    }
    return (
      <div className={Style.selectPhotoContainer}>
        <div className={Style.selectPhotoInput}>
          <label className={Style.fileInput}>
            <div className={Style.selectFileBtnContainer}>
              <div className={Style.selectFileBtn}>Select</div>
              <div className={Style.fileName}>
                {selectedFileName || "No file selected"}
              </div>
            </div>
            <input accept="image/*" type="file" onChange={this.onSelectFile} />
          </label>
        </div>
        <Button
          className={Style.cancelSelectFile}
          name="keep current photo"
          onClick={this.onHideSelectFileSection}
        >
          <ArrowIcon /> Keep current photo
        </Button>
      </div>
    );
  };

  render() {
    const { image_url = "" } = this.props;
    if (this.state.showUpdatePhotoSection)
      return <div>{this.renderUpdatePhotoSection()}</div>;
    return (
      <React.Fragment>
        {this.state.show_confirm_remove_modal &&
          this.renderConfirmRemoveModal()}
        <div className={Style.buttons}>
          <Button
            className={Style.updatePictureBtn}
            name="update photo"
            onClick={this.onShowSelectFileSection}
          >
            <div className={Style.add_photo_icon}>
              <ArrowIcon />
            </div>
            {image_url ? "Update" : "Upload"}
          </Button>
          {image_url && (
            <Button
              className={Style.remove_photo_button}
              name="remove photo"
              onClick={() => this.setState({ show_confirm_remove_modal: true })}
            >
              <div className={Style.close_icon}>
                <CloseIcon />
              </div>
              Remove
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

ImageUpload.propTypes = {
  imageQuality: PropTypes.number,
  image_url: PropTypes.string,
  onChangeImageURL: PropTypes.func,
  onChangeImageFile: PropTypes.func,
  onRemovePhoto: PropTypes.func,
  uploadMaxHeight: PropTypes.number,
  uploadMaxWidth: PropTypes.number,
};

ImageUpload.defaultProps = {
  imageQuality: 95,
  image_url: "",
  onChangeImageURL: () => {},
  onChangeImageFile: () => {},
  onRemovePhoto: () => {},
  uploadMaxHeight: 600,
  uploadMaxWidth: 600,
};

export default ImageUpload;
