// BUTI DINERS, INC. All right Reserved ©

import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const _sortReviews = ({ guest_reviews }) =>
  Object.keys(guest_reviews).sort((review_a_id, review_b_id) => {
    const { createdAt: time_stamp_1 } = guest_reviews[review_a_id];
    const { createdAt: time_stamp_2 } = guest_reviews[review_b_id];
    return dayjs(time_stamp_2).diff(dayjs(time_stamp_1));
  });

export { _sortReviews };
