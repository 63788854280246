// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Style from "./style.module.scss";

// Fields
import { Checkbox, ResponsiveModal } from "fields";

class ConfirmCloseAllOrderModal extends React.Component {
  state = { confirm_close_all_order: true };

  onGetSubmitButtonStatus = () => {
    const { confirm_close_all_order } = this.state;

    return confirm_close_all_order ? "active" : "inactive";
  };
  render() {
    const { confirm_close_all_order } = this.state;
    const {
      sendAllOrderReadyText,
      onSendAllOrderReadyTextChange,
      confirmed_orders={},
    } = this.props;

    return (
      <ResponsiveModal
        contentLabel="confirm all close order modal"
        contentDescriptionProps={{
          contentDescription: (
            <div>
              All accepted order will move to <strong>Past Orders</strong> after you
              click Submit
            </div>
          ),
          showContentDescription: true,
        }}
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Submit",
            loadingText: "Submitting",
            status: this.onGetSubmitButtonStatus(),
          },
        }}
        headerProps={{ header: `Close ${confirmed_orders.length} order${confirmed_orders.length === 1 ? '' : 's'}` }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.props.onCloseAllActiveOrder}
      >
        <div className={Style.checkbox}>
          <Checkbox
            id="notify guest"
            checked={sendAllOrderReadyText}
            label="Notify customer the order is ready for pick up/delivery"
            onClick={() =>
              onSendAllOrderReadyTextChange(!sendAllOrderReadyText)
            }
          />
        </div>
        <div>
          <Checkbox
            id="notify guest"
            checked={confirm_close_all_order}
            label={`I want to close ${confirmed_orders.length} order${confirmed_orders.length === 1 ? '' : 's'}`}
            onClick={() =>
              this.setState({
                confirm_close_all_order: !confirm_close_all_order,
              })
            }
          />
        </div>
      </ResponsiveModal>
    );
  }
}

ConfirmCloseAllOrderModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onCloseAllActiveOrder: PropTypes.func.isRequired,
  confirmed_orders: PropTypes.array.isRequired,
};

export default ConfirmCloseAllOrderModal;
