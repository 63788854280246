const dayjs = require("dayjs");
const _translateRating = (selected_rating_id) => {
  switch (selected_rating_id) {
    case "rating_1":
      return 1;
    case "rating_2":
      return 2;
    case "rating_3":
      return 3;
    case "rating_4":
      return 4;
    case "rating_5":
      return 5;
    default:
      return;
  }
};
const _reviewToday = ({ guest_reviews = {}, timeZone }) => {
  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  let count_review_today = 0;
  let reviewsYesterday = 0;
  let total_selected_ratings = 0;
  let total_reviews_today = 0;

  for (const reviewId in guest_reviews) {
    if (guest_reviews.hasOwnProperty(reviewId)) {
      const review = guest_reviews[reviewId];
      const createdAt = dayjs.utc(review.createdAt).tz(timeZone);
      if (createdAt.isSame(today, "day")) {
        count_review_today++;
        // Calculate the total selected rating for today's reviews
        if (review.selected_rating_id) {
          total_selected_ratings += parseInt(
            review.selected_rating_id.replace("rating_", ""),
            10
          );
          total_reviews_today++;
        }
      } else if (createdAt.isSame(yesterday, "day")) {
        reviewsYesterday++;
      }
    }
  }

  const percentage_change =
    reviewsYesterday === 0 && count_review_today !== 0
      ? 100
      : reviewsYesterday === 0 && count_review_today === 0
      ? 0
      : ((count_review_today - reviewsYesterday) / reviewsYesterday) * 100;
  const average_selected_rating_day =
    total_reviews_today > 0 ? total_selected_ratings / total_reviews_today : 0;

  return { count_review_today, percentage_change, average_selected_rating_day };
};

const _7dayAgoReviews = ({ guest_reviews = {}, timeZone }) => {
  const today = dayjs();
  const count_review_7 = [];
  const reviewsIn14Days = [];
  let total_selected_ratings = 0;
  let total_reviews_within_7_days = 0;

  for (const reviewId in guest_reviews) {
    if (guest_reviews.hasOwnProperty(reviewId)) {
      const review = guest_reviews[reviewId];
      const createdAt = dayjs.utc(review.createdAt).tz(timeZone);
      if (createdAt.isAfter(today.subtract(7, "day"))) {
        count_review_7.push(review);
        // Calculate the total selected rating for reviews within the last 7 days
        if (review.selected_rating_id) {
          total_selected_ratings += parseInt(
            review.selected_rating_id.replace("rating_", ""),
            10
          );
          total_reviews_within_7_days++;
        }
      }
      if (
        createdAt.isAfter(today.subtract(14, "day")) &&
        createdAt.isBefore(today.subtract(7, "day"))
      ) {
        reviewsIn14Days.push(review);
      }
    }
  }

  const percentage_change_weeks =
    reviewsIn14Days.length === 0 && count_review_7.length !== 0
      ? 100
      : reviewsIn14Days.length === 0 && count_review_7.length === 0
      ? 0
      : ((count_review_7.length - reviewsIn14Days.length) /
          reviewsIn14Days.length) *
        100;
  const average_selected_rating_week =
    total_reviews_within_7_days > 0
      ? total_selected_ratings / total_reviews_within_7_days
      : 0;

  return {
    count_review_weeks: count_review_7.length,
    percentage_change_weeks,
     average_selected_rating_week,
  };
};


const _30dayAgoReviews = ({ guest_reviews = {}, timeZone }) => {
  const today = dayjs();
  const count_review_30 = [];
  const reviewsIn60Days = [];
  let total_selected_ratings = 0;
  let total_reviews_within_30_days = 0;

  for (const reviewId in guest_reviews) {
    if (guest_reviews.hasOwnProperty(reviewId)) {
      const review = guest_reviews[reviewId];
      const createdAt = dayjs.utc(review.createdAt).tz(timeZone);
      if (createdAt.isAfter(today.subtract(30, "day"))) {
        count_review_30.push(review);
        if (review.selected_rating_id) {
          total_selected_ratings += parseInt(
            review.selected_rating_id.replace("rating_", ""),
            10
          );
          total_reviews_within_30_days++;
        }
      }
      if (
        createdAt.isAfter(today.subtract(60, "day")) &&
        createdAt.isBefore(today.subtract(30, "day"))
      ) {
        reviewsIn60Days.push(review);
      }
    }
  }
  const percentage_change_months =
    reviewsIn60Days.length === 0 && count_review_30.length !== 0
      ? 100
      : count_review_30.length === 0 && count_review_30.length === 0
      ? 0
      : ((count_review_30.length - reviewsIn60Days.length) /
          reviewsIn60Days.length) *
        100;
  const average_selected_rating_within_30_days =
    total_reviews_within_30_days > 0
      ? total_selected_ratings / total_reviews_within_30_days
      : 0;

  return {
    count_review_months: count_review_30.length,
    percentage_change_months,
   average_selected_rating_within_30_days,
  };
};


const _countReviewsByMonth = ({ guest_reviews = {}, timeZone }) => {
  const current_month = dayjs();
  const six_months_ago = current_month.subtract(6, "month");
  const reviewCounts = Object.values(guest_reviews).reduce((counts, review) => {
    const reviewDate = dayjs.utc(review.createdAt).tz(timeZone);
    if (
      reviewDate.isAfter(six_months_ago) &&
      reviewDate.isBefore(current_month, "month")
    ) {
      const monthYear = reviewDate.format("MMM");
      const ratingId = review.selected_rating_id;
      const reviewType = ["rating_1", "rating_2", "rating_3"].includes(ratingId)
        ? "bad"
        : "good";

      counts[monthYear] = counts[monthYear] || { good: 0, bad: 0 };
      counts[monthYear][reviewType] = (counts[monthYear][reviewType] || 0) + 1;
    }
    return counts;
  }, {});
  const result = Array.from({ length: 7 }).map((_, index) => {
    const currentMonthCursor = six_months_ago.add(index, "month");
    const monthYear = currentMonthCursor.format("MMM");
    const { good = 0, bad = 0 } = reviewCounts[monthYear] || {};
    return { monthYear, good, bad };
  });

  return result;
};

export {
  _translateRating,
  _7dayAgoReviews,
  _30dayAgoReviews,
  _reviewToday,
  _countReviewsByMonth,
};
