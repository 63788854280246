// BUTI DINERS, INC. All right Reserved ©

import dayjs from "dayjs";

const getYearsFrom2020ToCurrent = () => {
  const currentYear = dayjs().year(); 
  const startYear = 2020;
  let years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ id: String(year), name: String(year) });
  }
  return years;
};

const months = Array.from({ length: 12 }, (_, i) => ({
  id: i + 1,
  label: dayjs().month(i).format("MMMM"),
}));

const YEAR_MODULES = getYearsFrom2020ToCurrent();
const MONTH_MODULES =months.reduce((acc, month) => {
  acc[month.id] = { label: month.label };
  return acc;
}, {})

export { YEAR_MODULES,MONTH_MODULES };
