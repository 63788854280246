// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set, wrap } from "object-path-immutable";
import { formatToTimeZone } from "date-fns-timezone/dist/formatToTimeZone";

import { SAMPLE_DELIVERY_ORDER } from "./HelperFunctions";

import AllowPrintingOrderSelector from "./AllowPrintingOrderSelector";
import AddedPrinters from "./AddedPrinters";
import SearchPrinters from "./SearchPrinters";

// Style
import Style from "./style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { Modals } from "components";

// Fields
import { Button, ExpansionPanel, Link } from "fields";

// Lib
import { Constants, Functions, Services } from "lib";

class PrintersModule extends React.Component {
  confirmNotif = null;
  state = {};

  onAddPrinter = async (printerName = "") => {
    this.setState({ showLoadingModal: true });
    try {
      const { Merchants } = Services;
      const { AddPrinter } = Merchants.PostRequests;
      const {
        addedPrinters = {},
        onUpdateAddedPrinters,
        shopID,
      } = this.props.context;

      const isPrinterPrimary = Object.keys(addedPrinters).length === 0;
      const { success } = await AddPrinter({
        printerName,
        printerInfo: { isPrimary: isPrinterPrimary },
        shopID,
      });
      this.setState({ showLoadingModal: false }, () => {
        if (success) {
          onUpdateAddedPrinters(
            wrap(addedPrinters)
              .set(`${printerName}.isPrimary`, isPrinterPrimary)
              .set(`${printerName}.isConnected`, true)
              .value()
          );
        } else this.onHandleAddPrinterFail(printerName);
      });
    } catch {
      this.setState({ showLoadingModal: false }, () =>
        this.onHandleAddPrinterFail(printerName)
      );
    }
  };

  onConvertTimestamp = ({ timeStamp }) => {
    const { timeZone } = this.props.context.shopBasicInfo;
    const { DATE_FORMAT, DEFAULT_TIMEZONE, TIME_FORMAT } = Constants;
    const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
    return formatToTimeZone(timeStamp, DATETIME_FORMAT, {
      timeZone: timeZone || DEFAULT_TIMEZONE,
    });
  };

  onChangePrinterBrand = async ({ printerBrand, printerName }) => {
    this.setState({ showLoadingModal: true });
    try {
      const { Merchants } = Services;
      const { ChangePrinterBrand } = Merchants.PostRequests;
      const {
        addedPrinters,
        onUpdateAddedPrinters,
        shopID,
      } = this.props.context;
      const { success } = await ChangePrinterBrand({
        printerBrand,
        printerName,
        shopID,
      });
      success
        ? onUpdateAddedPrinters(
            set(addedPrinters, `${printerName}.printerBrand`, printerBrand)
          )
        : this.onHandleChangePrinterBrandFail(printerName);
    } catch {
      this.onHandleChangePrinterBrandFail(printerName);
    } finally {
      this.setState({ showLoadingModal: false });
    }
  };

  onHandleAddPrinterFail = (printerName) => {
    this.confirmNotif = Functions.ShowConfirmNotif({
      message: `Failed to add ${printerName}. Please try again.`,
      type: "error",
    });
  };

  onHandleChangePrinterBrandFail = (printerName) => {
    this.confirmNotif = Functions.ShowConfirmNotif({
      message: `Failed to change the brand of ${printerName}. Please try again.`,
      type: "error",
    });
  };

  onHandleRemovePrinterFail = (printerName) => {
    this.confirmNotif = Functions.ShowConfirmNotif({
      message: `Failed to remove ${printerName}. Please try again.`,
      type: "error",
    });
  };

  onRemovePrinter = async (printerName) => {
    this.setState({ showLoadingModal: true });
    try {
      const { Merchants } = Services;
      const { RemovePrinter } = Merchants.PostRequests;
      const {
        addedPrinters,
        onUpdateAddedPrinters,
        shopID,
      } = this.props.context;
      const { success } = await RemovePrinter({ printerName, shopID });
      success
        ? onUpdateAddedPrinters(del(addedPrinters, printerName))
        : this.onHandleRemovePrinterFail(printerName);
    } catch {
      this.onHandleRemovePrinterFail(printerName);
    } finally {
      this.setState({ showLoadingModal: false });
    }
  };

  onTestPrinterConnection = ({
    isPrintingTestOrder = false,
    printerBrand = "epson",
    printerName = "",
  }) => {
    const {
      addedPrinters,
      onConnectToPrintServer,
      onUpdateAddedPrinters,
      printerQZConfigs = {},
      shopBasicInfo,
    } = this.props.context;
    const { OrdersManagement, ShowConfirmNotif } = Functions;
    const { _printOrder } = OrdersManagement;
    const { timeZone } = shopBasicInfo;
    try {
      _printOrder({
        isPrintingTestOrder,
        qzConfigForPrinter: printerQZConfigs[printerName],
        orderID: "44BuAE",
        orderInfo: SAMPLE_DELIVERY_ORDER,
        printerBrand,
        printerName,
        shopTimeZone: timeZone,
      });
    } catch (error) {
      let message = error;
      if (error === "printServerNotConnected") {
        message = "Print server is not connected";
        onConnectToPrintServer();
      } else if (error === "printerNotConnected") {
        message = `${printerName} printer is not connected`;
        onUpdateAddedPrinters(
          set(addedPrinters, `${printerName}.isConnected`, false)
        );
      }
      ShowConfirmNotif({ message, type: "error" });
    }
  };

  renderOfflinePrintServer = () => {
    const { isConnectingToPrintServer } = this.props.context;
    return (
      <div>
        <div className={Style.offlineTitle}>Print Server not found</div>
        <p>
          Please open QZ Tray on this device or{" "}
          <Link className={Style.qzDownloadLink} href="https://qz.io/download/">
            download here
          </Link>
          .
        </p>
        <Button
          className={Style.connectPrintServer}
          loadingText="Connecting"
          name="Connect to print server"
          onClick={this.props.context.onConnectToPrintServer}
          status={isConnectingToPrintServer ? "loading" : "active"}
        >
          Connect to Print Server
        </Button>
      </div>
    );
  };

  renderOnlinePrintServer = () => (
    <React.Fragment>
      <AddedPrinters
        onChangePrinterBrand={this.onChangePrinterBrand}
        onRemovePrinter={this.onRemovePrinter}
        onTestPrinterConnection={this.onTestPrinterConnection}
      />
      {!this.state.showAddPrinterBox ? (
        <Button
          className={Style.addPrinter}
          hasShadow
          name="add printer button"
          onClick={() => this.setState({ showAddPrinterBox: true })}
        >
          Add Printer
        </Button>
      ) : (
        <SearchPrinters
          onAddPrinter={this.onAddPrinter}
          onHideAddPrinterBox={() =>
            this.setState({ showAddPrinterBox: false })
          }
        />
      )}
    </React.Fragment>
  );

  renderContent = () => {
    const { isQZSocketConnected, shopBasicInfo } = this.props.context;
    const { allowPrintingOrder = "false" } = shopBasicInfo;
    return (
      <div>
        <AllowPrintingOrderSelector />
        {allowPrintingOrder === "true" &&
          (isQZSocketConnected
            ? this.renderOnlinePrintServer()
            : this.renderOfflinePrintServer())}
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <ExpansionPanel moduleTitle="Printers" showExpandableContent>
          {this.renderContent()}
        </ExpansionPanel>
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
      </React.Fragment>
    );
  }
}

PrintersModule.propTypes = {
  context: PropTypes.shape({
    isConnectingToPrintServer: PropTypes.bool.isRequired,
    isQZSocketConnected: PropTypes.bool.isRequired,
    onConnectToPrintServer: PropTypes.func.isRequired,
    printerQZConfigs: PropTypes.object,
    shopBasicInfo: PropTypes.shape({
      allowPrintingOrder: PropTypes.oneOf(["false", "true"]),
    }).isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(PrintersModule);
