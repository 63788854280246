// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import { DEFAULT_TEMPLATE, _setUpInitialWebsiteInfo } from "./HelperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import MerchantViewStyle from "views/style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals, WebPageEditWindow, WebsiteViewModules } from "components";

// Fields
import { Button, PageMsg, SubNavbarContainer } from "fields";

// Icons
import { PencilIcon, SaveIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

const { Merchants } = Services;
const { GetWebsiteInfo } = Merchants.GetRequests;
const { SaveWebsite } = Merchants.PostRequests;

class Website extends React.Component {
  confirmNotif = null;
  state = {
    latestWebsite: {},
    saveButtonStatus: "inactive",
  };

  componentDidMount = () => {
    document.title = "Your Website";
    this.onGetWebsite();
  };

  onChangeWebsiteModule = ({ moduleId = "", moduleContent = {} }) => {
    this.setState({
      saveButtonStatus: "active",
      latestWebsite: set(this.state.latestWebsite, moduleId, moduleContent),
    });
  };

  onCreateSite = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { address = "" } = shopBasicInfo;
    const template = DEFAULT_TEMPLATE({ shopAddress: address });
    const newSite = {
      ...this.state.latestWebsite,
      ...template,
    };
    this.setState({ latestWebsite: newSite }, () =>
      this.setState({ showPageEditWindow: true })
    );
  };

  onEditSite = () =>
    this.setState({ showPageEditWindow: true }, () => {
      document.title = "Your Website";
    });

  onGetWebsite = async () => {
    this.setState({ isFindingWebsiteInfo: true });
    const { shopID = "" } = this.props.context;
    try {
      const { website = {} } = await GetWebsiteInfo({ shopID });
      this.setState(
        { latestWebsite: website },
        () =>
          Object.keys(website).length === 0 && this.onSetUpInitialWebsiteInfo()
      );
    } catch {
      this.setState({ failedToFindWebsiteInfo: true });
    } finally {
      this.setState({ isFindingWebsiteInfo: false });
    }
  };

  onSaveWebsite = async () => {
    this.setState({ showLoadingModal: true });
    const { shopID = "" } = this.props.context;
    const { latestWebsite = {} } = this.state;
    try {
      await SaveWebsite({ shopID, website: latestWebsite });
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: "Saved your changes! Yay!",
        type: "success",
      });
      this.setState({
        saveButtonStatus: "inactive",
      });
    } catch {
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: "An error occured! Try again.",
        type: "error",
      });
    } finally {
      this.setState({ showLoadingModal: false });
    }
  };

  onSetUpInitialWebsiteInfo = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { name = "" } = shopBasicInfo;
    const initialWebsiteInfo = _setUpInitialWebsiteInfo({ shopName: name });
    this.setState({ latestWebsite: initialWebsiteInfo });
  };

  renderSubNavbar = () => {
    const { latestWebsite = {} } = this.state;
    const { latestVersion = {} } = latestWebsite;
    const { components = {} } = latestVersion;
    const createNewSite = Object.keys(components).length === 0;

    return (
      <SubNavbarContainer>
        <div className={Style.subNavbarLeftColumn}>
          <h1>Your Website</h1>
        </div>
        <div className={Style.subNavbarRightColumn}>
          <Button
            className={Style.editButton}
            color="primary"
            name="edit page"
            onClick={createNewSite ? this.onCreateSite : this.onEditSite}
          >
            {createNewSite ? (
              <React.Fragment>+ Create Site</React.Fragment>
            ) : (
              <React.Fragment>
                <PencilIcon /> Edit Site
              </React.Fragment>
            )}
          </Button>
          <Button
            className={Style.saveButton}
            name="save button"
            onClick={this.onSaveWebsite}
            status={this.state.saveButtonStatus}
          >
            <SaveIcon />
            Save
          </Button>
        </div>
      </SubNavbarContainer>
    );
  };

  renderWebPageEditWindow = () => (
    <WebPageEditWindow
      onCloseEditWindow={() => this.setState({ showPageEditWindow: false })}
      onUpdateWebsite={(newWebsite) =>
        this.setState({ latestWebsite: newWebsite })
      }
      website={this.state.latestWebsite}
    />
  );

  render() {
    const { isFindingWebsiteInfo, latestWebsite } = this.state;
    const { general = {}, seo = {} } = latestWebsite;
    return (
      <React.Fragment>
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        {this.state.showPageEditWindow && this.renderWebPageEditWindow()}
        {isFindingWebsiteInfo ? (
          <PageMsg>Finding website info...</PageMsg>
        ) : (
          <div>
            {this.renderSubNavbar()}
            <div className={MerchantViewStyle.extraPaddingTop}>
              <WebsiteViewModules.General
                general={general}
                onChangeWebsiteModule={(content) =>
                  this.onChangeWebsiteModule({
                    moduleId: "general",
                    moduleContent: content,
                  })
                }
              />
              <WebsiteViewModules.SEO
                seo={seo}
                onChangeWebsiteModule={(content) =>
                  this.onChangeWebsiteModule({
                    moduleId: "seo",
                    moduleContent: content,
                  })
                }
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Website.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    shopID: PropTypes.string.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(Website);
