// BUTI DINERS, INC. All right Reserved ©

import Deliver from "./Deliver";
import InStore from "./InStore";
import PickUp from "./PickUp";

const PreparationSections = {
  Deliver,
  InStore,
  PickUp
};

export default PreparationSections;
