// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import NewRefundRequest from "./NewRefundRequest";
import RefundRequests from "./RefundRequests";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

class ConfirmRefundOrderModal extends React.Component {
  state = {};

  componentDidMount = () => {
    const { orderInfo = {} } = this.props;
    const { refundRequests = {} } = orderInfo;
    this.setState({
      showRefundRequests: Object.keys(refundRequests).length > 0,
    });
  };

  onGetModalHeader = () =>
    this.state.showRefundRequests ? "Past Requests" : "New Request";

  renderContent = () => {
    const { orderID = "", orderInfo = {} } = this.props;
    const { customerName, refundRequests = {} } = orderInfo;
    if (this.state.showRefundRequests)
      return (
        <RefundRequests
          customerName={customerName}
          onCloseModal={this.props.onCloseModal}
          onCreateNewRequest={() =>
            this.setState({ showRefundRequests: false })
          }
          orderID={orderID}
          refundRequests={refundRequests}
        />
      );
    return <NewRefundRequest {...this.props} />;
  };

  render() {
    const { orderInfo = {}, orderID } = this.props;
    const { customerName } = orderInfo;
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentDescriptionProps={{
          contentDescription: (
            <div className={Style.orderInfo}>
              <div>
                Customer: <strong>{customerName}</strong>
              </div>
              <div>
                Order Id: <strong>{orderID}</strong>
              </div>
            </div>
          ),
          showContentDescription: true,
          title: "Refunds take 5-10 days to appear on a customer's statement",
        }}
        contentLabel="Refund order"
        headerProps={{ header: this.onGetModalHeader() }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

ConfirmRefundOrderModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onRefundRequestSuccess: PropTypes.func.isRequired,
  orderID: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    orderDeliveryTypeID: PropTypes.oneOf(["inStore", "deliver", "pickUp"])
      .isRequired,
    orderItems: PropTypes.object.isRequired,
    paymentIntentID: PropTypes.string,
    phoneNumber: PropTypes.string,
    timeStamp: PropTypes.string.isRequired,
    totalPriceAfterTax: PropTypes.any.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  refundRequests: PropTypes.object,
};

export default withContext(MerchantInterfaceConsumer)(ConfirmRefundOrderModal);
