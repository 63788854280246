// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import ModuleStyle from "../style.module.scss";

// Icons
import { PencilIcon } from "assets/Icons";

// Fields
import { ExpansionPanel } from "fields";

class ActiveCoupons extends React.Component {
  renderActiveCoupons = () => {
    const { activeCoupons = {} } = this.props;
    return Object.keys(activeCoupons).map(couponID => {
      const { description, name } = activeCoupons[couponID];
      return (
        <div className={ModuleStyle.activeCoupon} key={couponID}>
          <div style={{ width: "80%" }}>
            <strong>{name}</strong>
            {description && (
              <p className={ModuleStyle.couponDescription}>{description}</p>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <button onClick={() => this.props.onEditCoupon(couponID)}>
              <PencilIcon className={ModuleStyle.editIcon} />
            </button>
            {/* <button
              onClick={() => this.props.onArchiveCoupon(couponID)}
              style={{ marginLeft: "15px" }}
            >
              <CloseIcon className={ModuleStyle.editIcon} />
            </button> */}
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <ExpansionPanel moduleTitle="Active Coupons" showExpandableContent>
          {this.renderActiveCoupons()}
        </ExpansionPanel>
      </div>
    );
  }
}

ActiveCoupons.propTypes = {
  activeCoupons: PropTypes.object,
  onArchiveCoupon: PropTypes.func,
  onEditCoupon: PropTypes.func
};

ActiveCoupons.defaultProps = {
  activeCoupons: {},
  onArchiveCoupon: () => {},
  onEditCoupon: () => {}
};

export default ActiveCoupons;
