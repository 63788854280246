// BUTI Corp All right Reserved ©

import General from "./General";
import SEO from "./SEO";

const WebsiteViewModules = {
  General,
  SEO,
};

export default WebsiteViewModules;
