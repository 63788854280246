// BUTI DINERS, INC. All right Reserved ©

import { DEFAULT_CHECKIN_SECURITY_CODE } from "./constants";

const _createDefaultGuestCheckinInfo = ({
  shop_address,
  shop_id,
  shop_name,
}) => {
  return {
    after_checkin: {
      automated_text_after_checkin: `Welcome to ${shop_name} at ${shop_address}! Tap the link for our menu: www.skiplinow.com/shop/${shop_id}`,
      instruction_after_checkin:
        "Bring a table number to any available table. Order now & skip the line using the link on the text.",
    },
    checkin_screen: {
      header: "Can I have your number?",
      keypad_background_color: "#006AFF",
      message: "I'll reward you for loving local.",
      security_code: DEFAULT_CHECKIN_SECURITY_CODE,
    },
  };
};

export { _createDefaultGuestCheckinInfo };
