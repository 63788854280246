// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { ArrowIcon, SaveIcon } from "assets/Icons";

const SkipliHeader = (props) => {
  const renderLeftColumn = () => (
    <div className={Style.leftColumn}>
      <Button
        className={Style.backButton}
        name="exit modal button"
        onClick={props.onExitEditMode}
      >
        <ArrowIcon /> Back
      </Button>
    </div>
  );

  const renderSkipliLogo = () => (
    <div className={Style.centerColumn}>Website Builder</div>
  );

  const renderRightColumn = () => (
    <div className={Style.rightColumn}>
      <Button
        className={Style.saveBtn}
        name="save latest site"
        onClick={props.onSave}
      >
        <SaveIcon />
        Save
      </Button>
      <Button
        className={Style.publishBtn}
        color="info"
        name="publish site"
        onClick={props.onPublish}
      >
        Publish
      </Button>
    </div>
  );

  return (
    <div className={Style.container}>
      <div className={Style.content}>
        {renderLeftColumn()}
        {renderSkipliLogo()}
        {renderRightColumn()}
      </div>
    </div>
  );
};

SkipliHeader.propTypes = {
  onExitEditMode: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SkipliHeader;
