// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import shortid from "shortid";

import {
  _createEmailToMerchant,
  _createEmailToSkipli,
  _getSubmitBtnStatus,
} from "./HelperFunctions";

import Agreement from "./Agreement";
import ContactInfo from "./ContactInfo";
import Description from "./Description";
import FailedToFindForm from "./FailedToFindForm";
import Location from "./Location";
import SubmittedFormSuccess from "./SubmittedFormSuccess";
import ReviewButton from "../ReviewButton";

// Style
import Style from "./style.module.scss";

// Utils
import { _generateRandomID } from "utils";

// Components
import { Modals } from "components";

// Fields
import { Button } from "fields";

// Assets
import { PlusIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

class AgreementForm extends React.Component {
  confirmNotif = null;
  state = {
    form: {
      agreement: { hasAgreed: false },
      approval: { isApproved: false },
      ownerInfo: { email: "", name: "", phone: "" },
      locations: { [shortid.generate()]: {} },
      lastModifiedAt: "",
      submittedAt: "",
    },
  };

  componentDidMount = () => {
    const { formId = "" } = this.props;
    if (formId) this.onGetForm(formId);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { endAt, startAt } = this.state;
    if (prevState.endAt !== endAt || prevState.startAt !== startAt)
      this.onGetPastOrders();
  };

  onAddLocation = () =>
    this.setState({
      form: set(this.state.form, `locations.${shortid.generate()}`, {}),
    });

  onChangeAgreement = (newAgreement) =>
    this.setState({
      form: set(this.state.form, "agreement", newAgreement),
    });

  onChangeOwnerInfo = (newOwnerInfo) =>
    this.setState({
      form: set(this.state.form, "ownerInfo", newOwnerInfo),
    });

  onChangeLocationInfo = ({ locationId = "", locationInfo }) =>
    locationId &&
    this.setState({
      form: set(this.state.form, `locations.${locationId}`, locationInfo),
    });

  onEmailFormToMerchant = async ({ formId = "", isApprovingForm = false }) => {
    if (!formId) return;
    const { BUTI } = Services;
    const { SendEmail } = BUTI.PostRequests;
    const { form = {} } = this.state;
    const { lastModifiedAt = "", locations = {}, ownerInfo = {} } = form;
    const { email = "", name = "" } = ownerInfo;
    const { body, subject } = _createEmailToMerchant({
      formId,
      isApprovingForm,
      lastModifiedAt,
      locations,
      name,
    });
    try {
      await SendEmail({
        addresses: [email],
        body,
        from: "business@skiplinow.com",
        subject,
      });
    } catch {}
  };

  onNotifySkipliOfSubmission = async ({ formId, isApprovingForm = false }) => {
    if (!formId) return;
    const { BUTI } = Services;
    const { SendEmail } = BUTI.PostRequests;
    const { form = {} } = this.state;
    const { lastModifiedAt = "", locations = {} } = form;
    const { body, subject } = _createEmailToSkipli({
      formId,
      isApprovingForm,
      lastModifiedAt,
      locations,
    });
    try {
      await SendEmail({
        addresses: ["stripe@skiplinow.com"],
        body,
        from: "business@skiplinow.com",
        subject,
      });
    } catch {}
  };

  onGetForm = async (formId = "") => {
    this.setState({ isFindingForm: true });
    const { Merchants } = Services;
    const { GetOnlineOrderingAgreement } = Merchants.GetRequests;
    try {
      const { form = {} } = await GetOnlineOrderingAgreement({ formId });
      this.setState({ form, failedToFindForm: Object.keys(form).length === 0 });
    } catch {
      this.setState({ failedToFindForm: true });
    } finally {
      this.setState({ isFindingForm: false });
    }
  };

  onRemoveLocation = (locationId) =>
    locationId &&
    this.setState({ form: del(this.state.form, `locations.${locationId}`) });

  onSubmitForm = async ({ form = {}, isApprovingForm = false }) => {
    if (Object.keys(form).length === 0) return;
    this.setState({ showLoadingModal: true });
    const { Merchants } = Services;
    const { SubmitOnlineOrderingAgreement } = Merchants.PostRequests;
    const { formId = "" } = this.props;
    try {
      const submittedFormId = formId || _generateRandomID();
      await SubmitOnlineOrderingAgreement({
        formId: submittedFormId,
        form,
        isApprovingForm,
      });
      this.setState({ submittedForm: true, submittedFormId }, () => {
        this.onEmailFormToMerchant({
          formId: submittedFormId,
          isApprovingForm,
        });
        this.onNotifySkipliOfSubmission({
          formId: submittedFormId,
          isApprovingForm,
        });
        Functions.ShowConfirmNotif({
          message: "We received your form! Yay!",
          type: "success",
        });
      });
    } catch {
      Functions.ShowConfirmNotif({
        message: "An error occurred! Please try again!",
        type: "error",
      });
    } finally {
      this.setState({ showLoadingModal: false });
    }
  };

  renderAddLocationButton = () => (
    <Button
      className={Style.addLocationButton}
      hasShadow
      name="add location"
      onClick={() => this.onAddLocation()}
    >
      <PlusIcon />
      Add Location
    </Button>
  );

  renderLocations = (locations = {}) => {
    const { form = {} } = this.state;
    const { approval = {} } = form;
    const { isApproved = false } = approval;
    return Object.keys(locations).map((id, index) => (
      <div key={id}>
        <Location
          locationId={id}
          locationInfo={locations[id]}
          locationNumber={index + 1}
          onChange={(newLocationInfo) =>
            this.onChangeLocationInfo({
              locationId: id,
              locationInfo: newLocationInfo,
            })
          }
          onRemove={this.onRemoveLocation}
          readOnly={isApproved}
        />
      </div>
    ));
  };

  renderSubmitButton = () => {
    const { form = {} } = this.state;
    const { lastModifiedAt = "" } = form;
    return (
      <Button
        className={Style.submitBtn}
        color="primary"
        name="submit form"
        onClick={() => this.onSubmitForm({ form })}
        status={_getSubmitBtnStatus({ form })}
      >
        {lastModifiedAt ? "SAVE CHANGES" : "SUBMIT"}
      </Button>
    );
  };

  render() {
    const {
      isFindingForm = false,
      failedToFindForm = false,
      form = {},
      showLoadingModal = false,
      submittedForm = false,
      submittedFormId = "",
    } = this.state;
    const {
      agreement = {},
      approval = {},
      ownerInfo = {},
      locations = {},
    } = form;
    const { isApproved = false } = approval;
    if (isFindingForm)
      return <p className={Style.description}>Finding your form...</p>;
    else if (failedToFindForm) return <FailedToFindForm />;
    else if (submittedForm)
      return (
        <div>
          <SubmittedFormSuccess
            onEmailFormToMerchant={this.onEmailFormToMerchant}
            submittedFormId={submittedFormId}
          />
          {this.props.showReviewButton && <ReviewButton />}
        </div>
      );
    return (
      <div>
        {showLoadingModal && <Modals.LoadingModal message="Sending..." />}
        <Description form={form} />
        {this.renderLocations(locations)}
        {/* {!isApproved && this.renderAddLocationButton()} */}
        <ContactInfo
          ownerInfo={ownerInfo}
          onChange={this.onChangeOwnerInfo}
          readOnly={isApproved}
        />
        <Agreement
          agreement={agreement}
          onChange={this.onChangeAgreement}
          readOnly={isApproved}
          submitButton={!isApproved ? this.renderSubmitButton() : null}
        />
        {this.props.showReviewButton && !isApproved && (
          <ReviewButton
            form={this.state.form}
            onHideReviewButton={this.props.onHideReviewButton}
            onSubmitForm={this.onSubmitForm}
            submitButtonStatus={_getSubmitBtnStatus({ form: this.state.form })}
          />
        )}
      </div>
    );
  }
}

AgreementForm.propTypes = {
  formId: PropTypes.string,
  onHideReviewButton: PropTypes.func.isRequired,
  showReviewButton: PropTypes.bool,
};

AgreementForm.defaultProps = {
  formId: "",
  showReviewButton: false,
};

export default AgreementForm;
