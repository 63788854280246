// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Parent_Style from "../style.module.scss";

// Assets
import { ExportIcon } from "assets/Icons";

const MenusManagementExportButton = (props) => {
  const role = _get(props.context, "personnel.role");
  return (
    role === "owner" && (
      <button
        className={Parent_Style.button}
        name="add new button"
        onClick={props.onClick}
      >
        <div className={Parent_Style.button_icon}>
          <ExportIcon />
        </div>
        <div className={Parent_Style.button_label}>Export</div>
      </button>
    )
  );
};

MenusManagementExportButton.propTypes = {
  context: PropTypes.shape({
    personnel: PropTypes.object.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(
  MenusManagementExportButton
);
