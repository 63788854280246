// BUTI DINERS, INC. All right Reserved ©

import MerchantGlobalNavbar from "./MerchantGlobalNavbar";
import SubNavbar from "./SubNavbar";

const Navbars = {
  MerchantGlobalNavbar,
  SubNavbar
};

export default Navbars;
