// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import cx from "classnames";
import Style from "./style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { Button, Input, ResponsiveModal } from "fields";
import { createDownloadLink } from "./HelperFunctions";

import { DownloadIcon, ExcelIcon, PDFIcon, SendEmailIcon } from "assets/Icons";
import { Services } from "lib";

class ExportMenuModal extends Component {
  state = {
    email: "",
    select_option: "",
    selected_format: "",
    show_select_export_modal: false,
    show_select_format_modal: true,
    show_email_modal: false,
  };
  componentDidMount() {
    const { shopBasicInfo = {} } = this.props.context;
    const { email = "" } = shopBasicInfo;
    if (email) this.setState({ email });
  }
  onInputChange = (email) =>
    this.setState({
      email,
      show_select_export_modal: false,
    });

  previousExportFormat = () =>
    this.setState({
      select_option: "",
      show_select_export_modal: false,
      show_select_format_modal: true,
    });

  previousExportOption = () =>
    this.setState({
      show_email_modal: false,
      show_select_export_modal: true,
      show_select_format_modal: false,
    });

  handleSelectionSubmit = () => {
    this.setState({
      show_select_export_modal: true,
      show_select_format_modal: false,
    });
  };

  handleShowEmail = () => {
    this.setState({
      show_email_modal: true,
      show_select_export_modal: false,
    });
  };

  onSubmitCustomFooter = () => {
    const { select_option = "", show_email_modal } = this.state;
    if (select_option === "send_email" && !show_email_modal) {
      this.handleShowEmail();
    } else {
      this.exportToPDF();
      this.setState({
        show_email_modal: false,
      });
    }
  };

  exportToPDF = async () => {
    const { Merchants } = Services;
    const { ExportMenuToPDF, ExportMenuToExcel } = Merchants.GetRequests;
    const { selected_format, email } = this.state;
    const { shopID } = this.props;
    const export_function =
      selected_format === "PDF" ? ExportMenuToPDF : ExportMenuToExcel;
    this.setState({ is_exporting: true });
    try {
      const blobData = await export_function({ email, shopID });
      if (!email)
        createDownloadLink(
          blobData,
          selected_format === "PDF" ? "menu_data.pdf" : "menu_data.xlsx"
        );
    } catch (error) {
    } finally {
      this.props.onCloseModal();
      this.setState({
        email: "",
        show_select_export_modal: false,
        show_email_modal: false,
        selected_format: "",
        select_option: "",
        is_exporting: false,
      });
    }
  };

  onGetSubmitButtonStatus = () => {
    const {
      email = "",
      selected_format,
      select_option,
      show_select_export_modal,
      show_select_format_modal,
    } = this.state;
    if (show_select_format_modal)
      return selected_format ? "active" : "inactive";
    if (show_select_export_modal) return select_option ? "active" : "inactive";
    else return email ? "active" : "inactive";
  };
  renderEmailModal = () => {
    return (
      <ResponsiveModal
        headerProps={{ header: "Enter Email Address" }}
        contentLabel="Enter Email Address"
        footerProps={{
          customFooter: this.renderCustomFooter(),
          showFooter: true,
        }}
        onCloseModal={this.previousExportOption}
      >
        <Input.TextInput
          label="Email"
          name="email"
          value={this.state.email}
          onChange={this.onInputChange}
        />
      </ResponsiveModal>
    );
  };

  renderCustomFooter = () => {
    const { show_email_modal } = this.state;
    return (
      <div className={Style.footer_container}>
        <Button
          name="back"
          className={Style.cancelButton}
          onClick={
            show_email_modal
              ? this.previousExportOption
              : this.previousExportFormat
          }
        >
          Back
        </Button>
        <Button
          name="next"
          color="info"
          className={Style.submitButton}
          status={this.onGetSubmitButtonStatus()}
          onClick={this.onSubmitCustomFooter}
        >
          {show_email_modal ? "Send Email" : "Next"}
        </Button>
      </div>
    );
  };

  renderSelectExport = () => {
    const { select_option = "", is_exporting } = this.state;
    return (
      <ResponsiveModal
        footerProps={{
          customFooter: this.renderCustomFooter(),
          showFooter: !(is_exporting && select_option === "download"),
        }}
        contentLabel="Select Export Options"
        headerProps={{ header: "Select Export Options" }}
        onCloseModal={this.previousExportFormat}
      >
        {is_exporting && select_option === "download" ? (
          <div className={Style.contentContainer}>
            <div>
              <div className={Style.loader}></div>
              <div className={Style.message}>Exporting...</div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={cx(
                Style.select_menu,
                select_option === "download" && Style.active
              )}
              onClick={() => this.setState({ select_option: "download" })}
            >
              <DownloadIcon className={Style.button_icon} /> DownLoad
            </div>
            <div
              className={cx(
                Style.select_menu,
                select_option === "send_email" && Style.active
              )}
              onClick={() => this.setState({ select_option: "send_email" })}
            >
              <SendEmailIcon className={Style.button_icon} /> Send To Email
            </div>
          </div>
        )}
      </ResponsiveModal>
    );
  };

  renderSelectFormat = () => {
    const { selected_format = "" } = this.state;
    return (
      <ResponsiveModal
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Next",
            status: this.onGetSubmitButtonStatus(),
          },
        }}
        contentLabel="Select Export Format"
        onSubmit={this.handleSelectionSubmit}
        closeModalAfterSuccessSubmit={false}
        onCloseModal={this.props.onCloseModal}
        headerProps={{ header: "Select Export Format" }}
      >
        {["PDF", "Excel"].map((format) => (
          <div
            key={format}
            className={cx(
              Style.select_menu,
              selected_format === format && Style.active
            )}
            onClick={() => this.setState({ selected_format: format })}
          >
            {format === "Excel" ? (
              <ExcelIcon className={Style.button_icon} />
            ) : (
              <PDFIcon className={Style.button_icon} />
            )}
            {format}
          </div>
        ))}
      </ResponsiveModal>
    );
  };

  render() {
    const {
      show_email_modal,
      show_select_export_modal,
      show_select_format_modal,
    } = this.state;
    return (
      <React.Fragment>
        {show_select_format_modal && this.renderSelectFormat()}
        {show_select_export_modal && this.renderSelectExport()}
        {show_email_modal && this.renderEmailModal()}
      </React.Fragment>
    );
  }
}

ExportMenuModal.propTypes = {
  shopID: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(ExportMenuModal);
