// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, WebsiteEditOverlay } from "fields";

// Icons
import { MenuIcon } from "assets/Icons";

const { WebsiteBuilderEdit } = Modals;

const NAVIGATION_OPTIONS = {
  home: { label: "home" },
  menu: { label: "menu" },
  about: { label: "About Us" },
  order_delivery: { label: "Order Delivery" },
  order_pickup: { label: "Order Pickup" },
  contactUs: { label: "Contact US" },
};

class ShopHeader extends React.Component {
  state = {};

  onClickOption = (optionId = "") => {
    switch (optionId) {
      case "order_delivery":
      case "order_pickup": {
        this.props.onRedirectToOrderOnline();
        return;
      }
      default:
        this.props.onScrollTo(optionId);
    }
  };

  onHideEditModal = () => this.setState({ showEditModal: false });

  onShowEditModal = () => this.setState({ showEditModal: true });

  renderEditModal = () => (
    <WebsiteBuilderEdit.ShopHeader
      header={this.props.header}
      onChange={this.props.onChange}
      onCloseModal={this.onHideEditModal}
    />
  );

  renderShopLogo = () => {
    const { shopBasicInfo = {} } = this.props;
    const { logoURL = "", name = "" } = shopBasicInfo;
    return logoURL ? (
      <img alt="" className={Style.shopLogo} src={logoURL} />
    ) : (
      <h1 className={Style.shopName}>{name}</h1>
    );
  };

  renderNavigationOptions = () => {
    const { header = {}, themeColor = "#3aafa9" } = this.props;
    const { textColor, textHoverColor } = header;

    const NavOption = styled(Button)`
      color: ${textColor};
      &:hover {
        border-bottom-color: ${textHoverColor || themeColor || "#3aafa9"};
        color: ${textHoverColor || themeColor || "#3aafa9"};
      }
    `;

    return Object.keys(NAVIGATION_OPTIONS).map((optionId) => {
      const { label } = NAVIGATION_OPTIONS[optionId];
      return (
        <div key={optionId}>
          <NavOption
            className={Style.navOption}
            name="label"
            onClick={() => this.onClickOption(optionId)}
          >
            {label}
          </NavOption>
        </div>
      );
    });
  };

  render() {
    const { header = {} } = this.props;
    const { backgroundColor = "#fff" } = header;

    return (
      <React.Fragment>
        {this.state.showEditModal && this.renderEditModal()}
        <WebsiteEditOverlay
          classNames={{
            container: Style.editOverlay,
            button: Style.editOverlayButton,
          }}
          onShowEditModal={this.onShowEditModal}
        >
          <div
            className={Style.shopHeader}
            style={{ background: backgroundColor }}
          >
            <div className={Style.content}>
              <div onClick={() => this.props.onScrollTo("home")}>
                {this.renderShopLogo()}
              </div>
              <div className={Style.menuIconContainer}>
                <MenuIcon />
              </div>
              <div className={Style.navigation}>
                {this.renderNavigationOptions()}
              </div>
            </div>
          </div>
        </WebsiteEditOverlay>
      </React.Fragment>
    );
  }
}

ShopHeader.propTypes = {
  header: PropTypes.exact({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    textHoverColor: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  onScrollTo: PropTypes.func.isRequired,
  shopBasicInfo: PropTypes.shape({
    logoURL: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  themeColor: PropTypes.string,
};

ShopHeader.defaultProps = {
  themeColor: "#3aafa9",
};

export default ShopHeader;
