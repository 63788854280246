// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import _pick from "lodash.pick";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import cx from "classnames";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Box, Button, Input } from "fields";

// Lib
import { Constants, Services } from "lib";

class GetPaidForm extends React.Component {
  state = { merchantGetPaidInfo: {}, showConfirmModal: false };

  componentDidMount = () => this.onGetMerchantGetPaidInfo();

  onGetMerchantGetPaidInfo = async () => {
    const { shopID } = this.props.context;
    const { Merchants } = Services;
    const { GetMerchantGetPaidInfo } = Merchants.GetRequests;
    this.setState({ isLoading: true });
    try {
      const { merchantGetPaidInfo = {} } = await GetMerchantGetPaidInfo({
        shopID,
      });
      this.setState({ merchantGetPaidInfo });
    } catch {
      this.setState({ failedToFindGetPaidInfo: true });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onHideConfirmModal = () => this.setState({ showConfirmModal: false });

  onShowConfirmModal = () => this.setState({ showConfirmModal: true });

  onInputChange = (fieldID, val = "") => {
    let fieldValue = val;
    if (fieldID === "ssn" && fieldValue.length > 4) return;
    if (fieldID === "ownerMobileNumber" && fieldValue.length > 11) return;
    if (fieldID === "businessName") fieldValue = fieldValue.toUpperCase();
    this.setState({
      merchantGetPaidInfo: set(
        this.state.merchantGetPaidInfo,
        fieldID,
        fieldValue
      ),
    });
  };

  onGetInputType = ({ fieldID, isReadOnly, type = "string" }) => {
    if (isReadOnly) {
      if (fieldID === "lastFourDigitsOfSSN") return "password";
      else if (fieldID === "accountNumber") return "string";
    }
    return type;
  };

  onGetInputValue = ({ fieldID, isReadOnly }) => {
    let fieldValue = this.state.merchantGetPaidInfo[fieldID] || "";
    if (isReadOnly) {
      if (fieldID === "accountNumber") return `•••• ${fieldValue.slice(-4)}`;
    }
    return fieldValue;
  };

  onGetReviewButtonStatus = () => {
    const { REGEX } = Constants;
    const { merchantGetPaidInfo } = this.state;
    const { dob, ownerMobileNumber, lastFourDigitsOfSSN } = merchantGetPaidInfo;
    if (
      !merchantGetPaidInfo.businessName ||
      !merchantGetPaidInfo.accountNumber ||
      !merchantGetPaidInfo.routingNumber ||
      !merchantGetPaidInfo.ownerName ||
      !lastFourDigitsOfSSN ||
      !ownerMobileNumber ||
      !dob
    ) {
      return "inactive";
    } else if (!REGEX.LAST_FOUR_DIGITS_OF_SSN.test(lastFourDigitsOfSSN))
      return "inactive";
    else if (!REGEX.US_PHONE_NUMBER.test(ownerMobileNumber)) return "inactive";
    else if (!REGEX.DATE_OF_BIRTH.test(dob)) return "inactive";
    return "active";
  };

  renderBanner = () => {
    if (this.props.isStripeConnectComplete) return;
    else if (this.state.isLoading) return;
    else if (this.state.failedToFindGetPaidInfo)
      return (
        <div className={cx(Style.banner, Style.danger)}>
          Failed to find your get paid info
        </div>
      );
    const { accountNumber, routingNumber } = this.state.merchantGetPaidInfo;
    if (!accountNumber || !routingNumber) return;
    else
      return (
        <div className={cx(Style.banner, Style.warning)}>
          Payments information is under review
        </div>
      );
  };

  renderContent = () => {
    const { CHECKING_ACCOUNT_FIELDS, OWNER_INFO_FIELDS } = Constants;
    return (
      <React.Fragment>
        <h3 className={Style.sectionTitle}>Owner Information</h3>
        <div className={Style.fields}>{this.renderForm(OWNER_INFO_FIELDS)}</div>
        <h3 className={Style.sectionTitle}>Checking Account</h3>
        <div className={Style.fields}>
          {this.renderForm(CHECKING_ACCOUNT_FIELDS)}
        </div>
        {!this.props.isStripeConnectComplete && (
          <Button
            className={Style.reviewFormBtn}
            name="Review Get Paid Form"
            onClick={this.onShowConfirmModal}
            status={this.onGetReviewButtonStatus()}
          >
            Review & Submit
          </Button>
        )}
      </React.Fragment>
    );
  };

  renderForm = (fieldIDs) => {
    const { MERCHANT_GET_PAID_FORM } = Constants;
    const fields = _pick(MERCHANT_GET_PAID_FORM, fieldIDs);
    const isReadOnly = this.props.isStripeConnectComplete;
    return Object.keys(fields).map((fieldID) => {
      const { type } = fields[fieldID];
      return (
        <div className={Style.input} key={fieldID}>
          <Input.TextInput
            {...fields[fieldID]}
            name={fieldID}
            onChange={(val) => this.onInputChange(fieldID, val)}
            readOnly={this.props.isStripeConnectComplete}
            type={this.onGetInputType({ fieldID, isReadOnly, type })}
            value={this.onGetInputValue({
              fieldID,
              isReadOnly,
            })}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <Box
        description="Your private information is encrypted and only made available to trusted
        banks. Each transaction is carefully screened to prevent potential
        disputes before being transferred into your account under US Payment Card
        Industry (PCI) rules."
        header="Get Paid"
      >
        {this.renderBanner()}
        {this.state.showConfirmModal && (
          <Modals.ConfirmMerchantGetPaidModal
            merchantGetPaidInfo={this.state.merchantGetPaidInfo}
            onCloseModal={this.onHideConfirmModal}
          />
        )}
        {this.state.isLoading
          ? "Finding get paid information..."
          : this.renderContent()}
      </Box>
    );
  }
}

GetPaidForm.propTypes = {
  context: PropTypes.shape({
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  isStripeConnectComplete: PropTypes.bool,
};

GetPaidForm.defaultProps = {
  isStripeConnectComplete: false,
};

export default withContext(MerchantInterfaceConsumer)(GetPaidForm);
