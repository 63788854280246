// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import dayjs from "dayjs";

// Style
import Style from "./style.module.scss";

var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const _convertPayoutStatusToText = (status) => {
  switch (status) {
    case "in_transit":
      return "in transit";
    case "paid":
      return "paid";
    default:
      return "";
  }
};

const _convertDate = ({ date, timeZone }) =>
  dayjs
    .unix(date)
    .add(1, "day")
    .tz(timeZone)
    .format("MMM-DD-YYYY");

const _createColumnsForTable = () => [
  {
    accessor: "amount",
    header: "Amount",
  },
  {
    accessor: "status",
    header: "Status",
  },
  {
    accessor: "description",
    header: "Description",
  },
  {
    accessor: "created",
    header: "Created",
  },
  {
    accessor: "arrival_date",
    header: "Estimate Arrival",
  },
];

const _renderPayoutStatus = (status) => (
  <div className={cx(Style.payoutStatus, Style[status])}>
    {_convertPayoutStatusToText(status)}
  </div>
);

const _sanitizeDataForTable = ({ data = [], timeZone = "America/New_York" }) =>
  data.map((infoObject = {}) => {
    const {
      amount = 0,
      arrival_date,
      created,
      currency,
      description = "",
      status,
    } = infoObject;
    return {
      ...infoObject,
      amount: (
        <div className={Style.currency}>
          ${parseFloat(`${amount}e-2`).toFixed(2)} {currency}
        </div>
      ),
      arrival_date: _convertDate({ date: arrival_date, timeZone }),
      created: _convertDate({ date: created, timeZone }),
      description: (
        <div className={Style.description}>
          {(description || "").toLowerCase()}
        </div>
      ),
      status: _renderPayoutStatus(status),
    };
  });

export {
  _convertDate,
  _createColumnsForTable,
  _renderPayoutStatus,
  _sanitizeDataForTable,
};
