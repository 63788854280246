// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Icons
import { PencilIcon, PlusIcon, PrinterIcon } from "assets/Icons";

class Printer extends React.Component {
  state = {};

  renderBadges = () => {
    const { isConnected } = this.props;
    return (
      <div className={Style.badges}>
        {isConnected ? (
          <div className={Style.onlineBadge}>Online</div>
        ) : (
          <div className={Style.offlineBadge}>Offline</div>
        )}
      </div>
    );
  };

  renderPrinterName = () => {
    const { isAddedToShop, printerName } = this.props;
    return (
      <div className={Style.printerNameContainer}>
        <div className={Style.printerName}>
          {isAddedToShop && <PrinterIcon />}
          {printerName}
        </div>
        {!isAddedToShop ? (
          <button
            className={Style.addPrinterBtn}
            onClick={() => this.props.onAddPrinter(printerName)}
          >
            <PlusIcon />
          </button>
        ) : (
          <button>
            <PencilIcon className={Style.pencilIcon} />
          </button>
        )}
      </div>
    );
  };

  render() {
    const { isAddedToShop } = this.props;
    return (
      <React.Fragment>
        {this.state.showPrinterInfoModal && (
          <Modals.PrinterInfoModal
            {...this.props}
            onCloseModal={() => this.setState({ showPrinterInfoModal: false })}
          />
        )}
        <div
          className={Style.container}
          onClick={() =>
            isAddedToShop && this.setState({ showPrinterInfoModal: true })
          }
        >
          {this.renderPrinterName()}
          {isAddedToShop && this.renderBadges()}
        </div>
      </React.Fragment>
    );
  }
}

Printer.propTypes = {
  isAddedToShop: PropTypes.bool,
  isConnected: PropTypes.bool,
  isPrimary: PropTypes.bool,
  onAddPrinter: PropTypes.func,
  onChangePrinterBrand: PropTypes.func,
  onRemovePrinter: PropTypes.func,
  onSetPrinterAsPrimary: PropTypes.func,
  onTestPrinterConnection: PropTypes.func,
  printerBrand: PropTypes.string,
  printerName: PropTypes.string.isRequired,
};

Printer.defaultProps = {
  isAddedToShop: false,
  isConnected: false,
  isPrimary: false,
  onAddPrinter: () => {},
  onChangePrinterBrand: () => {},
  onRemovePrinter: () => {},
  onSetPrinterAsPrimary: () => {},
  onTestPrinterConnection: () => {},
};

export default Printer;
