// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { Box, ColorPicker, Input } from "fields";

// Lib
import REGEX from "lib/Constants/RegEx";

class CheckinScreen extends React.Component {
  renderCheckinScreenHeader = ({ header = "" }) => (
    <Input.TextInput
      label="Header"
      max_character_count={24}
      name="check-in screen header"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({ key: "checkin_screen.header", value })
      }
      required
      value={header}
    />
  );

  renderCheckinScreenMessage = ({ message = "" }) => (
    <Input.TextInput
      label="Message"
      max_character_count={80}
      name="check-in screen message"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "checkin_screen.message",
          value,
        })
      }
      value={message}
    />
  );

  renderKeypadBackgroundColorPicker = ({ keypad_background_color = "" }) => (
    <ColorPicker
      currentHexColor={keypad_background_color}
      heading="Keypad background color"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "checkin_screen.keypad_background_color",
          value,
        })
      }
    />
  );

  renderSecuritycode = ({ security_code = "" }) => (
    <Input.TextInput
      errortext="Must be 5 digits"
      label="Security code to activate/deactivate check-in"
      max_character_count={5}
      name="security code"
      onChange={(value) =>
        this.props.onChangeGuestCheckin({
          key: "checkin_screen.security_code",
          value,
        })
      }
      placeholder="00000"
      regEx={REGEX.FIVE_DIGITS_NUMBER}
      required
      type="number"
      value={security_code}
    />
  );

  render() {
    const { checkin_screen = {} } = this.props;
    const {
      header = "",
      keypad_background_color,
      message = "",
      security_code = "",
    } = checkin_screen;

    return (
      <Box header="Check-in Screen">
        {this.renderSecuritycode({ security_code })}
        {this.renderCheckinScreenHeader({ header })}
        {this.renderCheckinScreenMessage({ message })}
        {this.renderKeypadBackgroundColorPicker({ keypad_background_color })}
      </Box>
    );
  }
}

CheckinScreen.propTypes = {
  checkin_screen: PropTypes.shape({
    header: PropTypes.string.isRequired,
    message: PropTypes.string,
    keypad_background_color: PropTypes.string.isRequired,
    security_code: PropTypes.string.isRequired,
  }).isRequired,
  onChangeGuestCheckin: PropTypes.func.isRequired,
};

export default CheckinScreen;
