// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const RefundAmount = ({ refundAmount = 0, selectedRefundTypeId }) => {
  const renderRefundAllMsg = () => (
    <strong>Refund everything in the order</strong>
  );

  const renderRefundAmount = () => (
    <React.Fragment>
      Refund amount:{" "}
      <strong>
        ${!refundAmount ? 0 : parseFloat(refundAmount).toFixed(2)}
      </strong>
    </React.Fragment>
  );

  return (
    <div className={Style.container}>
      <div className={Style.notice}>
        {selectedRefundTypeId === "allItems"
          ? renderRefundAllMsg()
          : renderRefundAmount()}
      </div>
    </div>
  );
};

RefundAmount.propTypes = {
  refundAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedRefundTypeId: PropTypes.oneOf([
    "selectItems",
    "customAmount",
    "allItems",
  ]).isRequired,
};

export default RefundAmount;
