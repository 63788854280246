// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Input } from "fields";

const META_DESCRIPTION_MAX_CHARS = 240;

const SEO = (props) => {
  const onChange = ({ fieldID = "", fieldValue = "" }) =>
    props.onChangeWebsiteModule(set(props.seo, fieldID, fieldValue));

  const { seo = {} } = props;
  const { pageTitle = "", metaDescription = "" } = seo;

  return (
    <Box header="Search Engine Optimization">
      <div className={Style.field}>
        <Input.TextInput
          description="This will override the current page title in search results"
          label="Page Title"
          max_character_count={60}
          name="page title"
          onChange={(value) =>
            onChange({ fieldID: "pageTitle", fieldValue: value })
          }
          value={pageTitle}
        />
      </div>
      <div>
        <Input.TextInput
          description="It is best to keep meta descriptions long enough that they're sufficiently descriptive"
          hasMultipleLines
          label="Meta Description"
          max_character_count={META_DESCRIPTION_MAX_CHARS}
          name="meta description"
          onChange={(value) =>
            onChange({ fieldID: "metaDescription", fieldValue: value })
          }
          rows={3}
          value={metaDescription}
        />
      </div>
    </Box>
  );
};

SEO.propTypes = {
  onChangeWebsiteModule: PropTypes.func.isRequired,
  seo: PropTypes.exact({
    pageTitle: PropTypes.string,
    metaDescription: PropTypes.string,
  }),
};

SEO.defaultProps = {
  seo: {},
};

export default SEO;
