const _translateRating = (selected_rating_id) => {
    switch (selected_rating_id) {
      case "rating_1":
        return 1;
      case "rating_2":
        return 2;
      case "rating_3":
        return 3;
      case "rating_4":
        return 4;
      case "rating_5":
        return 5;
      default:
        return;
    }
  };
export { _translateRating };
