// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import ModuleStyle from "../style.module.scss";

// Fields
import { Button, Input } from "fields";

// Lib
import { Functions } from "lib";

class PhoneNumbersToReceiveOrders extends React.Component {
  confirmNotif = null;

  state = {};

  onInputChange = (id, val) => {
    let phoneNumber = val.replace(/[^0-9]/g, "");
    if (phoneNumber.length <= 10)
      this.props.onChangePhoneNumber(id, phoneNumber);
  };

  onTestNumber = async (phoneNumber = "") => {
    if (phoneNumber && phoneNumber.length === 10) {
      const {
        SendSingleTextMessageToMultipleNumbers,
        ShowConfirmNotif,
      } = Functions;
      await SendSingleTextMessageToMultipleNumbers({
        body: "Test message from Skipli",
        phoneNumbers: { 1: phoneNumber },
      });
      this.confirmNotif = ShowConfirmNotif({
        message: `Sent a text to ${phoneNumber}`,
        type: "success",
      });
    }
  };

  renderPhoneNumbers = (phoneNumbers) =>
    Object.entries(phoneNumbers).map((entry, index) => {
      const phoneNumber = entry[1];
      return (
        <div className={ModuleStyle.field} key={entry[0]}>
          <Input.TextInput
            label={`Mobile Number #${index + 1}`}
            name={String(phoneNumber)}
            onChange={(val) => this.onInputChange(entry[0], val)}
            value={phoneNumber || ""}
            showError={true}
            regEx={/^\d{10}$/}
          />
          <button
            className={ModuleStyle.testNumberButton}
            onClick={() => this.onTestNumber(phoneNumber)}
          >
            Test Number
          </button>
        </div>
      );
    });

  render() {
    const { phoneNumbers } = this.props;
    return (
      <div>
        <div className={ModuleStyle.fieldsContainer}>
          {this.renderPhoneNumbers(phoneNumbers)}
        </div>
        {Object.keys(phoneNumbers).length < 2 && (
          <Button
            className={ModuleStyle.moduleButton}
            hasShadow
            name="add phone number"
            onClick={this.props.onAddPhoneNumber}
          >
            Add Number
          </Button>
        )}
      </div>
    );
  }
}

PhoneNumbersToReceiveOrders.propTypes = {
  onAddPhoneNumber: PropTypes.func,
  onChangePhoneNumber: PropTypes.func,
  phoneNumbers: PropTypes.object,
};

PhoneNumbersToReceiveOrders.defaultProps = {
  onAddPhoneNumber: () => {},
  onChangePhoneNumber: () => {},
  phoneNumbers: {},
};

export default PhoneNumbersToReceiveOrders;
