// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import _get from "lodash.get";

import Admin from "./Admin";
import {
  LiveShopNotAllowed,
  ShopNotExist,
  TestShopNotAllowed,
} from "./HelperFunctions";

// Views
//
import { AdminLogin, Home, NewMerchantAccount } from "views";

// Style
import Style from "./style.module.scss";

// Lib
import { Constants, Functions, Services } from "lib";

// Icons
import { SkipliLogoWithTextIcon } from "assets/Icons";

class Main extends React.Component {
  constructor(props) {
    super(props);
    if (props.stripeRedirect) {
      const { code, state } = queryString.parse(props.location.search);
      const { shopID, token } = JSON.parse(state);
      this.state = {
        code,
        personnel: null,
        shopID,
        shopBasicInfo: {},
        token,
      };
    } else
      this.state = {
        personnel: null,
        shopBasicInfo: {},
        shopID: _get(this.props, "match.params.shopID") || "",
      };
  }

  componentDidMount = () => {
    if (this.props.showAgreementForm || this.props.showHomeScreen) return;
    const { shopID } = this.state;
    // Check if the shop should be displayed
    const { TEST_SHOP_IDS } = Constants;
    const { LIVE_MODE_ENABLED } = Services;
    if (LIVE_MODE_ENABLED() && TEST_SHOP_IDS.includes(shopID)) {
      this.setState({ showTestShopInLiveModeWarning: true });
      return;
    } else if (!LIVE_MODE_ENABLED() && !TEST_SHOP_IDS.includes(shopID)) {
      this.setState({ showLiveShopInTestModeWarning: true });
      return;
    }
    this.onGetShopBasicInfo();
    this.onGetPersonnelInfo();
  };

  onGetPersonnelInfo = async () => {
    this.setState({ isFindingPersonnel: true });
    const { Merchants } = Services;
    const { GetShopPersonnelInfo } = Merchants.GetRequests;
    const { LocalStorage } = Functions;
    const personnelID = LocalStorage.getItemFromStorage("personnelID");
    if (!personnelID) {
      this.setState({ isFindingPersonnel: false, personnel: null });
      return;
    }
    const { personnel = {}, success } = await GetShopPersonnelInfo({
      personnelID,
      shopID: this.state.shopID,
    });
    if (Object.keys(personnel).length === 0 || !success)
      this.setState({ isFindingPersonnel: false, personnel: null });
    else this.setState({ isFindingPersonnel: false, personnel });
  };

  onGetShopBasicInfo = async () => {
    const { Merchants } = Services;
    const { GetShopBasicInfo } = Merchants.GetRequests;
    this.setState({ isFindingShopBasicInfo: true });
    const { shopID } = this.state;
    const { shopBasicInfo = {} } = await GetShopBasicInfo({ shopID });
    this.setState({ shopBasicInfo }, () => {
      this.setState({ isFindingShopBasicInfo: false });
    });
  };

  onRemovePersonnelInfo = () => {
    const { LocalStorage } = Functions;
    this.setState({ personnel: null }, () =>
      LocalStorage.saveItemIntoStorage("personnelID", null)
    );
  };

  renderContent = (shopID) =>
    this.state.shopBasicInfo && (
      <Admin
        activeNavbarItemId={this.props.activeNavbarItemId}
        onRemovePersonnelInfo={this.onRemovePersonnelInfo}
        personnel={this.state.personnel}
        shopBasicInfo={this.state.shopBasicInfo}
        shopID={shopID}
        stripeConnectAuthCode={this.state.code}
        stripeConnectToken={this.state.token}
      />
    );

  renderPersonnelLogin = (shopID) => {
    const { shopBasicInfo } = this.state;
    const params = _get(this.props, "match.params") || {};
    const { personnelPin = "" } = params;
    return (
      <AdminLogin
        onLoadPersonnelInfo={this.onGetPersonnelInfo}
        personnelPin={personnelPin}
        shopBasicInfo={shopBasicInfo}
        shopID={shopID}
      />
    );
  };

  renderAdminView = () => {
    const { shopBasicInfo, shopID } = this.state;
    if (this.state.showLiveShopInTestModeWarning) return <LiveShopNotAllowed />;
    else if (this.state.showTestShopInLiveModeWarning)
      return <TestShopNotAllowed />;
    else if (this.state.isFindingShopBasicInfo || this.state.isFindingPersonnel)
      return (
        <div className={Style.background}>
          <div className={Style.skipliLogoContainer}>
            <SkipliLogoWithTextIcon />
          </div>
        </div>
      );
    else if (Object.keys(shopBasicInfo).length === 0) return <ShopNotExist />;
    else if (!this.state.personnel) return this.renderPersonnelLogin(shopID);
    return this.renderContent(shopID);
  };

  render() {
    if (this.props.showAgreementForm)
      return <NewMerchantAccount formId={this.props.formId} />;
    else if (this.props.showHomeScreen) return <Home />;
    return this.renderAdminView();
  }
}

Main.propTypes = {
  activeNavbarItemId: PropTypes.string,
  formId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({ shopID: PropTypes.string }),
  }).isRequired,
  showAgreementForm: PropTypes.bool,
  showHomeScreen: PropTypes.bool,
};

Main.defaultProps = {
  formId: "",
  showAgreementForm: false,
  showHomeScreen: false,
};

export default Main;
