// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { ScreenSize } from "fields";

const SubNavbarContainer = props => {
  const renderSubNavbar = (device = "desktop") => (
    <div
      className={cx(
        Style.subNavbarContainer,
        device !== "desktop" && Style.mobile
      )}
    >
      {props.children}
    </div>
  );

  return (
    <React.Fragment>
      <ScreenSize deviceType="mobile">{renderSubNavbar("mobile")}</ScreenSize>
      <ScreenSize deviceType="tablet">{renderSubNavbar("tablet")}</ScreenSize>
      <ScreenSize deviceType="desktop">{renderSubNavbar("desktop")}</ScreenSize>
    </React.Fragment>
  );
};

export default SubNavbarContainer;
