// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";

// Components
import { SettingsModules } from "components";

// Fields
import { Box } from "fields";

class Settings extends Component {
  render() {
    return (
      <div>
        <Box>
          <SettingsModules.NewOrdersAlarm />
        </Box>
        {/* <Box>
          <SettingsModules.Printers />
        </Box> */}
      </div>
    );
  }
}

export default Settings;
