// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _omit from "lodash.omit";

// Style
import CommonStyle from "../style.module.scss";
import AddButtonStyle from "../style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { MenusListOfElements } from "components";

// Fields
import { LoadingSpinner, PageMsg, ResponsiveModal } from "fields";

// Lib
import { Services } from "lib";

class AddItemModal extends React.Component {
  state = { isFindingItems: false };

  componentDidMount = () => this.onGetAllItems();

  onGetAllItems = async () => {
    const { Merchants } = Services;
    const { GetShopAllItems } = Merchants.GetRequests;
    this.setState({ isFindingItems: true });
    const { allItems } = await GetShopAllItems({
      shopID: this.props.context.shopID,
    }).finally(() => this.setState({ isFindingItems: false }));
    this.setState({ allItems: allItems || {} });
  };

  renderContent = () => {
    const { allowChangeItemPhoto, classNames = {} } = this.props;
    if (this.state.isFindingItems)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for items" />
        </PageMsg>
      );
    return (
      <MenusListOfElements.ListOfMenuItems
        classNames={{ itemContainer: classNames.itemContainer }}
        emptyListMessage="All items is added."
        itemHelperButton={this.renderItemHelperButton}
        itemProps={{ allowChangeItemPhoto }}
        items={_omit(this.state.allItems, Object.keys(this.props.addedItems))}
      />
    );
  };

  renderItemHelperButton = (itemID) => (
    <div
      className={AddButtonStyle.addButton}
      onClick={() => this.props.onAddItem(itemID)}
    >
      +
    </div>
  );

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentDescriptionProps={{
          contentDescription: "Click on the item for details",
          showContentDescription: true,
        }}
        contentLabel="Add items modal"
        headerProps={{
          close_button_icon: "arrow",
          header: "Add item",
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

AddItemModal.propTypes = {
  addedItems: PropTypes.object,
  allowChangeItemPhoto: PropTypes.bool,
  classNames: PropTypes.exact({
    itemContainer: PropTypes.string,
    modalBox: PropTypes.string,
    overlay: PropTypes.string,
  }),
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  destinationName: PropTypes.string.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

AddItemModal.defaultProps = {
  addedItems: {},
  allowChangeItemPhoto: true,
};

export default withContext(MerchantInterfaceConsumer)(AddItemModal);
