// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import ModifierFormFields from "./ModifierFormFields";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions, Services } from "lib";

class ModifierModal extends Component {
  confirmNotif = null;

  state = { showLoadingModal: false };

  componentWillUnmount = () => this.setState({ showLoadingModal: false });

  onCreateModifier = async (modifierInfo) => {
    const { Merchants } = Services;
    const { CreateNewModifier } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const params = { modifierInfo, shopID: this.props.context.shopID };
    const { modifierID } = await CreateNewModifier(params);
    if (!modifierID) return false;
    this.props.onUpdateModifiersAfterCreate({
      modifierID,
      modifierInfo,
    });
    return true;
  };

  onDeleteModifier = () => {
    const { Merchants } = Services;
    const { DeleteModifier } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { modifierID } = this.props;
    DeleteModifier({ modifierID, shopID: this.props.context.shopID })
      .then(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: `Removed ${this.props.modifierInfo.modifierName}`,
          type: "success",
        });
        this.props.onCloseModal();
        this.props.onUpdateAfterModifierDeleted();
      })
      .catch(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: "An error has occured. Please try again.",
          type: "error",
        });
      });
  };

  onSaveModifier = async (modifierInfo) => {
    const { Merchants } = Services;
    const { SaveChangedModifierInfo } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { modifierID } = this.props;
    const params = {
      modifierID,
      modifierInfo,
      shopID: this.props.context.shopID,
    };
    if (await SaveChangedModifierInfo(params)) {
      this.props.onUpdateAfterModifierSaved({ modifierInfo });
      return true;
    }
    return false;
  };

  onSubmitModifierInfo = async (modifierInfo) => {
    const { ShowConfirmNotif } = Functions;
    const { isInEditMode } = this.props;
    const res = await (isInEditMode
      ? this.onSaveModifier(modifierInfo)
      : this.onCreateModifier(modifierInfo));
    if (res) {
      this.confirmNotif = ShowConfirmNotif({
        message: `${isInEditMode ? "Saved" : "Created"} ${
          modifierInfo.modifierName
        }`,
        type: "success",
      });
      this.props.onCloseModal();
    } else {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error",
      });
    }
  };

  onHideConfirmDeleteModal = (e) => {
    this.setState({ showConfirmDeleteModal: false });
  };

  onShowConfirmDeleteModal = (e) => {
    e && e.preventDefault();
    this.setState({ showConfirmDeleteModal: true });
  };

  // renderDeleteModifierForm = () => (
  //   <form onSubmit={this.onShowConfirmDeleteModal}>
  //     <button
  //       className={LargeCenterModalStyle.deleteButton}
  //       name="delete modifier"
  //       type="submit"
  //     >
  //       Delete
  //     </button>
  //   </form>
  // );

  renderModalTitle = () => {
    if (this.props.isReadOnly) return "Modifier info";
    return `${this.props.isInEditMode ? "Edit" : "New"} modifier`;
  };

  render() {
    const { isInEditMode, isReadOnly, modifierInfo } = this.props;
    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentLabel="Modifier information modal"
        headerProps={{
          close_button_icon: "arrow",
          header: this.renderModalTitle(),
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.state.showConfirmDeleteModal && (
          <Modals.ConfirmDeleteModifierModal
            modifierName={modifierInfo.modifierName}
            onCloseModal={this.onHideConfirmDeleteModal}
            onDeleteModifier={this.onDeleteModifier}
          />
        )}
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        <ModifierFormFields
          isInEditMode={isInEditMode}
          isReadOnly={isReadOnly}
          modifierID={this.props.modifierID}
          modifierInfo={modifierInfo}
          onSubmit={this.onSubmitModifierInfo}
          shopID={this.props.context.shopID}
        />
        {/* {isInEditMode && !isReadOnly && this.renderDeleteModifierForm()} */}
      </ResponsiveModal>
    );
  }
}

ModifierModal.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  modifierID: PropTypes.string,
  modifierInfo: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
  onUpdateModifiersAfterCreate: PropTypes.func,
  onUpdateAfterModifierDeleted: PropTypes.func,
  onUpdateAfterModifierSaved: PropTypes.func,
};

ModifierModal.defaultProps = {
  isInEditMode: false,
  isReadOnly: false,
  modifierInfo: {},
  onUpdateModifiersAfterCreate: () => {},
  onUpdateAfterModifierDeleted: () => {},
  onUpdateAfterModifierSaved: () => {},
};

export default withContext(MerchantInterfaceConsumer)(ModifierModal);
