// BUTI DINERS, INC. All right Reserved ©

import _isempty from "lodash.isempty";
import { del, wrap } from "object-path-immutable";
import _get from "lodash.get";

const _checkIfDateIsDuplicate = ({ date, id, special_hours }) =>
  Object.keys(special_hours).reduce((result, key) => {
    if (key === id) return result;
    else if (date === _get(special_hours, `${key}.date`)) return true;
    return result;
  }, false);

const _sanitizeSpecialHours = ({ special_hours }) =>
  Object.keys(special_hours).reduce((result, id) => {
    const {
      date = "",
      is_closed = false,
      end_at = "",
      start_at = "",
    } = special_hours[id];
    if (_isempty(date)) return del(result, id);
    else if (is_closed)
      return wrap(result)
        .del(`${id}.start_at`)
        .del(`${id}.end_at`)
        .del(`${id}.time_format`)
        .value();
    else if (!is_closed && _isempty(start_at) && _isempty(end_at))
      return del(result, id);
    return result;
  }, special_hours);

export { _checkIfDateIsDuplicate, _sanitizeSpecialHours };
