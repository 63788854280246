// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import ModuleStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, Img } from "fields";

// Lib
import BUTI from "lib/Services/BUTI";

class ShopThumbnailImageModule extends React.Component {
  state = {};

  onChangeShopThumbnailImg = async (imageURL) => {
    try {
      const { ChangeShopThumbnailImage } = BUTI.PostRequests;
      const { success } = await ChangeShopThumbnailImage({
        shopID: this.props.shopID,
        thumbnailImg: imageURL,
      });
      if (success) {
        this.props.onUpdateShopThumbnailImgAfterSaved(imageURL);
        this.onHideChangeThumbnailImageModal();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.onHideChangeThumbnailImageModal();
    }
  };

  onHideChangeThumbnailImageModal = () =>
    this.setState({ showChangeThumbnailImageModal: false });

  onShowChangeThumbnailImageModal = () =>
    this.setState({ showChangeThumbnailImageModal: true });

  render() {
    return (
      <React.Fragment>
        {this.state.showChangeThumbnailImageModal && (
          <Modals.ImageViewerModal
            destinationFolderName={`shops/${this.props.shopID}/thumbnails`}
            imageQuality={1}
            imageURL={this.props.thumbnailImg}
            modalContentLabel="Shop's Thumbnail Image Modal"
            onCloseModal={this.onHideChangeThumbnailImageModal}
            onCompleteImageUpload={this.onChangeShopThumbnailImg}
            uploadMaxHeight={1400}
            uploadMaxWidth={1400}
            saveButtonName="Save new shop thumbnail"
          />
        )}
        <div>
          <div>
            <Img
              className={ModuleStyle.image}
              onClick={this.onShowChangeThumbnailImageModal}
              src={this.props.thumbnailImg}
            />
          </div>
          <Button
            className={ModuleStyle.moduleButton}
            name="change thumbnail image"
            onClick={this.onShowChangeThumbnailImageModal}
          >
            Change Image
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

ShopThumbnailImageModule.propTypes = {
  shopID: PropTypes.string.isRequired,
  thumbnailImg: PropTypes.string,
  onUpdateShopThumbnailImgAfterSaved: PropTypes.func.isRequired,
};

ShopThumbnailImageModule.defaultProps = {
  thumbnailImg: "",
};

export default ShopThumbnailImageModule;
