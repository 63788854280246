// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import _isempty from "lodash.isempty";

import { _createDefaultGuestCheckinInfo } from "./functions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { GuestCheckinModules } from "components";

// Style
import MerchantViewStyle from "views/style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, SubNavbarContainer } from "fields";

// Assets
import { SaveIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

const { LocalStorage, ShowConfirmNotif } = Functions;
const { Merchants } = Services;
const { GetGuestCheckinInfo } = Merchants.GetRequests;
const { SaveCheckInInfo } = Merchants.PostRequests;
const { getItemFromStorage, saveItemIntoStorage } = LocalStorage;

class CustomerSelfCheckIn extends React.Component {
  state = { customerCheckIn: {}, save_button_status: "inactive" };

  componentDidMount = () => this.onGetGuestCheckinInfo();

  onGetGuestCheckinInfo = async () => {
    const { shopBasicInfo = {}, shopID: shop_id } = this.props.context;
    try {
      const { guest_checkin_info = {} } = await GetGuestCheckinInfo({
        shop_id,
      });
      const { address = "", name = "" } = shopBasicInfo;
      this.setState(
        {
          customerCheckIn: !_isempty(guest_checkin_info)
            ? guest_checkin_info
            : _createDefaultGuestCheckinInfo({
                shop_address: address,
                shop_id,
                shop_name: name,
              }),
        },
        () => {
          const is_guest_checkin_activated = getItemFromStorage(
            "is_guest_checkin_activated"
          );
          if (is_guest_checkin_activated)
            this.setState({ showSelfCheckInModal: true });
        }
      );
    } catch {
      this.setState({ showSelfCheckInModal: false }, () => {
        ShowConfirmNotif({
          message:
            "Failed to fetch guest check-in info. Please refresh the page.",
          type: "error",
        });
      });
    }
  };

  onChangeGuestCheckin = ({ key, value }) =>
    this.setState({
      customerCheckIn: set(this.state.customerCheckIn, key, value),
      save_button_status: "active",
    });

  onHideSelfCheckInModal = () =>
    this.setState({ showSelfCheckInModal: false }, () => {
      saveItemIntoStorage("is_guest_checkin_activated", false);
    });

  onShowSelfCheckInModal = () =>
    this.setState({ showSelfCheckInModal: true }, () => {
      saveItemIntoStorage("is_guest_checkin_activated", true);
    });

  onSaveGuestCheckinInfo = async () => {
    const { shopID } = this.props.context;
    this.setState({ save_button_status: "loading" });
    try {
      await SaveCheckInInfo({
        guest_checkin_info: this.state.customerCheckIn,
        shop_id: shopID,
      });
      ShowConfirmNotif({
        message: "Updated guest check-in",
        type: "success",
      });
      this.setState({ save_button_status: "inactive" });
    } catch {
      ShowConfirmNotif({
        message: "Failed to update guest check-in. Please try again.",
        type: "error",
      });
    }
  };

  renderCustomerCheckInModal = () => {
    const { customerCheckIn = {} } = this.state;
    return (
      <Modals.CustomerCheckInModal
        customerCheckIn={customerCheckIn}
        onCloseModal={this.onHideSelfCheckInModal}
      />
    );
  };

  renderSubNavbar = () => (
    <SubNavbarContainer>
      <div className={Style.subNavbarLeftColumn}>
        <h1>Guest Check-in</h1>
      </div>
      <div className={Style.subNavbarRightColumn}>
        <Button
          className={Style.activate_checkin_button}
          color="info"
          name="Activate guest check-in"
          onClick={this.onShowSelfCheckInModal}
        >
          Activate Check-in
        </Button>
        <Button
          className={Style.saveBtn}
          color="info"
          loadingText="Saving"
          name="save check in info"
          onClick={this.onSaveGuestCheckinInfo}
          status={this.state.save_button_status}
        >
          <div className={Style.save_icon}>
            <SaveIcon />
          </div>
          Save
        </Button>
      </div>
    </SubNavbarContainer>
  );

  render() {
    const { customerCheckIn = {} } = this.state;
    const { after_checkin = {}, checkin_screen = {} } = customerCheckIn;
    return (
      <div>
        {this.state.showSelfCheckInModal && this.renderCustomerCheckInModal()}
        {this.renderSubNavbar()}
        <div className={MerchantViewStyle.extraPaddingTop}>
          <GuestCheckinModules.CheckinScreen
            checkin_screen={checkin_screen}
            onChangeGuestCheckin={this.onChangeGuestCheckin}
          />
          <GuestCheckinModules.AfterCheckin
            after_checkin={after_checkin}
            onChangeGuestCheckin={this.onChangeGuestCheckin}
          />
        </div>
      </div>
    );
  }
}

CustomerSelfCheckIn.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }),
};

export default withContext(MerchantInterfaceConsumer)(CustomerSelfCheckIn);
