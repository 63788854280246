// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import cx from "classnames";
import shortid from "shortid";

// Utils
import { _isTrue } from "utils";

// Style
import Style from "./style.module.scss";

// Lib
import { Constants, Functions } from "lib";

class ShopHoursTable extends React.Component {
  state = { convertedHours: {} };

  componentDidMount = () => this.onConvertHours();

  onConvertHours = async () => {
    const { DateTime } = Functions;
    const { _convertExtraHours, _convertHoursToLocal } = DateTime;
    this.setState({ isConvertingHours: true });
    const { hours, timeZone } = this.props;
    const convertedHours = await Object.keys(hours).reduce(
      async (previousPromise, weekday) => {
        const result = await previousPromise;
        const { extraHours = {}, isClosedAllDay = false } =
          hours[weekday] || {};
        return set(result, weekday, {
          extraHours: await _convertExtraHours({ extraHours, timeZone }),
          isClosedAllDay: _isTrue(isClosedAllDay),
          openHours: await _convertHoursToLocal({
            hours: hours[weekday] || {},
            timeZone: timeZone,
          }),
        });
      },
      Promise.resolve({})
    );
    this.setState({ convertedHours, isConvertingHours: false });
  };

  renderHours = () => {
    const { classNames = {} } = this.props;
    const { DAYS_IN_A_WEEK } = Constants;
    return Object.keys(DAYS_IN_A_WEEK).map((weekDay) => (
      <div
        className={cx(
          Style.weekdayContainer,
          this.props.today === weekDay && Style.isToday,
          classNames.weekDay
        )}
        key={weekDay}
      >
        <div className={Style.weekday}>{weekDay}</div>
        {this.renderWeekDayHours(this.state.convertedHours[weekDay] || {})}
      </div>
    ));
  };

  renderWeekDayHours = ({
    extraHours = {},
    isClosedAllDay = false,
    openHours = {},
  }) => {
    if (isClosedAllDay) return "CLOSED";
    const { localEndAt12Hr, localStartAt12Hr } = openHours;
    return (
      <div>
        <div>
          {localStartAt12Hr} - {localEndAt12Hr}
        </div>
        {Object.values(extraHours).map((hours) => {
          const { localEndAt12Hr: end, localStartAt12Hr: start } = hours;
          return (
            <div key={shortid.generate()} style={{ marginTop: "0.2rem" }}>
              {start} - {end}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { classNames = {} } = this.props;
    return (
      <div className={cx(Style.container, classNames.container)}>
        {this.state.isConvertingHours ? "Finding hours..." : this.renderHours()}
      </div>
    );
  }
}

ShopHoursTable.propTypes = {
  classNames: PropTypes.exact({
    container: PropTypes.string,
    weekDay: PropTypes.string,
  }),
  hours: PropTypes.shape({
    monday: PropTypes.shape({
      endAt: PropTypes.string,
      isClosedAllDay: PropTypes.bool,
      startAt: PropTypes.string,
    }),
    tuesday: PropTypes.shape({
      endAt: PropTypes.string,
      isClosedAllDay: PropTypes.bool,
      startAt: PropTypes.string,
    }),
  }),
  timeZone: PropTypes.string.isRequired,
  today: PropTypes.oneOf([
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]),
};

export default ShopHoursTable;
