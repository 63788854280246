// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { set } from "object-path-immutable";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Utils
import { _roundNumber } from "utils";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Chip, Img } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

// Lib
import BUTI from "lib/Services/BUTI";

class Item extends Component {
  state = {};

  componentDidMount = () => this.setState({ itemInfo: this.props.itemInfo });

  onChangeItemImage = async (imageURL) => {
    const image = { imageURL };
    const { success } = await BUTI.PostRequests.ChangeMenuItemImage({
      image,
      itemID: this.props.itemID,
      shopID: this.props.context.shopID,
    });
    if (success) {
      this.onUpdateItemImageAfterSaved(image);
      this.onHideChangePhotoModal();
    }
  };

  onGetItemImage = () => _get(this.state.itemInfo, "itemImages.full") || {};

  onHideChangePhotoModal = () => this.setState({ showChangePhotoModal: false });

  onHideEditItemModal = () => this.setState({ showEditItemModal: false });

  onShowChangePhotoModal = () => this.setState({ showChangePhotoModal: true });

  onShowEditItemModal = () => this.setState({ showEditItemModal: true });

  onUpdateAfterItemArchived = ({ shouldArchiveItem = false }) => {
    this.setState(
      {
        itemInfo: set(this.state.itemInfo, "itemIsArchived", shouldArchiveItem),
      },
      () =>
        this.props.onUpdateAllItems({
          itemID: this.props.itemID,
          itemInfo: this.state.itemInfo,
        })
    );
  };

  onUpdateAfterItemSaved = ({ itemInfo }) =>
    this.setState({ itemInfo }, () =>
      this.props.onUpdateAllItems({
        itemID: this.props.itemID,
        itemInfo: this.state.itemInfo,
      })
    );

  onUpdateItemImageAfterSaved = (newImage = "") =>
    this.setState(
      { itemInfo: set(this.state.itemInfo, "itemImages.full", newImage) },
      () =>
        this.props.onUpdateAllItems({
          itemID: this.props.itemID,
          itemInfo: this.state.itemInfo,
        })
    );

  renderChangePhotoModal = () => {
    const { itemName } = this.state.itemInfo;
    const itemImage = this.onGetItemImage();
    const { imageURL = "" } = itemImage;
    return (
      <Modals.ImageViewerModal
        destinationFolderName={`shops/${this.props.context.shopID}/item-images`}
        imageURL={imageURL}
        modalContentLabel="Item's Image"
        modal_header={itemName}
        onCloseModal={this.onHideChangePhotoModal}
        onCompleteImageUpload={this.onChangeItemImage}
        saveButtonName="Save new item image"
      />
    );
  };

  renderChipAvatar = () => {
    const { imageURL = "" } = this.onGetItemImage();
    return <Img alt="" src={imageURL} />;
  };

  renderChipLabel = () => {
    const { itemInfo } = this.state;
    const { itemPrice } = itemInfo;
    const modifierGroupsCount = Object.keys(itemInfo.modifierGroups || {})
      .length;
    return (
      <React.Fragment>
        <div className={CommonStyle.chipName}>{itemInfo.itemName}</div>
        <div className={Style.chipDescription}>
          {itemPrice && (
            <div className={CommonStyle.price}>
              ${_roundNumber(itemPrice).toFixed(2)}
            </div>
          )}
          {modifierGroupsCount > 0 && (
            <div className={CommonStyle.elementsCounter}>
              {modifierGroupsCount} mod. group
              {modifierGroupsCount !== 1 ? "s" : ""}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderEditModal = () => (
    <Modals.MenuItemModal
      isInEditMode={this.props.isInEditMode}
      itemID={this.props.itemID}
      itemInfo={this.state.itemInfo}
      onCloseModal={this.onHideEditItemModal}
      onUpdateAfterItemArchived={this.onUpdateAfterItemArchived}
      onUpdateAfterItemSaved={this.onUpdateAfterItemSaved}
    />
  );

  renderHelperButton = () => {
    const { itemHelperButton } = this.props;
    if (itemHelperButton) return itemHelperButton(this.props.itemID);
    return (
      <PencilIcon
        className={CommonStyle.pencilIcon}
        onClick={this.onShowEditItemModal}
      />
    );
  };

  renderItem = () => (
    <div className={CommonStyle.chipContainer}>
      <Chip
        avatar={this.renderChipAvatar()}
        label={this.renderChipLabel()}
        onClickAvatar={() =>
          this.props.allowChangeItemPhoto && this.onShowChangePhotoModal()
        }
        onClickLabel={this.onShowEditItemModal}
        helperButtonContent={this.renderHelperButton()}
      />
      {this.renderSigns()}
    </div>
  );

  renderSigns = () => {
    const {
      itemIsArchived = false,
      itemIsOutOfStock = {},
      itemIsOnSale = {},
      itemSaleRate = 0,
    } = this.state.itemInfo;
    if (itemIsArchived)
      return (
        <div className={CommonStyle.signsContainer}>
          <div className={CommonStyle.archivedSign}>Archived</div>
        </div>
      );
    if (itemIsOutOfStock.true || itemIsOnSale.true) {
      return (
        <div className={CommonStyle.signsContainer}>
          {itemIsOutOfStock.true && (
            <div className={CommonStyle.soldOutSign}>Sold Out</div>
          )}
          {itemIsOnSale.true && (
            <div className={CommonStyle.onSaleSign}>
              On Sale ({itemSaleRate || 0}%)
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showChangePhotoModal && this.renderChangePhotoModal()}
        {this.state.showEditItemModal && this.renderEditModal()}
        {this.state.itemInfo && this.renderItem()}
      </React.Fragment>
    );
  }
}

Item.propTypes = {
  allowChangeItemPhoto: PropTypes.bool,
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }).isRequired,
  isInEditMode: PropTypes.bool,
  itemHelperButton: PropTypes.func,
  itemID: PropTypes.string.isRequired,
  itemInfo: PropTypes.shape({ itemName: PropTypes.string }).isRequired,
  onUpdateAllItems: PropTypes.func,
};

Item.defaultProps = {
  allowChangeItemPhoto: true,
  isInEditMode: true,
  onUpdateAllItems: () => {},
};

export default withContext(MerchantInterfaceConsumer)(Item);
