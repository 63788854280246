// BUTI DINERS, INC. All right Reserved ©

import jsPDF from "jspdf";
import { Functions } from "lib";
import { _formatPhoneNumber, _removeAccents, _roundNumber } from "utils";
import Skipli_Logo from "assets/Images/Skipli-Logo-With-Text.png";

const addTextToDocument = (
  doc,
  content,
  x,
  y,
  font_size = 12,
  font_style = "normal",
  font_color = "#858585"
) => {
  const replaced_content = content.replace(/Đ/g, "D");
  doc.setFontSize(font_size);
  doc.setFont("helvetica", font_style);
  doc.setTextColor(font_color);
  doc.text(replaced_content, x, y);
};

const _createPDFModifierGroups = (
  doc,
  modifier_groups,
  modifierY,
  y,
  start_y,
  current_page,
  modifier_spacing
) => {
  Object.values(modifier_groups).forEach((modifier_group) => {
    modifier_group.modifiers &&
      Object.values(modifier_group.modifiers).forEach((modifier) => {
        if (modifierY > 280) {
          doc.addPage();
          y = start_y;
          current_page++;
          modifierY = y + 6;
        }
        addTextToDocument(doc, `•`, 30, modifierY);
        const modifier_text = `${
          modifier.modifierName
        } ($${modifier.modifierPrice.toFixed(2)})`;
        const modifier_lines = doc.splitTextToSize(modifier_text, 150);
        modifier_lines.forEach((line, line_index) => {
          const modifierX = line_index === 0 ? 35 : 20;
          addTextToDocument(doc, line, modifierX, modifierY, 10, "bold");
          modifierY += modifier_spacing;
        });

        modifierY += modifier_spacing;
      });
  });
  return modifierY;
};

const _createOrderItems = (doc, items, y) => {
  let current_page = 1;
  const start_y = 10;
  const modifier_spacing = 3;
  items.forEach((item, index) => {
    const {
      item_name,
      quantity,
      item_price,
      modifier_groups,
      item_sale_rate,
    } = item;
    const discount = item_sale_rate ? (item_price * item_sale_rate) / 100 : 0;

    if (y > 280) {
      doc.addPage();
      y = start_y;
      current_page++;
    }
    const MAX_ITEM_NAME_LENGTH = 60;
    let item_name_no_accents = _removeAccents({ text: item_name });
    addTextToDocument(doc, `${quantity}x`, 20, y);
    addTextToDocument(
      doc,
      `${
        item_name_no_accents.length > MAX_ITEM_NAME_LENGTH
          ? item_name_no_accents.slice(0, MAX_ITEM_NAME_LENGTH) + "..."
          : item_name_no_accents
      }`,
      30,
      y,
      12,
      "bold"
    );
    let modifierY = y + 6;
    let total_price = (item_price - discount) * quantity;
    if (modifier_groups) {
      Object.values(modifier_groups).forEach((modifier_group) => {
        modifier_group.modifiers &&
          Object.values(modifier_group.modifiers).forEach((modifier) => {
            total_price += modifier.modifierPrice * quantity;
          });
      });
    }
    addTextToDocument(doc, `$${total_price.toFixed(2)}`, 180, y);
    if (modifier_groups) {
      modifierY = _createPDFModifierGroups(
        doc,
        modifier_groups,
        modifierY,
        y,
        start_y,
        current_page,
        modifier_spacing
      );
    }
    item_sale_rate &&
      addTextToDocument(
        doc,
        `${item_sale_rate} % discount`,
        169,
        y + 6,
        10,
        "bold",
        "#009459"
      );

    y = modifierY;
    y += 7;
    if (
      index !== items.length - 1 &&
      y > 280 &&
      current_page !== doc.getNumberOfPages()
    ) {
      doc.addPage();
      y = start_y;
      current_page++;
    }
  });

  return y;
};

const _createReceiptPDF = ({
  customerName,
  order_id,
  order_info,
  payment_info,
  shop_address,
  shop_name,
  time_order,
}) => {
  const { OrderMathFuncs } = Functions;
  const {
    _calSubTotalOfOrder,
    _calcSubTotalPlusTax,
    _calcTotalDiscount,
    _calcTaxPlusTip,
  } = OrderMathFuncs;

  const {
    phoneNumber,
    tipAmount,
    pickUpTime,
    orderItems = {},
    orderDeliveryTypeID,
    gift_card_ledger = {},
    feesForBUTI,
    deliveryDetails = {},
    tableNumber,
    store_front = {},
  } = order_info;
  const { fees_list = 0 } = store_front;
  const {
    convenience: convenience_fee = 0,
    online_payment_processing = 0,
  } = fees_list;
  const {
    monetaryAmount: monetary_amount_online_payment_processing = 0,
  } = online_payment_processing;
  const {
    monetaryAmount: monetary_amount_convenience_fee = 0,
  } = convenience_fee;
  const { last4, amount_payment = 0 } = payment_info;
  const { redeemed_amount = 0 } = gift_card_ledger;
  const {
    deliveryFee = 0,
    technologyFee = 0,
    serviceFee = 0,
    convenience = 0,
  } = feesForBUTI;
  const { courier = "" } = deliveryDetails;
  const amount_payment_card = (amount_payment / 100).toFixed(2);

  const items = Object.values(orderItems).map((item) => ({
    item_name: item.itemSimpleDescription.itemName,
    itemKitchenChitName: item.itemSimpleDescription.itemKitchenChitName,
    item_price: item.itemSimpleDescription.itemPrice,
    item_sale_rate: item.itemSaleRate,
    quantity: item.quantity,
    modifier_groups: item.modifierGroups,
  }));

  const total_discount = _calcTotalDiscount({ orderItems });
  const sub_total_plus_tax = _roundNumber(
    total_discount + _calcSubTotalPlusTax({ orderDetails: order_info })
  );
  const sub_total = _roundNumber(
    total_discount + _calSubTotalOfOrder({ orderItems })
  );
  const tax = _roundNumber(
    parseFloat(sub_total_plus_tax) - parseFloat(sub_total)
  );
  const values = [tipAmount, tax, serviceFee, technologyFee, convenience];

  const tax_plus_tip = _calcTaxPlusTip({ values });

  const doc = new jsPDF();
  const deliveryTypes = {
    pickUp: "Pick Up",
    deliver: "Delivery",
    inStore: "In Store",
  };
  const deliveryTypeText = deliveryTypes[orderDeliveryTypeID] || "";
  const title = `Receipt for Skipli order #${order_id}`;
  doc.addImage(Skipli_Logo, 20, 7, 30, 10);
  addTextToDocument(
    doc,
    `Customer: ${customerName}`,
    20,
    27,
    12,
    "bold",
    "black"
  );
  addTextToDocument(
    doc,
    `Phone: ${_formatPhoneNumber(phoneNumber)}`,
    20,
    34,
    12,
    "bold"
  );

  addTextToDocument(doc, `${time_order}`, 20, 41, 12, "bold");
  addTextToDocument(doc, title, 20, 57, 18, "bold", "black");
  addTextToDocument(doc, `${shop_name}`, 20, 64, 11, "bold");
  addTextToDocument(doc, `${shop_address}`, 20, 71, 11, "bold");
  addTextToDocument(doc, `${deliveryTypeText}`, 20, 78, 11, "bold");

  if (orderDeliveryTypeID !== "deliver" && orderDeliveryTypeID !== "inStore")
    addTextToDocument(doc, `Time: ${pickUpTime}`, 20, 85, 11, "bold");
  if (orderDeliveryTypeID !== "pickUp" && orderDeliveryTypeID !== "inStore")
    addTextToDocument(
      doc,
      `Courier: ${courier.charAt(0).toUpperCase() + courier.slice(1)}`,
      20,
      85,
      11,
      "bold"
    );
  if (orderDeliveryTypeID !== "pickUp" && orderDeliveryTypeID !== "deliver")
    addTextToDocument(doc, `Table Number: ${tableNumber}`, 20, 85, 11, "bold");
  let amount_y = 92;

  if (redeemed_amount !== 0) {
    addTextToDocument(
      doc,
      `Payment Giftcard: $ ${redeemed_amount.toFixed(2)}`,
      20,
      amount_y,
      11,
      "bold"
    );
    amount_y += 7;
  }
  if (last4) {
    if (redeemed_amount === 0) {
      amount_y = 92;
    }
    addTextToDocument(
      doc,
      `Payment Card: ****${last4} || Amount: $ ${amount_payment_card}`,
      20,
      amount_y,
      11,
      "bold"
    );
  }

  let y = 110;

  y = _createOrderItems(doc, items, y);

  doc.setLineWidth(0.2);
  doc.line(20, y - 9, 200, y - 9);
  addTextToDocument(doc, `  Subtotal `, 20, y, 10);
  addTextToDocument(doc, `  $ ${sub_total.toFixed(2)}`, 180, y, 10);

  if (deliveryFee) {
    addTextToDocument(doc, `  Delivery Fee `, 20, y + 6, 10);
    addTextToDocument(doc, `  $ ${deliveryFee.toFixed(2)}`, 180, y + 6, 10);
  } else {
    y -= 6;
  }
  if (total_discount !== 0) {
    addTextToDocument(doc, `  Discount `, 20, y + 12, 10);
    addTextToDocument(doc, `- $ ${total_discount.toFixed(2)}`, 180, y + 12, 10);
  } else {
    y -= 6;
  }

  addTextToDocument(doc, `  Tip & Tax `, 20, y + 18, 10);
  addTextToDocument(doc, `  $ ${tax_plus_tip.toFixed(2)}`, 180, y + 18, 10);

  if (
    monetary_amount_convenience_fee !== 0 &&
    monetary_amount_convenience_fee
  ) {
    addTextToDocument(doc, `  Packaging & Handling `, 20, y + 24, 10);
    addTextToDocument(
      doc,
      `  $ ${monetary_amount_convenience_fee.toFixed(2)}`,
      180,
      y + 24,
      10
    );
  } else {
    y -= 6;
  }
  if (
    monetary_amount_online_payment_processing !== 0 &&
    monetary_amount_online_payment_processing
  ) {
    addTextToDocument(doc, `  Online Payment Processing `, 20, y + 30, 10);
    addTextToDocument(
      doc,
      `  $ ${monetary_amount_online_payment_processing.toFixed(2)}`,
      180,
      y + 30,
      10
    );
  }
  const pdf_data = "Receipt Order Information.pdf";
  doc.save(pdf_data);
  const data = doc.output("datauristring");
  const attachments_pdf = {
    filename: pdf_data,
    content: data.split("base64,")[1],
    encoding: "base64",
  };
  return attachments_pdf;
};

export { _createReceiptPDF };
