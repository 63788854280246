// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";
import { del, set } from "object-path-immutable";
import cx from "classnames";

import AddButton from "../AddButton";
import FilterButton from "../FilterButton";
import { _filter } from "../functions";

import { DEFAULT_FILTER_VALUES, FILTER_MODIFIERS_CRITERIAS } from "./constants";

// Style
import Style from "../style.module.scss";

// Components
import { MenusListOfElements, Modals } from "components";

// Fields
import { LoadingSpinner, PageMsg } from "fields";

// Lib
import { Services } from "lib";

const { Merchants } = Services;
const { GetAllModifiers } = Merchants.GetRequests;

class AllModifiers extends Component {
  state = { allModifiers: {}, selected_filter_values: DEFAULT_FILTER_VALUES };

  componentDidMount = () => this.onGetAllModifiers();

  componentWillUnmount = () => this.setState({ isGettingModifiers: false });

  onGetAllModifiers = async () => {
    this.setState({ isGettingModifiers: true });
    try {
      const { allModifiers = {} } = await GetAllModifiers({
        shopID: this.props.shopID,
      });
      this.setState({ allModifiers }, this.onUpdateFilterResults);
    } catch {
    } finally {
      this.setState({ isGettingModifiers: false });
    }
  };

  onUpdateFilterResults = () =>
    this.setState({
      filter_results: _filter({
        list: this.state.allModifiers,
        filter_criterias: FILTER_MODIFIERS_CRITERIAS,
        selected_filter_values: this.state.selected_filter_values,
      }),
    });

  onUpdateAllModifiers = ({ modifierID = "", modifierInfo = {} }) => {
    const { allModifiers = {} } = this.state;
    if (!modifierID) return;
    else if (_isEmpty(modifierInfo))
      this.setState(
        { allModifiers: del(allModifiers, modifierID) },
        this.onUpdateFilterResults
      );
    else {
      this.setState(
        { allModifiers: set(allModifiers, modifierID, modifierInfo) },
        this.onUpdateFilterResults
      );
    }
  };

  onUpdateSelectedFilterValues = (selected_filter_values) =>
    this.setState({ selected_filter_values }, this.onUpdateFilterResults);

  renderCreateModal = () => (
    <Modals.ModifierModal
      onCloseModal={() => this.setState({ showCreateModifierModal: false })}
      onUpdateModifiersAfterCreate={this.onUpdateAllModifiers}
    />
  );

  renderContent = () => {
    const { filter_results = {} } = this.state;
    const { scroll_y } = this.props;
    const count = Object.keys(filter_results).length;

    return (
      <div>
        <div
          className={cx(Style.headingContainer, {
            [Style.headingContainerEnd]: scroll_y > 50,
          })}
        >
          <h2 className={Style.heading}>
            {count} {count > 1 ? "Modifiers" : "Modifier"}
          </h2>
          <div
            className={cx(Style.buttons_group, {
              [Style.buttons_group_update]: scroll_y > 50,
            })}
          >
            <AddButton
              onClick={() => this.setState({ showCreateModifierModal: true })}
            />
            <FilterButton
              filter_criterias={FILTER_MODIFIERS_CRITERIAS}
              onUpdateSelectedFilterValues={this.onUpdateSelectedFilterValues}
              results_count={count}
              selected_filter_values={this.state.selected_filter_values}
            />
          </div>
        </div>
        {this.state.showCreateModifierModal && this.renderCreateModal()}
        {this.renderModifiers()}
      </div>
    );
  };

  renderModifiers = () => {
    const { filter_results = {} } = this.state;
    return (
      <MenusListOfElements.ListOfModifiers
        modifierProps={{ isInEditMode: true }}
        modifiers={filter_results}
        onUpdateAllModifiers={this.onUpdateAllModifiers}
      />
    );
  };

  render() {
    if (this.state.isGettingModifiers)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for modifiers" />
        </PageMsg>
      );
    return this.renderContent();
  }
}

AllModifiers.propTypes = {
  shopID: PropTypes.string.isRequired,
};

export default AllModifiers;
