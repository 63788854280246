// BUTI DINERS, INC. All right Reserved ©

import dayjs from "dayjs";

// Utils
import { _isTrue } from "utils";

// Lib
import { Services } from "lib";

const _convertSpecialHoursToText = ({ special_hours }) =>
  _sortSpecialHours({ special_hours }).reduce((result, id) => {
    const { date, is_closed, end_at = "", start_at = "" } = special_hours[id];
    if (_isTrue(is_closed)) return result.concat(`${date}: CLOSED\r\n\r\n`);
    return result.concat(
      `${date}: ${dayjs("1/1/1 " + start_at).format("h:mm A")} - ${dayjs(
        "1/1/1 " + end_at
      ).format("h:mm A")}\r\n\r\n`
    );
  }, "");

  const _dayjs = (date, c) => {
    if (typeof date === 'string') date = date.replace(/-/g, '/')
    return dayjs(date, c)
  }
const _createEmailBody = async ({ special_hours, shop_id }) => {
  const { FRONT_END_BASE_URL } = Services;
  const merchant_portal = `${FRONT_END_BASE_URL}/${shop_id}`;
  const hoursInText = _convertSpecialHoursToText({ special_hours });
  return `The merchant updated the special hours.\r\n\r\n${hoursInText}See more details in the merchant portal:\r\n${merchant_portal}`;
};

const _sortSpecialHours = ({ special_hours }) =>
  Object.keys(special_hours).sort((a, b) => {
    const { date: date_a = "" } = special_hours[a];
    const { date: date_b = "" } = special_hours[b];
    return dayjs(date_b).diff(dayjs(date_a));
  });

export { _createEmailBody, _sortSpecialHours ,_dayjs };
