// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Img from "react-cool-img";
import { set } from "object-path-immutable";

import { SOCIAL_MEDIA_COMPANIES } from "./constants";

// Style
import ModuleStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Input } from "fields";

class SocialMediaProfiles extends React.Component {
  constructor(props) {
    super(props);
    const { social_media_profiles = {} } = this.props;
    this.state = { copy_social_media_profiles: social_media_profiles };
  }

  onInputChange = ({
    company_id,
    company_name,
    is_regex_valid = false,
    new_value,
  }) => {
    const { copy_social_media_profiles = {} } = this.state;
    this.setState(
      {
        copy_social_media_profiles: set(
          copy_social_media_profiles,
          company_id,
          { name: company_name, profile_href: new_value }
        ),
      },
      () =>
        is_regex_valid &&
        this.props.onInputChange(
          "social_media_profiles",
          this.state.copy_social_media_profiles
        )
    );
  };

  renderSocialMediaCompanies = () => {
    const { copy_social_media_profiles = {} } = this.state;
    return Object.keys(SOCIAL_MEDIA_COMPANIES).map((company_id) => {
      const { logo, name, placeholder, regEx } = SOCIAL_MEDIA_COMPANIES[
        company_id
      ];
      const { profile_href = "" } =
        copy_social_media_profiles[company_id] || {};
      return (
        <div className={ModuleStyle.field} key={company_id}>
          <div className={Style.company_logo_and_name}>
            <Img alt={`${name} logo`} className={Style.logo} src={logo} />
            <span className={Style.company_name}>{name}</span>
          </div>
          <Input.TextInput
            errortext={`Not a valid ${name} link`}
            name={`${name} hyperlink`}
            onChange={(new_value) =>
              this.onInputChange({
                company_id,
                company_name: name,
                is_regex_valid: regEx.test(new_value),
                new_value,
              })
            }
            placeholder={placeholder}
            regEx={regEx}
            showError
            value={profile_href}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <div className={ModuleStyle.fieldsContainer}>
        {this.renderSocialMediaCompanies()}
      </div>
    );
  }
}

SocialMediaProfiles.propTypes = {
  onInputChange: PropTypes.func,
  social_media_profiles: PropTypes.shape({
    facebook: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    google: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    instagram: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    trip_adviser: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    yelp: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
  }),
};

export default SocialMediaProfiles;
