// BUTI DINERS, INC. All right Reserved ©

import ActiveCoupons from "./ActiveCoupons";
import CouponInfo from "./CouponInfo";
import CouponSelections from "./CouponSelections";
import CouponSubmitButton from "./CouponSubmitButton";

const CouponModules = {
  ActiveCoupons,
  CouponInfo,
  CouponSelections,
  CouponSubmitButton
};

export default CouponModules;
