// BUTI DINERS, INC. All right Reserved ©

const SHOP_INFO_MODULES = [
  { id: "basic_info", name: "Basic Info" },
  { id: "social_media_profiles", name: "Social Media Profiles" },
  { id: "new_orders_notification", name: "New Orders Notification" },
  { id: "shop_logo", name: "Shop Logo" },
  { id: "shop_thumbnail", name: "Shop Cover Photo" },
  { id: "membership", name: "Membership" },
];

export { SHOP_INFO_MODULES };
