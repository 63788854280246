// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Utils
import { _roundNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Lib
import { Functions } from "lib";

const SubTotal = ({ orderInfo }) => {
  const { OrderMathFuncs } = Functions;
  const { _calSubTotalOfOrder, _calcSubTotalPlusTax } = OrderMathFuncs;
  const {
    // merchantFees = {},
    orderItems = {},
  } = orderInfo;
  // const { totalFee = 0 } = merchantFees;
  const subTotal = _calSubTotalOfOrder({ orderItems });
  const subTotalPlusTax = _calcSubTotalPlusTax({ orderDetails: orderInfo });
  const tax = _roundNumber(parseFloat(subTotalPlusTax) - parseFloat(subTotal));
  // const showMerchantFees =
  //   this.props.orderType === "past" && parseFloat(totalFee) > 0;

  return (
    <div className={Style.subTotal}>
      <div className={Style.subTotalItem}>
        <div className={Style.label}>Sub Total</div>
        <div className={Style.amount}>${subTotal.toFixed(2)}</div>
      </div>
      <div className={Style.subTotalItem}>
        <div className={Style.label}>Sales Tax</div>
        <div className={Style.amount}>${tax.toFixed(2)}</div>
      </div>
      {/* {showMerchantFees && (
        <div className={cx(Style.subTotalItem, Style.totalMerchantFee)}>
          <div className={Style.label}>Total Fee:</div>
          <div className={Style.amount}>${_roundNumber(totalFee)}</div>
        </div>
      )} */}
    </div>
  );
};

SubTotal.propTypes = {
  orderInfo: PropTypes.object.isRequired,
};

export default SubTotal;
