// BUTI DINERS, INC. All right Reserved ©

const LIVE_MODE_ENABLED = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV !== "development";
};

const STRIPE_PUBLIC_KEY_TEST = "pk_test_vfivIGYPmiSF4hLKHWqfUawB00SZHxSGVu";
const STRIPE_PUBLIC_KEY_LIVE = "pk_live_29b6NJN6S1NncoKZ6Ypev98000ZOzqAhIo";

const STRIPE_PUBLIC_KEY = LIVE_MODE_ENABLED()
  ? STRIPE_PUBLIC_KEY_LIVE
  : STRIPE_PUBLIC_KEY_TEST;

const STRIPE_CREDENTIALS = { STRIPE_PUBLIC_KEY };

const STRIPE_TRANSACTION_FEES = {
  BASE_FEE: 0.3, // $0.3
  CARD_FEE: 0.035, // 3.5 percent for cards that is not Amex
  CARD_AMEX_FEE: 0.04, // 4 percent for American Express
  CONV_FEE: 0.6 // $0.69
};

export default {
  STRIPE_CREDENTIALS,
  STRIPE_TRANSACTION_FEES
};
