// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Lib
import { Constants } from "lib";

const SubNavbar = props => {
  const renderNavItems = () => {
    const selectedStyle = cx(Style.selected, props.selectedNavItemClassname);
    const { MAX_SUB_NAVBAR_ITEMS } = Constants;
    return props.navItems.map(({ id, label }, index) => {
      const isSelected = id === props.activeNavItemId;
      return (
        index < MAX_SUB_NAVBAR_ITEMS && (
          <div key={id} onClick={() => props.onNavItemClick(id)}>
            <button
              className={cx(
                Style.subNavItem,
                props.navItemClassname,
                isSelected && selectedStyle
              )}
              name={`navigation bar item ${label}`}
            >
              {label}
            </button>
          </div>
        )
      );
    });
  };

  return <div className={Style.subNavContainer}>{renderNavItems()}</div>;
};

SubNavbar.propTypes = {
  activeNavItemId: PropTypes.string,
  navItems: PropTypes.array.isRequired,
  navItemClassname: PropTypes.string,
  onNavItemClick: PropTypes.func.isRequired
};

export default SubNavbar;
