/* eslint-disable no-throw-literal */
// BUTI DINERS, INC. All right Reserved ©

import { set } from "object-path-immutable";

// Lib
import { Services } from "lib";

const _checkPrintersConnectivity = async (printers = {}) =>
  await Object.keys(printers).reduce(async (previousPromise, printerName) => {
    const result = await previousPromise;
    return await window.qz.printers
      .find(printerName)
      .then(() => {
        return set(result, `${printerName}.isConnected`, true);
      })
      .catch(() => set(result, `${printerName}.isConnected`, false));
  }, Promise.resolve(printers));

const _getDigitalCertificate = async () => {
  const { GetQZDigitalCertificate } = Services.Merchants.GetRequests;
  const certificate = await GetQZDigitalCertificate();
  await window.qz.security.setCertificatePromise(async (resolve, reject) => {
    resolve(certificate.data);
  });
};

const _getQZSignature = async () => {
  const { Merchants } = Services;
  const { GetQZSignature } = Merchants.GetRequests;
  await window.qz.security.setSignaturePromise((toSign) => {
    return (resolve, reject) => {
      GetQZSignature({ toSign })
        .then((signature) => resolve(signature.data))
        .catch((err) => console.error(err));
    };
  });
};

const _verifyShopBasicInfo = ({ shopInfo = {} }) => {
  const { address = "", name = "" } = shopInfo;
  try {
    if (!name) throw "Shop must have a name";
    else if (!address) throw "Shop must have an address";
    // else if (parseFloat(salesTax) < 0 || parseFloat(salesTax) === 0)
    //   throw "Sales tax must be greater than 0";
    return true;
  } catch (error) {
    throw error;
  }
};

export {
  _checkPrintersConnectivity,
  _getDigitalCertificate,
  _getQZSignature,
  _verifyShopBasicInfo,
};
