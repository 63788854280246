// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import ConfirmArchiveStyle from "components/Modals/style.module.scss";

// Fields
import { Input, ResponsiveModal } from "fields";

const { TextInput } = Input;

class ConfirmArchiveItemModal extends Component {
  state = { itemName: "" };

  onInputChange = (itemName) => this.setState({ itemName });

  onGetSubmitBtnStatus = () =>
    this.state.itemName.toLowerCase() === this.props.itemName.toLowerCase()
      ? "active"
      : "inactive";

  onSubmit = (e) => {
    e && e.preventDefault();
    this.props.onCloseModal();
    this.props.onArchiveItem();
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{
          footer_submit_button: ConfirmArchiveStyle.confirmArchiveButton,
          modal: ConfirmArchiveStyle.confirmArchiveModalBox,
        }}
        contentLabel="Confirm archive item modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Archive",
            status: this.onGetSubmitBtnStatus(),
          },
        }}
        headerProps={{
          close_button_icon: "arrow",
          header: "Archive item",
        }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.onSubmit}
      >
        <div className={ConfirmArchiveStyle.confirmArchiveTitle}>
          Type <strong>{this.props.itemName}</strong> to archive
        </div>
        <TextInput
          name="type item name to confirm archiving"
          value={this.state.itemName}
          onChange={this.onInputChange}
        />
      </ResponsiveModal>
    );
  }
}

ConfirmArchiveItemModal.propTypes = {
  itemName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onArchiveItem: PropTypes.func.isRequired,
};

export default ConfirmArchiveItemModal;
