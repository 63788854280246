// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import ModuleStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, Img } from "fields";

// Lib
import BUTI from "lib/Services/BUTI";

class ShopLogoModule extends React.Component {
  state = {};

  onChangeShopLogo = async (imageURL) => {
    const { success } = await BUTI.PostRequests.ChangeShopLogo({
      logoURL: imageURL,
      shopID: this.props.shopID,
    });
    if (success) {
      this.props.onUpdateShopLogoAfterSaved(imageURL);
      this.onHideChangeLogoModal();
    }
  };

  onHideChangeLogoModal = () => this.setState({ showChangeLogoModal: false });

  onShowChangeLogoModal = () => this.setState({ showChangeLogoModal: true });

  render() {
    return (
      <React.Fragment>
        {this.state.showChangeLogoModal && (
          <Modals.ImageViewerModal
            destinationFolderName={`shops/${this.props.shopID}/logos`}
            imageURL={this.props.logoURL}
            modalContentLabel="Shop's Logo Image Modal"
            onCloseModal={this.onHideChangeLogoModal}
            onCompleteImageUpload={this.onChangeShopLogo}
            saveButtonName="Save new shop logo"
          />
        )}
        <div>
          <div>
            <Img
              className={ModuleStyle.image}
              onClick={this.onShowChangeLogoModal}
              src={this.props.logoURL}
            />
          </div>
          <Button
            className={ModuleStyle.moduleButton}
            name="change logo"
            onClick={this.onShowChangeLogoModal}
          >
            Change Logo
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

ShopLogoModule.propTypes = {
  logoURL: PropTypes.string,
  onUpdateShopLogoAfterSaved: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
};

ShopLogoModule.defaultProps = {
  logoURL: "",
};

export default ShopLogoModule;
