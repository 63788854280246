// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import FindExternalMenu from "./FindExternalMenu";
import ReviewMenu from "./ReviewMenu";
import Submit from "./Submit";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

const IMPORT_STEPS = {
  step1: { name: "Search Menu" },
  step2: { name: "Review Menu" },
  step3: { name: "Submit" },
};

class ImportMenuModal extends React.Component {
  state = { current_set_id: "step1" };

  onChangeStep = (stepId) => this.setState({ current_set_id: stepId });

  onMenuFound = (menu = {}) =>
    this.setState({ menu }, () => this.setState({ current_set_id: "step2" }));

  onGetModalHeader = () => {
    const { current_set_id } = this.state;
    const { name } = IMPORT_STEPS[current_set_id];
    return name;
  };

  renderStepContent = () => {
    switch (this.state.current_set_id) {
      case "step1":
        return <FindExternalMenu onMenuFound={this.onMenuFound} />;
      case "step2":
        return (
          <ReviewMenu menu={this.state.menu} onChangeStep={this.onChangeStep} />
        );
      case "step3":
        return (
          <Submit
            menu={this.state.menu}
            onAddNewMenu={this.props.onAddNewMenu}
            onChangeStep={this.onChangeStep}
            shopID={this.props.shopID}
          />
        );
      default:
        return;
    }
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modalClassname }}
        contentLabel="web page canvas"
        headerProps={{ header: this.onGetModalHeader() }}
        onCloseModal={this.props.onCloseModal}
      >
        <div
          className={cx(Style.stepContent, Style[this.state.current_set_id])}
        >
          {this.renderStepContent()}
        </div>
      </ResponsiveModal>
    );
  }
}

ImportMenuModal.propTypes = {
  onAddNewMenu: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default ImportMenuModal;

export { IMPORT_STEPS };
