// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import {
  _createTransactionsTableColumns,
  _sanitizeDataForTable,
  _sanitizeTransactions,
} from "./helperFunctions";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Button, Table } from "fields";

// Assets
import { ArrowIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

class PayoutTransactions extends React.Component {
  state = {};

  componentDidMount = async () => {
    const { ShowConfirmNotif } = Functions;
    try {
      this.setState({ isFindingTransactions: true });
      const transactions_info = await this.onGetPayoutTransactions();
      this.setState({ transactions_info });
    } catch {
      ShowConfirmNotif({
        message: "An error occured. Please try again later.",
        type: "error",
      });
    } finally {
      this.setState({ isFindingTransactions: false });
    }
  };

  onGetPayoutTransactions = async ({ starting_after = "" } = {}) => {
    const { Merchants } = Services;
    const { GetPayoutTransactions } = Merchants.GetRequests;
    const { payout = {}, stripeAccountID } = this.props;
    const { id = "" } = payout;
    try {
      const { transactions_info } = await GetPayoutTransactions({
        payoutId: id,
        starting_after,
        stripeAccountId: stripeAccountID,
      });
      const { data } = transactions_info;
      return {
        ...transactions_info,
        data: _sanitizeTransactions(data),
      };
    } catch (e) {
      throw e;
    }
  };

  onShowMoreData = async () => {
    const { ShowConfirmNotif } = Functions;
    this.setState({ isFindingMoreData: true });
    const { transactions_info = {} } = this.state;
    const { data = [] } = transactions_info;
    const oldestTransaction = data[data.length - 1] || {};
    const { id } = oldestTransaction;
    try {
      const more_transactions_info = await this.onGetPayoutTransactions({
        starting_after: id,
      });
      const { data: moreData = [], has_more } = more_transactions_info;
      this.setState({
        transactions_info: {
          ...transactions_info,
          data: _sanitizeTransactions(data.concat(moreData)),
          has_more,
        },
      });
    } catch {
      ShowConfirmNotif({
        message: "An error occured. Please try again later.",
        type: "error",
      });
    } finally {
      this.setState({ isFindingMoreData: false });
    }
  };

  renderBackToRecentPayoutsButton = () => (
    <Button
      className={Style.backButton}
      name="back to all payouts"
      onClick={this.props.onShowRecentPayouts}
    >
      <ArrowIcon /> All Payouts
    </Button>
  );

  renderOverView = () => {
    const { arrivalDate, created, payout = {}, status } = this.props;
    const { amount, description, id } = payout;
    return (
      <Box header="Overview">
        <div className={Style.infoRow}>
          <div className={Style.label}>Created</div>
          <div className={Style.value}>{created}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>Estimate Arrival</div>
          <div className={Style.value}>{arrivalDate}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>statement description</div>
          <div className={Style.value}>{description}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>status</div>
          <div className={Style.value}>{status}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>amount</div>
          <div className={Style.value}>
            ${parseFloat(`${amount}e-2`).toFixed(2)}
          </div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>ID</div>
          <div className={Style.value}>{id}</div>
        </div>
      </Box>
    );
  };

  renderTransactions = () => {
    const { transactions_info = {} } = this.state;
    const { data = [], has_more } = transactions_info;
    return (
      <Box header="Transactions">
        {this.state.isFindingTransactions ? (
          "Finding transactions..."
        ) : (
          <Table
            classNames={{ table: Style.table }}
            columns={_createTransactionsTableColumns()}
            data={_sanitizeDataForTable({
              data,
              timeZone: this.props.timeZone,
            })}
            hasMoreData={has_more}
            isFindingMoreData={this.state.isFindingMoreData}
            onShowMoreData={this.onShowMoreData}
            showMoreDataMessage="Show More Transactions"
          />
        )}
      </Box>
    );
  };

  render() {
    return (
      <div>
        {this.renderBackToRecentPayoutsButton()}
        {this.renderOverView()}
        {this.renderTransactions()}
      </div>
    );
  }
}

PayoutTransactions.propTypes = {
  arrivalDate: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  onShowRecentPayouts: PropTypes.func.isRequired,
  payout: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.object.isRequired,
  timeZone: PropTypes.string,
};

export default PayoutTransactions;
