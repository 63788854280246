// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input, Link } from "fields";

// Icons
import { SkipliLogoWithTextIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";
const { LocalStorage } = Functions;

const { FRONT_END_BASE_URL, Merchants } = Services;
const { VerifyPersonnelPin } = Merchants.GetRequests;

class AdminLogin extends Component {
  state = { inputErrorText: "", pin: "" };

  componentDidMount = () => {
    const { personnelPin = "", shopBasicInfo = {} } = this.props;
    const { name = "" } = shopBasicInfo;
    document.title = `Login | ${name}`;
    this.setState(
      { pin: personnelPin },
      () => personnelPin && this.onSubmitPin()
    );
  };

  onInputChange = (pin) => this.setState({ pin });

  onShowError = () =>
    this.setState({ inputErrorText: "Could not find your personnel PIN" });

  onSubmitPin = async () => {
    const { shopID } = this.props;
    const params = { personnelPin: this.state.pin, shopID };
    const { personnelID = "", success } = await VerifyPersonnelPin(params);
    if (!personnelID || !success) this.onShowError();
    else {
      this.setState({ inputErrorText: "" }, () => {
        LocalStorage.saveItemIntoStorage("personnelID", personnelID);
        if (this.props.personnelPin) {
          window.location.replace(`${FRONT_END_BASE_URL}/${shopID}`);
        } else this.props.onLoadPersonnelInfo();
      });
    }
  };

  renderContent = () => {
    const { shopBasicInfo = {} } = this.props;
    const { address, name = "" } = shopBasicInfo;
    return (
      <div className={Style.contentContainer}>
        <div className={Style.content}>
          <div
            className={Style.skipliLogo}
            onClick={() => (window.location.href = "/")}
          >
            <SkipliLogoWithTextIcon />
          </div>
          <div className={Style.shopName}>
            <strong>STORE</strong>
            <h1>{name}</h1>
            <p>{address}</p>
          </div>
          <p className={Style.message}>Enter your pin to continue</p>
          {this.renderForm()}
        </div>
      </div>
    );
  };

  renderHaveNoAccount = () => (
    <div className={Style.haveNoAccount}>
      Don't have an account?{" "}
      <div
        className={Style.signUpLink}
        onClick={() => (window.location.href = "/new-merchant-account")}
      >
        Sign up
      </div>
    </div>
  );

  renderForm = () => {
    const { pin, inputErrorText } = this.state;
    return (
      <div className={Style.pinInputForm}>
        <Input.TextInput
          errortext={inputErrorText}
          name="Admin Pin Field"
          onChange={this.onInputChange}
          placeholder="Pin Number"
          showError={inputErrorText !== ""}
          value={pin}
          required
          type="number"
        />
        <Button
          className={Style.submitBtn}
          color="info"
          name="submit pin number"
          onClick={this.onSubmitPin}
          status={!pin ? "inactive" : "active"}
        >
          Log In
        </Button>
        {this.renderHaveNoAccount()}
        {this.renderSupport()}
      </div>
    );
  };

  renderSupport = () => (
    <div className={Style.support}>
      <div className={Style.needHelp}>Need help? Contact us</div>
      <div>
        <Link className={Style.needHelp} href={`tel:6789991044`} target="_self">
          (678) 999-1044
        </Link>
        <Link className={Style.needHelp} href="mailto: accounts@skiplinow.com">
          accounts@skiplinow.com
        </Link>
      </div>
    </div>
  );

  render() {
    return <div className={Style.background}>{this.renderContent()}</div>;
  }
}

AdminLogin.propTypes = {
  onLoadPersonnelInfo: PropTypes.func.isRequired,
  personnelPin: PropTypes.string,
  shopBasicInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  shopID: PropTypes.string.isRequired,
};

AdminLogin.defaultProps = {
  personnelPin: "",
};

export default AdminLogin;
