// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { InfoIcon } from "assets/Icons";

class Notice extends React.Component {
  state = { showMore: true };

  renderContent = () => (
    <div className={Style.content}>
      <div>
        Your data security is our top priority. We only show shops that closely
        match with your shop's name. Please see example below to quickly find
        your shop.
      </div>
      <div className={Style.example}>
        <div className={Style.label}>Shop Name</div>
        <div className={Style.searchText}>My Awesome Restaurant</div>
        <div className={Style.label}>Recommended Search Text</div>
        <div className={Style.searchText}>My Awesome Restaurant</div>
      </div>
    </div>
  );

  render() {
    const { showMore } = this.state;
    return (
      <div className={Style.container}>
        <div
          className={cx(Style.notice, showMore && Style.expanded)}
          onClick={() => this.setState({ showMore: !showMore })}
        >
          <div className={Style.title}>
            <InfoIcon /> Search text is case sensitive
          </div>
          <div className={Style.learnMore}>
            {showMore ? "Hide Tip" : "Show Tip"}
          </div>
        </div>
        {showMore && this.renderContent()}
      </div>
    );
  }
}

export default Notice;
