// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Components
import { RadioGroup } from "components";

// Fields
import { Box, ColorPicker } from "fields";

const PAGE_VISIBILITY_OPTIONS = {
  true: { label: "Yes" },
  false: { label: "No" },
};

class General extends React.Component {
  onChangePageVisibility = (optionId) =>
    this.props.onChangeWebsiteModule(
      set(this.props.general, "isPageVisible", optionId)
    );

  onChangeThemeColor = (hexColor) =>
    this.props.onChangeWebsiteModule(
      set(this.props.general, "themeColor", hexColor)
    );

  render() {
    const { general = {} } = this.props;
    const { isPageVisible = "false", themeColor = "" } = general;
    return (
      <Box header="General">
        <RadioGroup
          onClick={this.onChangePageVisibility}
          heading="Page is Visible"
          options={PAGE_VISIBILITY_OPTIONS}
          required
          selectedOptionId={isPageVisible}
        />
        <ColorPicker
          currentHexColor={themeColor}
          heading="Website theme color"
          onChange={this.onChangeThemeColor}
        />
      </Box>
    );
  }
}

General.propTypes = {
  general: PropTypes.exact({
    isPageVisible: PropTypes.string,
    themeColor: PropTypes.string,
  }),
  onChangeWebsiteModule: PropTypes.func.isRequired,
};

General.defaultProps = {
  isPageVisible: "false",
};

export default General;
