// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { del, set } from "object-path-immutable";
import shortid from "shortid";

// Style
import WebsiteBuilderEditStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button, Input, ResponsiveModal } from "fields";

// Assets
import { CloseIcon, PlusIcon } from "assets/Icons";

class OrderingChannelsEditModal extends React.Component {
  onAddNewPartner = () => {
    const { orderingChannels = {} } = this.props;
    this.props.onChange(
      set(orderingChannels, `${shortid.generate()}`, { name: "" })
    );
  };

  onChange = ({ channelId, fieldId = "", fieldValue }) => {
    if (!fieldId) return;
    const { orderingChannels = {} } = this.props;
    this.props.onChange(
      set(orderingChannels, `${channelId}.${fieldId}`, fieldValue)
    );
  };

  onRemoveChannel = ({ channelId }) => {
    if (!channelId) return;
    const { orderingChannels = {} } = this.props;
    this.props.onChange(del(orderingChannels, `${channelId}`));
  };

  renderModalContent = () => (
    <div className={WebsiteBuilderEditStyle.modalContent}>
      <div className={Style.channelsList}>{this.renderOrderingChannels()}</div>
      <Button
        className={Style.addChannelButton}
        name="add more channels"
        onClick={this.onAddNewPartner}
      >
        <PlusIcon /> Add Partner
      </Button>
    </div>
  );

  renderOrderingChannels = () => {
    const { orderingChannels = {}, WELL_KNOWN_ORDERING_CHANNELS } = this.props;
    const channels = [
      ...new Set(
        Object.keys(orderingChannels).concat(
          Object.keys(WELL_KNOWN_ORDERING_CHANNELS)
        )
      ),
    ];
    return channels.map((channelId) => {
      const { name = "", url = "" } = orderingChannels[channelId] || {};
      const { logo } = WELL_KNOWN_ORDERING_CHANNELS[channelId] || {};
      let channelName;
      if (logo)
        channelName = (
          <div className={Style.logoContainer}>
            <div className={Style.logo}>{logo}</div>
          </div>
        );
      else
        channelName = (
          <div className={Style.logoContainer}>
            <Input.TextInput
              name="channel name"
              onChange={(value) =>
                this.onChange({
                  channelId,
                  fieldId: "name",
                  fieldValue: value,
                })
              }
              placeholder="Name"
              required
              value={name}
            />
          </div>
        );
      return (
        <div
          className={cx(
            Style.channel,
            Style[channelId],
            !logo ? Style.unknownChannel : ""
          )}
          key={channelId}
        >
          {channelName}
          <div className={Style.urlInputContainer}>
            <Input.TextInput
              name={channelId}
              onChange={(value) =>
                this.onChange({
                  channelId,
                  fieldId: "url",
                  fieldValue: value,
                })
              }
              placeholder="URL"
              readOnly={channelId === "skipli"}
              value={url || ""}
            />
            {!logo && (
              <Button
                className={Style.removeChannelButton}
                name="remove channel"
                onClick={() => this.onRemoveChannel({ channelId })}
              >
                <CloseIcon /> Remove
              </Button>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: WebsiteBuilderEditStyle.modal }}
        contentLabel="Edit website ordering channels modal"
        headerProps={{ header: "Ordering Channels" }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderModalContent()}
      </ResponsiveModal>
    );
  }
}

OrderingChannelsEditModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  orderingChannels: PropTypes.shape({
    skipli: PropTypes.shape({ url: PropTypes.string }),
    doordash: PropTypes.shape({ url: PropTypes.string }),
    postmates: PropTypes.shape({ url: PropTypes.string }),
    grubhub: PropTypes.shape({ url: PropTypes.string }),
    ubereats: PropTypes.shape({ url: PropTypes.string }),
  }),
  WELL_KNOWN_ORDERING_CHANNELS: PropTypes.object,
};

export default OrderingChannelsEditModal;
