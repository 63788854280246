// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Header from "./Header";
import HeroImage from "./HeroImage";
import Menu from "./Menu";
import OrderingChannels from "./OrderingChannels";

// Style
import Style from "./style.module.scss";

// Utils
import { _scrollTo } from "utils";

// Lib
import { Services } from "lib";

class WebsiteBuilder extends React.Component {
  refs = {};

  componentDidMount = () => {
    const { seo = {} } = this.props;
    const { pageTitle = "" } = seo;
    if (pageTitle) {
      document.title = pageTitle;
    }
  };

  onChange = ({ componentId = "", component = {} }) => {
    if (!componentId) return;
    const { latestVersion = {} } = this.props;
    this.props.onChangeLatestVersion(
      set(latestVersion, `components.${componentId}`, component)
    );
  };

  onRedirectToOrderOnline = () => {
    const { CUSTOMER_INTERFACE_BASE_URL } = Services;
    const { shopID } = this.props;
    window.location.href = `${CUSTOMER_INTERFACE_BASE_URL}/shop/${shopID}`;
  };

  onScrollTo = (refID = "") => {
    if (refID) {
      const ref = this.refs[refID];
      ref && _scrollTo({ ref });
    }
  };

  onUpdateRefs = ({ refID = "", ref }) => {
    if (refID) {
      this.refs = set(this.refs, refID, ref);
    }
  };

  render() {
    const {
      general = {},
      latestVersion = {},
      shopBasicInfo,
      shopID,
    } = this.props;
    const { components = {} } = latestVersion;
    const { themeColor = "" } = general;
    const {
      aboutUs = {},
      contactUs = {},
      header = {},
      heroImage = {},
      menu = {},
      orderingChannels = {},
    } = components;

    return (
      <div className={Style.container}>
        <Header
          header={header}
          onChange={(newHeader) =>
            this.onChange({ componentId: "header", component: newHeader })
          }
          onRedirectToOrderOnline={this.onRedirectToOrderOnline}
          onScrollTo={this.onScrollTo}
          shopBasicInfo={shopBasicInfo}
          themeColor={themeColor}
        />
        <div ref={(ref) => this.onUpdateRefs({ refID: "home", ref })}>
          <HeroImage
            heroImage={heroImage}
            onChange={(newHeroImage) =>
              this.onChange({
                componentId: "heroImage",
                component: newHeroImage,
              })
            }
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            onScrollTo={this.onScrollTo}
            themeColor={themeColor}
          />
        </div>
        <div ref={(ref) => this.onUpdateRefs({ refID: "menu", ref })}>
          <Menu
            menu={menu}
            onChange={(newMenu) =>
              this.onChange({ componentId: "menu", component: newMenu })
            }
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            shopID={shopID}
            themeColor={themeColor}
          />
        </div>
        <div ref={(ref) => this.onUpdateRefs({ refID: "about", ref })}>
          <AboutUs
            aboutUs={aboutUs}
            onChange={(newAboutUs) =>
              this.onChange({ componentId: "aboutUs", component: newAboutUs })
            }
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            themeColor={themeColor}
          />
        </div>
        <div>
          <OrderingChannels
            onChange={(newOrderingChannels) =>
              this.onChange({
                componentId: "orderingChannels",
                component: newOrderingChannels,
              })
            }
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            orderingChannels={orderingChannels}
            shopID={shopID}
            themeColor={themeColor}
          />
        </div>
        <div ref={(ref) => this.onUpdateRefs({ refID: "contactUs", ref })}>
          <ContactUs
            contactUs={contactUs}
            onChange={(newContactUs) =>
              this.onChange({
                componentId: "contactUs",
                component: newContactUs,
              })
            }
            shopBasicInfo={shopBasicInfo}
            themeColor={themeColor}
          />
        </div>
        {/* <SkipliFooter /> */}
      </div>
    );
  }
}

WebsiteBuilder.propTypes = {
  general: PropTypes.shape({
    themeColor: PropTypes.string.isRequired,
  }).isRequired,
  latestVersion: PropTypes.shape({
    components: PropTypes.object,
  }),
  onChangeLatestVersion: PropTypes.func.isRequired,
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    pageTitle: PropTypes.string,
  }),
  shopBasicInfo: PropTypes.object.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default WebsiteBuilder;
