// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Style from "./style.module.scss";

// Fields
import { Checkbox, Input, ResponsiveModal } from "fields";
import { EyeIcon } from "assets/Icons";

class ConfirmCloseOrderModal extends React.Component {
  state = { sendOrderReadyText: true };

  componentDidMount() {
    this.props.renderBodyAccept();
  }

  onConfirmClosePickupOrder = () => {
    this.props.onClosePickupOrder({
      sendOrderReadyText: this.state.sendOrderReadyText,
    });
    this.props.onCloseModal();
  };

  handleShowMessage = () => {
    this.setState({ show_message: true });
  };

  onCloseModal = () => {
    this.setState({ show_message: false });
  };

  render() {
    const { sendOrderReadyText, show_message } = this.state;
    const { body_message_accept = "" } = this.props;
    return (
      <ResponsiveModal
        contentLabel="confirm close order modal"
        contentDescriptionProps={{
          contentDescription: (
            <div>
              Order will move to <strong>Past Orders</strong> after you click
              Submit
            </div>
          ),
          showContentDescription: true,
        }}
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Submit",
            loadingText: "Submitting",
          },
        }}
        headerProps={{ header: "Close Order" }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.onConfirmClosePickupOrder}
      >
        <div className={Style.check_box_container}>
          <Checkbox
            id="notify guest"
            checked={sendOrderReadyText}
            label="Send automated message to customer"
            onClick={() =>
              this.setState({ sendOrderReadyText: !sendOrderReadyText })
            }
          />
          {sendOrderReadyText && (
            <div
              onClick={this.handleShowMessage}
              className={Style.label_see_message}
            >
              <EyeIcon className={Style.eyeIcon} />
              <div> See message </div>
            </div>
          )}
        </div>
        {show_message && (
          <ResponsiveModal
            contentLabel="Message"
            headerProps={{
              header: "Message",
              show_back_button: true,
              show_close_button: false,
            }}
            onCloseModal={this.onCloseModal}
          >
            <Input.TextInput
              name="Custom"
              hasMultipleLines
              readOnly
              rows={5}
              value={body_message_accept}
            />
          </ResponsiveModal>
        )}
      </ResponsiveModal>
    );
  }
}

ConfirmCloseOrderModal.propTypes = {
  body_message_accept: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onClosePickupOrder: PropTypes.func.isRequired,
};

export default ConfirmCloseOrderModal;
