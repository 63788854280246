// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const PageMsg = ({ children, class_names = {} }) => (
  <div className={cx(Style.container, class_names.container)}>{children}</div>
);

PageMsg.propTypes = {
  children: PropTypes.any.isRequired,
  class_names: PropTypes.shape({ container: PropTypes.string }),
};

export default PageMsg;
