// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Button, ExpansionPanel } from "fields";

class ConbineOrdersSetupConfirmCard extends React.Component {
  onGetButtonStatus = () => "active";

  onConfirmCard = () => {
    this.props.onChangeStep("createMailbox");
  };

  render() {
    return (
      <Box>
        <ExpansionPanel
          moduleTitle="Step 1: Confirm your payment"
          showExpandableContent
        >
          <Button
            className={Style.confirmButton}
            name="confirm card"
            onClick={this.onConfirmCard}
            status={this.onGetButtonStatus()}
          >
            Confirm Card
          </Button>
        </ExpansionPanel>
      </Box>
    );
  }
}

ConbineOrdersSetupConfirmCard.propTypes = {
  onChangeStep: PropTypes.func.isRequired,
};

export default ConbineOrdersSetupConfirmCard;
