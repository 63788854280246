// BUTI DINERS, INC. All right Reserved ©

// Lib
import { Constants } from "lib";

const ROLE_NAV_ITEMS = {
  staff: [
    "menusManagement",
    "orders",
    "pastOrders",
    "personnelProfile",
    "settings",
  ],
  owner: [
    "analytics",
    "combineThirdPartyOrders",
    "customerSelfCheckIn",
    "guest_reviews",
    "marketing",
    "menusManagement",
    "orders",
    "pastOrders",
    "payoutsForConnectedAccount",
    "personnelProfile",
    "preparation",
    "shopInfo",
    "paymentInfo",
    "settings",
    "website",
  ],
};

const _getNavbarItems = ({
  role,
  show_payouts = false,
  stripeConnectInfo = {},
}) => {
  const { MOBILE_NAVBAR_ITEMS: allOptions } = Constants;
  if (role === "owner") {
    return allOptions.filter(({ id }) => {
      if (id === "payoutsForConnectedAccount") {
        const {
          applicationStatus = "",
          stripeAccountID = "",
        } = stripeConnectInfo;
        if (
          applicationStatus !== "complete" ||
          !stripeAccountID ||
          !show_payouts
        )
          return false;
      }
      return ROLE_NAV_ITEMS["owner"].includes(id);
    });
  } else if (role === "staff")
    return allOptions.filter(({ id }) => ROLE_NAV_ITEMS["staff"].includes(id));
  return [];
};

export { _getNavbarItems };
