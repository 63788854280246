// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import CheckboxAndRadioGroup from "./index";

class RadioGroup extends React.Component {
  render() {
    return <CheckboxAndRadioGroup {...this.props} kind="radio" />;
  }
}

RadioGroup.propTypes = {
  heading: PropTypes.string,
  headingClassname: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  optionClassName: PropTypes.string,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedOptionId: PropTypes.string,
};

RadioGroup.defaultProps = {
  readOnly: false,
  required: false,
};

export default RadioGroup;
