// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Components
import { NewMerchantAccountModules } from "components";

// Fields
import { Link } from "fields";

// Icons
import { PhoneIcon, SkipliLogoWithTextIcon } from "assets/Icons";

class NewMerchantAccount extends React.Component {
  state = { headingClicksCount: 1 };

  onClickHeading = () => {
    const { headingClicksCount } = this.state;
    const newClicksCount = headingClicksCount + 1;
    this.setState({ headingClicksCount: newClicksCount }, () => {
      if (newClicksCount > 20) this.setState({ showReviewButton: true });
    });
  };

  renderHeader = () => (
    <div className={Style.header}>
      <div className={Style.headerContent}>
        <div className={Style.skipliLogoCol}>
          <SkipliLogoWithTextIcon
            className={Style.skipliLogo}
            onClick={() => (window.location.href = "/")}
          />
        </div>
        <h1 className={Style.heading} onClick={this.onClickHeading}>
          Online Ordering Account
        </h1>
        <div className={Style.helpCol}>
          <Link
            className={Style.helpCall}
            href={`tel:6789991044`}
            target="_self"
          >
            <PhoneIcon />
          </Link>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        {this.renderHeader()}
        <div className={Style.content}>
          <NewMerchantAccountModules.AgreementForm
            formId={this.props.formId}
            onHideReviewButton={() =>
              this.setState({ showReviewButton: false })
            }
            showReviewButton={this.state.showReviewButton}
          />
        </div>
      </div>
    );
  }
}

NewMerchantAccount.propTypes = {
  formId: PropTypes.string,
};

NewMerchantAccount.defaultProps = {
  formId: "",
};

export default NewMerchantAccount;
