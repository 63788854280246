// BUTI DINERS, INC. All right Reserved ©

const _setUpInitialWebsiteInfo = ({ shopName = "" }) => ({
  general: DEFAULT_WEBSITE_GENERAL,
  seo: DEFAULT_WEBSITE_SEO({ shopName }),
});

const DEFAULT_WEBSITE_GENERAL = {
  isPageVisible: "false",
  themeColor: "#3aafa9",
};

const DEFAULT_WEBSITE_SEO = ({ shopName }) => ({
  pageTitle: shopName,
  metaDescription: "",
});

const DEFAULT_TEMPLATE = ({ shopAddress = "" }) => ({
  latestVersion: {
    components: {
      aboutUs: {
        imageURL: "https://i.imgur.com/cgBlF4h.jpg",
        text:
          "Our restaurant is a premier and creative restaurant that has been proudly serving your local area. Since day one, our mission has been to provide high-quality food for those who seek skillful cooking and extraordinary dining experience.",
        textPosition: "right",
      },
      contactUs: {
        address: shopAddress,
        email: "",
        instagram: "",
        facebook: "",
        phoneNumber: "",
        youtube: "",
        twitter: "",
        yelp: "",
      },
      header: {
        backgroundColor: "#fff",
        textColor: "#525f7f",
        textHoverColor: "#3aafa9",
      },
      heroImage: {
        heading: "We serve great food!",
        imageURL: "https://i.imgur.com/Ex3vG59.jpg",
        message:
          "We now offer quick and easy online ordering for take out and delivery. Click the button below to place an order online.",
        showOrderNowButton: "true",
        showSeeMenuButton: "true",
        textPosition: "left",
      },
    },
  },
});

export { DEFAULT_TEMPLATE, DEFAULT_WEBSITE_SEO, _setUpInitialWebsiteInfo };
