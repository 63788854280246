// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";

import {
  _30dayAgoReviews,
  _countReviewsByMonth,
  _reviewToday,
  _7dayAgoReviews,
} from "./functions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Box } from "fields";

// Assets
import { ChartIcon, StarEmptyIcon, StarFullIcon } from "assets/Icons";

import Rating from "react-rating";

class ReviewStatistics extends React.Component {
  renderChartReview = (review_data_six_months) => (
    <Box
      class_names={{ container: Style.chart_box }}
      header="Rating Performance"
    >
      <LineChart width={450} height={250} data={review_data_six_months}>
        <XAxis
          dataKey="monthYear"
          tick={true}
          axisLine={false}
          tickLine={false}
        />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="bad" stroke="#8884d8" dot={false} />
        <Line type="monotone" dataKey="good" stroke="#82ca9d" dot={false} />
      </LineChart>
    </Box>
  );

  renderRatingStatistics = (guest_reviews, timeZone) => {
    const rating_boxes = [
      {
        key: "today",
        averageRating: _reviewToday({ guest_reviews, timeZone })
          .average_selected_rating_day,
        reviewCount: _reviewToday({ guest_reviews, timeZone })
          .count_review_today,
        percentageChange: _reviewToday({ guest_reviews, timeZone })
          .percentage_change,
        time: "yesterday",
      },
      {
        key: "week",
        averageRating: _7dayAgoReviews({ guest_reviews, timeZone })
          .average_selected_rating_week,
        reviewCount: _7dayAgoReviews({ guest_reviews, timeZone })
          .count_review_weeks,
        percentageChange: _7dayAgoReviews({ guest_reviews, timeZone })
          .percentage_change_weeks,
        time: "last week",
      },
      {
        key: "30days",
        averageRating: _30dayAgoReviews({ guest_reviews, timeZone })
          .average_selected_rating_within_30_days,
        reviewCount: _30dayAgoReviews({ guest_reviews, timeZone })
          .count_review_months,
        percentageChange: _30dayAgoReviews({ guest_reviews, timeZone })
          .percentage_change_months,
        time: "last month",
      },
    ];

    const total_review_count = Object.keys(guest_reviews).length;

    return (
      <Box
        class_names={{ container: Style.rating_statistics_box }}
        header="Rating Statistics"
      >
        <div className={Style.box_header_container}>
          <div className={Style.reviews_count}>
            {total_review_count} review{total_review_count > 1 ? "s" : ""}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {rating_boxes.map((box) => this.renderRatingStatisticsInnerBox(box))}
        </div>
      </Box>
    );
  };

  renderRatingStatisticsInnerBox = ({
    key,
    averageRating,
    reviewCount,
    percentageChange,
    time,
  }) => (
    <Box
      class_names={{
        container: Style.rating_statistics_inner_box,
      }}
      key={key}
    >
      <div className={Style.icon}>
        <ChartIcon fill="white" />
      </div>
      <Rating
        className={Style.rating}
        initialRating={averageRating}
        emptySymbol={<StarEmptyIcon height="20px" width="20px" fill="grey" />}
        fullSymbol={<StarFullIcon height="20px" width="20px" />}
        readonly
      />
      <div className={Style.count_review_box}>
        {reviewCount} review{reviewCount > 1 ? "s" : ""}
      </div>
      <div className={Style.count_review_percent}>
        {percentageChange.toFixed(2)}% from {time}
      </div>
    </Box>
  );

  render() {
    const { guest_reviews = {}, context = {} } = this.props;
    const { shop_basic_info = {} } = context;
    const { timeZone } = shop_basic_info;
    const review_data_six_months = _countReviewsByMonth({
      guest_reviews,
      timeZone,
    });
    return (
      <div className={Style.container}>
        {this.renderRatingStatistics(guest_reviews, timeZone)}
        {this.renderChartReview(review_data_six_months)}
      </div>
    );
  }
}

ReviewStatistics.propTypes = {
  context: PropTypes.shape({
    shop_id: PropTypes.string.isRequired,
  }).isRequired,
};

ReviewStatistics.defaultProps = {};

export default withContext(MerchantInterfaceConsumer)(ReviewStatistics);
