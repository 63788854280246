// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

class ReviewInfo extends React.Component {
  render() {
    const {
      created_at,
      feedback_text,
      selected_rating = {},
      review_id,
      uuid,
    } = this.props;
    return (
      <ResponsiveModal
        classNames={{
          contentContainer: Style.modal_content_container,
          modal: Style.modal,
        }}
        contentLabel="Review Details"
        onCloseModal={this.props.onDeselectReview}
        headerProps={{ header: "Review Details" }}
      >
        <div className={Style.infoRow}>
          <div className={Style.label}>Rating</div>
          <div>{selected_rating}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>Comment</div>
          <div>{feedback_text}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>Created At</div>
          <div>{created_at}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>Review ID</div>
          <div className={Style.review_id}>{review_id}</div>
        </div>
        <div className={Style.infoRow}>
          <div className={Style.label}>Guest ID</div>
          <div>{uuid}</div>
        </div>
      </ResponsiveModal>
    );
  }
}

ReviewInfo.propTypes = {
  created_at: PropTypes.string.isRequired,
  feedback_text: PropTypes.string.isRequired,
  onDeselectReview: PropTypes.func.isRequired,
  review_id: PropTypes.string.isRequired,
  selected_rating: PropTypes.object,
  uuid: PropTypes.string.isRequired,
};

ReviewInfo.defaultProps = {};

export default ReviewInfo;
