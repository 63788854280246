// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import AllMenus from "./AllMenus";
import AllGroups from "./AllGroups";
import AllItems from "./AllItems";
import AllModifierGroups from "./AllModifierGroups";
import AllModifiers from "./AllModifiers";

// Style
import MerchantViewStyle from "views/style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals, Navbars } from "components";

// Fields
import { Button, Dropdown, SubNavbarContainer } from "fields";

// Assets
import { ImportDocumentIcon } from "assets/Icons";

// Lib
import { Constants, Functions } from "lib";
const { MENUS_MANAGEMENT_NAVBAR_ITEMS } = Constants;
// const { FRONT_END_BASE_URL } = Services;

class MenusManagement extends Component {
  state = {
    activeNavItemId: "allItems",
    showImportMenuButton: false,
  };

  onChangeDropdownItemId = (option = {}) => {
    const { id } = option;
    this.setState({ activeNavItemId: id });
  };

  onChangeNavItemId = (activeNavItemId) => this.setState({ activeNavItemId });

  renderContent = () => {
    const { shopID, scroll_y } = this.props;
    switch (this.state.activeNavItemId) {
      case "allMenus":
        return (
          <AllMenus
            newMenuId={this.state.newMenuId}
            newMenuInfo={this.state.newMenuInfo}
            onShowImportMenuButton={() =>
              this.setState({ showImportMenuButton: true })
            }
            scroll_y={scroll_y}
            shopID={shopID}
          />
        );
      case "allGroups":
        return <AllGroups shopID={shopID} scroll_y={scroll_y} />;
      case "allItems":
        return <AllItems shopID={shopID} scroll_y={scroll_y} />;
      case "allModifierGroups":
        return <AllModifierGroups shopID={shopID} scroll_y={scroll_y} />;
      case "allModifiers":
        return <AllModifiers shopID={shopID} scroll_y={scroll_y} />;
      default:
        return null;
    }
  };

  renderImportMenuButton = () => (
    <Button
      className={Style.import_menu_button}
      name="import menu button"
      onClick={() => this.setState({ showImportMenuModal: true })}
    >
      <ImportDocumentIcon />
      Import Menu
    </Button>
  );

  renderImportMenuModal = () => (
    <Modals.ImportMenuModal
      onAddNewMenu={({ newMenuId, newMenuInfo }) =>
        this.setState({ showImportMenuModal: false }, () => {
          const { ShowConfirmNotif } = Functions;
          ShowConfirmNotif({
            message: "Successfully imported the menu.",
            type: "success",
          });
          this.setState({ newMenuId, newMenuInfo });
        })
      }
      onCloseModal={() => this.setState({ showImportMenuModal: false })}
      shopID={this.props.shopID}
    />
  );

  renderSubNavbar = () => {
    const { activeNavItemId } = this.state;
    const { name } =
      MENUS_MANAGEMENT_NAVBAR_ITEMS.find(({ id }) => activeNavItemId === id) ||
      {};

    return (
      <SubNavbarContainer>
        <div className={Style.subNavbarColumn}>
          <Navbars.SubNavbar
            activeNavItemId={activeNavItemId}
            navItems={MENUS_MANAGEMENT_NAVBAR_ITEMS}
            onNavItemClick={this.onChangeNavItemId}
          />
        </div>
        <div className={Style.subNavbarColumnDropdown}>
          <Dropdown
            class_names={{ placeholder: Style.dropdown_placeholder }}
            modal_props={{ headerProps: { header: `${name}` } }}
            onSelectOption={this.onChangeDropdownItemId}
            options={MENUS_MANAGEMENT_NAVBAR_ITEMS}
            placeholder={name}
          />
        </div>
        {this.state.showImportMenuButton && (
          <div className={Style.secondColumn}>
            {this.renderImportMenuButton()}
          </div>
        )}
      </SubNavbarContainer>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showImportMenuModal && this.renderImportMenuModal()}
        <div>
          {this.renderSubNavbar()}
          <div className={MerchantViewStyle.extraPaddingTop}>
            {this.renderContent()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MenusManagement.propTypes = {
  shopID: PropTypes.string.isRequired,
};

export default MenusManagement;
