// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { MenusManagement } from "components";

// Fields
import { PageMsg } from "fields";

// Assets
import { MenusIcon } from "assets/Icons";

class ListOfMenuItems extends Component {
  renderItems = (items = {}) => {
    const { classNames = {} } = this.props;
    return (this.props.sortedItemIDs || Object.keys(items)).map((itemID) => (
      <div
        className={cx(CommonStyle.elementContainer, classNames.itemContainer)}
        key={itemID}
      >
        <MenusManagement.Item
          {...this.props.itemProps}
          itemHelperButton={this.props.itemHelperButton}
          itemID={itemID}
          itemInfo={items[itemID]}
          onUpdateAllItems={this.props.onUpdateAllItems}
        />
      </div>
    ));
  };

  render() {
    const { items = {} } = this.props;
    if (Object.keys(items).length === 0)
      return (
        <PageMsg>
          <div className={CommonStyle.menus_icon}>
            <MenusIcon />
          </div>
          {this.props.emptyListMessage}
        </PageMsg>
      );
    return (
      <div
        className={cx(
          CommonStyle.listContainer,
          this.props.listContainerClassname
        )}
      >
        {this.renderItems(items)}
      </div>
    );
  }
}

ListOfMenuItems.propTypes = {
  classNames: PropTypes.exact({ itemContainer: PropTypes.string }),
  emptyListMessage: PropTypes.string,
  itemProps: PropTypes.object,
  items: PropTypes.object,
  itemHelperButton: PropTypes.func,
  listContainerClassname: PropTypes.string,
  onUpdateAllItems: PropTypes.func,
  sortedItemIDs: PropTypes.array,
};

ListOfMenuItems.defaultProps = {
  classNames: {},
  emptyListMessage: "No items found.",
  itemProps: {},
  items: {},
  onUpdateAllItems: () => {},
  sortedItemIDs: null,
};

export default ListOfMenuItems;
