// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import CheckInSection from "./CheckInSection";
import ConfirmCloseModal from "./ConfirmCloseModal";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Img, ResponsiveModal } from "fields";

// Icons
import { CloseIcon } from "assets/Icons";
import buildings_illustration_image from "assets/Images/check_in_buildings_illustration.png";

class CustomerCheckInModal extends React.Component {
  state = { showConfirmCloseInput: false };

  onCloseGuestCheckinScreen = () =>
    this.setState({ showConfirmCloseInput: true });

  renderAdSection = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { name } = shopBasicInfo;

    const { customerCheckIn = {} } = this.props;
    const { checkin_screen = {} } = customerCheckIn;
    const {
      header: checkin_screen_header,
      message: checkin_screen_message,
    } = checkin_screen;

    return (
      <React.Fragment>
        <p className={Style.shop_name}>{name}</p>
        <h1 className={Style.title}>{checkin_screen_header}</h1>
        <p className={Style.reward_message}>{checkin_screen_message}</p>
        <Img
          className={Style.illustration_image}
          src={buildings_illustration_image}
        />
      </React.Fragment>
    );
  };

  renderConfirmCloseModal = () => {
    const security_code = _get(
      this.props,
      "customerCheckIn.checkin_screen.security_code"
    );
    return (
      <ConfirmCloseModal
        deactivateCheckInCode={security_code}
        onDeactivateCheckIn={this.props.onCloseModal}
        onHideConfirmCloseModal={() =>
          this.setState({ showConfirmCloseInput: false })
        }
      />
    );
  };

  render() {
    const keypad_background_color = _get(
      this.props,
      "customerCheckIn.checkin_screen.keypad_background_color"
    );

    return (
      <ResponsiveModal
        contentLabel="customer check in"
        classNames={{
          contentContainer: Style.contentContainer,
          modal: Style.modal,
        }}
        headerProps={{ showHeader: false }}
        onCloseModal={this.onCloseGuestCheckinScreen}
        shouldCloseOnOverlayClick={false}
      >
        {this.state.showConfirmCloseInput && this.renderConfirmCloseModal()}
        <React.Fragment>
          <button
            className={Style.close_guest_checkin_button}
            onClick={this.onCloseGuestCheckinScreen}
          >
            <CloseIcon />
          </button>
          <div className={Style.adContainer}>{this.renderAdSection()}</div>
          <div
            className={Style.checkInContainer}
            style={{ background: keypad_background_color }}
          >
            <CheckInSection customerCheckIn={this.props.customerCheckIn} />
          </div>
        </React.Fragment>
      </ResponsiveModal>
    );
  }
}

CustomerCheckInModal.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }),
  customerCheckIn: PropTypes.shape({
    after_checkin: PropTypes.shape({
      automated_text_after_checkin: PropTypes.string.isRequired,
      instruction_after_checkin: PropTypes.string.isRequired,
    }).isRequired,
    checkin_screen: PropTypes.shape({
      header: PropTypes.string.isRequired,
      message: PropTypes.string,
      keypad_background_color: PropTypes.string.isRequired,
      security_code: PropTypes.string.isRequired,
    }).isRequired,
  }),
  onCloseModal: PropTypes.func.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(CustomerCheckInModal);
