// BUTI Corp All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { Input, ResponsiveModal, Dropdown } from "fields";
import { CheckBoxGroup } from "components";

import { YEAR_MODULES, MONTH_MODULES } from "./constants";
import { _sanitizeData, generateEmailContent } from "./helperFunctions";

import Style from "./style.module.scss";
import { MerchantInterfaceConsumer, withContext } from "context";
import Merchants from "lib/Services/Merchants";

import { Constants } from "lib";
const { GetPayoutsForConnectedAccountByCreated } = Merchants.GetRequests;

class ExportPayoutModal extends React.Component {
  state = { email: "" };
  onSelectDropdownOption = ({ id }) =>
    this.setState({
      select_year: id,
      is_modal_open: false,
    });

    onGetSubmitButtonStatus = () => {
      const { email = "", select_month = [], select_year = "" } = this.state;
      const { REGEX } = Constants;
    
      return REGEX.EMAIL.test(email) && select_month.length > 0 && select_year
        ? "active"
        : "inactive";
    };
    

  onSendExportToMail = async () => {
    const { select_year = "", select_month = [], email = "" } = this.state;
    const { shopBasicInfo = {} } = this.props;
    const { address = "", name = "", timeZone = "", id = "" } = shopBasicInfo;
    const { payouts_info = {} } = await GetPayoutsForConnectedAccountByCreated({
      stripe_account_id: this.props.stripe_account_id,
      months: select_month,
      year: select_year,
    });
    const displayed_data = _sanitizeData({
      payouts_info: payouts_info,
      timeZone,
    });

    const { subject = "", body = "" } = generateEmailContent(
      name,
      id,
      address,
      select_year,
      select_month
    );

    const { SendExportPayoutToMail } = Merchants.PostRequests;
    await SendExportPayoutToMail({
      displayed_data,
      email,
      subject,
      body,
    });
  };

  renderEmailInput = () => {
    const { email = "" } = this.state;
    return (
      <Input.TextInput
        name="Email"
        onChange={(email) => this.setState({ email })}
        placeholder="Email"
        label="Email"
        required={true}
        value={email}
      />
    );
  };

  renderYearDropdown = () => {
    const { select_year = "" } = this.state;
    return (
      <div>
        <div className={Style.label}>Year Selection</div>
        <div className={Style.has_border}>
          <Dropdown
            class_names={{ placeholder: Style.dropdown_placeholder }}
            modal_props={{ headerProps: { header: "Select Year" } }}
            onSelectOption={this.onSelectDropdownOption}
            options={YEAR_MODULES}
            placeholder={select_year ? select_year : "Select Year"}
            renderOption={this.renderDropdownOption}
          />
        </div>
      </div>
    );
  };

  renderMonthCheckBoxGroup = () => {
    const { select_month = [] } = this.state;
    return (
      <CheckBoxGroup
        heading="Month Selection"
        option_props={{
          has_border: true,
          has_border_style: Style.has_border_style,
        }}
        required
        onClick={(option_id) => {
          const new_select_month = select_month.includes(option_id)
            ? select_month.filter((month) => month !== option_id)
            : [...select_month, option_id];
          this.setState({ select_month: new_select_month });
        }}
        selectedOptionIds={select_month}
        options={MONTH_MODULES}
      />
    );
  };

  render() {
    return (
      <ResponsiveModal
        contentLabel="Sent Export Payout Excel To Email"
        headerProps={{ header: "Sent Export Payout Excel To Email" }}
        classNames={{ modal: Style.modal }}
        onCloseModal={this.props.onCloseModal}
        footerProps={{
          showFooter: true,
          submitButtonProps: { status: this.onGetSubmitButtonStatus() },
        }}
        onSubmit={this.onSendExportToMail}
      >
        {this.renderEmailInput()}
        <div>
          <div className={Style.margin}>{this.renderYearDropdown()}</div>
          {this.renderMonthCheckBoxGroup()}
        </div>
      </ResponsiveModal>
    );
  }
}
ExportPayoutModal.propTypes = {
  shopBasicInfo: PropTypes.shape({
    address: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
};
export default withContext(MerchantInterfaceConsumer)(ExportPayoutModal);
