// BUTI DINERS, INC. All right Reserved ©

import _get from "lodash.get";
import { set } from "object-path-immutable";

const _applyFilterCriterias = ({
  object_info,
  filter_criterias,
  selected_filter_values,
}) =>
  Object.keys(filter_criterias).reduce((is_matched, criteria_id) => {
    const { comparator = () => {} } = _get(filter_criterias, criteria_id);
    return (
      is_matched &&
      comparator(
        _get(object_info, criteria_id),
        _get(selected_filter_values, criteria_id)
      )
    );
  }, true);

const _filter = ({
  list = {},
  filter_criterias = {},
  selected_filter_values = {},
}) =>
  Object.keys(list).reduce((result, object_id) => {
    const object_info = list[object_id];
    const is_matched = _applyFilterCriterias({
      object_id,
      object_info,
      filter_criterias,
      selected_filter_values,
    });
    return is_matched ? set(result, object_id, object_info) : result;
  }, {});

export { _filter };
