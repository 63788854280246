// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { CouponModules } from "components";

// Lib
import { Constants } from "lib";
const { COUPON_TYPES } = Constants;

class MarketingCreateCoupon extends React.Component {
  state = { couponInfo: {} };

  onChangeCouponInfo = (fieldKey, fieldValue) => {
    const { couponInfo } = this.state;
    this.setState({
      couponInfo: immutable.set(couponInfo, fieldKey, fieldValue)
    });
  };

  onSetCouponSelections = selections => {
    const { couponInfo } = this.state;
    this.setState({
      couponInfo: immutable.set(couponInfo, "selections", selections)
    });
  };

  render() {
    const { context, couponType } = this.props;
    const { shopID } = context;
    const { couponInfo = {} } = this.state;
    const { selections = {} } = couponInfo;
    return (
      <div>
        <CouponModules.CouponInfo
          couponInfo={couponInfo}
          onChangeCouponInfo={this.onChangeCouponInfo}
        />
        <CouponModules.CouponSelections
          couponType={couponType}
          onSetCouponSelections={this.onSetCouponSelections}
          selections={selections}
          shopID={shopID}
        />
        <CouponModules.CouponSubmitButton
          isSubmitting={this.props.isCreatingCoupon}
          couponInfo={couponInfo}
          couponType={couponType}
          onClick={() =>
            this.props.onCreateCoupon({ ...couponInfo, couponType, shopID })
          }
        />
      </div>
    );
  }
}

MarketingCreateCoupon.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }).isRequired,
  couponType: PropTypes.oneOf(Object.keys(COUPON_TYPES)).isRequired,
  isCreatingCoupon: PropTypes.bool,
  onCreateCoupon: PropTypes.func.isRequired
};

MarketingCreateCoupon.defaultProps = {
  isCreatingCoupon: false
};

export default withContext(MerchantInterfaceConsumer)(MarketingCreateCoupon);
