// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

const SoundFile = require("assets/Sound/Ding.mp3");

class Alarm extends Component {
  timer = null;
  sound = new window.Audio(SoundFile);

  componentDidUpdate = (prevProps) => {
    const { shouldPlayAudio } = this.props;
    if (prevProps.shouldPlayAudio !== shouldPlayAudio) {
      if (shouldPlayAudio) this.onPlayAudio();
      else this.onStopAudio();
    }
  };

  onPlayAudio = () => {
    this.timer = setInterval(() => this.sound.play(), 300);
  };

  onStopAudio = () => {
    clearInterval(this.timer);
    this.sound && this.sound.pause();
    this.sound = new window.Audio(SoundFile);
  };

  render() {
    return <div style={{ display: "none" }} />;
  }
}

Alarm.propTypes = {
  shouldPlayAudio: PropTypes.bool,
};

Alarm.defaultProps = {
  shouldPlayAudio: false,
};

export default Alarm;
