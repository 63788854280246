// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { _createEmailBody } from "./functions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Button, Input } from "fields";

// Lib
import { Functions, Services } from "lib";

// Assets
import { HyperlinkIcon, ShareIcon } from "assets/Icons";

class GuestReviewLink extends React.Component {
  onCopyLink = (review_url) => {
    const { ShowConfirmNotif } = Functions;
    ShowConfirmNotif({ message: "Copied link!", type: "success" });
    navigator.clipboard.writeText(review_url);
  };

  onShareLink = (review_url) => {
    if (navigator.share) {
      navigator
        .share({
          title: "review us",
          text: "Tell us how we did",
          url: review_url,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      const { shop_basic_info = {} } = this.props.context;
      const { name } = shop_basic_info;
      const link =
        "mailto:" +
        "?cc=" +
        "&subject=" +
        encodeURIComponent("Help Us Shine with Your Positive Reviews!") +
        "&body=" +
        encodeURIComponent(_createEmailBody({ review_url, shop_name: name }));
      window.location.href = link;
    }
  };

  render() {
    const { context = {} } = this.props;
    const { shop_id = "" } = context;
    const { CUSTOMER_INTERFACE_BASE_URL } = Services;
    const review_url = `${CUSTOMER_INTERFACE_BASE_URL}/shop/${shop_id}/review`;
    return (
      <Box
        class_names={{ container: Style.box }}
        description="Send this link to your guest to collect reviews and feedback"
        header="Review Link"
      >
        <div className={Style.input_container}>
          <Input.TextInput
            fieldClassname={Style.text_field}
            className={Style.review_link}
            name="review link"
            readOnly
            value={review_url}
          />
          <div className={Style.buttons}>
            <Button
              className={Style.button}
              name="copy review link"
              onClick={() => this.onCopyLink(review_url)}
            >
              <HyperlinkIcon />
              Copy
            </Button>
            <Button
              className={Style.button}
              name="share review link"
              onClick={() => this.onShareLink(review_url)}
            >
              <ShareIcon />
              Share
            </Button>
          </div>
        </div>
      </Box>
    );
  }
}

GuestReviewLink.propTypes = {
  context: PropTypes.shape({
    shop_id: PropTypes.string.isRequired,
  }).isRequired,
};

GuestReviewLink.defaultProps = {};

export default withContext(MerchantInterfaceConsumer)(GuestReviewLink);
