// BUTI DINERS, INC. All right Reserved ©

import AddedGroupsModule from "./AddedGroupsModule";
import AddedItemsModule from "./AddedItemsModule";
import AddedModifierGroupsModule from "./AddedModifierGroupsModule";
import AddedModifiersModule from "./AddedModifiersModule";
import ImageEffectsModule from "./ImageEffectsModule";
import ImagePositionControllerModule from "./ImagePositionControllerModule";

const MenusManagementModules = {
  AddedGroupsModule,
  AddedItemsModule,
  AddedModifierGroupsModule,
  AddedModifiersModule,
  ImageEffectsModule,
  ImagePositionControllerModule,
};

export default MenusManagementModules;
