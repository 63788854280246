// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Components
import { CouponModules } from "components";

// Fields
import { ComingSoonButton } from "fields";

// Icons
import { ArrowIcon } from "assets/Icons";

// Lib
import { Constants } from "lib";

const { COUPON_TYPES } = Constants;

class MarketingOption extends React.Component {
  state = { showActiveCoupons: false };

  onSelectCreateCoupon = () => {};

  onShowActiveCoupons = () =>
    this.setState({
      showActiveCoupons: !this.state.showActiveCoupons
    });

  renderOptionDetails = () => {
    const { couponsOfThisType = {}, type } = this.props;
    const { showActiveCoupons } = this.state;
    const couponsCount = Object.keys(couponsOfThisType).length;
    return (
      <div>
        <div className={Style.couponActionBtns}>
          <button
            className={Style.createCouponBtn}
            onClick={() => this.props.onCreateNewCoupon(type)}
          >
            New Coupon
          </button>
          {couponsCount > 0 && (
            <button
              className={Style.showCouponsBtn}
              onClick={this.onShowActiveCoupons}
            >
              <ArrowIcon
                className={cx(
                  Style.arrowIcon,
                  showActiveCoupons && Style.collapse
                )}
              />
              {couponsCount} active
            </button>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={Style.cardContainer}>
        <div className={Style.cardView}>
          <h3>{this.props.header}</h3>
          <p>{this.props.description}</p>
          {this.props.comingSoon ? (
            <div style={{ marginTop: "20px" }}>
              <ComingSoonButton />
            </div>
          ) : (
            this.renderOptionDetails()
          )}
          {this.state.showActiveCoupons && (
            <CouponModules.ActiveCoupons
              activeCoupons={this.props.couponsOfThisType}
              onEditCoupon={this.props.onEditCoupon}
              onArchiveCoupon={this.props.onArchiveCoupon}
            />
          )}
        </div>
      </div>
    );
  }
}

MarketingOption.propTypes = {
  comingSoon: PropTypes.bool,
  couponsOfThisType: PropTypes.object,
  description: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onArchiveCoupon: PropTypes.func,
  onCreateNewCoupon: PropTypes.func,
  onEditCoupon: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(COUPON_TYPES)).isRequired
};

MarketingOption.defaultProps = {
  comingSoon: false,
  couponsOfThisType: {},
  onArchiveCoupon: () => {},
  onCreateNewCoupon: () => {},
  onEditCoupon: () => {}
};

export default MarketingOption;
