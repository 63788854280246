// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import cx from "classnames";

import { _convertDateObjectToString } from "./HelperFunctions";

// Style
import Style from "./style.module.scss";

// Icons
import { CalendarIcon } from "assets/Icons";

class DateRangePicker extends React.Component {
  onChange = (selectedDayRange) => this.props.onChange(selectedDayRange);

  renderInput = ({ ref }) => {
    const { class_names = {}, selectedDayRange } = this.props;
    const { from, to } = selectedDayRange;
    return (
      <div className={cx(Style.container, class_names.container)}>
        <div className={Style.calendarIcon}>
          <CalendarIcon />
        </div>
        <input
          className={cx(
            Style.input,
            class_names.input,
            (from || to) && Style.fullWidth
          )}
          placeholder="From - To"
          readOnly
          ref={ref}
          value={this.renderInputValue()}
        />
      </div>
    );
  };

  renderInputValue = () => {
    const { selectedDayRange = {} } = this.props;
    const { from, to } = selectedDayRange;
    if (!from && !to) return "";
    const fromString = _convertDateObjectToString(from);
    const toString = _convertDateObjectToString(to);
    if (from && !to) return `${fromString} - To`;
    else if (!from && to) return `From - ${toString}`;
    return `${fromString} - ${toString}`;
  };

  render() {
    const {
      selectedDayRange = { from: null, to: null },
      shouldHighlightWeekends,
    } = this.props;
    return (
      <DatePicker
        wrapperClassName={Style.calendar}
        colorPrimary="#006AFF"
        onChange={this.onChange}
        value={selectedDayRange}
        renderInput={this.renderInput}
        shouldHighlightWeekends={shouldHighlightWeekends}
      />
    );
  }
}

DateRangePicker.propTypes = {
  class_names: PropTypes.shape({ container: PropTypes.string }),
  onChange: PropTypes.func,
  selectedDayRange: PropTypes.exact({
    from: PropTypes.exact({
      day: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number,
    }),
    to: PropTypes.exact({
      day: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number,
    }),
  }),
  shouldHighlightWeekends: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  onChange: () => {},
  selectedDayRange: { from: null, to: null },
  shouldHighlightWeekends: false,
};

export default DateRangePicker;
