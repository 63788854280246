// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Utils
import { _generateRandomID } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Button, ExpansionPanel } from "fields";

// Lib
import { Services } from "lib";

class CombineOrdersSetupCreateMailbox extends React.Component {
  onGetButtonStatus = () => "active";

  onCreateMailbox = async ({ mailbox_prefix, shopID }) => {
    const { Merchants } = Services;
    const { CreateParseurMailbox } = Merchants.PostRequests;
    try {
      const { mailbox = {} } = await CreateParseurMailbox({
        mailbox_prefix,
        shopID,
      });
      return _pick(mailbox, ["id", "owner_email", "owner_id", "secret"]);
    } catch {}
  };

  onSetupMailbox = async () => {
    const { Merchants } = Services;
    const { SaveMailboxForCombineOrders } = Merchants.PostRequests;
    const { shopID } = this.props.context;
    const mailbox_prefix = _generateRandomID();
    try {
      const mailbox_info = await this.onCreateMailbox({
        mailbox_prefix,
        shopID,
      });
      await SaveMailboxForCombineOrders({
        mailbox_info,
        mailbox_prefix,
        shopID,
      });
    } catch {}
  };

  render() {
    return (
      <Box>
        <ExpansionPanel
          moduleTitle="Step 2: Create mailbox"
          showExpandableContent
        >
          <Button
            className={Style.confirmButton}
            name="create mailbox"
            onClick={this.onSetupMailbox}
            status={this.onGetButtonStatus()}
          >
            Create
          </Button>
        </ExpansionPanel>
      </Box>
    );
  }
}

CombineOrdersSetupCreateMailbox.propTypes = {
  context: PropTypes.shape({
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  onChangeStep: PropTypes.func.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(
  CombineOrdersSetupCreateMailbox
);
