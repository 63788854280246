// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Field
import { Button, Checkbox, RadioButton, ResponsiveModal, Input } from "fields";

// Lib
import { Constants } from "lib";
// Icons
import { DoordashIcon, PostmatesIcon, EyeIcon } from "assets/Icons";

class SelectEstimatePrepTimeModal extends React.Component {
  state = { selectedTimeId: "timeRange2", send_notify: true };

  componentDidMount() {
    const { AVERAGE_PREPARATION_TIMES: preparationTimes } = Constants;
    if (!preparationTimes[this.state.selectedTimeId]) {
      const firstId = Object.keys(preparationTimes)[0];
      if (firstId) {
        this.setState({ selectedTimeId: firstId });
      }
    }
    this.props.onRenderBody({
      preparationTime: preparationTimes[this.state.selectedTimeId]?.label,
      send_notify: this.state.send_notify,
    });
  }

  onAcceptOrder = () => {
    const { AVERAGE_PREPARATION_TIMES: preparationTimes } = Constants;
    let preparationTime;
    if (this.state.selectedTimeId === "timeRange4") {
      preparationTime = this.state.custom_message;
    } else {
      preparationTime = preparationTimes[this.state.selectedTimeId]?.label;
    }
    this.props.onAcceptOrder({
      preparationTime: preparationTime,
      send_notify: this.state.send_notify,
    });

    this.props.onCloseModal();
  };

  onChangeCustomLabel = (text) => {
    this.setState({ custom_message: text });
    this.props.onRenderBody({
      preparationTime: text,
      send_notify: this.state.send_notify,
    });
  };

  renderOrderInfo = () => {
    const { orderInfo = {} } = this.props;
    const {
      customerName,
      deliveryDetails = {},
      orderDeliveryTypeID,
      pickUpTime = "ASAP",
      tableNumber,
    } = orderInfo;
    let extraInfo;
    if (orderDeliveryTypeID === "inStore") {
      extraInfo = (
        <div className={Style.pickupBy}>
          Table: <strong>{tableNumber}</strong>
        </div>
      );
    } else {
      let courier = "";
      if (orderDeliveryTypeID === "pickUp") courier = customerName;
      else {
        const { courier: deliveryCourier } = deliveryDetails;
        courier =
          deliveryCourier === "postmates" ? (
            <PostmatesIcon className={Style.deliveryCourierIcon} />
          ) : (
            <DoordashIcon className={Style.deliveryCourierIcon} />
          );
      }
      extraInfo = (
        <div className={Style.pickupBy}>
          Pickup By: <strong className={Style.courier}>{courier}</strong>
        </div>
      );
    }
    return (
      <div className={Style.orderInfo}>
        <div className={Style.pickupTime}>
          Pickup Time: <strong>{pickUpTime}</strong>
        </div>
        <div>{extraInfo}</div>
      </div>
    );
  };

  renderOptions = () => {
    const { AVERAGE_PREPARATION_TIMES: preparationTimes } = Constants;
    const { custom_message = "" } = this.state;

    return Object.keys(preparationTimes).map((id) => {
      const { label } = preparationTimes[id];
      return (
        <div className={Style.option} key={id}>
          <RadioButton
            checked={this.state.selectedTimeId === id}
            id={id}
            label={label}
            onClick={() => {
              this.setState({ selectedTimeId: id }, () => {
                const preparationTime =
                  id === "timeRange4"
                    ? custom_message
                    : preparationTimes[id]?.label;

                this.props.onRenderBody({
                  preparationTime: preparationTime,
                  send_notify: this.state.send_notify,
                });
              });
            }}
          />
        </div>
      );
    });
  };
  handleShowMessage = () => {
    this.setState({ show_message: true });
  };
  onCloseModal = () => {
    this.setState({ show_message: false });
  };
  render() {
    const { selectedTimeId, show_message, send_notify } = this.state;
    const { bodyMessage = "" } = this.props;
    return (
      <ResponsiveModal
        contentDescriptionProps={{
          contentDescription: (
            <div>
              Let the guest know how long it takes to prepare this order
            </div>
          ),
          showContentDescription: true,
        }}
        contentLabel="Select estimate preparation time"
        headerProps={{ header: "Preparation time" }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderOrderInfo()}
        <div className={Style.options}>{this.renderOptions()}</div>
        {selectedTimeId === "timeRange4" && (
          <Input.TextInput
            name="Custom"
            max_character_count={50}
            onChange={this.onChangeCustomLabel}
            label="Message"
            placeholder="Message"
            value={this.state.custom_message}
          />
        )}

        <div className={Style.check_box_container}>
          <Checkbox
            id="notify guest"
            checked={this.state.send_notify}
            label="Send automated message to customer"
            onClick={() =>
              this.setState({ send_notify: !this.state.send_notify })
            }
          />
          {send_notify && (
            <div
              onClick={this.handleShowMessage}
              className={Style.label_see_message}
            >
              <EyeIcon className={Style.eyeIcon} />
              <div>See message</div>
            </div>
          )}
        </div>

        {show_message && (
          <ResponsiveModal
            contentLabel="Select estimate preparation time"
            headerProps={{
              header: "Message",
              show_back_button: true,
              show_close_button: false,
            }}
            onCloseModal={this.onCloseModal}
            classNames={{ modal: Style.modalBox }}
          >
            <Input.TextInput
              name="Custom"
              hasMultipleLines
              readOnly
              rows={6}
              value={bodyMessage}
            />
          </ResponsiveModal>
        )}
        <Button
          className={Style.submitButton}
          color="info"
          name="select the time"
          onClick={this.onAcceptOrder}
        >
          Submit
        </Button>
      </ResponsiveModal>
    );
  }
}

SelectEstimatePrepTimeModal.propTypes = {
  bodyMessage: PropTypes.string.isRequired,
  onAcceptOrder: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({
    orderDeliveryTypeID: PropTypes.oneOf(["inStore", "pickUp", "deliver"])
      .isRequired,
    pickUpTime: PropTypes.string,
    tableNumber: PropTypes.string,
  }).isRequired,
};

export default SelectEstimatePrepTimeModal;
