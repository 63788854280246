// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import {
  _convertDateRangeToUtc,
  _createColumnsForTable,
  _sanitizeDataForTable,
  _sortReviews,
} from "./functions";
import Toolbar from "./Toolbar";

import ReviewInfo from "../ReviewInfo";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Table } from "fields";

// Assets
import NoReviewsFound from "assets/Images/no-reviews.png";
import ReviewsGrid from "../ReviewsGrid";

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_display_option_id: "grid",
      selected_timeRange_id: "today",
      selected_dropdown_value: "default",
      sorted_reviews: null,
      sort_ascending: true,
      reviews: [],
      sortType: "date",
      sortAscending: false,
    };
  }

  handleDropdownChange = (event) =>
    this.setState({ selected_dropdown_value: event.target.value });

  onShowReviewDetails = ({ dataObject }) =>
    this.setState({ selected_review: dataObject });

  // filterReviewsByDate = (guest_reviews, startAt, endAt) => {
  //   const filtered_id = Object.keys(guest_reviews).filter((key) => {
  //     const created_at = new Date(guest_reviews[key]?.createdAt);
  //     return created_at >= startAt && created_at <= endAt;
  //   });
  //   return filtered_id;
  // };

  handleFilterClick = (
    sort_date,
    sort_type_date,
    sort_rating,
    sort_type_rating,
    filter_rating
  ) => {
    const { guest_reviews } = this.props;
    this.setState(() => ({
      sorted_reviews: _sortReviews({
        guest_reviews,
        sort_rating,
        sort_date,
        sort_type_date,
        sort_type_rating,
        filter_rating,
      }),
    }));
  };

  onSortChange = (
    sort_date,
    sort_type_date,
    sort_rating,
    sort_type_rating,
    filter_rating
  ) => {
    this.setState(
      {
        sort_date,
        sort_type_date,
        sort_rating,
        sort_type_rating,
        filter_rating,
      },
      this.handleFilterClick(
        sort_date,
        sort_type_date,
        sort_rating,
        sort_type_rating,
        filter_rating
      )
    );
  };

  onChangeDateRange = (selectedDayRange) => {
    const timeZone = _get(this.props, "context.shopBasicInfo.timeZone");
    this.setState({ selectedDayRange }, () => {
      const { from_utc, to_utc } = _convertDateRangeToUtc({
        selectedDayRange,
        timeZone,
      });
      const start_date = new Date(from_utc);
      const end_date = new Date(to_utc);
      const filtered_reviews_keys = Object.keys(
        this.props.guest_reviews
      ).filter((reviewKey) => {
        const createdAtDate = new Date(
          this.props.guest_reviews[reviewKey].createdAt
        );
        return createdAtDate >= start_date && createdAtDate <= end_date;
      });

      this.setState({
        endAt: to_utc,
        selected_timeRange_id: "",
        startAt: from_utc,
        sorted_reviews: filtered_reviews_keys,
      });
    });
  };

  renderNoReviewsFound = () => (
    <div className={Style.no_reviews_found_container}>
      <img
        alt="no reviews found"
        className={Style.no_reviews_found_img}
        src={NoReviewsFound}
      />
      <h2>No Reviews Found</h2>
      <p>Share the review link above and get great reviews with SKIPLI.</p>
    </div>
  );

  renderReviewsGrid = (guest_reviews) => {
    const { context = {}, sorted_review_id_list } = this.props;
    const { sorted_reviews } = this.state;
    const { shop_basic_info = {} } = context;
    const { timeZone } = shop_basic_info;
    const sorted_keys =
      sorted_reviews === null ? sorted_review_id_list : sorted_reviews;

    return (
      <ReviewsGrid
        guest_reviews={_sanitizeDataForTable({
          guest_reviews,
          sorted_review_id_list: sorted_keys,
          time_zone: timeZone,
        })}
        onShowReviewDetails={this.onShowReviewDetails}
      />
    );
  };

  renderReviewsList = (guest_reviews) => {
    const { context = {}, sorted_review_id_list } = this.props;
    const { sorted_reviews } = this.state;
    const { shop_basic_info = {} } = context;
    const { timeZone } = shop_basic_info;
    const sortedKeys =
      sorted_reviews === null ? sorted_review_id_list : sorted_reviews;
    return (
      <Table
        classNames={{
          "created_at-column": Style.created_at_column,
          "feedback_text-column": Style.comment_column,
          "selected_rating-column": Style.rating_column,
          "uuid-column": Style.uuid_column,
          table: Style.table,
        }}
        columns={_createColumnsForTable()}
        data={_sanitizeDataForTable({
          guest_reviews,
          sorted_review_id_list: sortedKeys,
          time_zone: timeZone,
        })}
        onClickRow={this.onShowReviewDetails}
        showMoreDataMessage="Show more reviews"
      />
    );
  };

  renderReviewInfo = (selected_review) => {
    const { guest_reviews = {} } = this.props;
    const { review_id } = selected_review || {};
    const { feedback_text } = guest_reviews[review_id];
    return (
      <ReviewInfo
        {...selected_review}
        feedback_text={feedback_text}
        onDeselectReview={() => this.setState({ selected_review: null })}
      />
    );
  };

  render() {
    const {
      selected_review,
      selected_display_option_id,
      sorted_reviews,
    } = this.state;
    const { guest_reviews = {} } = this.props;
    return (
      <div>
        {selected_review && this.renderReviewInfo(selected_review)}
        <Toolbar
          onChangeDateRange={this.onChangeDateRange}
          onChangeDisplayOption={(selected_display_option_id) =>
            this.setState({ selected_display_option_id })
          }
          onSortChange={this.onSortChange}
          selected_display_option_id={this.state.selected_display_option_id}
          selectedDayRange={this.state.selectedDayRange}
          selected_timeRange_id={this.state.selected_timeRange_id}
          scroll_y={this.props.scroll_y}
        />
        {Object.keys(guest_reviews).length === 0 ||
        sorted_reviews?.length === 0 ? (
          this.renderNoReviewsFound()
        ) : (
          <div style={{ marginTop: "40px" }}>
            {selected_display_option_id === "grid"
              ? this.renderReviewsGrid(guest_reviews)
              : this.renderReviewsList(guest_reviews)}
          </div>
        )}
      </div>
    );
  }
}

Reviews.propTypes = {
  context: PropTypes.shape({
    shop_basic_info: PropTypes.shape({
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
    shop_id: PropTypes.string.isRequired,
  }).isRequired,
  scroll_y: PropTypes.number,
};

Reviews.defaultProps = {};

export default withContext(MerchantInterfaceConsumer)(Reviews);
