// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

// Style
import Style from "./style.module.scss";

// Components
import Personnel from "views/PersonnelProfile/Personnel";

// Fields
import { ResponsiveModal } from "fields";

class CreateUserModal extends React.Component {
  renderContent = () => {
    return <Personnel
      allPersonnels={this.props.allPersonnels}
      isEditable
      isNewUser
      onCancelCreateNewUser={this.props.onCloseModal}
      onCreateUserSuccess={this.props.onCreateUserSuccess}
      personnelInfo={{ personnelID: shortid.generate(), role: "staff" }}
      showPersonnelPin
      showRemoveButton={false}
      shopID={this.props.shopID}
    />
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentLabel="Create New User Modal"
        headerProps={{
          close_button_icon: "arrow",
          header: "New User",
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

CreateUserModal.propTypes = {
  allPersonnels: PropTypes.object.isRequired,
  onCreateUserSuccess: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default CreateUserModal;
