// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Checkbox, RadioButton } from "fields";

class CheckboxAndRadioGroup extends React.Component {
  onClickOption = (optionId) => {
    const { onClick, readOnly = false } = this.props;
    if (onClick && !readOnly) onClick(optionId);
  };

  renderOptions = () => {
    const {
      kind = "radio",
      optionClassName = "",
      options = {},
      option_props = {},
      readOnly = false,
      selectedOptionIds = [],
      selectedOptionId,
    } = this.props;
    const { has_border = false, has_border_style = "" } = option_props;
    return Object.keys(options).map((id) => {
      const { description, label } = options[id];
      const props = {
        description,
        descriptionClassName: this.props.descriptionClassName,
        id,
        label,
        labelClassName: this.props.labelClassName,
        onClick: this.onClickOption,
        readOnly,
      };
      const is_option_selected =
        kind === "checkbox"
          ? selectedOptionIds.includes(id)
          : selectedOptionId === id;
      return (
        <div
          className={cx(
            Style.option,
            optionClassName,
            has_border && Style.has_border,
            has_border_style,
            is_option_selected && Style.selected
          )}
          key={id}
        >
          {kind === "checkbox" ? (
            <Checkbox {...props} checked={is_option_selected} />
          ) : (
            <RadioButton {...props} checked={is_option_selected} />
          )}
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <div className={cx(Style.heading, this.props.headingClassname)}>
          <div className={Style.headingText}>{this.props.heading}</div>
          {this.props.required ? (
            <div className={Style.requiredSign}>required</div>
          ) : (
            <div className={Style.optionalSign}>optional</div>
          )}
        </div>
        <div className={Style.optionsContainer}>{this.renderOptions()}</div>
      </div>
    );
  }
}

CheckboxAndRadioGroup.propTypes = {
  heading: PropTypes.string,
  headingClassname: PropTypes.string,
  kind: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  optionClassName: PropTypes.string,
  option_props: PropTypes.shape({
    has_border: PropTypes.bool,
    has_border_style: PropTypes.string,
  }),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedOptionId: PropTypes.string,
  selectedOptionIds: PropTypes.array,
};

CheckboxAndRadioGroup.defaultProps = {
  option_props: { has_border: false, has_border_style: "" },
  readOnly: false,
  required: false,
};

export default CheckboxAndRadioGroup;
