// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Lib
import { Functions } from "lib";

class RefundRequests extends React.Component {
  onConvertCreatedAt = (createdAt) => {
    const { DateTime } = Functions;
    const { _convertUTCTimestampToLocalTime } = DateTime;
    const { local_date, local_time } = _convertUTCTimestampToLocalTime({
      localTimeZone: _get(this.props.context, "shopBasicInfo.timeZone"),
      timeStamp: createdAt,
    });
    return `${local_date} ${local_time}`;
  };

  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.cancelButton}
        color="white"
        name="close modal"
        onClick={this.props.onCloseModal}
      >
        Cancel
      </Button>
      <Button
        className={Style.submitButton}
        name="create new refund request"
        onClick={this.props.onCreateNewRequest}
      >
        Create New Request
      </Button>
    </div>
  );

  renderRefundRequests = () => {
    const { refundRequests = {} } = this.props;
    return Object.keys(refundRequests).map((requestId) => {
      const { createdAt, reason } = refundRequests[requestId];
      return (
        <div className={Style.refundRequest} key={requestId}>
          <div className={Style.requestId}>Request #: {requestId}</div>
          <div className={Style.infoRow}>
            <div className={Style.label}>Created:</div>{" "}
            {this.onConvertCreatedAt(createdAt)}
          </div>
          {/* <div className={Style.infoRow}>
            <div className={Style.label}>Refund Amount:</div> $
            {parseFloat(refundAmount).toFixed(2)}
          </div> */}
          <div className={Style.infoRow}>
            <div className={Style.label}>Reason:</div> {reason.text}
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <div style={{ marginTop: "-1.6rem" }}>
          {this.renderRefundRequests()}
        </div>
        {this.renderButtons()}
      </div>
    );
  }
}

RefundRequests.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  customerName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCreateNewRequest: PropTypes.func.isRequired,
  orderID: PropTypes.string.isRequired,
  refundRequests: PropTypes.object.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(RefundRequests);
