// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import dayjs from "dayjs";

import { _checkIfDateIsDuplicate, _sanitizeSpecialHours } from "./functions";
import { _dayjs } from "../functions";
import { _sortSpecialHours } from "../functions";
import AddNewDateModal from "../AddNewDateModal";

// Style
import ModalStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Checkbox, ResponsiveModal } from "fields";

// Icons
import { CalendarIcon, PencilIcon, PlusIcon, TrashCanIcon } from "assets/Icons";

import { Functions } from "lib";
import Navbars from "components/Navbars";
import { SPECIAL_HOURS_SUBNAV_ITEMS } from "lib/Constants/NavbarItems";

class SpecialHoursEditModal extends React.Component {
  state = {
    close_modal_after_submit: true,
    special_hours: {},
    show_past_special_hours: "live",
  };

  componentDidMount = () =>
    this.setState({ special_hours: this.props.special_hours });

  onSaveNewSpecialHours = () => {
    const sanitized_special_hours = _sanitizeSpecialHours({
      special_hours: this.state.special_hours,
    });
    const has_duplicate_dates = Object.keys(sanitized_special_hours).reduce(
      (result, id) => {
        const { date } = sanitized_special_hours[id];
        const is_duplicate = _checkIfDateIsDuplicate({
          date,
          id,
          special_hours: sanitized_special_hours,
        });
        if (is_duplicate) return true;
        return result;
      },
      false
    );
    if (has_duplicate_dates) {
      this.setState({ close_modal_after_submit: false }, () => {
        Functions.ShowConfirmNotif({
          message: "Please remove duplicate dates",
          options: { autoClose: 4000 },
          type: "warning",
        });
      });
    } else {
      this.setState({ close_modal_after_submit: true }, () => {
        this.props.onSaveNewSpecialHours({
          new_special_hours: sanitized_special_hours,
        });
      });
    }
  };

  renderAddNewDateButton = () => (
    <div className={Style.container_button}>
      <button
        className={Style.add_new_date}
        onClick={() => this.setState({ show_add_new_date_modal: true })}
      >
        <PlusIcon />
        Add new date
      </button>
    </div>
  );

  onChangeSubNavItemId = (itemId) =>
    this.setState({ show_past_special_hours: itemId });

  renderSubNavbar = () => (
    <Navbars.SubNavbar
      activeNavItemId={this.state.show_past_special_hours}
      navItems={SPECIAL_HOURS_SUBNAV_ITEMS}
      onNavItemClick={this.onChangeSubNavItemId}
    />
  );
  renderContent = () => {
    const { special_hours = {}, show_past_special_hours } = this.state;
    const { today = "" } = this.props;
     
    if (Object.keys(special_hours).length === 0) return;
    const today_date = today;

    return _sortSpecialHours({ special_hours }).map((id) => {
      const { date, end_at, is_closed, start_at } = special_hours[id];
      const special_date = _dayjs(date);
      if (show_past_special_hours === "past") {
        if (!special_date.isBefore(_dayjs(today_date), "day")) return null;
      } else {
        if (special_date.isBefore(_dayjs(today_date), "day")) return null;
      }
      return (
        <div className={Style.special_hour} key={id}>
          <div
            className={Style.special_hour_info_container}
            onClick={() => {
              if (this.state.show_past_special_hours === "live") {
                this.setState({
                  selected_date_id_to_edit: id,
                  show_add_new_date_modal: true,
                });
              }
            }}
          >
            <div className={Style.calendar_date}>
              <div className={Style.calendar_icon}>
                <CalendarIcon />
              </div>
              <div>{date}</div>
            </div>
            <div>
              {is_closed ? (
                <Checkbox checked={is_closed} id={id} label="Closed" readOnly />
              ) : (
                `${dayjs("1/1/1 " + start_at).format("h:mm A")} - ${dayjs(
                  "1/1/1 " + end_at
                ).format("h:mm A")}`
              )}
            </div>
          </div>
          {this.state.show_past_special_hours === "live" && (
            <div className={Style.special_hours_buttons}>
              <div
                className={Style.edit_date}
                onClick={() =>
                  this.setState({
                    selected_date_id_to_edit: id,
                    show_add_new_date_modal: true,
                  })
                }
              >
                <PencilIcon />
              </div>
              <div
                className={Style.delete_date}
                onClick={() =>
                  this.setState({
                    selected_date_id_to_delete: id,
                    show_confirm_date_removal: true,
                  })
                }
              >
                <TrashCanIcon />
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  renderConfirmDateRemovalModal = () => {
    const { selected_date_id_to_delete = "", special_hours = {} } = this.state;
    const { date = "" } = special_hours[selected_date_id_to_delete] || {};
    return (
      <ResponsiveModal
        classNames={{
          footer_submit_button: Style.confirm_delete_button,
          modal: Style.confirm_date_removal_modal,
        }}
        contentDescriptionProps={{
          contentDescription:
            "This action cannot be undone. Are you sure you want to delete this date?",
          showContentDescription: true,
        }}
        contentLabel="Confirm date removal modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: { activeText: "Delete" },
        }}
        headerProps={{ header: `Delete ${date}` }}
        onCloseModal={() => this.setState({ show_confirm_date_removal: false })}
        onSubmit={() =>
          this.setState(
            {
              selected_date_id_to_delete: "",
              special_hours: del(special_hours, selected_date_id_to_delete),
            },
            this.onSaveNewSpecialHours
          )
        }
      ></ResponsiveModal>
    );
  };

  render() {
    return (
      <>
        {this.state.show_add_new_date_modal && (
          <AddNewDateModal
            onCloseModal={() =>
              this.setState({
                selected_date_id_to_edit: "",
                show_add_new_date_modal: false,
              })
            }
            onSave={({ key, value }) =>
              this.setState(
                { special_hours: set(this.state.special_hours, key, value) },
                this.onSaveNewSpecialHours
              )
            }
            selected_date_id_to_edit={this.state.selected_date_id_to_edit}
            special_hours={this.props.special_hours}
          />
        )}

        {this.state.show_confirm_date_removal &&
          this.renderConfirmDateRemovalModal()}

        <ResponsiveModal
          classNames={{
            modal: ModalStyle.modal,
            footer_cancel_button: Style.footer_cancel_button,
            footer_container: Style.footer_container,
            contentDescriptionContainer: Style.contentDescriptionContainer,
          }}
          closeModalAfterSuccessSubmit={false}
          contentDescriptionProps={{
            contentDescription: this.renderSubNavbar(),
            showContentDescription: true,
          }}
          contentLabel="Edit special hours modal"
          headerProps={{ header: "Special Hours" }}
          onCloseModal={this.props.onCloseModal}
          // onSubmit={this.togglePastSpecialHours}
        >
          <div>
            {this.renderAddNewDateButton()}
            {this.renderContent()}
          </div>
        </ResponsiveModal>
      </>
    );
  }
}

SpecialHoursEditModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  today: PropTypes.string.isRequired,
  onSaveNewSpecialHours: PropTypes.func.isRequired,
  special_hours: PropTypes.object,
};

SpecialHoursEditModal.defaultProps = {
  special_hours: {},
  today: "",
};

export default SpecialHoursEditModal;
