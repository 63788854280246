// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Chip } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

class ModifierGroup extends Component {
  state = { showEditModifierGroupModal: false };

  componentDidMount = () =>
    this.setState({ modifierGroupInfo: this.props.modifierGroupInfo });

  onHideEditModifierGroupModal = () =>
    this.setState({ showEditModifierGroupModal: false });

  onShowEditModifierGroupModal = (e) => {
    e && e.preventDefault();
    this.setState({ showEditModifierGroupModal: true });
  };

  onUpdateAfterModifierGroupArchived = ({
    shouldArchiveModifierGroup = false,
  }) => {
    const { modifierGroupInfo } = this.state;
    this.setState(
      {
        modifierGroupInfo: set(
          modifierGroupInfo,
          "modifierGroupIsArchived",
          shouldArchiveModifierGroup
        ),
      },
      () =>
        this.props.onUpdateAllModifierGroups({
          modifierGroupID: this.props.modifierGroupID,
          modifierGroupInfo: this.state.modifierGroupInfo,
        })
    );
  };

  onUpdateAfterModifierGroupSaved = ({ modifierGroupInfo }) =>
    this.setState({ modifierGroupInfo }, () =>
      this.props.onUpdateAllModifierGroups({
        modifierGroupID: this.props.modifierGroupID,
        modifierGroupInfo: this.state.modifierGroupInfo,
      })
    );

  renderChipLabel = () => {
    const { modifierGroupInfo } = this.state;
    const modifiersCount = Object.keys(modifierGroupInfo.modifiers || {})
      .length;
    return (
      <React.Fragment>
        <div className={CommonStyle.chipName}>
          {modifierGroupInfo.modifierGroupName}
        </div>
        <div className={Style.chipDescription}>
          {modifiersCount > 0 && (
            <div className={CommonStyle.elementsCounter}>
              {modifiersCount} modifier
              {modifiersCount !== 1 ? "s" : ""}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderEditModal = () => (
    <Modals.ModifierGroupModal
      isInEditMode={true}
      modifierGroupID={this.props.modifierGroupID}
      modifierGroupInfo={this.state.modifierGroupInfo}
      onCloseModal={this.onHideEditModifierGroupModal}
      onUpdateAfterModifierGroupArchived={
        this.onUpdateAfterModifierGroupArchived
      }
      onUpdateAfterModifierGroupSaved={this.onUpdateAfterModifierGroupSaved}
    />
  );

  renderHelperButton = () => {
    const { modifierGroupHelperButton } = this.props;
    if (!this.props.showHelperButton) return;
    let buttonContent;
    if (modifierGroupHelperButton)
      buttonContent = modifierGroupHelperButton(this.props.modifierGroupID);
    else
      buttonContent = (
        <PencilIcon
          className={CommonStyle.pencilIcon}
          onClick={this.onShowEditModifierGroupModal}
        />
      );
    return buttonContent;
  };

  renderModifierGroup = () => (
    <div className={CommonStyle.chipContainer}>
      <Chip
        label={this.renderChipLabel()}
        onClickLabel={this.onShowEditModifierGroupModal}
        helperButtonContent={this.renderHelperButton()}
      />
      {this.renderSigns()}
    </div>
  );

  renderSigns = () => {
    const { modifierGroupIsArchived = false } = this.state.modifierGroupInfo;
    if (modifierGroupIsArchived)
      return (
        <div className={CommonStyle.signsContainer}>
          <div className={CommonStyle.archivedSign}>Archived</div>
        </div>
      );
    return null;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showEditModifierGroupModal && this.renderEditModal()}
        {this.state.modifierGroupInfo && this.renderModifierGroup()}
      </React.Fragment>
    );
  }
}

ModifierGroup.propTypes = {
  isInEditMode: PropTypes.bool,
  modifierGroupHelperButton: PropTypes.func,
  modifierGroupID: PropTypes.string.isRequired,
  modifierGroupInfo: PropTypes.shape({
    modifierGroupName: PropTypes.string.isRequired,
  }).isRequired,
  onUpdateAllModifierGroups: PropTypes.func,
  showHelperButton: PropTypes.bool,
};

ModifierGroup.defaultProps = {
  isInEditMode: false,
  onUpdateAllModifierGroups: () => {},
  showHelperButton: true,
};

export default ModifierGroup;
