// BUTI Corp All right Reserved ©

import React from "react";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const ComingSoonButton = props => (
  <button className={cx(Style.comingSoonButton, props.buttonStyle)}>
    coming soon
  </button>
);

export default ComingSoonButton;
