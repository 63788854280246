// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import _pick from "lodash.pick";

import ReasonsList from "./ReasonsList";
import RefundAmount from "./RefundAmount";

import {
  _calculateRefundAmount,
  _convertItemsToCheckboxOptions,
  _createEmailRequestToSkipli,
  _createTextMessageToGuest,
  _getSubmitButtonStatus,
  REFUND_TYPES,
} from "./HelperFunctions";

// Utils
import { _roundNumber } from "utils";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { CheckBoxGroup } from "components";

// Fields
import { Button, Checkbox, Input, Tabs } from "fields";

// Lib
import { Functions, Services } from "lib";
import { set } from "object-path-immutable";

class NewRefundRequest extends React.Component {
  state = {
    reason: { id: "", text: "" },
    refundAmount: "",
    selectedOrderItems: [],
    selectedRefundTypeId: "selectItems",
  };

  onChangeRefundAmount = (refundAmount = "") => {
    const { selectedRefundTypeId } = this.state;
    let amount = refundAmount;
    if (selectedRefundTypeId === "selectItems") {
      const { orderInfo = {} } = this.props;
      const { orderItems = {} } = orderInfo;
      amount = _calculateRefundAmount({ ...this.state, orderItems });
    } else if (selectedRefundTypeId === "allItems") {
      const { orderInfo = {} } = this.props;
      const { totalPriceAfterTax } = orderInfo;
      amount = _roundNumber(totalPriceAfterTax);
    } else {
      if (isNaN(amount) || parseFloat(amount) < 0) amount = 0;
      else if (amount) amount = _roundNumber(amount);
    }
    this.setState({ refundAmount: amount });
  };

  onChangeRefundTypeId = (optionId) =>
    !this.state.isCreatingRequest &&
    this.setState(
      {
        reason: { id: "", text: "" },
        refundAmount: "",
        selectedOrderItems: [],
        selectedRefundTypeId: optionId,
      },
      this.onChangeRefundAmount
    );

  onCloseModal = () =>
    !this.state.isCreatingRequest && this.props.onCloseModal();

  onNotifyGuestOfRefund = async () => {
    const { BUTI } = Services;
    const { SendTextMessage } = BUTI.PostRequests;
    const { shopBasicInfo } = this.props.context;
    const { orderInfo = {} } = this.props;
    const { customerName, phoneNumber, timeStamp } = orderInfo;
    const { name: shopName, timeZone } = shopBasicInfo;
    try {
      await SendTextMessage({
        body: _createTextMessageToGuest({
          ...this.state,
          customerName,
          shopName,
          shopTimeZone: timeZone,
          timeStamp,
        }),
        to: phoneNumber,
      });
    } catch {}
  };

  onSelectOrderItem = (id) => {
    const { selectedOrderItems = [] } = this.state;
    this.setState(
      {
        selectedOrderItems: selectedOrderItems.includes(id)
          ? selectedOrderItems.filter((orderItemID) => orderItemID !== id)
          : selectedOrderItems.concat(id),
      },
      this.onChangeRefundAmount
    );
  };

  onSendRequestToSkipli = async ({ refund_request_id } = {}) => {
    const { BUTI } = Services;
    const { SendEmail } = BUTI.PostRequests;
    const { personnel, shopBasicInfo, shopID } = this.props.context;

    const { email_body, email_subject } = _createEmailRequestToSkipli({
      ...this.state,
      orderId: this.props.orderID,
      orderInfo: this.props.orderInfo,
      personnel,
      refund_request_id,
      shopBasicInfo,
      shopID,
    });
    try {
      await SendEmail({
        addresses: ["support@skiplinow.com"],
        subject: email_subject,
        body: email_body,
      });
    } catch {}
  };

  onSubmitRefundRequest = async () => {
    this.setState({ isCreatingRequest: true });
    const { Merchants } = Services;
    const { CreateRefundRequest } = Merchants.PostRequests;
    const { ShowConfirmNotif } = Functions;
    const refund_request_id = shortid.generate();
    const { shopID } = this.props.context;
    const request_details = _pick(this.state, [
      "reason",
      "refundAmount",
      "selectedOrderItems",
      "selectedRefundTypeId",
    ]);

    try {
      const { createdAt } = await CreateRefundRequest({
        orderId: this.props.orderID,
        refund_request_id,
        request_details,
        shopId: shopID,
      });
      ShowConfirmNotif({
        message: "Your refund request is sent!",
        type: "success",
      });
      await this.onNotifyGuestOfRefund();
      await this.onSendRequestToSkipli({ refund_request_id });
      this.setState({ isCreatingRequest: false }, () =>
        this.props.onRefundRequestSuccess({
          refund_request_id,
          request_details: set(request_details, "createdAt", createdAt),
        })
      );
    } catch {
      ShowConfirmNotif({
        message: "Please try again!",
        type: "error",
      });
      this.setState({ isCreatingRequest: false });
    }
  };

  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.cancelButton}
        color="white"
        name="submit refund request"
        onClick={this.onCloseModal}
      >
        Cancel
      </Button>
      <Button
        className={Style.submitButton}
        loadingText="Requesting"
        name="submit refund request"
        onClick={this.onSubmitRefundRequest}
        status={_getSubmitButtonStatus(this.state)}
      >
        Request Refund
      </Button>
    </div>
  );

  renderConfirmation = () => (
    <Checkbox
      id="Confirm Refund"
      checkboxClassName={Style.confirmCheckbox}
      checked={this.state.confirmed}
      label="I confirm the refund adheres to the restaurant policy and is processed immediately. A copy will be sent to my manager for later review."
      onClick={() => this.setState({ confirmed: !this.state.confirmed })}
    />
  );

  renderItemsList = ({ items = {} }) => (
    <CheckBoxGroup
      descriptionClassName={Style.checkboxDescription}
      heading="Select items to refund"
      onClick={(optionID) => this.onSelectOrderItem(optionID)}
      optionClassName={Style.checkboxOption}
      options={_convertItemsToCheckboxOptions({ items })}
      readOnly={this.state.isCreatingRequest}
      required
      selectedOptionIds={this.state.selectedOrderItems}
    />
  );

  renderRefundAmountInput = () => (
    <div style={{ marginBottom: "1.2rem" }}>
      <Input.TextInput
        label="Refund Amount ($)"
        name="refund amount"
        onChange={this.onChangeRefundAmount}
        readOnly={this.state.isCreatingRequest}
        required
        type="number"
        value={this.state.refundAmount}
      />
    </div>
  );

  renderRefundType = () => {
    const { orderInfo = {} } = this.props;
    const { orderItems } = orderInfo;
    switch (this.state.selectedRefundTypeId) {
      case "customAmount":
        return this.renderRefundAmountInput();
      case "selectItems":
        return this.renderItemsList({ items: orderItems });
      default:
        return;
    }
  };

  render() {
    return (
      <div>
        <div>
          <Tabs
            onClick={this.onChangeRefundTypeId}
            options={REFUND_TYPES}
            selectedOptionId={this.state.selectedRefundTypeId}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <ReasonsList
            onSelectReason={(reason) => this.setState({ reason })}
            readOnly={this.state.isCreatingRequest}
            selectedReason={this.state.reason}
          />
        </div>
        <div style={{ margin: "20px 0 40px 0" }}>{this.renderRefundType()}</div>
        <RefundAmount
          refundAmount={this.state.refundAmount}
          selectedRefundTypeId={this.state.selectedRefundTypeId}
        />
        {this.renderButtons()}
      </div>
    );
  }
}

NewRefundRequest.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRefundRequestSuccess: PropTypes.func.isRequired,
  orderID: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    orderDeliveryTypeID: PropTypes.oneOf(["inStore", "deliver", "pickUp"])
      .isRequired,
    orderItems: PropTypes.object.isRequired,
    paymentIntentID: PropTypes.string,
    phoneNumber: PropTypes.string,
    timeStamp: PropTypes.string.isRequired,
    totalPriceAfterTax: PropTypes.any.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  refundRequests: PropTypes.object,
};

export default withContext(MerchantInterfaceConsumer)(NewRefundRequest);
