// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Lib
import { Constants, Services } from "lib";

const { TIME_RANGE_OPTIONS } = Constants;

class TimeRangePicker extends React.Component {
  componentDidMount = () => this.onGetTimeRange(this.props.selectedTimeRangeID);

  componentDidUpdate = (prevProps) => {
    const { selectedTimeRangeID } = this.props;
    if (prevProps.selectedTimeRangeID !== selectedTimeRangeID) {
      this.onGetTimeRange(selectedTimeRangeID);
    }
  };

  onGetTimeRange = async (selectedTimeRangeID) => {
    if (!selectedTimeRangeID) return;
    const { BUTI } = Services;
    const { GetTimeRange } = BUTI.GetRequests;
    const { endAt = "", startAt = "" } = await GetTimeRange({
      timeRange: selectedTimeRangeID,
      timeZone: this.props.timeZone,
    });
    this.props.onChange({ endAt, selectedTimeRangeID, startAt });
  };

  renderOptions = () =>
    Object.entries(TIME_RANGE_OPTIONS).map((entry) => {
      const { label } = entry[1];
      return (
        <button
          className={cx(
            Style.option,
            entry[0] === this.props.selectedTimeRangeID && Style.selected
          )}
          key={entry[0]}
          onClick={() => this.onGetTimeRange(entry[0])}
        >
          {label}
        </button>
      );
    });

  render() {
    const { class_names = {} } = this.props;
    return (
      <div className={cx(class_names.container, Style.container)}>
        {this.renderOptions()}
      </div>
    );
  }
}

TimeRangePicker.propTypes = {
  class_names: PropTypes.shape({ container: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
  selectedTimeRangeID: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default TimeRangePicker;
