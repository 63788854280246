// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import CustomerInfo from "./CustomerInfo";
import DeliveryAddress from "./deliveryAddress";
import DeliveryInfo from "./DeliveryInfo";
import Items from "./Items";
import Notices from "./Notices";
// import SubTotal from "./subTotal";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Input } from "fields";
import ReportModal from "./ReportModal";

class OrderDetails extends React.Component {
  state = { showAllInfo: false, click_count: 0  };


  handleOrderClick = () => {
    const { click_count } = this.state;
    this.setState({
      click_count: click_count < 6 ? click_count + 1 : 0,
      show_input_modal: click_count === 5 ? true : false
    });
  };


  renderCurbsideInfo = () => {
    const { curbsideInfo = "" } = this.props.orderInfo;
    return (
      curbsideInfo && (
        <div className={Style.curbsidePickUp}>
          <Input.TextInput
            label="Curbside (Parking lot # / Car brand + color)"
            name="curbsideInfo"
            value={curbsideInfo}
            required={false}
            readOnly
          />
        </div>
      )
    );
  };

  // renderSubmittedTime = () => {
  //   const { DATE_FORMAT, DEFAULT_TIMEZONE, TIME_FORMAT } = Constants;

  //   const { orderInfo } = this.props;
  //   const { timeStamp } = orderInfo;
  //   const { timeZone } = this.props.context.shopBasicInfo;
  //   const submittedDate = formatToTimeZone(timeStamp, DATE_FORMAT, {
  //     timeZone: timeZone || DEFAULT_TIMEZONE,
  //   });
  //   const submittedTime = formatToTimeZone(timeStamp, TIME_FORMAT, {
  //     timeZone: timeZone || DEFAULT_TIMEZONE,
  //   });
  //   return (
  //     <div className={Style.infoCol}>
  //       <div className={Style.detailHeading}>received at</div>
  //       <div>{submittedDate}</div>
  //       <div>{submittedTime}</div>
  //     </div>
  //   );
  // };

  render() {
    const { orderID, orderInfo = {}, shop_id } = this.props;
    const {shopBasicInfo} =  this.props.context
    const {address,name} = shopBasicInfo
    const {
      customerName,
      deliveryDetails = {},
      orderDeliveryTypeID,
      phoneNumber,
      refundRequests = {},
      status,
    } = orderInfo;

    return (
      <div className={cx(Style.orderDetailsBox, Style[status])}>
        <Notices
          onShowRefundModal={this.props.onShowRefundModal}
          refundRequests={refundRequests}
        />
        <div className={Style.infoRow}>
          <CustomerInfo
            customer_name={customerName}
            phone_number={phoneNumber}
          />
          {["pickUp", "deliver"].includes(orderDeliveryTypeID) && (
            <DeliveryInfo
              onShowDeliveryAddress={() =>
                this.setState({ showDeliveryAddress: true })
              }
              order_id={orderID}
              orderInfo={orderInfo}
              shop_id={shop_id}
            />
          )}
          {this.state.showDeliveryAddress && (
            <DeliveryAddress deliveryDetails={deliveryDetails} />
          )}
          {/* {this.renderSubmittedTime()} */}
        </div>
        {this.renderCurbsideInfo()}
        <Items order_id={orderID} order_info={orderInfo}  handleClick={this.handleOrderClick} />
        {this.state.click_count === 6 && this.state.show_input_modal && (    
          <ReportModal
            order_id={orderID}
            order_info={orderInfo}
            onCloseModal={() => this.setState({ show_input_modal: false,click_count:0 })}
            shop_name ={name}
            shop_address ={address}
           
          />
        )}

        {/* {orderType === "past" && (
          <ViewReceiptButton
            paymentIntentID={orderInfo.paymentIntentID}
            orderDetails={orderInfo}
            orderID={this.props.orderID}
            shopAddress={address}
            shopName={name}
            timeZone={timeZone}
          />
        )} */}
      </div>
    );
  }
}

OrderDetails.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  onShowRefundModal: PropTypes.func.isRequired,
  orderID: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    deliverAddress: PropTypes.string,
    deliveryDetails: PropTypes.shape({
      deliveryAddress: PropTypes.object,
    }),
    curbsideInfo: PropTypes.string,
    customerName: PropTypes.string.isRequired,
    orderDeliveryTypeID: PropTypes.oneOf(["deliver", "inStore", "pickUp"])
      .isRequired,
    orderItems: PropTypes.object.isRequired,
    paymentIntentID: PropTypes.string,
    phoneNumber: PropTypes.string,
    status: PropTypes.string.isRequired,
    tableNumber: PropTypes.string,
    timeStamp: PropTypes.string.isRequired,
    tipAmount: PropTypes.any,
    totalPriceAfterTax: PropTypes.any.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  orderType: PropTypes.oneOf(["active", "past"]).isRequired,
  shop_id: PropTypes.string.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(OrderDetails);
