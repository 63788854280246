// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { _getChangeStatusButton } from "./helperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { Button } from "fields";

// Icons
import {
  CheckIcon,
  LikeIcon,
  MinusIcon,
  PlusIcon,
  RefundIcon,
} from "assets/Icons";

// Style
import Style from "./style.module.scss";

class ShopOrderPillButtons extends React.Component {
  onGetChangeStatusButton = (orderCurrentStatus) => {
    const { orderInfo = {} } = this.props;
    const { orderDeliveryTypeID } = orderInfo;
    return _getChangeStatusButton({
      checkIcon: (
        <div className={Style.checkIcon}>
          <CheckIcon />
        </div>
      ),
      likeIcon: (
        <div className={Style.likeIcon}>
          <LikeIcon />
        </div>
      ),
      onCloseActiveOrder: this.props.onCloseActiveOrder,
      onConfirmCloseOrder: this.props.onShowConfirmCloseOrder,
      onShowEstimatePrepTimeModal: this.props.onShowEstimatePrepTimeModal,
      orderCurrentStatus,
      orderDeliveryTypeID,
    });
  };

  renderActiveOrderButtons = () => {
    const { orderInfo = {} } = this.props;
    const { status = "active" } = orderInfo;
    return (
      <div className={Style.container}>
        {this.renderShowDetailsButton(status)}
        {/* {this.renderPrintButton(status)} */}
        {this.renderChangeStatusBtn(status)}
      </div>
    );
  };

  renderChangeStatusBtn = (orderCurrentStatus) => {
    const { isLoading } = this.props;
    const {
      buttonAction,
      icon,
      loadingText,
      name,
      nextStatus,
      text,
    } = this.onGetChangeStatusButton(orderCurrentStatus);
    return (
      <Button
        className={cx(Style.changeOrderStatusBtn, Style[orderCurrentStatus])}
        loadingText={loadingText}
        name={name}
        onClick={() => !isLoading && buttonAction({ nextStatus })}
        status={isLoading ? "loading" : "active"}
      >
        {icon}
        {text}
      </Button>
    );
  };

  renderPastOrderButtons = () => {
    const { orderInfo = {} } = this.props;
    const { status = "active" } = orderInfo;
    return (
      <div className={Style.container}>
        {this.renderShowDetailsButton(status)}
        {/* {this.renderPrintButton(status)} */}
        {this.renderRefundButton()}
        {/* <Button
          className={Style.optionsButton}
          hasShadow
          name="show actions menu button"
          onClick={this.props.onShowActionsModal}
        >
          <MoreIcon />
        </Button> */}
      </div>
    );
  };

  // renderPrintButton = (orderCurrentStatus) => {
  //   const { allowPrintingOrder = "false" } = this.props.context.shopBasicInfo;
  //   if (orderCurrentStatus === "active") return;
  //   return (
  //     _isTrue(allowPrintingOrder) && (
  //       <Button
  //         className={Style.button}
  //         onClick={this.props.onPrintOrder}
  //         name="reprint order"
  //       >
  //         <div className={Style.icon}>
  //           <PrinterIcon />
  //         </div>
  //         Reprint
  //       </Button>
  //     )
  //   );
  // };

  renderRefundButton = () => (
    <Button
      className={Style.button}
      name="refund order"
      onClick={this.props.onShowRefundModal}
    >
      <div className={Style.icon}>
        <RefundIcon />
      </div>
      Refund
    </Button>
  );

  renderShowDetailsButton = (orderStatus) => {
    const { showExpandedInfo } = this.props;
    return (
      <Button
        className={Style.showOrderDetailsButton}
        name="show order details"
        onClick={this.props.onShowExpandedInfo}
      >
        <div className={Style.showDetailsIcon}>
          {showExpandedInfo ? <MinusIcon /> : <PlusIcon />}
        </div>
        Details
      </Button>
    );
  };

  render() {
    return this.props.orderType === "active"
      ? this.renderActiveOrderButtons()
      : this.renderPastOrderButtons();
  }
}

ShopOrderPillButtons.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      allowPrintingOrder: PropTypes.any,
    }).isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCloseActiveOrder: PropTypes.func.isRequired,
  onPrintOrder: PropTypes.func.isRequired,
  onShowActionsModal: PropTypes.func.isRequired,
  onShowConfirmCloseOrder: PropTypes.func.isRequired,
  onShowEstimatePrepTimeModal: PropTypes.func.isRequired,
  onShowExpandedInfo: PropTypes.func.isRequired,
  onShowRefundModal: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({
    status: PropTypes.oneOf(["active", "confirmed", "closed"]).isRequired,
  }).isRequired,
  orderType: PropTypes.oneOf(["active", "past"]).isRequired,
  showExpandedInfo: PropTypes.bool,
};

ShopOrderPillButtons.defaultProps = {
  isLoading: false,
  showExpandedInfo: false,
};

export default withContext(MerchantInterfaceConsumer)(ShopOrderPillButtons);
