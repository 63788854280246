// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import ConfirmDeleteStyle from "components/Modals/style.module.scss";

// Fields
import { Button, CenterModal, Input } from "fields";

const { TextInput } = Input;

class ConfirmDeleteMenuModal extends Component {
  state = { menuName: "" };

  onInputChange = (menuName) => this.setState({ menuName });

  onGetSubmitBtnStatus = () =>
    this.state.menuName.toLowerCase() === this.props.menuName.toLowerCase()
      ? "active"
      : "inactive";

  onSubmit = (e) => {
    e && e.preventDefault();
    this.props.onCloseModal();
    this.props.onDeleteMenu();
  };

  render() {
    return (
      <CenterModal
        contentLabel="Confirm delete menu modal"
        modalBoxClassname={ConfirmDeleteStyle.confirmDeleteModalBox}
        contentContainerClassname={
          ConfirmDeleteStyle.confirmDeleteModalContainer
        }
        onCloseModal={this.props.onCloseModal}
      >
        <div className={ConfirmDeleteStyle.confirmDeleteTitle}>
          Re-type <strong>{this.props.menuName}</strong> to delete
        </div>
        <form onSubmit={this.onSubmit}>
          <TextInput
            name="confirm delete group"
            value={this.state.menuName}
            onChange={this.onInputChange}
          />
          <Button
            className={ConfirmDeleteStyle.confirmDeleteButton}
            name="Comfirm delete menu button"
            status={this.onGetSubmitBtnStatus()}
            type="submit"
          >
            Delete Away
          </Button>
        </form>
      </CenterModal>
    );
  }
}

ConfirmDeleteMenuModal.propTypes = {
  menuName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDeleteMenu: PropTypes.func.isRequired,
};

export default ConfirmDeleteMenuModal;
