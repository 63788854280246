// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { EyeIcon, PencilIcon } from "assets/Icons";

class WebsiteEditOverlay extends React.Component {
  state = { showOverlay: false };

  onMouseEnter = () => this.setState({ showOverlay: true });

  onMouseLeave = () => this.setState({ showOverlay: false });

  render() {
    const { classNames = {} } = this.props;
    return (
      <div
        className={cx(Style.container, classNames.container)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.state.showOverlay && (
          <div className={Style.overlay}>
            <div className={Style.content}>
              <Button
                className={cx(Style.button, classNames.button)}
                hasShadow
                name="edit button"
                onClick={this.props.onShowEditModal}
              >
                <PencilIcon className={Style.pencilIcon} /> Edit
              </Button>
              <Button
                className={cx(Style.button, classNames.button)}
                hasShadow
                name="view button"
                onClick={this.onMouseLeave}
              >
                <EyeIcon className={Style.eyeIcon} />
                View
              </Button>
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

WebsiteEditOverlay.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.exact({
    container: PropTypes.string,
    button: PropTypes.string,
  }),
  onShowEditModal: PropTypes.func.isRequired,
};

export default WebsiteEditOverlay;
