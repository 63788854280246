// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { toast } from "react-toastify";

// Lib
import { Functions } from "lib";

// Style
import Style from "./style.module.scss";

class PayoutNotConnectedAlert extends React.Component {
  alert = null;

  onClickButton = () => {
    this.alert = null;
    toast.dismiss();
    this.props.onShowFinances("paymentInfo");
  };

  renderMessage = () => (
    <div className={Style.message}>
      <div>Your payout is missing.</div>
      <button onClick={this.onClickButton}>Connect Now</button>
    </div>
  );

  onShowNotification = () => {
    const { ShowConfirmNotif } = Functions;
    if (!this.alert) {
      this.alert = ShowConfirmNotif({
        message: this.renderMessage(),
        options: {
          alertContainerClass: Style.alert,
          autoClose: false,
          draggable: false,
          closeOnClick: false,
        },
        type: "warning",
      });
    }
    return null;
  };

  render() {
    const stripeAccountID = _get(
      this.props,
      "shopBasicInfo.stripeConnectInfo.stripeAccountID"
    );
    return !stripeAccountID && this.onShowNotification();
  }
}

PayoutNotConnectedAlert.propTypes = {
  onShowFinances: PropTypes.func.isRequired,
  shopBasicInfo: PropTypes.shape().isRequired,
};

export default PayoutNotConnectedAlert;
