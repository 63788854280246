// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import Style from "components/PreparationSections/style.module.scss";

// Field
import { ExpansionPanel, RadioButton } from "fields";

// Lib
import { Constants } from "lib";

class AverageTimeModule extends React.Component {
  onSelectOption = (optionID) =>
    this.props.onChangePickUpInfo(
      set(this.props.pickUpInfo, "averagePreparationTimeID", optionID)
    );

  renderPreparationTimes = () => {
    const { AVERAGE_PREPARATION_TIMES: preparationTimes } = Constants;
    const { averagePreparationTimeID } = this.props.pickUpInfo;
    return Object.entries(preparationTimes).map((entry) => {
      const { label } = entry[1];
      return (
        <div key={entry[0]} style={{ margin: "0 25px 20px 0" }}>
          <RadioButton
            checked={averagePreparationTimeID === entry[0]}
            id={entry[0]}
            label={label}
            onClick={this.onSelectOption}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <ExpansionPanel
        moduleTitle="Average Preparation Time"
        moduleDescription="Let us know how long it usually takes to prepare a pickup order, and we’ll use this information to improve pickup accuracy."
        showExpandableContent
      >
        <div className={Style.radioButtons}>
          {this.renderPreparationTimes()}
        </div>
      </ExpansionPanel>
    );
  }
}

AverageTimeModule.propTypes = {
  onChangePickUpInfo: PropTypes.func.isRequired,
  pickUpInfo: PropTypes.shape({ averagePreparationTimeID: PropTypes.string }),
};

AverageTimeModule.defaultProps = {
  pickUpInfo: {},
};

export default AverageTimeModule;
