// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import _isEmpty from "lodash.isempty";
import cx from "classnames";

import { DEFAULT_FILTER_VALUES, FILTER_ITEMS_CRITERIAS } from "./constants";

import AddButton from "../AddButton";
import FilterButton from "../FilterButton";
import { _filter } from "../functions";

// Style
import Style from "../style.module.scss";

// Components
import { MenusListOfElements, Modals } from "components";

// Fields
import { LoadingSpinner, PageMsg } from "fields";

// Lib
import { Functions, Services } from "lib";

const { SortMenuItems } = Functions.FoodMenuFuncs;
const { Merchants } = Services;
const { GetShopAllItems } = Merchants.GetRequests;

class AllItems extends React.Component {
  state = { allItems: {}, selected_filter_values: DEFAULT_FILTER_VALUES };

  componentDidMount = () => this.onGetAllItems();

  componentWillUnmount = () => this.setState({ isGettingItems: false });

  onGetAllItems = async () => {
    this.setState({ isGettingItems: true });
    try {
      const { allItems = {} } = await GetShopAllItems({
        shopID: this.props.shopID,
      });
      this.setState({ allItems }, this.onUpdateFilterResults);
    } catch {
    } finally {
      this.setState({ isGettingItems: false });
    }
  };

  onUpdateAllItems = ({ itemID = "", itemInfo = {} }) => {
    const { allItems = {} } = this.state;
    if (!itemID) return;
    else if (_isEmpty(itemInfo))
      this.setState(
        { allItems: del(allItems, itemID) },
        this.onUpdateFilterResults
      );
    else {
      this.setState(
        { allItems: set(allItems, itemID, itemInfo) },
        this.onUpdateFilterResults
      );
    }
  };

  onUpdateSelectedFilterValues = (selected_filter_values) =>
    this.setState({ selected_filter_values }, this.onUpdateFilterResults);

  onUpdateFilterResults = () =>
    this.setState({
      filter_results: _filter({
        list: this.state.allItems,
        filter_criterias: FILTER_ITEMS_CRITERIAS,
        selected_filter_values: this.state.selected_filter_values,
      }),
    });

  renderItems = () => {
    const { filter_results = {} } = this.state;
    return (
      <MenusListOfElements.ListOfMenuItems
        itemProps={{ isInEditMode: true }}
        items={filter_results}
        onUpdateAllItems={this.onUpdateAllItems}
        sortedItemIDs={SortMenuItems(filter_results)}
      />
    );
  };

  renderCreateModal = () => (
    <Modals.MenuItemModal
      onCloseModal={() => this.setState({ showCreateItemModal: false })}
      onUpdateAfterItemCreated={this.onUpdateAllItems}
    />
  );

  renderContent = () => {
    const { filter_results = {} } = this.state;
    const { scroll_y } = this.props;

    const count = Object.keys(filter_results).length;
    return (
      <div>
        <div
          className={cx(Style.headingContainer, {
            [Style.headingContainerEnd]: scroll_y > 50,
          })}
        >
          <h2 className={Style.heading}>
            {count} {count > 1 ? "Items" : "Item"}
          </h2>
          <div
            className={cx(Style.buttons_group, {
              [Style.buttons_group_update]: scroll_y > 50,
            })}
          >
            <AddButton
              onClick={() => this.setState({ showCreateItemModal: true })}
            />
            <FilterButton
              filter_criterias={FILTER_ITEMS_CRITERIAS}
              onUpdateSelectedFilterValues={this.onUpdateSelectedFilterValues}
              results_count={count}
              selected_filter_values={this.state.selected_filter_values}
            />
          </div>
        </div>
        {this.state.showCreateItemModal && this.renderCreateModal()}
        {this.renderItems()}
      </div>
    );
  };

  render() {
    if (this.state.isGettingItems)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for items" />
        </PageMsg>
      );
    return this.renderContent();
  }
}

AllItems.propTypes = {
  shopID: PropTypes.string.isRequired,
};

export default AllItems;
