// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { Calendar } from "react-modern-calendar-datepicker";

// Utils
import {
  _convertDateObjectToString,
  _convertDateStringToObject,
} from "utils/dayjs";

// Style
import Style from "./style.module.scss";

// Components
import { RadioGroup } from "components";

class SelectDateScreen extends React.Component {
  state = { selected_date_object: null };

  onSelectDate = (date_object = {}) =>
    this.setState({ selected_date_object: date_object }, () =>
      this.props.onSelectDate(_convertDateObjectToString({ date_object }))
    );

  renderCalendar = () => (
    <Calendar
      calendarClassName={Style.calendar}
      colorPrimary="#006AFF"
      minimumDate={_convertDateStringToObject({
        date_string: this.props.today_date,
      })}
      onChange={this.onSelectDate}
      value={this.state.selected_date_object}
    />
  );

  renderOtherOption = () => {
    const { selected_date, today_date } = this.props;
    return (
      <div className={Style.selected_date_input}>
        Other
        <div className={Style.selected_date_text}>
          {selected_date === today_date ? "" : selected_date}
        </div>
      </div>
    );
  };

  render() {
    const { selected_date, today_date } = this.props;
    return (
      <>
        <RadioGroup
          heading="Select a date"
          onClick={(option_id) =>
            this.props.onSelectDate(option_id === "today" ? today_date : "")
          }
          optionClassName={Style.option}
          options={{
            today: { label: `Today (${today_date})` },
            other: { label: this.renderOtherOption() },
          }}
          option_props={{ has_border: true }}
          required
          selectedOptionId={today_date === selected_date ? "today" : "other"}
        />
        {today_date !== selected_date && this.renderCalendar()}
      </>
    );
  }
}

SelectDateScreen.defaultProps = {
  onSelectDate: PropTypes.func.isRequired,
  selected_date: PropTypes.string,
  today_date: PropTypes.string.isRequired,
};

export default SelectDateScreen;
