// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import Style from "components/PreparationSections/style.module.scss";

// Field
import { Checkbox, ExpansionPanel, TimePicker } from "fields";

// Lib
import { Constants } from "lib";

// Lib
import { ArrowIcon } from "assets/Icons";

class PickUpHoursModule extends React.Component {
  state = {};

  _shouldUseOpenHoursStatus = () => {
    const { pickUpHours = {} } = this.props.pickUpInfo;
    const { useOpenHours = false } = pickUpHours;
    return useOpenHours;
  };

  onChangeEndTime = ({ day, utc_time }) => {
    this.props.onChangePickUpInfo(
      set(this.props.pickUpInfo, `pickUpHours.${day}.endAt`, utc_time)
    );
  };

  onChangeStartTime = ({ day, utc_time }) => {
    this.props.onChangePickUpInfo(
      set(this.props.pickUpInfo, `pickUpHours.${day}.startAt`, utc_time)
    );
  };

  onSelectClosedAllDay = ({ day, isClosedAllDay }) => {
    this.props.onChangePickUpInfo(
      set(
        this.props.pickUpInfo,
        `pickUpHours.${day}.isClosedAllDay`,
        isClosedAllDay ? false : true
      )
    );
  };

  onUseOpenHours = () =>
    this.props.onChangePickUpInfo(
      set(
        this.props.pickUpInfo,
        `pickUpHours.useOpenHours`,
        this._shouldUseOpenHoursStatus() ? false : true
      )
    );

  renderTimePickers = ({ day, endAt = "", startAt = "" }) => {
    const { timeZone } = this.props;
    return (
      <div className={Style.timePickers}>
        <div className={Style.fromField}>
          <TimePicker
            defaultTime={startAt}
            name={`Start time picker for ${day}`}
            onChange={(utc_time) => this.onChangeStartTime({ day, utc_time })}
            timeZone={timeZone}
          />
        </div>
        <ArrowIcon className={Style.arrowIcon} />
        <div className={Style.toField}>
          <TimePicker
            defaultTime={endAt}
            name={`End time picker for ${day}`}
            onChange={(utc_time) => this.onChangeEndTime({ day, utc_time })}
            timeZone={timeZone}
          />
        </div>
      </div>
    );
  };

  renderWeekDays = () => {
    const { DAYS_IN_A_WEEK } = Constants;
    const { pickUpHours = {} } = this.props.pickUpInfo;
    return Object.keys(DAYS_IN_A_WEEK).map((day) => {
      const { isClosedAllDay, endAt, startAt } = pickUpHours[day] || {};
      return (
        <div className={Style.weekday} key={day}>
          <div className={Style.weekdayLabel}>{day}</div>
          {!isClosedAllDay && this.renderTimePickers({ day, endAt, startAt })}
          <div>
            <Checkbox
              checked={isClosedAllDay}
              id={`Closed all day on ${day}`}
              label="Closed"
              onClick={() => this.onSelectClosedAllDay({ day, isClosedAllDay })}
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const useOpenHours = this._shouldUseOpenHoursStatus();
    return (
      <ExpansionPanel
        moduleTitle="Pickup Hours"
        moduleDescription="Decide when your customers can submit pickup orders. Ensure that the start time is before end time to maintain good service for the customers."
        showExpandableContent
      >
        <div style={{ marginBottom: "30px" }}>
          <Checkbox
            checked={useOpenHours}
            id="Use open hours for pickup hours"
            label="Same as open hours"
            onClick={this.onUseOpenHours}
          />
        </div>
        {!useOpenHours && (
          <div className={Style.days}>{this.renderWeekDays()}</div>
        )}
      </ExpansionPanel>
    );
  }
}

PickUpHoursModule.propTypes = {
  onChangePickUpInfo: PropTypes.func.isRequired,
  openHours: PropTypes.object,
  pickUpInfo: PropTypes.shape({
    pickUpHours: PropTypes.object,
  }),
  timeZone: PropTypes.string.isRequired,
};

PickUpHoursModule.defaultProps = {
  openHours: {},
  pickUpInfo: {},
};

export default PickUpHoursModule;
