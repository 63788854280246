// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import { REFUND_REASONS } from "./constants";

// Style
import Style from "./style.module.scss";

// Components
import { RadioGroup } from "components";

// Fields
import { Input } from "fields";

class ReasonsList extends React.Component {
  onChangeReasonInput = (text) => {
    if (text.length < 50)
      this.props.onSelectReason(set(this.props.selectedReason, "text", text));
  };

  onSelectReason = (optionId) => {
    const { label } = REFUND_REASONS[optionId];
    this.props.onSelectReason({
      id: optionId,
      text: optionId !== "other" ? label : "",
    });
  };

  renderReasonInput = (text) => (
    <div className={Style.input}>
      <Input.TextInput
        label="Other Reason"
        name="refund reason"
        onChange={this.onChangeReasonInput}
        value={text}
      />
    </div>
  );

  render() {
    const { readOnly, selectedReason = {} } = this.props;
    const { id = "", text } = selectedReason;
    return (
      <div>
        <RadioGroup
          heading="Select a reason"
          onClick={this.onSelectReason}
          optionClassName={Style.option}
          options={REFUND_REASONS}
          readOnly={readOnly}
          required
          selectedOptionId={id}
        />
        {id === "other" && this.renderReasonInput(text)}
      </div>
    );
  }
}

ReasonsList.propTypes = {
  onSelectReason: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  selectedReason: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
};

ReasonsList.defaultProps = {
  readOnly: false,
};

export default ReasonsList;
