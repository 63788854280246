// BUTI DINERS, INC. All right Reserved ©

import _get from "lodash.get";
import { formatToTimeZone } from "date-fns-timezone";
import { Constants } from "lib";

// Utils
import { _formatPhoneNumber } from "utils";

// ------------------------------------------
// Create confirm message for customer after the order is accepted
const _createConfirmMsgForCustomer = ({
  customerName,
  deliveryDetails = {},
  preparationTime,
  shopBasicInfo,
  time_order,
}) => {
  const tracking_url =
    _get(deliveryDetails, "courierConfirmation.tracking_url") || "";
  const tracking_text = !tracking_url
    ? ""
    : ` Click the link below to track your order:\r\n${tracking_url}`;
  const { name: shopName, phoneNumber = "" } = shopBasicInfo;
  const shopNumberText = !phoneNumber
    ? ""
    : ` Call ${_formatPhoneNumber(phoneNumber)} if you have any questions.`;
  const estimateTime =
    preparationTime === "Other"
      ? ""
      : `Your order will be ready in: ${preparationTime}.`;
  return `Hi ${customerName}, ${shopName} confirmed your order on ${time_order}! ${estimateTime}${shopNumberText}${tracking_text}`;
};

// ------------------------------------------
// Create a text for customer when the order is ready
const _createOrderReadyMsgForCustomer = ({
  orderDeliveryTypeID,
  shopName,
  time_order,
}) => {
  const message =
    orderDeliveryTypeID === "deliver"
      ? `Your order at ${shopName} on ${time_order} is on the way!`
      : `Your order at ${shopName} on ${time_order} is almost ready!`;
  return `${message} Ignore this msg if you received your order.`;
};
// format order time
const formatOrderTime = (timeStamp, timeZone) => {
  const DATETIME_FORMAT = `${Constants.DATE_FORMAT} ${Constants.TIME_FORMAT}`;
  const DEFAULT_TIMEZONE = Constants.DEFAULT_TIMEZONE;

  return formatToTimeZone(timeStamp, DATETIME_FORMAT, {
    timeZone: timeZone || DEFAULT_TIMEZONE,
  });
};

export {
  _createConfirmMsgForCustomer,
  _createOrderReadyMsgForCustomer,
  formatOrderTime,
};
