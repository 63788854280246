// BUTI DINERS, INC. All right Reserved ©

import React from "react";

import RegularHours from "./RegularHours";
import SpecialHours from "./SpecialHours";

// Fields
import { Box } from "fields";

class InStore extends React.Component {
  state = { openHours: {} };

  render() {
    return (
      <div>
        <Box
          description="Add special hours or closures for special events or other exceptional events. This will temporarily replace your regular menu hours"
          header="Special Hours"
        >
          <SpecialHours />
        </Box>
        <Box
          description="These are the hours your store is available on SKIPLI"
          header="Open Hours"
        >
          <RegularHours />
        </Box>
      </div>
    );
  }
}

InStore.propTypes = {};

export default InStore;
