// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import CheckboxAndRadioButton from "./index";

class CheckBox extends React.Component {
  render() {
    return <CheckboxAndRadioButton {...this.props} kind="checkbox" />;
  }
}

CheckBox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  description: PropTypes.any,
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func,
  checkboxClassName: PropTypes.string,
  checkIconClassName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CheckBox.defaultProps = {
  className: "",
  checked: false,
  checkboxClassName: "",
  checkIconClassName: "",
  labelClassName: "",
  onClick: () => {},
  readOnly: false,
};

export default CheckBox;
