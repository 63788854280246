// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import ModuleStyle from "../style.module.scss";

// Icons
import { ArrowIcon } from "assets/Icons";

// Fields
import { ComingSoonButton } from "fields";

class ImagePositionControllerModule extends React.Component {
  state = { distance: 5 };

  componentDidUpdate(prevProps) {
    if (this.props.imagePosition !== prevProps.imagePosition)
      this.onCalculateImagePosition(this.props.imagePosition);
  }

  componentDidMount = () =>
    this.onCalculateImagePosition(this.props.imagePosition);

  onCalculateImagePosition = (imagePosition = "") => {
    const sheryl = imagePosition.split(" ");
    this.setState({
      imagePositionArray: [parseInt(sheryl[0]), parseInt(sheryl[1])]
    });
  };

  onChangeDistance = distance =>
    this.setState({ distance: parseInt(distance) });

  onChangeImagePosition = () => {
    const { imagePositionArray } = this.state;
    this.props.onChangeImagePosition(imagePositionArray.join("px ") + "px");
  };

  onMoveImageHorizontally = (distance = 0) => {
    const { imagePositionArray } = this.state;
    imagePositionArray[0] = imagePositionArray[0] + distance;
    this.setState({ imagePositionArray }, this.onChangeImagePosition);
  };

  onMoveImageVertically = (distance = 0) => {
    const { imagePositionArray } = this.state;
    imagePositionArray[1] = imagePositionArray[1] + distance;
    this.setState({ imagePositionArray }, this.onChangeImagePosition);
  };

  onToggleModuleContentVisibility = () =>
    this.setState({ showModuleContent: !this.state.showModuleContent });

  renderModuleContent = () => {
    return <ComingSoonButton />;
    // const { distance } = this.state;
    // return (
    //   <div className={Style.controller}>
    //     <button
    //       className={Style.button}
    //       onClick={() => this.onMoveImageHorizontally(-distance)}
    //     >
    //       <ArrowIcon className={Style.left} />
    //     </button>
    //     <div>
    //       <button
    //         className={Style.button}
    //         onClick={() => this.onMoveImageVertically(-distance)}
    //       >
    //         <ArrowIcon className={Style.up} />
    //       </button>
    //       <div className={Style.distanceInput}>
    //         <Input.TextInput
    //           fieldClassname={Style.fieldClassname}
    //           inputClassname={Style.inputClassname}
    //           name="distance input"
    //           onChange={this.onChangeDistance}
    //           value={distance || ""}
    //         />
    //       </div>
    //       <button
    //         className={Style.button}
    //         onClick={() => this.onMoveImageVertically(distance)}
    //       >
    //         <ArrowIcon className={Style.down} />
    //       </button>
    //     </div>
    //     <button
    //       className={Style.button}
    //       onClick={() => this.onMoveImageHorizontally(distance)}
    //     >
    //       <ArrowIcon />
    //     </button>
    //   </div>
    // );
  };

  renderModuleTitle = () => (
    <div className={ModuleStyle.moduleTitleContainer}>
      <h3 className={ModuleStyle.moduleTitle}>Image Position</h3>
      <ArrowIcon
        className={cx(
          ModuleStyle.arrowIcon,
          this.state.showModuleContent && ModuleStyle.flip
        )}
      />
    </div>
  );

  render() {
    return (
      <div className={ModuleStyle.moduleContainer}>
        <button onClick={this.onToggleModuleContentVisibility}>
          {this.renderModuleTitle()}
          <p className={ModuleStyle.moduleDescription}>
            Adjust the position of the image to focus on the item you are
            selling
          </p>
        </button>
        {this.state.showModuleContent && this.renderModuleContent()}
      </div>
    );
  }
}

ImagePositionControllerModule.propTypes = {
  imagePosition: PropTypes.string,
  onChangeImagePosition: PropTypes.func
};

ImagePositionControllerModule.defaultProps = {
  imagePosition: "0px 0px",
  onChangeImagePosition: () => {}
};

export default ImagePositionControllerModule;
