// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import WebsiteBuilderEditStyle from "../style.module.scss";

// Fields
import { Box, Input, ResponsiveModal } from "fields";

const CONTACT_INFO_FIELDS = {
  address: { label: "Address", type: "text" },
  email: { label: "Email", type: "email" },
  phoneNumber: { label: "Phone Number", type: "text" },
};

const SOCIAL_MEDIA_FIELDS = {
  instagram: { label: "Instagram" },
  facebook: { label: "Facebook" },
  twitter: { label: "Twitter" },
  yelp: { label: "Yelp" },
  youtube: { label: "Youtube" },
};

class ContactUsEditModal extends React.Component {
  onChange = ({ fieldId = "", fieldValue }) => {
    if (!fieldId) return;
    const { contactUs = {} } = this.props;
    this.props.onChange(set(contactUs, fieldId, fieldValue));
  };

  renderContactInfoFields = () => {
    const { contactUs = {} } = this.props;
    return Object.keys(CONTACT_INFO_FIELDS).map((fieldId) => {
      const { label, readOnly = false, type } = CONTACT_INFO_FIELDS[fieldId];
      return (
        <div className={WebsiteBuilderEditStyle.fieldContainer} key={fieldId}>
          <Input.TextInput
            label={label}
            name={label}
            onChange={(value) => this.onChange({ fieldId, fieldValue: value })}
            readOnly={readOnly}
            type={type}
            value={contactUs[fieldId]}
          />
        </div>
      );
    });
  };

  renderModalContent = () => (
    <div className={WebsiteBuilderEditStyle.modalContent}>
      <Box header="Contact Information">{this.renderContactInfoFields()}</Box>
      <Box header="Social Media">{this.renderSocialMediaFields()}</Box>
    </div>
  );

  renderSocialMediaFields = () => {
    const { contactUs = {} } = this.props;
    return Object.keys(SOCIAL_MEDIA_FIELDS).map((fieldId) => {
      const { label } = SOCIAL_MEDIA_FIELDS[fieldId];
      return (
        <div className={WebsiteBuilderEditStyle.fieldContainer} key={fieldId}>
          <Input.TextInput
            label={label}
            name={label}
            onChange={(value) => this.onChange({ fieldId, fieldValue: value })}
            value={contactUs[fieldId]}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: WebsiteBuilderEditStyle.modal }}
        contentLabel="Edit website contact us modal"
        headerProps={{ header: "Contact Us" }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderModalContent()}
      </ResponsiveModal>
    );
  }
}

ContactUsEditModal.propTypes = {
  contactUs: PropTypes.shape({
    address: PropTypes.string,
    email: PropTypes.string,
    instagram: PropTypes.string,
    facebook: PropTypes.string,
    phoneNumber: PropTypes.string,
    youtube: PropTypes.string,
    twitter: PropTypes.string,
    yelp: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ContactUsEditModal;
