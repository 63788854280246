// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import ModuleStyle from "../style.module.scss";

// Components
import { MenusListOfElements } from "components";

// Fields
import { Box, Button } from "fields";

// Icons
import { CloseIcon, PlusIcon } from "assets/Icons";

// Lib
import { Functions } from "lib";

class AddedModifiersModule extends React.Component {
  state = { fullyLoadedModifiers: {} };

  componentDidMount = () =>
    this.onGetInfoForAddedModifiers(this.props.modifiers);

  componentDidUpdate(prevProps) {
    const { modifiers } = this.props;
    const oldModifiersCount = Object.keys(prevProps.modifiers).length;
    const newModifiersCount = Object.keys(modifiers).length;
    if (newModifiersCount > oldModifiersCount)
      this.onGetInfoForAddedModifiers(modifiers);
    else if (newModifiersCount < oldModifiersCount)
      this.setState({
        fullyLoadedModifiers: _pick(
          this.state.fullyLoadedModifiers,
          Object.keys(modifiers)
        ),
      });
  }

  onClickAddButton = (e) => {
    e && e.preventDefault();
    this.props.onClickAddButton();
  };

  onGetInfoForAddedModifiers = async (modifiers) => {
    const { GetInformationForModifiers } = Functions.FoodMenuFuncs;
    const params = { modifiers, shopID: this.props.context.shopID };
    const fullyLoadedModifiers = await GetInformationForModifiers(params);
    this.setState({ fullyLoadedModifiers });
  };

  onRemoveModifier = (modifierID) => this.props.onRemoveModifier(modifierID);

  renderAddedModifiers = () => {
    const { fullyLoadedModifiers } = this.state;
    return (
      Object.keys(fullyLoadedModifiers).length > 0 && (
        <MenusListOfElements.ListOfModifiers
          modifierHelperButton={(modifierID) => (
            <CloseIcon
              className={ModuleStyle.removeButton}
              onClick={() => this.onRemoveModifier(modifierID)}
            />
          )}
          modifiers={fullyLoadedModifiers}
        />
      )
    );
  };

  render() {
    const { modifiers = {} } = this.props;
    return (
      <Box
        description="Add modifier to a modifier group to allow customers to use toppings, sides and more to customize item"
        header={`Modifiers (${Object.keys(modifiers).length})`}
      >
        <Button
          className={ModuleStyle.addButton}
          color="info"
          name="add modifiers to modifier group"
          onClick={this.onClickAddButton}
        >
          <PlusIcon />
          Add Modifiers
        </Button>
        {this.renderAddedModifiers()}
      </Box>
    );
  }
}

AddedModifiersModule.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  modifiers: PropTypes.object,
  onClickAddButton: PropTypes.func,
  onRemoveModifier: PropTypes.func,
  showModifierHelperButton: PropTypes.bool,
};

AddedModifiersModule.defaultProps = {
  modifiers: {},
  onClickAddButton: () => {},
  onRemoveModifier: () => {},
  showModifierHelperButton: true,
};

export default withContext(MerchantInterfaceConsumer)(AddedModifiersModule);
