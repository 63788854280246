// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import io from "socket.io-client";
import { del, set } from "object-path-immutable";
import { toast } from "react-toastify";

import PageContentContainer from "./PageContentContainer";

import {
  _checkPrintersConnectivity,
  _getDigitalCertificate,
  _getQZSignature,
  _verifyShopBasicInfo,
} from "./HelperFunctions";

// Views
import {
  Analytics,
  CombineThirdPartyOrders,
  CustomerSelfCheckIn,
  GuestReviews,
  Help,
  Marketing,
  MenusManagement,
  PaymentInfo,
  PayoutsForConnectedAccount,
  PersonnelProfile,
  Preparation,
  Settings,
  ShopInfo,
  ShopOrders,
  SkipliDev,
  Website,
} from "views";

// Components
import { AdminHeader, PayoutNotConnectedAlert } from "components";

// Context
import { MerchantInterfaceProvider } from "context";

// Fields
import { Alarm } from "fields";

// Style
import Style from "./style.module.scss";

// Lib
import { Functions, Services } from "lib";

const DEFAULT_ACTIVE_NAVBAR_ITEM = "orders";

class AdminViewContainer extends React.Component {
  audioContext = new AudioContext();
  skipliDashboardSocket = io(Services.SKIPLI_DASHBOARD_SOCKET_IO_URL);
  socket = io(Services.SOCKET_IO_URL);
  containerRef = React.createRef();
  confirmNotif = null;
  state = {
    activeOrders: {},
    addedPrinters: {},
    activeNavbarItemId:
      this.props.activeNavbarItemId || DEFAULT_ACTIVE_NAVBAR_ITEM,
    isConnectingToPrintServer: true,
    isQZSocketConnected: false,
    isSocketConnected: true,
    scroll_y: 0,
    shopBasicInfo: this.props.shopBasicInfo,
  };

  handleScroll = () =>
    this.setState({ scroll_y: this.containerRef.current.scrollTop });

  componentDidMount() {
    this.containerRef.current.addEventListener("scroll", this.handleScroll);
    // Check the socket and get updates
    if (!this.socket) this.setState({ isSocketConnected: false });
    this.onCheckUpdatesFromSocket();

    // Check if guest_checkin_mode is activated
    const { LocalStorage } = Functions;
    const { getItemFromStorage } = LocalStorage;
    const is_guest_checkin_activated = getItemFromStorage(
      "is_guest_checkin_activated"
    );
    if (is_guest_checkin_activated) {
      this.setState({
        activeNavbarItemId: "customerSelfCheckIn",
        isPlayingAlarm: false,
      });
    } else {
      this.onGetActiveOrders();
    }

    // Check if allow printing order and initialize QZ
    // const { allowPrintingOrder = "false", name } = this.state.shopBasicInfo;
    // if (allowPrintingOrder === "true") this.onInitializeQZ();
    // document.title = name;
    // Check the new orders alarm
    const alarmStatus = this.audioContext.state;
    this.setState(
      { showActivateAlarmModal: alarmStatus === "running" ? false : true },
      () => this.onChangeAlarmStatus(alarmStatus)
    );
  }

  componentWillUnmount = () => {
    this.containerRef.current.removeEventListener("scroll", this.handleScroll);
  };

  onActivateAlarm = () => {
    this.setState({ showActivateAlarmModal: false }, () => {
      const { WebAudioTouchUnlock } = Functions;
      WebAudioTouchUnlock(this.audioContext).then(
        (unlocked) =>
          this.onChangeAlarmStatus(unlocked ? "running" : "suspended"),
        () => this.onChangeAlarmStatus("suspended")
      );
    });
  };

  onAddNewOrder = ({ orderDetails = {}, orderID = "" }) => {
    const { OrdersManagement } = Functions;
    const { RankActiveOrders } = OrdersManagement;
    if (orderID && Object.keys(orderDetails).length > 0) {
      this.setState({ isPlayingAlarm: true }, () => {
        const { activeOrders = {} } = this.state;
        const result = set(activeOrders, `${orderID}`, orderDetails);
        this.setState({ activeOrders: RankActiveOrders(result) });
      });
    }
  };

  onChangeStatusOfActiveOrder = ({ nextStatus = "", orderID = "" }) => {
    if (orderID && nextStatus) {
      this.setState({ isPlayingAlarm: false }, () => {
        const { activeOrders = {} } = this.state;
        if (activeOrders[orderID])
          this.setState({
            activeOrders: set(activeOrders, `${orderID}.status`, nextStatus),
          });
      });
    }
  };

  onCloseAnOrder = (orderID = "") => {
    if (orderID) {
      const { activeOrders = {} } = this.state;
      this.setState({ activeOrders: del(activeOrders, orderID) });
    }
  };

  onConnectToPrintServer = () => {
    if (window.qz.websocket.isActive()) return;
    this.setState({ isConnectingToPrintServer: true }, () => {
      window.qz.websocket
        .connect()
        .then(() => {
          this.setState(
            { isQZSocketConnected: true },
            this.onGetShopAddedPrinters
          );
        })
        .catch(() => this.setState({ isQZSocketConnected: false }))
        .finally(() => this.setState({ isConnectingToPrintServer: false }));
    });
  };

  onCreatePrinterQZConfigs = async (printers = {}) =>
    await Object.keys(printers).reduce(async (previousPromise, printerName) => {
      const result = await previousPromise;
      const config = await window.qz.configs.create(printerName, {
        altPrinting: true,
      });
      return set(result, `${printerName}`, config);
    }, Promise.resolve({}));

  onChangeAlarmStatus = (alarmStatus) => this.setState({ alarmStatus });

  onChangeGlobalNavItem = (newItemId) =>
    this.setState({ activeNavbarItemId: newItemId });

  onCheckUpdatesFromSocket = () => {
    const { shopID } = this.props;
    this.socket.on(`${shopID}-add-order-to-active-orders`, this.onAddNewOrder);
    this.socket.on(`${shopID}-change-status-of-active-order`, (data) =>
      this.onChangeStatusOfActiveOrder(data)
    );
    this.skipliDashboardSocket.on(
      "new-version-of-merchant-dashboard-available",
      this.onShowNewVersionNotification
    );
    this.socket.on(`${shopID}-close-an-order`, this.onCloseAnOrder);
  };

  onGetActiveOrders = async () => {
    const { GetShopOrders } = Services.Merchants.GetRequests;
    const { OrdersManagement } = Functions;
    const { RankActiveOrders } = OrdersManagement;
    const { orders } = await GetShopOrders({
      ordersType: "active",
      shopID: this.props.shopID,
    });
    this.setState({ activeOrders: RankActiveOrders(orders) });
  };

  onGetShopAddedPrinters = async () => {
    const { GetShopActivePrinters } = Services.Merchants.GetRequests;
    this.setState({ isFindingShopAddedPrinters: true });
    const { addedPrinters = {} } = await GetShopActivePrinters({
      shopID: this.props.shopID,
    });
    const printers = await _checkPrintersConnectivity(addedPrinters);
    const printerQZConfigs = await this.onCreatePrinterQZConfigs(printers);
    this.setState({
      addedPrinters: printers,
      isFindingShopAddedPrinters: false,
      printerQZConfigs,
    });
  };

  onInitializeQZ = async () => {
    await _getDigitalCertificate();
    await _getQZSignature();
    this.onConnectToPrintServer();
  };

  onShowNewVersionNotification = () =>
    Functions.ShowConfirmNotif({
      message: (
        <div
          className={Style.newVersionMsg}
          onClick={() => window.location.reload()}
        >
          A new version of Skipli is available!
          <button className={Style.refreshButton}>Refresh</button>
        </div>
      ),
      options: { autoClose: false, alertContainerClass: Style.newVersionAlert },
    });

  onUpdateAddedPrinters = (newAddedPrinters) =>
    this.setState({ addedPrinters: newAddedPrinters });

  onUpdateShopBasicInfo = (newShopBasicInfo) => {
    try {
      if (_verifyShopBasicInfo({ shopInfo: newShopBasicInfo }))
        this.setState({ shopBasicInfo: newShopBasicInfo });
    } catch (error) {
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: error,
        type: "error",
      });
    }
  };

  renderGlobalNavbar = () => {
    const { shopBasicInfo } = this.state;
    return (
      <MerchantInterfaceProvider
        value={{
          activeOrders: this.state.activeOrders,
          onChangeGlobalNavItem: this.onChangeGlobalNavItem,
          shopBasicInfo,
          shopID: this.props.shopID,
        }}
      >
        <AdminHeader
          activeNavbarItemId={this.state.activeNavbarItemId}
          personnel={this.props.personnel}
        />
      </MerchantInterfaceProvider>
    );
  };

  renderMenusManagement = () => (
    <MerchantInterfaceProvider
      value={{
        personnel: this.props.personnel,
        shopID: this.props.shopID,
        shopBasicInfo: this.state.shopBasicInfo,
      }}
    >
      <MenusManagement
        shopID={this.props.shopID}
        scroll_y={this.state.scroll_y}
      />
    </MerchantInterfaceProvider>
  );

  renderPayoutNotConnected = () => {
    if (
      ["menusManagement", "paymentInfo"].includes(this.state.activeNavbarItemId)
    ) {
      toast.dismiss();
      return null;
    }
    return (
      <PayoutNotConnectedAlert
        onShowFinances={this.onChangeGlobalNavItem}
        shopBasicInfo={this.state.shopBasicInfo}
      />
    );
  };

  renderShopOrders = () => (
    <MerchantInterfaceProvider
      value={{
        activeOrders: this.state.activeOrders,
        addedPrinters: this.state.addedPrinters,
        onChangeActiveOrders: (activeOrders = {}) =>
          this.setState({ activeOrders }),
        onChangeGlobalNavItem: this.onChangeGlobalNavItem,
        onConnectToPrintServer: this.onConnectToPrintServer,
        onUpdateAddedPrinters: this.onUpdateAddedPrinters,
        personnel: this.props.personnel,
        printerQZConfigs: this.state.printerQZConfigs,
        shopBasicInfo: this.state.shopBasicInfo,
        shopID: this.props.shopID,
      }}
    >
      <ShopOrders orderType="activeOrders" />
    </MerchantInterfaceProvider>
  );

  renderShopPastOrders = () => (
    <MerchantInterfaceProvider
      value={{
        addedPrinters: this.state.addedPrinters,
        onChangeActiveOrders: (activeOrders = {}) =>
          this.setState({ activeOrders }),
        onConnectToPrintServer: this.onConnectToPrintServer,
        onUpdateAddedPrinters: this.onUpdateAddedPrinters,
        personnel: this.props.personnel,
        printerQZConfigs: this.state.printerQZConfigs,
        shopBasicInfo: this.state.shopBasicInfo,
        shopID: this.props.shopID,
      }}
    >
      <ShopOrders orderType="pastOrders" />
    </MerchantInterfaceProvider>
  );

  renderShopPaymentInfo = () => (
    <MerchantInterfaceProvider
      value={{
        shopBasicInfo: this.state.shopBasicInfo,
        shopID: this.props.shopID,
        stripeConnectAuthCode: this.props.stripeConnectAuthCode,
        stripeConnectToken: this.props.stripeConnectToken,
      }}
    >
      <PaymentInfo />
    </MerchantInterfaceProvider>
  );

  renderPageContent = () => {
    const { personnel, shopID } = this.props;
    const { shopBasicInfo } = this.state;
    switch (this.state.activeNavbarItemId) {
      case "analytics":
        return <Analytics />;
      case "combineThirdPartyOrders":
        return (
          <MerchantInterfaceProvider value={{ shopBasicInfo, shopID }}>
            <CombineThirdPartyOrders />
          </MerchantInterfaceProvider>
        );
      case "customerSelfCheckIn":
        return (
          <MerchantInterfaceProvider value={{ shopBasicInfo, shopID }}>
            <CustomerSelfCheckIn />
          </MerchantInterfaceProvider>
        );
      case "guest_reviews":
        return (
          <MerchantInterfaceProvider
            value={{ shop_basic_info: shopBasicInfo, shop_id: shopID }}
          >
            <GuestReviews scroll_y={this.state.scroll_y} />
          </MerchantInterfaceProvider>
        );
      case "help":
        return <Help />;
      case "marketing":
        return (
          <MerchantInterfaceProvider value={{ shopBasicInfo, shopID }}>
            <Marketing />
          </MerchantInterfaceProvider>
        );
      case "menusManagement":
        return this.renderMenusManagement();
      case "orders":
        return this.renderShopOrders();
      case "pastOrders":
        return this.renderShopPastOrders();
      case "paymentInfo":
        return this.renderShopPaymentInfo();
      case "payoutsForConnectedAccount":
        return (
          <MerchantInterfaceProvider value={{ shopBasicInfo }}>
            <PayoutsForConnectedAccount />
          </MerchantInterfaceProvider>
        );
      case "personnelProfile":
        return (
          <PersonnelProfile
            onRemovePersonnelInfo={this.props.onRemovePersonnelInfo}
            personnel={personnel}
            shopID={shopID}
          />
        );
      case "preparation":
        return (
          <MerchantInterfaceProvider
            value={{
              onUpdateShopBasicInfo: this.onUpdateShopBasicInfo,
              shopBasicInfo,
              shopID,
            }}
          >
            <Preparation />
          </MerchantInterfaceProvider>
        );
      case "settings":
        return (
          <MerchantInterfaceProvider
            value={{
              addedPrinters: this.state.addedPrinters,
              alarmStatus: this.state.alarmStatus || "suspended",
              isConnectingToPrintServer: this.state.isConnectingToPrintServer,
              isFindingShopAddedPrinters: this.state.isFindingShopAddedPrinters,
              isQZSocketConnected: this.state.isQZSocketConnected,
              onActivateAlarm: this.onActivateAlarm,
              onConnectToPrintServer: this.onConnectToPrintServer,
              onUpdateAddedPrinters: this.onUpdateAddedPrinters,
              onUpdateShopBasicInfo: this.onUpdateShopBasicInfo,
              personnel,
              printerQZConfigs: this.state.printerQZConfigs,
              shopBasicInfo,
              shopID,
            }}
          >
            <Settings />
          </MerchantInterfaceProvider>
        );
      case "shopInfo":
        return (
          <ShopInfo
            onUpdateShopBasicInfo={this.onUpdateShopBasicInfo}
            shopID={shopID}
            shopInfo={shopBasicInfo}
          />
        );
      case "skipliDev":
        return <SkipliDev shopID={shopID} />;
      case "website":
        return (
          <MerchantInterfaceProvider
            value={{ personnel, shopBasicInfo, shopID }}
          >
            <Website />
          </MerchantInterfaceProvider>
        );
      default:
        return null;
    }
  };

  render() {
    // const { shopBasicInfo } = this.state;
    // const { allowPrintingOrder = "false" } = shopBasicInfo;
    // const showPrinterLoader =
    //   this.state.activeNavbarItemId === "orders" &&
    //   allowPrintingOrder === "true";
    return (
      <div
        className={Style.mobileBackgroundImg}
        ref={this.containerRef}
        onScroll={this.handleScroll}
      >
        <Alarm shouldPlayAudio={this.state.isPlayingAlarm} />
        {this.renderPayoutNotConnected()}
        {/* {showPrinterLoader && (
          <PrinterLoadingNotification
            addedPrinters={this.state.addedPrinters}
            isConnectingToPrintServer={this.state.isConnectingToPrintServer}
            isFindingShopAddedPrinters={this.state.isFindingShopAddedPrinters}
            isQZSocketConnected={this.state.isQZSocketConnected}
            onChangeGlobalNavItem={this.onChangeGlobalNavItem}
          />
        )} */}
        {/* {this.state.showActivateAlarmModal && (
          <Modals.NewOrdersAlarmModal onActivateAlarm={this.onActivateAlarm} />
        )} */}
        {!this.state.isSocketConnected && (
          <p className={Style.socketNotConnected}>
            Auto-update is not active. Refresh page for latest info.
          </p>
        )}
        {this.renderGlobalNavbar()}
        <PageContentContainer shopBasicInfo={this.state.shopBasicInfo}>
          {this.renderPageContent()}
        </PageContentContainer>
      </div>
    );
  }
}

AdminViewContainer.propTypes = {
  activeNavbarItemId: PropTypes.string,
  onRemovePersonnelInfo: PropTypes.func,
  personnel: PropTypes.object,
  shopBasicInfo: PropTypes.object,
  shopID: PropTypes.string.isRequired,
};

AdminViewContainer.defaultProps = {
  onRemovePersonnelInfo: () => {},
  personnel: null,
  shopBasicInfo: {},
};

export default AdminViewContainer;
