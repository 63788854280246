// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { ShopPaymentsModules } from "components";

class PaymentInfo extends React.Component {
  render() {
    const { shopBasicInfo } = this.props.context;
    const { stripeConnectInfo = {} } = shopBasicInfo;
    const isStripeConnectComplete =
      stripeConnectInfo && stripeConnectInfo.applicationStatus === "complete";
    return (
      <div>
        <ShopPaymentsModules.StripeConnect
          isStripeConnectComplete={isStripeConnectComplete}
        />
        <ShopPaymentsModules.GetPaidForm
          isStripeConnectComplete={isStripeConnectComplete}
        />
      </div>
    );
  }
}

PaymentInfo.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      stripeConnectInfo: PropTypes.shape({
        applicationStatus: PropTypes.oneOf("complete", "pending", ""),
      }),
    }).isRequired,
    shopID: PropTypes.string.isRequired,
    stripeConnectAuthCode: PropTypes.string,
    stripeConnectToken: PropTypes.string,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(PaymentInfo);
