// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";

// Style
import WebsiteBuilderEditStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { MenusManagementModules, Modals } from "components";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions } from "lib";

const MAX_ITEMS_COUNT = 12;

class ShopMenu extends React.Component {
  confirmNotif = null;
  state = {};

  onAddItemToMenu = (itemID) => {
    const { items = {} } = this.props;
    if (Object.keys(items).length < MAX_ITEMS_COUNT) {
      this.props.onChange(set(items, itemID, "buti"));
    } else {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: `Only ${MAX_ITEMS_COUNT} items allowed for display`,
        type: "warning",
      });
    }
  };

  onRemoveItem = (itemID) => this.props.onChange(del(this.props.items, itemID));

  onHideAddItemModal = () => this.setState({ showAddItemModal: false });

  onShowAddItemModal = () => this.setState({ showAddItemModal: true });

  renderModalContent = () => (
    <div className={WebsiteBuilderEditStyle.modalContent}>
      <MenusManagementModules.AddedItemsModule
        allowChangeItemPhoto={false}
        classNames={{ itemContainer: Style.itemContainer }}
        isExpandable={false}
        items={this.props.items}
        onClickAddButton={this.onShowAddItemModal}
        onRemoveItem={this.onRemoveItem}
        showModuleContent
      />
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.state.showAddItemModal && (
          <Modals.AddItemModal
            addedItems={this.props.items}
            allowChangeItemPhoto={false}
            classNames={{
              itemContainer: Style.itemContainer,
              modalBox: Style.addItemModalBox,
              overlay: Style.addItemModalOverlay,
            }}
            destinationName="Your Menu"
            onAddItem={this.onAddItemToMenu}
            onCloseModal={this.onHideAddItemModal}
          />
        )}
        <ResponsiveModal
          classNames={{ modal: WebsiteBuilderEditStyle.modal }}
          contentLabel="Edit website menu modal"
          headerProps={{ header: "Menu" }}
          onCloseModal={this.props.onCloseModal}
        >
          {this.renderModalContent()}
        </ResponsiveModal>
      </React.Fragment>
    );
  }
}

ShopMenu.propTypes = {
  items: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ShopMenu;
