// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import TimePicker from "react-time-picker";

// Style
import Style from "./style.module.scss";

// Components
import { RadioGroup } from "components";

// Assets
import { ArrowIcon } from "assets/Icons";

class SelectHoursScreen extends React.Component {
  renderTimePickers = () => {
    const { selected_hours = {} } = this.props;
    const { end_at = "", start_at = "" } = selected_hours;
    return (
      <div className={Style.time_pickers_group}>
        {this.renderTimePicker({
          onChange: (val) =>
            this.props.onChangeCustomHours(
              set(selected_hours, `start_at`, val)
            ),
          value: start_at,
        })}
        <div className={Style.arrow_icon_time}>
          <ArrowIcon />
        </div>
        {this.renderTimePicker({
          onChange: (val) =>
            this.props.onChangeCustomHours(set(selected_hours, `end_at`, val)),
          value: end_at,
        })}
      </div>
    );
  };

  renderTimePicker = ({ onChange, value }) => (
    <TimePicker
      className={Style.time_picker}
      clearIcon={null}
      disableClock
      hourPlaceholder="hh"
      minutePlaceholder="mm"
      onChange={onChange}
      value={value}
    />
  );

  render() {
    const { closed_all_day, selected_date } = this.props;
    return (
      <>
        <RadioGroup
          heading={`Select special hours for ${selected_date}`}
          onClick={(option_id) =>
            this.props.onCloseAllDay(option_id === "closed_all_day")
          }
          optionClassName={Style.option}
          options={{
            closed_all_day: { label: "Closed" },
            custom_hours: { label: "Custom hours" },
          }}
          option_props={{ has_border: true }}
          required
          selectedOptionId={closed_all_day ? "closed_all_day" : "custom_hours"}
        />
        {!closed_all_day && this.renderTimePickers()}
      </>
    );
  }
}

SelectHoursScreen.defaultProps = {
  closed_all_day: PropTypes.bool,
  onChangeCustomHours: PropTypes.func.isRequired,
  selected_date: PropTypes.string.isRequired,
  selected_hours: PropTypes.shape({
    end_at: PropTypes.string,
    start_at: PropTypes.sring,
  }),
};

export default SelectHoursScreen;
