// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import ConfirmDeleteStyle from "components/Modals/style.module.scss";

// Fields
import { Button, CenterModal, Input } from "fields";

const { TextInput } = Input;

class ConfirmDeleteModifierModal extends Component {
  state = { modifierName: "" };

  onInputChange = (modifierName) => this.setState({ modifierName });

  onGetSubmitBtnStatus = () =>
    this.state.modifierName.toLowerCase() ===
    this.props.modifierName.toLowerCase()
      ? "active"
      : "inactive";

  onSubmit = (e) => {
    e && e.preventDefault();
    this.props.onCloseModal();
    this.props.onDeleteModifier();
  };

  render() {
    return (
      <CenterModal
        contentLabel="Confirm delete modifier modal"
        modalBoxClassname={ConfirmDeleteStyle.confirmDeleteModalBox}
        contentContainerClassname={
          ConfirmDeleteStyle.confirmDeleteModalContainer
        }
        onCloseModal={this.props.onCloseModal}
      >
        <div className={ConfirmDeleteStyle.confirmDeleteTitle}>
          Re-type <strong>{this.props.modifierName}</strong> to delete
        </div>
        <form onSubmit={this.onSubmit}>
          <TextInput
            name="confirm delete modifier"
            onChange={this.onInputChange}
            value={this.state.modifierName}
          />
          <Button
            className={ConfirmDeleteStyle.confirmDeleteButton}
            name="Comfirm delete modifier button"
            status={this.onGetSubmitBtnStatus()}
            type="submit"
          >
            Delete Away
          </Button>
        </form>
      </CenterModal>
    );
  }
}

ConfirmDeleteModifierModal.propTypes = {
  modifierName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDeleteModifier: PropTypes.func.isRequired,
};

export default ConfirmDeleteModifierModal;
