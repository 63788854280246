// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { Box, ExpansionPanel } from "fields";

class MailboxInfo extends React.Component {
  render() {
    return (
      <Box>
        <ExpansionPanel
          moduleTitle="Combine Third-party Orders"
          showExpandableContent
        >
          Completed
        </ExpansionPanel>
      </Box>
    );
  }
}

MailboxInfo.propTypes = {
  combineThirdPartyOrdersInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mailbox_prefix: PropTypes.string.isRequired,
    owner_email: PropTypes.string,
    owner_id: PropTypes.number,
    secret: PropTypes.string,
  }),
};

export default MailboxInfo;
