// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

import { _getSubmitBtnStatus } from "./HelperFunctions";

import { _findSelectedRadioOptionID } from "components/Modals/MenusManagementModals/HelperFunctions";

// Utils
import { _removeAccents } from "utils";

// Style
import FieldStyle from "components/Modals/style.module.scss";

// Components
import { MenusManagementModules, Modals, RadioGroup } from "components";

// Fields
import { Box, Button, Input } from "fields";

// Lib
import { Constants } from "lib";

class ModifierGroupFormFields extends Component {
  state = { modifierGroupInfo: {} };

  componentDidMount = () => {
    const { modifierGroupInfo = {} } = this.props;
    const { modifierGroupAllowMultipleChoices = {} } = modifierGroupInfo;
    this.setState({
      modifierGroupInfo,
      showMinAndMaxChoicesFields: modifierGroupAllowMultipleChoices.false
        ? false
        : true,
    });
  };

  onAddModifierToModifierGroup = (modifierID) =>
    this.setState({
      modifierGroupInfo: immutable.set(
        this.state.modifierGroupInfo,
        `modifiers.${modifierID}`,
        "buti"
      ),
    });

  onChangeTextInputValue = (fieldID, value) =>
    this.setState({
      modifierGroupInfo: immutable.set(
        this.state.modifierGroupInfo,
        fieldID,
        _removeAccents({ text: value })
      ),
    });

  onClickRadioButton = (fieldID, optionID) => {
    const { modifierGroupInfo } = this.state;
    this.setState(
      {
        modifierGroupInfo: immutable.set(modifierGroupInfo, fieldID, {
          [optionID]: "checked",
        }),
      },
      () => {
        if (fieldID === "modifierGroupAllowMultipleChoices")
          this.onDetermineMaxChoices(optionID);
        else if (fieldID === "modifierGroupIsRequired")
          this.onDetermineMinChoices(optionID);
      }
    );
  };

  onDetermineMaxChoices = (selectedOptionID) => {
    let updates = {};
    // If NOT allow multiple choices, set max choices to 1
    if (selectedOptionID === "false") {
      const { modifierGroupInfo = {} } = this.state;
      const { modifierGroupIsRequired = {} } = modifierGroupInfo;
      updates = {
        modifierGroupMaxChoices: "1",
        modifierGroupMinChoices: modifierGroupIsRequired.true ? "1" : "0",
      };
    }
    // If allow multiple choices, set max choices to whatever it was
    else {
      const { modifierGroupInfo = {} } = this.props;
      const { modifierGroupMaxChoices } = modifierGroupInfo;
      updates = { modifierGroupMaxChoices };
    }
    this.setState({
      modifierGroupInfo: { ...this.state.modifierGroupInfo, ...updates },
      showMinAndMaxChoicesFields: selectedOptionID === "true",
    });
  };

  onDetermineMinChoices = (selectedOptionID) =>
    this.setState({
      modifierGroupInfo: {
        ...this.state.modifierGroupInfo,
        modifierGroupIsRequired: { [selectedOptionID]: "checked" },
        modifierGroupMinChoices: selectedOptionID === "false" ? "0" : "1",
      },
    });

  onHideAddModifierModal = () => this.setState({ showAddModifierModal: false });

  onShowAddModifierModal = () => this.setState({ showAddModifierModal: true });

  onSubmitForm = () => this.props.onSubmit(this.state.modifierGroupInfo);

  onRemoveModifier = (modifierID) =>
    this.setState({
      modifierGroupInfo: immutable.del(
        this.state.modifierGroupInfo,
        `modifiers.${modifierID}`
      ),
    });

  renderField = (field = {}) => {
    const { modifierGroupInfo = {} } = this.state;
    const { fieldKind, id } = field;
    switch (fieldKind) {
      case "radio":
        return (
          <div className={FieldStyle.radioForm} key={id}>
            <RadioGroup
              heading={field.label || ""}
              onClick={(optionID) => this.onClickRadioButton(id, optionID)}
              options={field.options || {}}
              required={field.required || false}
              selectedOptionId={_findSelectedRadioOptionID(
                modifierGroupInfo[id] || {}
              )}
            />
          </div>
        );
      case "text":
      case "textarea":
        if (
          ["modifierGroupMaxChoices", "modifierGroupMinChoices"].includes(id)
        ) {
          if (!this.state.showMinAndMaxChoicesFields) return null;
        }
        return (
          <div className={FieldStyle.formField} key={id}>
            <Input.TextInput
              {...field}
              hasMultipleLines={fieldKind === "textarea" ? true : false}
              name={id}
              onChange={(value) => this.onChangeTextInputValue(id, value)}
              value={this.state.modifierGroupInfo[id] || ""}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderSubmitButton = () => {
    const submitBtnLabel = this.props.isInEditMode ? "Save" : "Create";
    return (
      <Button
        className={FieldStyle.saveButton}
        color="info"
        name={`${submitBtnLabel} modifier group`}
        onClick={this.onSubmitForm}
        status={_getSubmitBtnStatus(this.state)}
        type="submit"
      >
        {submitBtnLabel}
      </Button>
    );
  };

  render() {
    const { NEW_MODIFIER_GROUP_FIELDS } = Constants;
    const { modifierGroupInfo } = this.state;
    return (
      <div>
        {this.state.showAddModifierModal && (
          <Modals.AddModifierModal
            addedModifiers={modifierGroupInfo.modifiers}
            destinationName={modifierGroupInfo.modifierGroupName}
            onAddModifier={this.onAddModifierToModifierGroup}
            onCloseModal={this.onHideAddModifierModal}
          />
        )}
        <Box header="Modifier Group Info">
          {NEW_MODIFIER_GROUP_FIELDS.map(this.renderField)}
        </Box>
        <MenusManagementModules.AddedModifiersModule
          modifiers={modifierGroupInfo.modifiers}
          onClickAddButton={this.onShowAddModifierModal}
          onRemoveModifier={this.onRemoveModifier}
        />
        {!this.props.isReadOnly && this.renderSubmitButton()}
      </div>
    );
  }
}

ModifierGroupFormFields.propTypes = {
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  modifierGroupInfo: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

ModifierGroupFormFields.defaultProps = {
  isInEditMode: false,
  isReadOnly: false,
  modifierGroupInfo: {},
};

export default ModifierGroupFormFields;
