// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { PageMsg } from "fields";

// Assets
import NotViewableOnMobileImg from "assets/Images/not-viewable-on-mobile.png";

const NotViewableOnMobile = ({ class_names = {}, description, heading }) => (
  <div className={cx(class_names.container, Style.container)}>
    <PageMsg>
      <img
        alt="not viewable on mobile"
        className={Style.img}
        src={NotViewableOnMobileImg}
      />
      <h3 className={Style.heading}>{heading}</h3>
      <p className={Style.description}>{description}</p>
    </PageMsg>
  </div>
);

NotViewableOnMobile.propTypes = {
  class_names: PropTypes.shape({ container: PropTypes.string }),
  description: PropTypes.string,
  heading: PropTypes.string,
};

NotViewableOnMobile.defaultProps = {
  description: "Content can be viewed only on tablet and laptop",
  heading: "Not available on mobile",
};

export default NotViewableOnMobile;
