// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { IMPORT_STEPS } from "../index";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { MenusListOfElements } from "components";

// Fields
import { Button, ExpansionPanel } from "fields";

// Assets
import { ArrowIcon } from "assets/Icons";

// Lib
import { Functions } from "lib";

class ReviewMenu extends React.Component {
  rennderCategories = (categories) => {
    const { SortMenuCategories } = Functions.FoodMenuFuncs;
    return (
      <ExpansionPanel
        moduleTitle={`${Object.keys(categories).length} categories`}
        showExpandableContent
      >
        <MenusListOfElements.ListOfMenuGroups
          groupProps={{ isInEditMode: true }}
          groups={categories}
          sortedGroupIDs={SortMenuCategories(categories)}
        />
      </ExpansionPanel>
    );
  };

  renderItems = (items) => {
    const { SortMenuItems } = Functions.FoodMenuFuncs;
    return (
      <ExpansionPanel
        moduleTitle={`${Object.keys(items).length} items`}
        showExpandableContent
      >
        <MenusListOfElements.ListOfMenuItems
          itemProps={{ isInEditMode: true }}
          items={items}
          sortedItemIDs={SortMenuItems(items)}
        />
      </ExpansionPanel>
    );
  };

  renderModifierGroups = (modifierGroups) => {
    return (
      <ExpansionPanel
        moduleTitle={`${Object.keys(modifierGroups).length} modifier groups`}
        showExpandableContent
      >
        <MenusListOfElements.ListOfModifierGroups
          modifierGroupProps={{ isInEditMode: true }}
          modifierGroups={modifierGroups}
        />
      </ExpansionPanel>
    );
  };

  renderModifiers = (modifiers) => {
    return (
      <ExpansionPanel
        moduleTitle={`${Object.keys(modifiers).length} modifiers`}
        showExpandableContent
      >
        <MenusListOfElements.ListOfModifiers
          modifierProps={{ isInEditMode: true }}
          modifiers={modifiers}
        />
      </ExpansionPanel>
    );
  };

  render() {
    const { menu = {} } = this.props;
    const {
      groups = {},
      items = {},
      modifierGroups = {},
      modifiers = {},
    } = menu;
    return (
      <div>
        {this.rennderCategories(groups)}
        {this.renderItems(items)}
        {this.renderModifierGroups(modifierGroups)}
        {this.renderModifiers(modifiers)}
        <div className={CommonStyle.footer}>
          <div className={CommonStyle.footerContent}>
            <Button
              className={CommonStyle.backButton}
              name="to previous step"
              onClick={() => this.props.onChangeStep("step1")}
            >
              <ArrowIcon /> {IMPORT_STEPS["step1"].name}
            </Button>
            <Button
              className={CommonStyle.nextButton}
              name="to next step"
              onClick={() => this.props.onChangeStep("step3")}
            >
              {IMPORT_STEPS["step3"].name} <ArrowIcon />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ReviewMenu.propTypes = {
  menu: PropTypes.exact({
    groupsForMenu: PropTypes.object.isRequired,
    groups: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    modifierGroups: PropTypes.object.isRequired,
    modifiers: PropTypes.object.isRequired,
  }).isRequired,
  onChangeStep: PropTypes.func.isRequired,
};

export default ReviewMenu;
