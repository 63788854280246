// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { Button, DateRangePicker, TimeRangePicker } from "fields";

// Icons
import { DollarSymbolIcon } from "assets/Icons";

class ShopOrdersToolbar extends React.Component {
  renderCloseAllOrderButton = () => {
    const { confirmed_orders = {} } = this.props;
    return (
      confirmed_orders.length > 0 && (
        <div className={Style.elementContainer}>
          <Button
            className={Style.close_all_button}
            color="info"
            name="close all order"
            onClick={this.props.onShowConfirmAllCloseOrder}
          >
            Close {confirmed_orders.length} order
            {confirmed_orders.length === 1 ? "" : "s"}
          </Button>
        </div>
      )
    );
  };

  renderDateRangePicker = () => (
    <div className={Style.elementContainer}>
      <DateRangePicker
        class_names={{ container: Style.button }}
        onChange={this.props.onChangeDateRange}
        selectedDayRange={this.props.selectedDayRange}
      />
    </div>
  );

  renderExpandOrdersButton = () => {
    const { ordersCount } = this.props;
    return (
      <div className={Style.elementContainer}>
        <Button
          className={Style.expandOrdersButton}
          hasShadow
          name="Orders Count"
          onClick={this.props.onExpandAllOrders}
        >
          {/* <ArrowIcon
            className={cx(
              Style.arrowIcon,
              this.props.expandAllOrders && Style.collapse
            )}
          /> */}
          <div>
            {ordersCount} {ordersCount === 1 ? "order" : "orders"}
          </div>
        </Button>
      </div>
    );
  };

  renderSalesReportButton = () => {
    const { ordersCount } = this.props;
    return (
      ordersCount > 0 && (
        <div className={Style.elementContainer}>
          <Button
            className={Style.salesReportButton}
            color="info"
            name="show sales report"
            onClick={this.props.onShowQuickReport}
          >
            <DollarSymbolIcon className={Style.dollarIcon} /> Sales Report
          </Button>
        </div>
      )
    );
  };

  renderTimeRangePicker = () => {
    const { context } = this.props;
    const { timeZone } = context.shopBasicInfo;
    return (
      <div className={Style.elementContainer}>
        <TimeRangePicker
          class_names={{ container: Style.button }}
          onChange={this.props.onChangeTimeRange}
          selectedTimeRangeID={this.props.selectedTimeRangeID}
          timeZone={timeZone}
        />
      </div>
    );
  };

  render() {
    const { ordersCount = 0, orderType = "activeOrders" } = this.props;
    return (
      <div className={Style.container}>
        {ordersCount > 0 && this.renderExpandOrdersButton()}
        {/* <DisplayOrdersCount
          numOrdersPerRow={this.props.numOrdersPerRow}
          onChangeNumOrdersPerRow={this.props.onChangeNumOrdersPerRow}
        /> */}
        {orderType === "activeOrders" && this.renderCloseAllOrderButton()}
        {orderType === "pastOrders" && (
          <>
            {this.renderDateRangePicker()}
            {this.renderTimeRangePicker()}
            {this.renderSalesReportButton()}
          </>
        )}
      </div>
    );
  }
}

ShopOrdersToolbar.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  expandAllOrders: PropTypes.bool,
  numOrdersPerRow: PropTypes.number,
  onChangeNumOrdersPerRow: PropTypes.func,
  onChangeTimeRange: PropTypes.func,
  onExpandAllOrders: PropTypes.func.isRequired,
  onShowQuickReport: PropTypes.func,
  ordersCount: PropTypes.number,
  orderType: PropTypes.oneOf(["activeOrders", "pastOrders"]).isRequired,
  selectedTimeRangeID: PropTypes.string,
  confirmed_orders: PropTypes.array.isRequired,
};

ShopOrdersToolbar.defaultProps = {
  expandAllOrders: false,
  numOrdersPerRow: 1,
  onChangeTimeRange: () => {},
  ordersCount: 0,
  selectedTimeRangeID: "today",
  showReport: false,
  showTimeRangePicker: false,
};

export default withContext(MerchantInterfaceConsumer)(ShopOrdersToolbar);
