// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import Notice from "./Notice";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input, Link } from "fields";

// Icons
import {
  ArrowIcon,
  SendEmailIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
} from "assets/Icons";

// Lib
import { Services } from "lib";

class SearchResults extends React.Component {
  state = { searchResults: {}, searchingForShop: true };

  componentDidMount = () => this.onSearchShopName();

  onSearchShopName = async () => {
    const { BUTI } = Services;
    const { SearchShopsForMerchantDashboard } = BUTI.GetRequests;
    const { shopName } = this.props;
    if (shopName) {
      this.setState({ searchingForShop: true });
      try {
        const { matched_shops = {} } = await SearchShopsForMerchantDashboard({
          shopName,
        });
        this.setState({ searchResults: matched_shops });
      } catch {
      } finally {
        this.setState({ searchingForShop: false });
      }
    } else {
      this.setState({ searchResults: {} });
    }
  };

  onSelectShop = (shopId) => (window.location.href = `/${shopId}`);

  renderLoading = () => <div className={Style.loading}>Searching...</div>;

  renderNotice = () => (
    <div className={Style.notice}>
      <div>Search text is case sensitive</div>
      <div>Learn More</div>
    </div>
  );

  renderSearchInput = () => {
    const { searchingForShop } = this.state;
    return (
      <div className={Style.searchInput}>
        <div className={Style.inputField}>
          <Input.TextInput
            name="shop name"
            onChange={this.props.onChangeShopName}
            readOnly={searchingForShop}
            value={this.props.shopName}
          />
        </div>
        <Button
          className={Style.glassIcon}
          name="search shop"
          onClick={this.onSearchShopName}
          status={searchingForShop ? "inactive" : "active"}
        >
          <MagnifyingGlassIcon />
        </Button>
      </div>
    );
  };

  renderSearchResults = () => {
    const { searchResults = {} } = this.state;
    if (Object.keys(searchResults).length === 0)
      return <div className={Style.loading}>0 Results</div>;
    else if (Object.keys(searchResults).length > 3)
      return (
        <div className={Style.tooManyResults}>
          We found many shops that match your search. Please type the exact name
          of your shop for better result.
        </div>
      );
    return (
      <div className={Style.matchedShops}>
        {this.renderShops(searchResults)}
      </div>
    );
  };

  renderShops = (searchResults = {}) =>
    Object.keys(searchResults).map((shopId) => {
      const { address, name } = searchResults[shopId];
      return (
        <div key={shopId}>
          <Button
            className={Style.shop}
            name={name}
            onClick={() => this.onSelectShop(shopId)}
          >
            <div className={Style.shopInfo}>
              <div>{name}</div>
              <div className={Style.shopAddress}>{address}</div>
            </div>
            <div className={Style.select}>
              Select <ArrowIcon />
            </div>
          </Button>
        </div>
      );
    });

  render() {
    return (
      <div>
        <Button
          className={Style.backButton}
          name="back to main screen"
          onClick={this.props.onBackToMainScreen}
        >
          <ArrowIcon /> Back
        </Button>
        {this.renderSearchInput()}
        {this.state.searchingForShop
          ? this.renderLoading()
          : this.renderSearchResults()}
        <div className={Style.needHelp}>
          <Notice />
          <p>Need help?</p>
          <div className={Style.skipliContact}>
            <Link
              className={Style.callButton}
              href={`tel:6789991044`}
              target="_self"
            >
              <PhoneIcon />
              {_formatPhoneNumber("6789991044")}
            </Link>
            <Link
              className={Style.callButton}
              href={`mailto:accounts@skiplinow.com`}
              target="_self"
            >
              <SendEmailIcon />
              accounts@skiplinow.com
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

SearchResults.propTypes = {
  onBackToMainScreen: PropTypes.func.isRequired,
  onChangeShopName: PropTypes.func.isRequired,
  shopName: PropTypes.string.isRequired,
};

export default SearchResults;
