// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { MenusManagement } from "components";

// Fields
import { PageMsg } from "fields";

// Assets
import { MenusIcon } from "assets/Icons";

class ListOfModifiers extends Component {
  renderModifiers = (modifiers = {}) =>
    (this.props.sortedModifierIDs || Object.keys(modifiers)).map(
      (modifierID) => (
        <div className={CommonStyle.elementContainer} key={modifierID}>
          <MenusManagement.Modifier
            {...this.props.modifierProps}
            modifierHelperButton={this.props.modifierHelperButton}
            modifierID={modifierID}
            modifierInfo={modifiers[modifierID]}
            onUpdateAllModifiers={this.props.onUpdateAllModifiers}
            showHelperButton={this.props.showModifierHelperButton}
          />
        </div>
      )
    );

  render() {
    const { modifiers = {} } = this.props;
    if (Object.keys(modifiers).length === 0)
      return (
        <PageMsg>
          <div className={CommonStyle.menus_icon}>
            <MenusIcon />
          </div>
          {this.props.emptyListMessage}
        </PageMsg>
      );
    return (
      <div
        className={cx(
          CommonStyle.listContainer,
          this.props.listContainerClassname
        )}
      >
        {this.renderModifiers(modifiers)}
      </div>
    );
  }
}

ListOfModifiers.propTypes = {
  emptyListMessage: PropTypes.string,
  modifierHelperButton: PropTypes.func,
  modifierProps: PropTypes.object,
  modifiers: PropTypes.object,
  onUpdateAllModifiers: PropTypes.func,
  showModifierHelperButton: PropTypes.bool,
  sortedModifierIDs: PropTypes.array,
};

ListOfModifiers.defaultProps = {
  emptyListMessage: "No modifiers found.",
  modifierProps: {},
  modifiers: {},
  onUpdateAllModifiers: () => {},
  showModifierHelperButton: true,
  sortedModifierIDs: null,
};

export default ListOfModifiers;
