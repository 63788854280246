// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { set } from "object-path-immutable";
import _pick from "lodash.pick";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, Img, WebsiteEditOverlay } from "fields";

// Lib
import { Functions } from "lib";

// Icons
import { ArrowIcon, PlusIcon } from "assets/Icons";

const { WebsiteBuilderEdit } = Modals;

// const TEST_ITEMS = {
//   "-LmGa1vK4KHHvDlrMx_B": "buti",
//   "-LmGab2Tr4TmZW8fqMwr": "buti",
//   "-LmGbiZyvDbxiPLMDwwC": "buti",
//   "-LmGc9TJ2a8_nHBJwKs7": "buti",
//   "-LmRNtGBmPTE96pg-w7C": "buti",
//   "-Ln9P_Knuk53CqpMxAQ_": "buti",
// };

class ShopMenu extends React.Component {
  state = { loadedItems: {} };

  componentDidMount = () => {
    const { menu = {} } = this.props;
    const { items = {} } = menu;
    this.onGetItemsDetails({ items });
  };

  onChange = (newItems = {}) => {
    const { menu = {} } = this.props;
    const { loadedItems = {} } = this.state;

    this.props.onChange(set(menu, "items", newItems));

    if (Object.keys(newItems).length < Object.keys(loadedItems).length)
      this.setState({
        loadedItems: _pick(loadedItems, Object.keys(newItems)),
      });
    else this.onGetItemsDetails({ items: newItems });
  };

  onHideEditModal = () => this.setState({ showEditModal: false });

  onShowEditModal = () => this.setState({ showEditModal: true });

  onGetItemsDetails = async ({ items }) => {
    const { GetInformationForItems } = Functions.FoodMenuFuncs;
    const loadedItems = await GetInformationForItems({
      items,
      shopID: this.props.shopID,
    });
    this.setState({ loadedItems });
  };

  renderEditModal = () => {
    const { menu = {} } = this.props;
    const { items = {} } = menu;
    return (
      <WebsiteBuilderEdit.ShopMenu
        items={items}
        onChange={this.onChange}
        onCloseModal={this.onHideEditModal}
      />
    );
  };

  renderMenuItems = () => {
    const { themeColor = "#3aafa9" } = this.props;
    const { loadedItems = {} } = this.state;
    const PlusIconWithThemeColor = styled(PlusIcon)`
      fill: ${themeColor};
    `;
    return Object.keys(loadedItems).map((itemID) => {
      const { itemImages = {}, itemDescription, itemName } = loadedItems[
        itemID
      ];
      const { full = {} } = itemImages;
      const { imageURL = "" } = full;
      return (
        <div
          className={Style.itemContainer}
          key={itemID}
          onClick={this.props.onRedirectToOrderOnline}
        >
          <Img alt="" className={Style.itemImage} src={imageURL} />
          <div className={Style.text_area}>
            <h3 className={Style.item_name}>{itemName}</h3>
            <p className={Style.item_description}>{itemDescription}</p>
          </div>
          <Button className={Style.add_item_button} name="add item to menu">
            Add item <PlusIconWithThemeColor />
          </Button>
        </div>
      );
    });
  };

  render() {
    const { themeColor = "#3aafa9" } = this.props;
    const { loadedItems = {} } = this.state;
    const SeeFullMenuBtn = styled(Button)`
      background: ${themeColor};
    `;

    return (
      <React.Fragment>
        {this.state.showEditModal && this.renderEditModal()}
        <WebsiteEditOverlay onShowEditModal={this.onShowEditModal}>
          <div className={Style.container}>
            <h2 className={Style.heading}>Our Menu</h2>
            <div className={Style.menuItems}>{this.renderMenuItems()}</div>
            {Object.keys(loadedItems).length > 0 && (
              <SeeFullMenuBtn
                className={Style.seeFullMenuBtn}
                name="see full menu"
                onClick={this.props.onRedirectToOrderOnline}
              >
                See Full Menu <ArrowIcon />
              </SeeFullMenuBtn>
            )}
          </div>
        </WebsiteEditOverlay>
      </React.Fragment>
    );
  }
}

ShopMenu.propTypes = {
  menu: PropTypes.exact({
    items: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  shopID: PropTypes.string,
  themeColor: PropTypes.string.isRequired,
};

export default ShopMenu;
