// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import ConfirmCard from "./ConfirmCard";
import CreateMailbox from "./CreateMailbox";

class CombineOrdersSetup extends React.Component {
  state = { currentStepId: "confirmCard" };

  onChangeStep = (stepId) => stepId && this.setState({ currentStepId: stepId });

  renderContent = () => {
    switch (this.state.currentStepId) {
      case "confirmCard":
        return <ConfirmCard onChangeStep={this.onChangeStep} />;
      case "createMailbox":
        return (
          <CreateMailbox
            onChangeStep={this.onChangeStep}
            onChangePage={this.props.onChangePage}
          />
        );
      default:
        return;
    }
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

CombineOrdersSetup.propTypes = {
  onChangePage: PropTypes.func.isRequired,
};

export default CombineOrdersSetup;
