// BUTI DINERS, INC. All right Reserved ©

// Utils
import _isEmpty from "lodash.isempty";

const DEFAULT_FILTER_VALUES = {};

const FILTER_MODIFIERS_CRITERIAS = {
  modifierName: {
    comparator: (a = "", b = "") =>
      !_isEmpty(b)
        ? String(a)
            .toLowerCase()
            .includes(String(b).toLowerCase())
        : true,
    input: {
      label: "Modifier Name",
      type: "text",
    },
    required: false,
  },
};

export { DEFAULT_FILTER_VALUES, FILTER_MODIFIERS_CRITERIAS };
