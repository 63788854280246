// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { RadioButton } from "fields";

// Lib
import { Functions, Services } from "lib";

class AllowPrintingOrderSelector extends React.Component {
  state = {};
  confirmNotif = null;

  onAllowPrintingOrder = async status => {
    this.setState({ isLoading: true });
    try {
      const { Merchants } = Services;
      const { SaveChangedShopInfo } = Merchants.PostRequests;
      const {
        onUpdateShopBasicInfo,
        shopBasicInfo,
        shopID
      } = this.props.context;
      const newShopBasicInfo = set(shopBasicInfo, "allowPrintingOrder", status);
      await SaveChangedShopInfo({
        shopID,
        shopInfo: newShopBasicInfo
      });
      onUpdateShopBasicInfo(newShopBasicInfo);
    } catch {
      this.confirmNotif = Functions.ShowConfirmNotif({
        message: "Failed to enable printer. Please try again.",
        type: "error"
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { allowPrintingOrder = "false" } = this.props.context.shopBasicInfo;
    return (
      <div>
        {this.state.isLoading && <Modals.LoadingModal />}
        <h4 style={{ marginTop: "0" }}>
          Enable a printer to print the customer's orders
        </h4>
        <div className={Style.radioButtons}>
          <RadioButton
            className={Style.button}
            checked={allowPrintingOrder === "true"}
            id="true"
            label="Yes"
            onClick={() => this.onAllowPrintingOrder("true")}
          />
          <RadioButton
            className={Style.button}
            checked={allowPrintingOrder === "false"}
            id="false"
            label="No"
            onClick={() => this.onAllowPrintingOrder("false")}
          />
        </div>
      </div>
    );
  }
}

AllowPrintingOrderSelector.propTypes = {
  context: PropTypes.shape({
    onUpdateShopBasicInfo: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.shape({
      allowPrintingOrder: PropTypes.oneOf(["false", "true"])
    }).isRequired,
    shopID: PropTypes.string.isRequired
  }).isRequired
};

export default withContext(MerchantInterfaceConsumer)(
  AllowPrintingOrderSelector
);
