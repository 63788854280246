// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icon
import { SkipliLogoCircleIcon } from "assets/Icons";

// Fields
import { Link } from "fields";

const SkipliLogo = ({ className }) => (
  <Link href="https://skiplinow.com">
    <SkipliLogoCircleIcon className={cx(Style.skipliLogo, className)} />
  </Link>
);

SkipliLogo.propTypes = {
  className: PropTypes.any,
};

export default SkipliLogo;
