// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { formatToTimeZone } from "date-fns-timezone/dist/formatToTimeZone";

// Style
import ShopOrderPillStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Assets
import { DeliverIcon, LunchBagIcon } from "assets/Icons";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Lib
import { Constants } from "lib";

class ShopOrderPillPreviewInfo extends React.Component {
  renderOrderDeliveryType = () => {
    const { ORDER_DELIVERY_TYPES } = Constants;
    const { orderInfo = {} } = this.props;
    const { orderDeliveryTypeID = "inStore" } = orderInfo;
    let icon;
    if (orderDeliveryTypeID === "pickUp") icon = <LunchBagIcon />;
    else if (orderDeliveryTypeID === "deliver") icon = <DeliverIcon />;
    return (
      <span className={Style.orderDeliveryType}>
        {icon}
        <strong className={ShopOrderPillStyle.boldText}>
          {ORDER_DELIVERY_TYPES[orderDeliveryTypeID].label}
        </strong>
      </span>
    );
  };

  renderPreviewInfo = () => {
    const { orderInfo = {} } = this.props;
    const { customerName } = orderInfo;
    return (
      <div className={Style.previewInfoContainer}>
        <div className={Style.previewInfo}>
          {/* {this.renderOrderDeliveryType()} */}
          <span>
            Name:{" "}
            <strong className={ShopOrderPillStyle.boldText}>
              {customerName}
            </strong>
          </span>
          {this.renderTableNumberOrPickUpTime()}
        </div>
        {this.renderTimeStamp()}
      </div>
    );
  };

  renderTableNumberOrPickUpTime = () => {
    const { orderInfo = {} } = this.props;
    const { orderDeliveryTypeID, pickUpTime = "ASAP", tableNumber } = orderInfo;
    switch (orderDeliveryTypeID) {
      case "inStore":
        return (
          <span>
            Table:{" "}
            <strong className={ShopOrderPillStyle.boldText}>
              {tableNumber}
            </strong>
          </span>
        );
      case "deliver":
      case "pickUp":
        return (
          <span>
            Pickup Time:{" "}
            <strong className={ShopOrderPillStyle.boldText}>
              {pickUpTime}
            </strong>
          </span>
        );
      default:
        return;
    }
  };

  renderTimeStamp = () => {
    const { DEFAULT_TIMEZONE } = Constants;
    const DATETIME_FORMAT = `${Constants.DATE_FORMAT} ${Constants.TIME_FORMAT}`;
    const { timeZone } = this.props.context.shopBasicInfo;
    const { timeStamp } = this.props.orderInfo;
    const receivedAt = timeStamp;
    // const result = formatToTimeZone(
    //   orderType === "active" ? receivedAt : closedAt,
    //   DATETIME_FORMAT,
    //   { timeZone: timeZone || DEFAULT_TIMEZONE }
    // );
    return (
      <div>
        <strong className={ShopOrderPillStyle.boldText}>
          {formatToTimeZone(receivedAt, DATETIME_FORMAT, {
            timeZone: timeZone || DEFAULT_TIMEZONE,
          })}
        </strong>
      </div>
    );
    // switch (orderType) {
    //   case "active":
    //     return (
    //       <div>
    //         <strong className={ShopOrderPillStyle.boldText}>{result}</strong>
    //       </div>
    //     );
    //   case "past":
    //     return (
    //       <div>
    //         Closed:{" "}
    //         <strong className={ShopOrderPillStyle.boldText}>{result}</strong>
    //       </div>
    //     );
    //   default:
    //     return "";
    // }
  };

  render() {
    const { showExpandedInfo } = this.props;
    return (
      <button
        className={Style.container}
        name={`${showExpandedInfo ? "hide" : "show"} order details`}
        onClick={this.props.onArrowBtnClick}
      >
        <div>{this.renderPreviewInfo()}</div>
      </button>
    );
  }
}

ShopOrderPillPreviewInfo.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  onArrowBtnClick: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({}).isRequired,
  orderType: PropTypes.oneOf(["active", "past"]).isRequired,
  showExpandedInfo: PropTypes.bool.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(ShopOrderPillPreviewInfo);
