// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import cx from "classnames";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import LargeCenterModalStyle from "components/Modals/style.module.scss";

const MenusManagementArchiveButton = props => {
  const role = _get(props.context, "personnel.role");
  const { isObjectArchived } = props;
  const showButton =
    props.isInEditMode && !props.isReadOnly && role === "owner";
  return (
    showButton && (
      <button
        className={cx(
          LargeCenterModalStyle.archiveButton,
          isObjectArchived && LargeCenterModalStyle.unarchive
        )}
        name={props.name}
        onClick={props.onClick}
        type="submit"
      >
        {isObjectArchived ? "Restore" : "Archive"}
      </button>
    )
  );
};

MenusManagementArchiveButton.propTypes = {
  context: PropTypes.shape({
    personnel: PropTypes.object.isRequired
  }),
  isInEditMode: PropTypes.bool,
  isObjectArchived: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

MenusManagementArchiveButton.defaultProps = {
  isInEditMode: false,
  isObjectArchived: false,
  isReadOnly: false
};

export default withContext(MerchantInterfaceConsumer)(
  MenusManagementArchiveButton
);
