// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";
import * as immutable from "object-path-immutable";

// Style
import Style from "../style.module.scss";

// Icons
import { CloseIcon } from "assets/Icons";

// Fields
import { LoadingSpinner, PageMsg, RadioButton } from "fields";

// Components
import { MenusListOfElements } from "components";

// Constants
import { Constants, Services } from "lib";

class BuyOneGetOne extends React.Component {
  state = { bogoChoice: "sameCategory" };

  componentDidMount = () => {
    this.onGetAllGroups();
    const { selections = {} } = this.props;
    const { buyCategoryID = "", getCategoryID = "" } = selections;
    if (buyCategoryID && getCategoryID) {
      this.setState({
        bogoChoice:
          buyCategoryID === getCategoryID
            ? "sameCategory"
            : "differentCategories",
      });
    }
  };

  onGetAllGroups = async () => {
    const { Merchants } = Services;
    this.setState({ isFindingGroups: true });
    const { GetShopAllGroups } = Merchants.GetRequests;
    const { allGroups } = await GetShopAllGroups({
      shopID: this.props.shopID,
    }).finally(() => this.setState({ isFindingGroups: false }));
    this.setState({ allGroups: allGroups || {} });
  };

  renderBOGORadioButtons = () => {
    const { BOGO_CHOICES } = Constants;
    return Object.keys(BOGO_CHOICES).map((choiceID) => {
      const { label, example } = BOGO_CHOICES[choiceID];
      return (
        <div className={Style.radioBtnContainer} key={choiceID}>
          <RadioButton
            checked={this.state.bogoChoice === choiceID}
            id={choiceID}
            label={label}
            onClick={() => this.onSelectBOGOChoice(choiceID)}
          />
          <p>E.g. {example}</p>
        </div>
      );
    });
  };

  onSelectBOGOChoice = (bogoChoice) =>
    this.setState({ bogoChoice }, () =>
      this.props.onSetCouponSelections({ buyCategoryID: "", getCategoryID: "" })
    );

  renderActiveSelections = (selectionsField = "buyCategoryID") => {
    const { selections } = this.props;
    const { buyCategoryID = "", getCategoryID = "" } = selections;
    const { allGroups, bogoChoice } = this.state;
    const removeBtn = (
      <CloseIcon
        className={Style.removeButton}
        onClick={() => this.onRemoveActiveSelection(selectionsField)}
      />
    );
    let categoryID;
    if (bogoChoice === "sameCategory") categoryID = buyCategoryID;
    else
      categoryID =
        selectionsField === "buyCategoryID" ? buyCategoryID : getCategoryID;
    return (
      <div>
        <h4>You Selected:</h4>
        <MenusListOfElements.ListOfMenuGroups
          emptyListMessage="All categories is added."
          groupHelperButton={() => removeBtn}
          groups={_pick(allGroups, categoryID)}
        />
      </div>
    );
  };

  onRemoveActiveSelection = (selectionsField = "buyCategoryID") => {
    const { onSetCouponSelections } = this.props;
    const { bogoChoice } = this.state;
    if (bogoChoice === "sameCategory")
      onSetCouponSelections({ buyCategoryID: "", getCategoryID: "" });
    else
      onSetCouponSelections(
        immutable.set(this.props.selections, selectionsField, "")
      );
  };

  onSelectCategory = (categoryID, selectionsField = "buyCategoryID") => {
    const { onSetCouponSelections } = this.props;
    if (this.state.bogoChoice === "sameCategory")
      onSetCouponSelections({
        buyCategoryID: categoryID,
        getCategoryID: categoryID,
      });
    else {
      const updatedSelections = immutable.set(
        this.props.selections,
        selectionsField,
        categoryID
      );
      const { buyCategoryID = "", getCategoryID = "" } = updatedSelections;
      if (buyCategoryID === getCategoryID)
        this.setState({ bogoChoice: "sameCategory" }, () =>
          onSetCouponSelections(updatedSelections)
        );
      else onSetCouponSelections(updatedSelections);
    }
  };

  renderCategory = (selectionsField = "buyCategoryID") =>
    this.state.isFindingGroups ? (
      <PageMsg>
        <LoadingSpinner message="Searching for categories" />
      </PageMsg>
    ) : (
      <MenusListOfElements.ListOfMenuGroups
        emptyListMessage="All categories is added."
        groupHelperButton={(categoryID) => (
          <div
            onClick={() => this.onSelectCategory(categoryID, selectionsField)}
          >
            +
          </div>
        )}
        groups={this.state.allGroups}
      />
    );

  renderContent = () => {
    const { selections = {} } = this.props;
    const { bogoChoice } = this.state;
    const { buyCategoryID = "", getCategoryID = "" } = selections;
    if (bogoChoice === "sameCategory")
      return (
        <div>
          <h3>Select Category</h3>
          {buyCategoryID === ""
            ? this.renderCategory()
            : this.renderActiveSelections("buyCategoryID")}
        </div>
      );
    return (
      <div>
        <h3>Select Buy Category</h3>
        {buyCategoryID === ""
          ? this.renderCategory("buyCategoryID")
          : this.renderActiveSelections("buyCategoryID")}
        <h3>Select Get Category</h3>
        {getCategoryID === ""
          ? this.renderCategory("getCategoryID")
          : this.renderActiveSelections("getCategoryID")}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div style={{ display: "flex" }}>{this.renderBOGORadioButtons()}</div>
        {this.renderContent()}
      </div>
    );
  }
}

BuyOneGetOne.propTypes = {
  onSetCouponSelections: PropTypes.func.isRequired,
  selections: PropTypes.shape({
    buyCategoryID: PropTypes.string,
    getCategoryID: PropTypes.string,
  }),
  shopID: PropTypes.string.isRequired,
};

BuyOneGetOne.defaultProps = {
  selections: {
    buyCategoryID: "",
    getCategoryID: "",
  },
};

export default BuyOneGetOne;
