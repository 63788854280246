// BUTI DINERS, INC. All right Reserved ©

const PRESET_COLORS = [
  "#000000",
  "#ffffff",
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#006AFF",
  "#3F51B5",
  "#03A9F4",
  "#2196F3",
  "#098dc7",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#607D8B",
];

export { PRESET_COLORS };
