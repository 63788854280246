// BUTI Corp All right Reserved ©

import GetPaidForm from "./GetPaidForm";
import StripeConnect from "./StripeConnect";

const ShopPaymentsModules = {
  GetPaidForm,
  StripeConnect
};

export default ShopPaymentsModules;
