// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Fields
import { ScreenSize } from "fields";

// Style
import Style from "./style.module.scss";

class PageContentContainer extends React.Component {
  renderAdminContentContainer = () => {
    const { children } = this.props;
    return (
      <React.Fragment>
        <ScreenSize deviceType="mobile">
          <div className={cx(Style.adminContentContainer, Style.mobile)}>
            {children}
          </div>
        </ScreenSize>
        <ScreenSize deviceType="tablet">
          <div className={cx(Style.adminContentContainer, Style.mobile)}>
            {children}
          </div>
        </ScreenSize>
        <ScreenSize deviceType="desktop">
          <div className={Style.adminContentContainer}>{children}</div>
        </ScreenSize>
      </React.Fragment>
    );
  };

  render() {
    return this.renderAdminContentContainer();
  }
}

PageContentContainer.propTypes = {
  children: PropTypes.any,
  shopBasicInfo: PropTypes.object.isRequired,
};

export default PageContentContainer;
