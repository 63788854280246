// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Fields
import { PageMsg } from "fields";

const LiveShopNotAllowed = () => (
  <PageMsg>
    <div className={Style.greetingMsg}>
      <h1>Access Denied!</h1>
      <p>
        This shop is only allowed in LIVE mode. Contact BUTI Diners for access.
      </p>
    </div>
  </PageMsg>
);

const ShopNotExist = () => (
  <PageMsg>
    <div className={Style.greetingMsg}>
      <h1>Shop Not Found!</h1>
      <p>Contact BUTI Diners for your shop.</p>
    </div>
  </PageMsg>
);

const TestShopNotAllowed = () => (
  <PageMsg>
    <div className={Style.greetingMsg}>
      <h1>Access Denied!</h1>
      <p>
        This shop is only allowed in DEV mode. Contact BUTI Diners for access.
      </p>
    </div>
  </PageMsg>
);

export { LiveShopNotAllowed, ShopNotExist, TestShopNotAllowed };
