// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import _pick from "lodash.pick";

// Style
import Style from "./style.module.scss";

const INPUT_ATTRIBUTES = [
  "autoFocus",
  "errortext",
  "label",
  "max",
  "min",
  "name",
  "placeholder",
  "readOnly",
  "required",
  "rows",
  "type",
  "value",
];

class TextInput extends Component {
  state = { focused: false };

  onValidateValue = () => {
    // Return true if value is valid, false if not
    const { regEx, required, showError, value } = this.props;
    if (required && !value) return false;
    else if (regEx) return value ? regEx.test(value) : true;
    else if (showError) return false;
    return true;
  };

  onChangeValue = (e) => {
    const { max_character_count, onChange = () => {} } = this.props;
    const new_value = e.target.value || "";
    if (max_character_count > 0) {
      if (new_value.length <= max_character_count) onChange(new_value);
      else return;
    } else onChange(new_value);
  };

  onFormFocus = () => {
    const { onFocus } = this.props;
    this.setState({ focused: true }, () => onFocus && onFocus());
  };

  onFormBlur = () => {
    const { onBlur } = this.props;
    this.setState({ focused: false }, () => onBlur && onBlur());
  };

  onKeyUp = () => {
    const { onKeyUp } = this.props;
    onKeyUp && onKeyUp();
  };

  onGetFieldClassname = () => {
    const { hasMultipleLines, readOnly } = this.props;
    const { focused } = this.state;
    return cx(
      Style.field,
      hasMultipleLines && Style.multilineField,
      !this.onValidateValue() && Style.invalid,
      focused && !readOnly && Style.focused,
      readOnly && Style.readOnly,
      this.props.fieldClassname
    );
  };

  renderCharacterCount = () => {
    const { max_character_count, value = "" } = this.props;
    const current_character_count = String(value).length;
    return (
      max_character_count > 0 && (
        <div
          className={cx(
            Style.character_count,
            current_character_count > max_character_count &&
              Style.max_character_count_warning
          )}
        >
          {current_character_count}/{max_character_count}
        </div>
      )
    );
  };

  renderInputLabel = () => {
    const { label, unit } = this.props;
    const labelForUnit = unit ? ` (${unit})` : "";
    return (
      <div
        className={cx(Style.label, this.props.labelClassname)}
      >{`${label}${labelForUnit}`}</div>
    );
  };

  renderFooter = () => {
    const { description = "", errortext } = this.props;
    const is_value_valid = this.onValidateValue();
    return (
      (!is_value_valid || description) && (
        <div className={Style.footer}>
          <p className={Style.description}>{description}</p>
          {!is_value_valid && (
            <div className={Style.error_text}>{errortext}</div>
          )}
        </div>
      )
    );
  };

  renderTextField = () => {
    const { hasMultipleLines } = this.props;
    const props = {
      ..._pick(this.props, INPUT_ATTRIBUTES),
      className: cx(Style.input, this.props.inputClassname),
      onBlur: this.onFormBlur,
      onChange: this.onChangeValue,
      onFocus: this.onFormFocus,
      onKeyUp: this.onKeyUp,
    };
    return !hasMultipleLines ? (
      <input {...props} />
    ) : (
      <textarea {...props} style={{ border: "none" }} />
    );
  };

  render() {
    const { class_names = {}, label, max_character_count } = this.props;
    return (
      <div
        className={cx(
          Style.container,
          this.props.className,
          class_names.container
        )}
        onBlur={() => this.onValidateValue()}
      >
        {(label || max_character_count) && (
          <div className={Style.label_container}>
            {this.renderInputLabel()}
            {this.renderCharacterCount()}
          </div>
        )}
        <div className={this.onGetFieldClassname()}>
          {this.renderTextField()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

TextInput.propTypes = {
  class_names: PropTypes.shape({}),
  errortext: PropTypes.string,
  hasMultipleLines: PropTypes.bool,
  label: PropTypes.string,
  max_character_count: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  readOnly: PropTypes.bool,
  regEx: PropTypes.any,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["number", "tel", "text"]),
  value: PropTypes.any,
};

TextInput.defaultProps = {
  hasMultipleLines: false,
  errortext: "Required",
  onChange: () => {},
  placeholder: "",
  readOnly: false,
  regEx: null,
  required: false,
  type: "text",
};

export default TextInput;
