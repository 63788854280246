// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import ConfirmArchiveStyle from "components/Modals/style.module.scss";

// Fields
import { Input, ResponsiveModal } from "fields";

const { TextInput } = Input;

class ConfirmArchiveModifierGroupModal extends Component {
  state = { modifierGroupName: "" };

  onInputChange = (modifierGroupName) => this.setState({ modifierGroupName });

  onGetSubmitBtnStatus = () =>
    this.state.modifierGroupName.toLowerCase() ===
    this.props.modifierGroupName.toLowerCase()
      ? "active"
      : "inactive";

  onSubmit = (e) => {
    e && e.preventDefault();
    this.props.onCloseModal();
    this.props.onArchiveModifierGroup();
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{
          footer_submit_button: ConfirmArchiveStyle.confirmArchiveButton,
          modal: ConfirmArchiveStyle.confirmArchiveModalBox,
        }}
        contentLabel="Confirm archive item modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Archive",
            status: this.onGetSubmitBtnStatus(),
          },
        }}
        headerProps={{
          close_button_icon: "arrow",
          header: "Archive modifier group",
        }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.onSubmit}
      >
        <div className={ConfirmArchiveStyle.confirmArchiveTitle}>
          Type <strong>{this.props.modifierGroupName}</strong> to archive
        </div>
        <TextInput
          name="confirm archive group modifier"
          onChange={this.onInputChange}
          value={this.state.modifierGroupName}
        />
      </ResponsiveModal>
    );
  }
}

ConfirmArchiveModifierGroupModal.propTypes = {
  modifierGroupName: PropTypes.string.isRequired,
  onArchiveModifierGroup: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ConfirmArchiveModifierGroupModal;
