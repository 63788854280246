// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import ModuleStyle from "../style.module.scss";

// Components
import { MenusListOfElements } from "components";

// Fields
import { Box, Button } from "fields";

// Icons
import { CloseIcon, PlusIcon } from "assets/Icons";

// Lib
import { Functions } from "lib";

class AddedGroupsModule extends React.Component {
  state = { fullyLoadedGroups: {} };

  componentDidMount = () => this.onGetInfoForAddedGroups(this.props.groups);

  componentDidUpdate(prevProps) {
    const { groups } = this.props;
    const oldGroupsCount = Object.keys(prevProps.groups).length;
    const newGroupsCount = Object.keys(groups).length;
    if (newGroupsCount > oldGroupsCount) this.onGetInfoForAddedGroups(groups);
    else if (newGroupsCount < oldGroupsCount)
      this.setState({
        fullyLoadedGroups: _pick(
          this.state.fullyLoadedGroups,
          Object.keys(groups)
        ),
      });
  }

  onGetInfoForAddedGroups = async (groups) => {
    const { GetInformationForGroups } = Functions.FoodMenuFuncs;
    const params = { groups, shopID: this.props.context.shopID };
    const fullyLoadedGroups = await GetInformationForGroups(params);
    this.setState({ fullyLoadedGroups });
  };

  renderAddedGroups = () => {
    const { fullyLoadedGroups } = this.state;
    return (
      Object.keys(fullyLoadedGroups).length > 0 && (
        <MenusListOfElements.ListOfMenuGroups
          groupHelperButton={(groupID) => (
            <CloseIcon
              className={ModuleStyle.removeButton}
              onClick={() => this.props.onRemoveGroup(groupID)}
            />
          )}
          groupProps={{ isInEditMode: true }}
          groups={fullyLoadedGroups}
        />
      )
    );
  };

  render() {
    const { groups = {} } = this.props;
    return (
      <Box
        header={`Categories (${Object.keys(groups).length})`}
        description="Add categories to a menu to allow customers to add and purchase items"
      >
        <div>
          <Button
            className={ModuleStyle.addButton}
            color="info"
            name="add categories to menu"
            onClick={this.props.onClickAddButton}
          >
            <PlusIcon />
            Add Categories
          </Button>
        </div>
        {this.renderAddedGroups()}
      </Box>
    );
  }
}

AddedGroupsModule.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  groups: PropTypes.object,
  onClickAddButton: PropTypes.func,
  onRemoveGroup: PropTypes.func,
};

AddedGroupsModule.defaultProps = {
  groups: {},
  onClickAddButton: () => {},
  onRemoveGroup: () => {},
};

export default withContext(MerchantInterfaceConsumer)(AddedGroupsModule);
