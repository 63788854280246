// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input, Link } from "fields";

// Lib
import { Functions, Services } from "lib";

class FindExternalMenu extends React.Component {
  state = { woflowMenuId: "" };

  componentWillUnmount = () => this.setState({ isSearchingMenu: false });

  onGetButtonStatus = () => {
    if (!this.state.woflowMenuId) return "inactive";
    return this.state.isSearchingMenu ? "loading" : "active";
  };

  onSearchMenu = async () => {
    this.setState({ isSearchingMenu: true });
    const { Merchants } = Services;
    const { GetMenuFromWoflow } = Merchants.GetRequests;
    const { ConvertMenuFromWoflow } = Functions;
    const { _convertMenuFromWoflow } = ConvertMenuFromWoflow;
    try {
      const { menu } = await GetMenuFromWoflow({
        woflowMenuId: this.state.woflowMenuId,
      });
      this.props.onMenuFound(_convertMenuFromWoflow({ menu }));
    } catch {
      this.setState({ isSearchingMenu: false, failedToFindMenu: true });
    } finally {
      this.setState({ isSearchingMenu: false });
    }
  };

  render() {
    return (
      <div>
        <Input.TextInput
          className={Style.inputField}
          errortext="The menu is not found."
          label="Enter Woflow Menu Id"
          name="woflow menu id"
          onChange={(value) => this.setState({ woflowMenuId: value })}
          showError={this.state.failedToFindMenu}
          value={this.state.woflowMenuId}
        />
        <Link
          className={Style.woflowPortalLink}
          href="https://portal.woflow.com/catalogs"
        >
          Need help? Access Woflow Portal
        </Link>
        <Button
          className={Style.findMenuButton}
          loadingText="searching"
          name="find menu button"
          onClick={this.onSearchMenu}
          status={this.onGetButtonStatus()}
        >
          Search
        </Button>
      </div>
    );
  }
}

FindExternalMenu.propTypes = {
  onMenuFound: PropTypes.func.isRequired,
};

export default FindExternalMenu;
