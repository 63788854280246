// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import ModuleStyle from "../style.module.scss";

// Components
import { MenusListOfElements } from "components";

// Fields
import { Box, Button } from "fields";

// Icons
import { CloseIcon, PlusIcon } from "assets/Icons";

// Lib
import { Functions } from "lib";

class AddedModifierGroupsModule extends React.Component {
  state = { fullyLoadedModifierGroups: {} };

  componentDidMount = () =>
    this.onGetInfoForAddedModifierGroups(this.props.modifierGroups);

  componentDidUpdate(prevProps) {
    const { modifierGroups } = this.props;
    const oldModifierGroupsCount = Object.keys(prevProps.modifierGroups).length;
    const newModifierGroupsCount = Object.keys(modifierGroups).length;
    if (newModifierGroupsCount > oldModifierGroupsCount)
      this.onGetInfoForAddedModifierGroups(modifierGroups);
    else if (newModifierGroupsCount < oldModifierGroupsCount)
      this.setState({
        fullyLoadedModifierGroups: _pick(
          this.state.fullyLoadedModifierGroups,
          Object.keys(modifierGroups)
        ),
      });
  }

  onClickAddButton = (e) => {
    e && e.preventDefault();
    this.props.onClickAddButton();
  };

  onGetInfoForAddedModifierGroups = async (modifierGroups) => {
    const { GetInformationForModifierGroups } = Functions.FoodMenuFuncs;
    const params = { modifierGroups, shopID: this.props.context.shopID };
    const fullyLoadedModifierGroups = await GetInformationForModifierGroups(
      params
    );
    this.setState({ fullyLoadedModifierGroups });
  };

  onRemoveModifier = (modifierID) => this.props.onRemoveModifier(modifierID);

  renderAddedModifierGroups = () => {
    const { fullyLoadedModifierGroups } = this.state;
    return (
      Object.keys(fullyLoadedModifierGroups).length > 0 && (
        <MenusListOfElements.ListOfModifierGroups
          modifierGroupHelperButton={(modifierGroupID) => (
            <CloseIcon
              className={ModuleStyle.removeButton}
              onClick={() => this.props.onRemoveModifierGroup(modifierGroupID)}
            />
          )}
          modifierGroups={fullyLoadedModifierGroups}
        />
      )
    );
  };

  render() {
    const { modifierGroups = {} } = this.props;
    return (
      <Box
        description="Modifier groups allow customers to use toppings, sides and more to customize item"
        header={`Modifier Groups (${Object.keys(modifierGroups).length})`}
      >
        <Button
          className={ModuleStyle.addButton}
          color="info"
          name="add modifier group to item"
          onClick={this.onClickAddButton}
        >
          <PlusIcon />
          Add Modifier Groups
        </Button>
        {this.renderAddedModifierGroups()}
      </Box>
    );
  }
}

AddedModifierGroupsModule.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  modifierGroups: PropTypes.object,
  onClickAddButton: PropTypes.func,
  onRemoveModifierGroup: PropTypes.func,
};

AddedModifierGroupsModule.defaultProps = {
  modifierGroups: {},
  onClickAddButton: () => {},
  onRemoveModifierGroup: () => {},
};

export default withContext(MerchantInterfaceConsumer)(
  AddedModifierGroupsModule
);
