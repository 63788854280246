/* eslint-disable jsx-a11y/anchor-has-content */
// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
// Style
import Style from "./style.module.scss";
import Rating from "react-rating";
// Icons
import { StarEmptyIcon, StarFullIcon } from "assets/Icons";
import { _translateRating } from "./helperFunctions";

class ReviewsGrid extends React.Component {
  renderReviews = () => {
    const { guest_reviews = [] } = this.props;
    return guest_reviews.map((review, index) => {
      const {
        created_at = "",
        feedback_text = "",
        review_id,
        selected_rating_id,
      } = review;

      return (
        <div
          className={Style.review_card}
          key={review_id}
          onClick={() => this.props.onShowReviewDetails({ dataObject: review })}
        >
          <div className={Style.header}>
            <Rating
              initialRating={_translateRating(selected_rating_id)}
              emptySymbol={
                <StarEmptyIcon height="20px" width="20px" fill="grey" />
              }
              fullSymbol={<StarFullIcon height="20px" width="20px" />}
              readonly
            />
          </div>
          <div className={Style.content_container}>
            <div className={Style.comment}>{feedback_text}</div>
            {feedback_text.length > 190 && (
              <button className={Style.read_more_button}>Read more</button>
            )}
          </div>
          <div className={Style.footer}>
            <div className={Style.reviewer_name}>Anonymous</div>
            <div>{created_at}</div>
          </div>
        </div>
      );
    });
  };

  render() {
    return <div className={Style.container}>{this.renderReviews()}</div>;
  }
}

ReviewsGrid.propTypes = {
  guest_reviews: PropTypes.arrayOf(
    PropTypes.shape({
      feedback_text: PropTypes.string,
      created_at: PropTypes.string,
    })
  ),
  onShowReviewDetails: PropTypes.func.isRequired,
};

export default ReviewsGrid;
