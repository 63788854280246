// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { CouponModules } from "components";

// Lib
import { Constants } from "lib";
const { COUPON_TYPES } = Constants;

class MarketingEditCoupon extends React.Component {
  state = { couponInfo: this.props.couponInfo };

  onChangeCouponInfo = (fieldKey, fieldValue) => {
    const { couponInfo } = this.state;
    this.setState({
      couponInfo: immutable.set(couponInfo, fieldKey, fieldValue)
    });
  };

  onSetCouponSelections = selections => {
    const { couponInfo } = this.state;
    this.setState({
      couponInfo: immutable.set(couponInfo, "selections", selections)
    });
  };

  render() {
    const { shopID } = this.props.context;
    const { couponInfo } = this.state;
    const { couponType, selections = {} } = couponInfo;
    return (
      <div>
        <CouponModules.CouponInfo
          couponInfo={couponInfo}
          onChangeCouponInfo={this.onChangeCouponInfo}
        />
        <CouponModules.CouponSelections
          couponType={couponType}
          onSetCouponSelections={this.onSetCouponSelections}
          selections={selections}
          shopID={shopID}
        />
        <CouponModules.CouponSubmitButton
          isSubmitting={this.props.isEditingCoupon}
          couponInfo={couponInfo}
          couponType={couponType}
          onClick={() => this.props.onSaveChangedCoupon(couponInfo)}
          editOnly
        />
      </div>
    );
  }
}

MarketingEditCoupon.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }).isRequired,
  couponInfo: PropTypes.shape({
    couponType: PropTypes.oneOf(Object.keys(COUPON_TYPES)),
    createdAt: PropTypes.string,
    name: PropTypes.string,
    selections: PropTypes.object,
    shopID: PropTypes.string
  }),
  isEditingCoupon: PropTypes.bool,
  onSaveChangedCoupon: PropTypes.func.isRequired
};

MarketingEditCoupon.defaultProps = {
  isEditingCoupon: false
};

export default withContext(MerchantInterfaceConsumer)(MarketingEditCoupon);
