// BUTI DINERS, INC. All right Reserved ©

// Utils
import { _isTrue } from "utils";

const _getSubmitButtonStatus = ({
  addToMenu = false,
  newMenu,
  selectedMenuId = "",
}) => {
  if (!_isTrue(addToMenu)) return "active";
  if (selectedMenuId === "createNewMenu") {
    const { menuName = "" } = newMenu;
    return menuName ? "active" : "inactive";
  }
  return selectedMenuId ? "active" : "inactive";
};

export { _getSubmitButtonStatus };
