// BUTI DINERS, INC. All right Reserved ©

import MailboxInfo from "./MailboxInfo";
import Setup from "./Setup";
import WelcomePage from "./WelcomePage";

const CombineThirdPartyOrdersModules = {
  MailboxInfo,
  Setup,
  WelcomePage,
};

export default CombineThirdPartyOrdersModules;
