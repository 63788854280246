// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const FloatingButton = props => (
  <div className={Style.container}>
    <button
      className={cx(Style.floatingButton, props.buttonClassname)}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  </div>
);

FloatingButton.propTypes = {
  buttonClassname: PropTypes.any,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FloatingButton;
