// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import { IMPORT_STEPS } from "../index";
import { _getSubmitButtonStatus } from "./helperFunctions";

// Utils
import { _isTrue } from "utils";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals, RadioGroup } from "components";

// Fields
import { Button, Input } from "fields";

// Assets
import { ArrowIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

class Submit extends React.Component {
  confirmNotif = null;

  state = {
    addToMenu: true,
    newMenu: {
      isMenuForCatering: { false: "checked" },
      menuName: "",
    },
    selectedMenuId: "",
  };

  componentDidMount = () =>
    this.setState({ isGettingMenus: true }, this.onGetExistingMenus);

  onGetExistingMenus = async () => {
    const { Merchants } = Services;
    const {
      GetAllModifierGroups,
      GetAllModifiers,
      GetShopAllGroups,
      GetShopAllItems,
      GetShopAllMenus,
    } = Merchants.GetRequests;
    try {
      const { allMenus = {} } = await GetShopAllMenus({
        shopID: this.props.shopID,
      });
      const { allGroups = {} } = await GetShopAllGroups({
        shopID: this.props.shopID,
      });
      const { allItems = {} } = await GetShopAllItems({
        shopID: this.props.shopID,
      });
      const { allModifierGroups = {} } = await GetAllModifierGroups({
        shopID: this.props.shopID,
      });
      const { allModifiers = {} } = await GetAllModifiers({
        shopID: this.props.shopID,
      });
      this.setState({
        allGroups,
        allItems,
        allMenus,
        allModifierGroups,
        allModifiers,
      });
    } catch {
      this.setState({
        allGroups: {},
        allItems: {},
        allMenus: {},
        allModifierGroups: {},
        allModifiers: {},
      });
    } finally {
      const allMenusCount = Object.keys(this.state.allMenus).length;
      this.setState({
        isGettingMenus: false,
        selectedMenuId: allMenusCount === 0 ? "createNewMenu" : "",
      });
    }
  };

  onImportSuccess = ({ newMenuId, newMenuInfo }) =>
    this.setState({ isImportingMenu: false }, () =>
      this.props.onAddNewMenu({ newMenuId, newMenuInfo })
    );

  onSubmit = async () => {
    const { Merchants } = Services;
    const {
      CreateNewMenu,
      SaveChangedMenuInfo,
      SaveNewAllGroups,
      SaveNewAllItems,
      SaveNewAllModifierGroups,
      SaveNewAllModifiers,
    } = Merchants.PostRequests;
    const { menu = {}, shopID } = this.props;
    const { addToMenu, newMenu, selectedMenuId } = this.state;
    const { groupsForMenu = {} } = menu;

    try {
      await SaveNewAllGroups({
        allGroups: { ...menu.groups, ...this.state.allGroups },
        shopID,
      });
      await SaveNewAllItems({
        allItems: { ...menu.items, ...this.state.allItems },
        shopID,
      });
      await SaveNewAllModifierGroups({
        allModifierGroups: {
          ...menu.modifierGroups,
          ...this.state.allModifierGroups,
        },
        shopID,
      });
      await SaveNewAllModifiers({
        allModifiers: { ...menu.modifiers, ...this.state.allModifiers },
        shopID,
      });
      if (_isTrue(addToMenu)) {
        if (selectedMenuId === "createNewMenu") {
          const newMenuInfo = set(newMenu, "groups", groupsForMenu);
          const { menuID = "" } = await CreateNewMenu({
            menuInfo: newMenuInfo,
            shopID,
          });
          this.onImportSuccess({ newMenuId: menuID, newMenuInfo });
        } else {
          const selectedMenu = this.state.allMenus[selectedMenuId];
          const { groups = {} } = selectedMenu;
          const menuInfo = set(selectedMenu, "groups", {
            ...groups,
            ...groupsForMenu,
          });
          await SaveChangedMenuInfo({
            menuID: selectedMenuId,
            menuInfo,
            shopID,
          });
          this.onImportSuccess({
            newMenuId: selectedMenuId,
            newMenuInfo: menuInfo,
          });
        }
      }
      this.onImportSuccess({ newMenuId: "", newMenuInfo: {} });
    } catch {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: "An error occured. Please try again.",
        type: "error",
      });
    }
  };

  renderContent = () => {
    const {
      addToMenu = false,
      allMenus = {},
      selectedMenuId = "",
    } = this.state;
    return (
      <div>
        <RadioGroup
          description="Add categories, items, modifier groups, & modifers to a menu"
          heading="Add categories, items, modifier groups, & modifers to a menu"
          onClick={(optionId) => this.setState({ addToMenu: optionId })}
          options={{
            true: {
              label: "Yes",
              description: "Add elements to an existing or new menu",
            },
            false: {
              label: "No",
              description: "Add elements without creating/adding to a menu",
            },
          }}
          required
          selectedOptionId={String(addToMenu)}
        />
        {_isTrue(addToMenu) && this.renderMenuSelect(allMenus)}
        {_isTrue(addToMenu) &&
          selectedMenuId === "createNewMenu" &&
          this.renderNewMenuInput()}
      </div>
    );
  };

  renderMenuSelect = (allMenus = {}) => (
    <div style={{ marginTop: "20px" }}>
      <RadioGroup
        heading="Select a menu"
        onClick={(menuId) => this.setState({ selectedMenuId: menuId })}
        options={set(
          Object.keys(allMenus).reduce((result, menuId) => {
            const { groups = {}, menuName } = allMenus[menuId];
            return set(result, menuId, {
              description: `${Object.keys(groups).length} categories`,
              label: menuName,
            });
          }, {}),
          "createNewMenu",
          { label: "Create New Menu" }
        )}
        required
        selectedOptionId={this.state.selectedMenuId}
      />
    </div>
  );

  renderNewMenuInput = () => {
    const { newMenu = {} } = this.state;
    return (
      <div style={{ marginTop: "20px" }}>
        <Input.TextInput
          className={Style.inputField}
          label="New Menu Name"
          onChange={(value) =>
            this.setState({ newMenu: set(newMenu, "menuName", value) })
          }
          name="new menu name"
          value={newMenu.menuName}
        />
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isImportingMenu && (
          <Modals.LoadingModal message="Importing..." />
        )}
        <div>
          {this.state.isGettingMenus ? (
            <div>Finding existing menus...</div>
          ) : (
            this.renderContent()
          )}
          <div className={CommonStyle.footer}>
            <div className={CommonStyle.footerContent}>
              <Button
                className={CommonStyle.backButton}
                name="to previous step"
                onClick={() => this.props.onChangeStep("step2")}
              >
                <ArrowIcon /> {IMPORT_STEPS["step2"].name}
              </Button>
              <Button
                className={CommonStyle.nextButton}
                name="import menu"
                onClick={() => {
                  this.setState({ isImportingMenu: true }, this.onSubmit);
                }}
                status={_getSubmitButtonStatus(this.state)}
              >
                Import Menu
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Submit.propTypes = {
  menu: PropTypes.exact({
    groupsForMenu: PropTypes.object.isRequired,
    groups: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    modifierGroups: PropTypes.object.isRequired,
    modifiers: PropTypes.object.isRequired,
  }).isRequired,
  onAddNewMenu: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default Submit;
