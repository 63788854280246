// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import _get from "lodash.get";

import {
  _getSubmitBtnStatus,
  _vetItemInfoBeforeSubmit,
} from "./HelperFunctions";

import {
  _findSelectedCheckboxOptionIDs,
  _findSelectedRadioOptionID,
} from "components/Modals/MenusManagementModals/HelperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Utils
import { _removeAccents } from "utils";

// Style
import FieldStyle from "components/Modals/style.module.scss";

// Components
import {
  CheckBoxGroup,
  MenusManagementModules,
  Modals,
  RadioGroup,
} from "components";

// Fields
import { Box, Button, Input } from "fields";

// Lib
import { Constants } from "lib";
const { TextInput } = Input;

class ItemFormFields extends Component {
  state = {
    inputErrors: {},
    itemInfo: {},
  };

  componentDidMount = () => this.setState({ itemInfo: this.props.itemInfo });

  onAddModifierGroupToItem = (modifierGroupID) =>
    this.setState({
      itemInfo: set(
        this.state.itemInfo,
        `modifierGroups.${modifierGroupID}`,
        "buti"
      ),
    });

  onChangeTextInputValue = (field_id, value, input_value_type) => {
    const { itemInfo = {} } = this.state;
    let inputValue = value;
    if (field_id === "itemSaleRate") {
      inputValue = parseFloat(inputValue);
      if (inputValue < 0) inputValue = 0;
      else if (inputValue > 99) inputValue = 99;
    }
    if (input_value_type === "number") {
      this.setState({
        itemInfo: set(itemInfo, field_id, inputValue),
      });
    } else {
      this.setState({
        itemInfo: set(itemInfo, field_id, _removeAccents({ text: inputValue })),
      });
    }
  };

  onClickCheckbox = (fieldID, optionID) => {
    const { itemInfo } = this.state;
    const path = `${fieldID}.${optionID}`;
    this.setState({
      itemInfo: !_get(itemInfo, path)
        ? set(itemInfo, path, "checked")
        : del(itemInfo, path),
    });
  };

  onClickRadioButton = (fieldID, optionID) => {
    const { itemInfo } = this.state;
    const result = set(itemInfo, fieldID, { [optionID]: "checked" });
    this.setState({ itemInfo: result });
  };

  onGetReadOnlyStatus = (fieldID) => {
    if (this.props.isReadOnly) return true;
    else if (this.props.isInEditMode) {
      const role = _get(this.props.context, "personnel.role");
      if (role === "owner") return false;
      else {
        if (
          [
            "itemName",
            "itemKitchenChitName",
            "itemIsOnSale",
            "itemPrice",
          ].includes(fieldID)
        )
          return true;
        return false;
      }
    }
    return false;
  };

  onRemoveModifierGroup = (modifierGroupID) =>
    this.setState({
      itemInfo: del(this.state.itemInfo, `modifierGroups.${modifierGroupID}`),
    });

  onHideAddModifierGroupModal = () =>
    this.setState({ showAddModifierGroupModal: false });

  onShowAddModifierGroupModal = () =>
    this.setState({ showAddModifierGroupModal: true });

  onSubmitForm = () =>
    this.props.onSubmit(
      _vetItemInfoBeforeSubmit({ itemInfo: this.state.itemInfo })
    );

  renderField = (field = {}) => {
    const { inputErrors = {}, itemInfo = {} } = this.state;
    const { fieldKind, id, type } = field;

    switch (fieldKind) {
      case "checkboxes":
        return (
          <div className={FieldStyle.radioForm} key={id}>
            <CheckBoxGroup
              heading={field.label || ""}
              onClick={(optionID) => this.onClickCheckbox(id, optionID)}
              options={field.options || {}}
              required={field.required || false}
              selectedOptionIds={_findSelectedCheckboxOptionIDs(
                itemInfo[id] || {}
              )}
            />
          </div>
        );
      case "radio":
        return (
          <div className={FieldStyle.radioForm} key={id}>
            <RadioGroup
              heading={field.label || ""}
              onClick={(optionID) => this.onClickRadioButton(id, optionID)}
              options={field.options || {}}
              required={field.required || false}
              selectedOptionId={_findSelectedRadioOptionID(itemInfo[id] || {})}
            />
          </div>
        );
      case "text":
      case "textarea":
        if (id === "itemSaleRate") {
          const { itemIsOnSale = { false: "checked" } } = itemInfo;
          if (itemIsOnSale.false) return null;
        }
        return (
          <div className={FieldStyle.formField} key={id}>
            <TextInput
              {...field}
              errortext={inputErrors[id]}
              hasMultipleLines={fieldKind === "textarea" ? true : false}
              readOnly={this.onGetReadOnlyStatus(id)}
              name={id}
              onChange={(value) => this.onChangeTextInputValue(id, value, type)}
              showError
              value={itemInfo[id] || ""}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderSubmitButton = () =>
    !this.props.isReadOnly && (
      <Button
        className={FieldStyle.saveButton}
        color="info"
        name="Save"
        onClick={this.onSubmitForm}
        status={_getSubmitBtnStatus(this.state)}
      >
        {this.props.isInEditMode ? "Save" : "Create"}
      </Button>
    );

  render() {
    const { NEW_ITEM_FIELDS } = Constants;
    const { itemInfo } = this.state;
    return (
      <div>
        {this.state.showAddModifierGroupModal && (
          <Modals.AddModifierGroupModal
            addedModifierGroups={itemInfo.modifierGroups}
            destinationName={itemInfo.itemName}
            onAddModifierGroup={this.onAddModifierGroupToItem}
            onCloseModal={this.onHideAddModifierGroupModal}
          />
        )}
        <Box header="Item Info">{NEW_ITEM_FIELDS.map(this.renderField)}</Box>
        <MenusManagementModules.AddedModifierGroupsModule
          modifierGroups={itemInfo.modifierGroups}
          onClickAddButton={this.onShowAddModifierGroupModal}
          onRemoveModifierGroup={this.onRemoveModifierGroup}
        />
        {this.renderSubmitButton()}
      </div>
    );
  }
}

ItemFormFields.propTypes = {
  context: PropTypes.shape({
    personnel: PropTypes.object.isRequired,
  }).isRequired,
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  itemInfo: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

ItemFormFields.defaultProps = {
  isInEditMode: false,
  isReadOnly: false,
  itemInfo: {},
};

export default withContext(MerchantInterfaceConsumer)(ItemFormFields);
