// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

import CreateCoupon from "./CreateCoupon";
import EditCoupon from "./EditCoupon";
import MarketingOption from "./MarketingOption";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import MerchantViewStyle from "views/style.module.scss";
import Style from "./style.module.scss";

// Fields
import { LoadingSpinner, PageMsg } from "fields";

// Icons
import { ArrowIcon } from "assets/Icons";

// Lib
import { Constants, Functions, Services } from "lib";

const { ShowConfirmNotif } = Functions;
const { Merchants } = Services;
const { GetShopActiveCoupons } = Merchants.GetRequests;
const { COUPON_TYPES } = Constants;

class Marketing extends React.Component {
  confirmNotif = null;

  state = { activeCoupons: {}, currentStage: "allMarketingOptions" };

  componentDidMount = () => this.onGetActiveCoupons();

  onGetActiveCoupons = async () => {
    this.setState({ isGettingCoupons: true });
    const { shopID } = this.props.context;
    const { activeCoupons = {} } = await GetShopActiveCoupons({ shopID });
    this.setState({ activeCoupons, isGettingCoupons: false });
  };

  onCreateNewCoupon = couponType =>
    this.setState({ currentStage: "newCoupon", couponType });

  onEditCoupon = couponID =>
    this.setState({ currentStage: "editCoupon", couponID });

  onShowAllOptions = () => {
    if (!this.state.isCreatingCoupon && !this.state.isEditingCoupon) {
      this.setState({
        currentStage: "allMarketingOptions",
        couponID: "",
        couponType: ""
      });
    }
  };

  // --------------------------------
  // Main methods for backend Calls

  onCreateCoupon = async (coupon = {}) => {
    this.setState({ isCreatingCoupon: true });
    const { shopID } = this.props.context;
    try {
      const { CreateCoupon } = Merchants.PostRequests;
      const { newCouponID = "" } = await CreateCoupon({ coupon, shopID });
      this.confirmNotif = ShowConfirmNotif({
        message: "Coupon successfuly created",
        type: "success"
      });
      const k = immutable.set(this.state.activeCoupons, newCouponID, coupon);
      this.setState(
        { activeCoupons: k, isCreatingCoupon: false },
        this.onShowAllOptions
      );
    } catch {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error"
      });
      this.setState({ isCreatingCoupon: false });
    }
  };

  onSaveChangedCoupon = async coupon => {
    this.setState({ isEditingCoupon: true });
    const { shopID } = this.props.context;
    const { activeCoupons, couponID } = this.state;
    try {
      const { SaveChangedCoupon } = Merchants.PostRequests;
      const params = { couponID, coupon, shopID };
      const { isCouponActive } = await SaveChangedCoupon(params);
      this.confirmNotif = ShowConfirmNotif({
        message: isCouponActive
          ? "Updated the coupon"
          : "Coupon is no longer active",
        type: isCouponActive ? "success" : "warning"
      });
      this.setState(
        {
          activeCoupons: isCouponActive
            ? immutable.set(activeCoupons, couponID, coupon)
            : immutable.del(activeCoupons, couponID),
          isEditingCoupon: false
        },
        this.onShowAllOptions
      );
    } catch {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error"
      });
      this.setState({ isEditingCoupon: false });
    }
  };

  onArchiveCoupon = async archiveCouponID => {
    const { shopID } = this.props.context;
    this.setState({ isRemovingCoupon: true });
    try {
      const { RemoveCoupon } = Merchants.PostRequests;
      const couponArchivedSuccessfully = await RemoveCoupon({
        archiveCouponID,
        shopID
      });
      if (couponArchivedSuccessfully) {
        this.confirmNotif = ShowConfirmNotif({
          message: "Coupon removed successfully",
          type: "success"
        });
      } else {
        this.confirmNotif = ShowConfirmNotif({
          message: "An error has occured. Please try again.",
          type: "error"
        });
      }
      this.setState(
        {
          isRemovingCoupon: false,
          currentStage: "allMarketingOptions",
          editCoupon: {},
          isGettingCoupons: true,
          isGettingMenus: true
        },
        () => {
          this.onGetActiveCoupons();
        }
      );
    } catch {
      this.setState({
        isRemovingCoupon: false,
        currentStage: "allMarketingOptions",
        editCoupon: {}
      });
    }
  };

  renderCouponOptions = () =>
    Object.keys(COUPON_TYPES).map(type => {
      const { activeCoupons = {} } = this.state;
      const couponsOfThisType = Object.keys(activeCoupons).reduce(
        (result, couponID) => {
          const coupon = activeCoupons[couponID];
          const { couponType } = coupon;
          if (couponType !== type) return result;
          return immutable.set(result, couponID, coupon);
        },
        {}
      );
      return (
        <React.Fragment key={type}>
          <MarketingOption
            {...COUPON_TYPES[type]}
            couponsOfThisType={couponsOfThisType}
            onCreateNewCoupon={this.onCreateNewCoupon}
            onEditCoupon={this.onEditCoupon}
            type={type}
          />
        </React.Fragment>
      );
    });

  renderContent = () => {
    const { activeCoupons, currentStage, couponID } = this.state;
    const backBtn = (
      <button onClick={this.onShowAllOptions}>
        <ArrowIcon className={Style.backBtn} />
      </button>
    );
    if (currentStage === "allMarketingOptions")
      return (
        <div className={Style.cardsList}>{this.renderCouponOptions()}</div>
      );
    else if (currentStage === "editCoupon")
      return (
        <div>
          {backBtn}
          <EditCoupon
            couponInfo={activeCoupons[couponID]}
            isEditingCoupon={this.state.isEditingCoupon}
            onSaveChangedCoupon={this.onSaveChangedCoupon}
          />
        </div>
      );
    else if (currentStage === "newCoupon")
      return (
        <div>
          {backBtn}
          <CreateCoupon
            couponType={this.state.couponType}
            isCreatingCoupon={this.state.isCreatingCoupon}
            onCreateCoupon={this.onCreateCoupon}
          />
        </div>
      );
  };

  render() {
    return (
      <div>
        <h2 className={MerchantViewStyle.contentTitle}>
          Coupons For Customers
        </h2>
        <p style={{ fontWeight: "500" }}>
          Choose what you want to offer customers and reach out to them.
        </p>
        {this.state.currentStage === "allMarketingOptions" && (
          <strong>
            Total Active Coupons: {Object.keys(this.state.activeCoupons).length}
          </strong>
        )}
        {this.state.isGettingMenus || this.state.isGettingCoupons ? (
          <PageMsg>
            <LoadingSpinner message="Loading Coupons" />
          </PageMsg>
        ) : (
          <div>{this.renderContent()}</div>
        )}
      </div>
    );
  }
}

Marketing.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }).isRequired
};

export default withContext(MerchantInterfaceConsumer)(Marketing);
