// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Alarm, Button, ExpansionPanel } from "fields";

class NewOrdersAlarmModule extends React.Component {
  audioContext = new AudioContext();
  state = {};

  onPlayAudio = () =>
    this.setState({ isPlayingAlarm: !this.state.isPlayingAlarm });

  render() {
    const { alarmStatus, onActivateAlarm } = this.props.context;
    const { isPlayingAlarm } = this.state;
    const isAlarmActive = alarmStatus === "running";

    const moduleTitle = (
      <div>
        New Order Alert{" "}
        <span className={Style[isAlarmActive ? "alarmOn" : "alarmOff"]}>
          {isAlarmActive ? "ACTIVE" : "OFF"}
        </span>
      </div>
    );

    return (
      <ExpansionPanel
        moduleTitle={moduleTitle}
        moduleDescription="The alarm will notify your staff when new orders arrives. You can test the alarm sound by clicking the button below."
        showExpandableContent
      >
        <Alarm shouldPlayAudio={isPlayingAlarm} />
        {isAlarmActive ? (
          <Button
            className={cx(Style.playButton, isPlayingAlarm && Style.stop)}
            hasShadow
            name="Test new order alarm"
            onClick={this.onPlayAudio}
          >
            {isPlayingAlarm ? "Stop Test" : "Test Alarm"}
          </Button>
        ) : (
          <button className={Style.playButton} onClick={onActivateAlarm}>
            Activate Alarm
          </button>
        )}
      </ExpansionPanel>
    );
  }
}

NewOrdersAlarmModule.propTypes = {
  context: PropTypes.shape({
    alarmStatus: PropTypes.oneOf(["running", "suspended"]).isRequired,
    onActivateAlarm: PropTypes.func.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(NewOrdersAlarmModule);
