// BUTI DINERS, INC. All right Reserved ©

import _isEmpty from "lodash.isempty";

const _countNumberOfSelectedFilters = ({ selected_filter_values = {} }) =>
  Object.values(selected_filter_values).reduce(
    (result, value = "") => (_isEmpty(value) ? result : result + 1),
    0
  );

export { _countNumberOfSelectedFilters };
