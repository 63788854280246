// BUTI DINERS, INC. All right Reserved ©

const pad = (n) => {
  return n < 10 ? "0" + n : n;
};

const _convertDateObjectToString = (date = {}) => {
  if (!date) return "";
  const { day = "", month = "", year = "" } = date;
  return `${pad(month)}/${pad(day)}/${year}`;
};

export { _convertDateObjectToString };
