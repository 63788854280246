// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import AdminNavbar from "./AdminNavbar";
import { _getNavbarItems } from "./helperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

class AdminHeader extends React.Component {
  render() {
    const { context, personnel } = this.props;
    const { shopBasicInfo } = context;
    const { show_payouts = false, stripeConnectInfo = {} } = shopBasicInfo;
    const { role = "" } = personnel;
    return (
      <AdminNavbar
        activeNavbarItemId={this.props.activeNavbarItemId}
        navItems={_getNavbarItems({
          role,
          show_payouts,
          stripeConnectInfo,
        })}
        personnel={this.props.personnel}
      />
    );
  }
}

AdminHeader.propTypes = {
  activeNavbarItemId: PropTypes.string.isRequired,
  personnel: PropTypes.shape({
    authorities: PropTypes.array,
    role: PropTypes.oneOf(["owner", "staff"]),
  }),
};

export default withContext(MerchantInterfaceConsumer)(AdminHeader);
