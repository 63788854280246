// BUTI DINERS, INC. All right Reserved ©

const SECTIONS = {
  activity: {
    label: "Activity",
    fields: {
      totalOrdersCount: { label: "Orders count" },
      totalItemsCount: { label: "Items count" },
    },
  },
  top_five: {
    label: "Top 5 Items",
    fields: { quantity: { label: `` } },
  },
  sales: {
    label: "Sales",
    fields: {
      net_sales: { label: "Net sales" },
      salesTax: { label: "Taxes" },
      net_total: { label: "Net total" },
      totalTipAmount: { label: "Tips" },
      net_total_plus_tips: { label: "Net total + tips" },
    },
  },
};

const DATA_TO_HIGHLIGHT = ["net_total", "net_total_plus_tips", "subTotal"];

export { DATA_TO_HIGHLIGHT, SECTIONS };
