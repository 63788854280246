// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { set } from "object-path-immutable";

// Utils
import { _isTrue } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

// Assets
import { DragVerticalIcon } from "assets/Icons";

// Lib
import { Functions } from "lib";

class SortCategoriesModal extends React.Component {
  constructor(props) {
    const { SortMenuCategories } = Functions.FoodMenuFuncs;
    super(props);
    this.state = { listOfCategoryIds: SortMenuCategories(props.categories) };
  }

  onDragEnd = (result = {}) => {
    // dropped outside the list
    if (!result.destination) return;
    this.setState({
      listOfCategoryIds: this.onReorderList(
        this.state.listOfCategoryIds,
        result.source.index,
        result.destination.index
      ),
    });
  };

  onGetItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "#d4dae0" : "inherit",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  onReorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onSaveSortResult = async () => {
    const { categories = {} } = this.props;
    const { listOfCategoryIds = [] } = this.state;
    try {
      await this.props.onSaveSortResult(
        listOfCategoryIds.reduce(
          (result, categoryId, index) =>
            set(result, `${categoryId}.index_in_list`, index),
          categories
        )
      );
    } catch (e) {
      throw e;
    }
  };

  renderCategories = () => {
    const { categories = {} } = this.props;
    return this.state.listOfCategoryIds.map((categoryId, index) => {
      const { groupIsArchived, groupName } = categories[categoryId];
      return (
        <Draggable key={categoryId} draggableId={categoryId} index={index}>
          {(provided, snapshot) => (
            <div
              className={Style.categoryContainer}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.onGetItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <DragVerticalIcon /> {groupName}
              {_isTrue(groupIsArchived) && (
                <div className={Style.archived_sign}>Archived</div>
              )}
            </div>
          )}
        </Draggable>
      );
    });
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentLabel="Sort Categories Modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Save",
            loadingText: "Saving",
          },
        }}
        headerProps={{ header: "Sort Categories" }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.onSaveSortResult}
      >
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.renderCategories()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ResponsiveModal>
    );
  }
}

SortCategoriesModal.propTypes = {
  categories: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSaveSortResult: PropTypes.func.isRequired,
};

export default SortCategoriesModal;
