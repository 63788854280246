// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { CombineThirdPartyOrdersModules } from "components";

// Lib
import { Services } from "lib";

const { Merchants } = Services;
const { GetCombineThirdPartyOrdersInfo } = Merchants.GetRequests;

class CombineThirdPartyOrders extends React.Component {
  state = { currentPageId: "" };

  componentDidMount = () => this.onGetInfo();

  onChangePage = ({ pageId = "" }) =>
    pageId && this.setState({ currentPageId: pageId });

  onGetInfo = async () => {
    this.setState({ isLoading: true });
    try {
      const {
        combineThirdPartyOrdersInfo: info = {},
      } = await GetCombineThirdPartyOrdersInfo({
        shopID: this.props.context.shopID,
      });
      this.setState({
        combineThirdPartyOrdersInfo: info,
        currentPageId:
          Object.keys(info).length === 0 ? "welcome" : "mailbox_info",
      });
    } catch {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderContent = () => {
    switch (this.state.currentPageId) {
      case "mailbox_info":
        return (
          <CombineThirdPartyOrdersModules.MailboxInfo
            combineThirdPartyOrdersInfo={this.state.combineThirdPartyOrdersInfo}
          />
        );
      case "setup":
        return (
          <CombineThirdPartyOrdersModules.Setup
            onChangePage={this.onChangePage}
          />
        );
      case "welcome":
        return (
          <CombineThirdPartyOrdersModules.WelcomePage
            onChangePage={this.onChangePage}
          />
        );
      default:
        return;
    }
  };

  render() {
    return this.state.isLoading ? (
      <div>Loading...</div>
    ) : (
      <div>{this.renderContent()}</div>
    );
  }
}

CombineThirdPartyOrders.propTypes = {
  context: PropTypes.shape({
    shopID: PropTypes.string.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(CombineThirdPartyOrders);
