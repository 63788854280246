// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import {
  _createActions,
  _createContentForCustomerReminder,
} from "./HelperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, CenterModal, Link } from "fields";

// Lib
import { Constants, Functions, Services } from "lib";

// Icons
import { PhoneIcon } from "assets/Icons";

class PastOrderActionsModal extends React.Component {
  confirmNotif = null;
  state = {};

  onGetButtonStatus = (actionID) => {
    switch (actionID) {
      case "printOrder":
        return this.state.isPrintingOrder ? "loading" : "active";
      case "sendReminder":
        return this.state.sendingReminder ? "loading" : "active";
      default:
        return "active";
    }
  };

  onPrintOrder = () => {
    const { context, orderID, orderInfo } = this.props;
    const { OrdersManagement, ShowConfirmNotif } = Functions;
    const { _printOrder } = OrdersManagement;
    const {
      addedPrinters,
      onConnectToPrintServer = () => {},
      onUpdateAddedPrinters = () => {},
      printerQZConfigs,
      shopBasicInfo,
    } = context;
    const { timeZone } = shopBasicInfo;
    Object.keys(addedPrinters).forEach((printerName) => {
      const { printerBrand } = addedPrinters[printerName];
      try {
        _printOrder({
          qzConfigForPrinter: printerQZConfigs[printerName],
          orderID,
          orderInfo,
          printerBrand,
          printerName,
          shopTimeZone: timeZone,
        });
      } catch (error) {
        let message = error;
        if (error === "printServerNotConnected") {
          message = "Print server is not connected";
          onConnectToPrintServer();
        } else if (error === "printerNotConnected") {
          message = `${printerName} printer is not connected`;
          onUpdateAddedPrinters(
            set(addedPrinters, `${printerName}.isConnected`, false)
          );
        }
        this.confirmNotif = ShowConfirmNotif({ message, type: "error" });
      } finally {
        this.props.onCloseModal();
      }
    });
  };

  onSendReminderToCustomer = async () => {
    const { orderDeliveryTypeID, phoneNumber } = this.props.orderInfo;
    const { REGEX } = Constants;
    const { SendTextMessage } = Services.BUTI.PostRequests;
    this.setState({ sendingReminder: true });
    const { name } = this.props.context.shopBasicInfo;
    const { message, notifMsg } = _createContentForCustomerReminder({
      orderDeliveryTypeID,
      shopName: name,
    });
    if (REGEX.US_PHONE_NUMBER.test(phoneNumber)) {
      await SendTextMessage({ body: message, to: phoneNumber });
    }
    this.setState({ sendingReminder: false }, () => {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: notifMsg,
        type: "success",
      });
      this.props.onCloseModal();
    });
  };

  renderActions = () => {
    const { allowPrintingOrder = "false" } = this.props.context.shopBasicInfo;
    const { orderDeliveryTypeID } = this.props.orderInfo;
    const actions = _createActions({
      allowPrintingOrder,
      onPrintOrder: this.onPrintOrder,
      onSendReminderToCustomer: this.onSendReminderToCustomer,
      onShowRefundModal: this.props.onShowRefundModal,
      orderDeliveryTypeID,
    });
    return Object.keys(actions).map((actionID) => {
      const { action, icon, label = "", loadingText } = actions[actionID];
      return (
        label && (
          <div key={actionID}>
            <Button
              className={Style.actionButton}
              hasShadow
              loadingText={loadingText}
              name={label}
              onClick={action}
              status={this.onGetButtonStatus(actionID)}
            >
              {icon}
              {label}
            </Button>
          </div>
        )
      );
    });
  };

  renderCustomerPhoneNumber = (phoneNumber) => (
    <Link
      className={Style.customerPhone}
      href={`tel:${phoneNumber}`}
      target="_self"
    >
      <PhoneIcon className={Style.phoneIcon} /> {phoneNumber}
    </Link>
  );

  renderTitle = () => {
    const {
      customerName,
      orderDeliveryTypeID,
      phoneNumber,
      pickUpTime,
      tableNumber,
    } = this.props.orderInfo;
    const { ORDER_DELIVERY_TYPES } = Constants;
    const { label } = ORDER_DELIVERY_TYPES[orderDeliveryTypeID];
    let text = "";
    if (orderDeliveryTypeID === "inStore") text = `Table: ${tableNumber}`;
    else if (orderDeliveryTypeID === "pickUp") text = `Time: ${pickUpTime}`;
    return (
      <div className={Style.title}>
        <h1>
          {label} order for <strong>{customerName}</strong>
        </h1>
        {text && <p>{text}</p>}
        {orderDeliveryTypeID !== "inStore" &&
          this.renderCustomerPhoneNumber(phoneNumber)}
      </div>
    );
  };

  render() {
    return (
      <CenterModal
        contentLabel="Actions for past order modal"
        onCloseModal={this.props.onCloseModal}
        modalBoxClassname={Style.modalBox}
        contentContainerClassname={Style.modalContainer}
        showCloseButton={false}
        shouldCloseOnOverlayClick
      >
        {this.renderTitle()}
        <div>{this.renderActions()}</div>
        <Button
          className={Style.closeModalButton}
          hasShadow
          onClick={this.props.onCloseModal}
          name="Close actions modal"
        >
          Back
        </Button>
      </CenterModal>
    );
  }
}

PastOrderActionsModal.defaultProps = {
  context: PropTypes.shape({
    addedPrinters: PropTypes.object,
    printerQZConfigs: PropTypes.object.isRequired,
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onShowRefundModal: PropTypes.func.isRequired,
  orderID: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    email: PropTypes.string,
    orderDeliveryTypeID: PropTypes.oneOf(["inStore", "pickUp"]).isRequired,
    phoneNumber: PropTypes.string,
    pickUpTime: PropTypes.string,
    tableNumber: PropTypes.string,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(PastOrderActionsModal);
