// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import ArchiveButton from "views/MenusManagement/ArchiveButton";
import ItemFormFields from "./ItemFormFields";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions, Services } from "lib";

class MenuItemModal extends Component {
  confirmNotif = null;

  state = { showLoadingModal: false };

  componentWillUnmount = () => this.setState({ showLoadingModal: false });

  onArchiveItem = ({ shouldArchiveItem = false }) => {
    const { Merchants } = Services;
    const { ArchiveItem } = Merchants.PostRequests;
    const { ShowConfirmNotif } = Functions;
    this.setState({ showLoadingModal: true });
    const { context, itemID } = this.props;
    const { shopID } = context;
    ArchiveItem({ itemID, shouldArchiveItem, shopID })
      .then(() => {
        this.confirmNotif = ShowConfirmNotif({
          message: `${shouldArchiveItem ? "Archived" : "Restored"} ${
            this.props.itemInfo.itemName
          }`,
          type: "success",
        });
        this.props.onCloseModal();
        this.props.onUpdateAfterItemArchived({ shouldArchiveItem });
      })
      .catch(() => {
        this.confirmNotif = ShowConfirmNotif({
          message: "An error has occured. Please try again.",
          type: "error",
        });
      });
  };

  onCreateItem = async (itemInfo) => {
    const { Merchants } = Services;
    const { CreateNewItem } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const res = await CreateNewItem({
      itemInfo,
      shopID: this.props.context.shopID,
    });
    if (res.newItemID && res.sanitizedItemInfo) {
      this.props.onUpdateAfterItemCreated({
        itemID: res.newItemID,
        itemInfo: res.sanitizedItemInfo,
      });
      return true;
    }
    return false;
  };

  onSaveItem = async (itemInfo) => {
    const { Merchants } = Services;
    const { SaveChangedItemInfo } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { itemID } = this.props;
    const params = { itemID, itemInfo, shopID: this.props.context.shopID };
    const { sanitizedItemInfo } = await SaveChangedItemInfo(params);
    if (sanitizedItemInfo) {
      this.props.onUpdateAfterItemSaved({ itemInfo: sanitizedItemInfo });
      return true;
    }
    return false;
  };

  onSubmitItemInfo = async (itemInfo) => {
    const { ShowConfirmNotif } = Functions;
    const { isInEditMode } = this.props;
    const res = await (isInEditMode
      ? this.onSaveItem(itemInfo)
      : this.onCreateItem(itemInfo));
    if (res) {
      this.confirmNotif = ShowConfirmNotif({
        message: `${isInEditMode ? "Saved" : "Created"} ${itemInfo.itemName}`,
        type: "success",
      });
      this.props.onCloseModal();
    } else {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error",
      });
    }
  };

  onHideConfirmArchiveModal = () =>
    this.setState({ showConfirmArchiveModal: false });

  onShowConfirmArchiveModal = () =>
    this.setState({ showConfirmArchiveModal: true });

  renderArchiveItemButton = () => {
    const { itemIsArchived } = this.props.itemInfo;
    return (
      <ArchiveButton
        isInEditMode={this.props.isInEditMode}
        isObjectArchived={itemIsArchived}
        isReadOnly={this.props.isReadOnly}
        name="Archive Item"
        onClick={() => {
          itemIsArchived
            ? this.onArchiveItem({ shouldArchiveItem: false })
            : this.onShowConfirmArchiveModal();
        }}
      />
    );
  };

  renderModalTitle = () => {
    if (this.props.isReadOnly) return "Item info";
    return `${this.props.isInEditMode ? "Edit" : "New"} item`;
  };

  render() {
    const { isInEditMode, isReadOnly, itemInfo } = this.props;
    const { showConfirmArchiveModal } = this.state;
    return (
      <ResponsiveModal
        classNames={{ modal: CommonStyle.modal }}
        contentLabel="Create or edit item modal"
        headerProps={{
          close_button_icon: "arrow",
          header: this.renderModalTitle(),
        }}
        onCloseModal={this.props.onCloseModal}
      >
        {showConfirmArchiveModal && (
          <Modals.ConfirmArchiveItemModal
            itemName={itemInfo.itemName}
            onArchiveItem={() =>
              this.onArchiveItem({ shouldArchiveItem: true })
            }
            onCloseModal={this.onHideConfirmArchiveModal}
          />
        )}
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        <ItemFormFields
          isInEditMode={isInEditMode}
          isReadOnly={isReadOnly}
          itemInfo={itemInfo}
          onSubmit={this.onSubmitItemInfo}
        />
        {this.renderArchiveItemButton()}
      </ResponsiveModal>
    );
  }
}

MenuItemModal.propTypes = {
  context: PropTypes.shape({
    personnel: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }),
  isInEditMode: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  itemInfo: PropTypes.object,
  itemID: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onUpdateAfterItemArchived: PropTypes.func,
  onUpdateAfterItemCreated: PropTypes.func,
  onUpdateAfterItemSaved: PropTypes.func,
};

MenuItemModal.defaultProps = {
  isInEditMode: false,
  isReadOnly: false,
  itemInfo: {},
};

export default withContext(MerchantInterfaceConsumer)(MenuItemModal);
