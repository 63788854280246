// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Utils
import { _roundNumber } from "utils";

// Style
import Style from "./style.module.scss";

class Modifiers extends React.Component {
  renderModifiers = (modifiers = {}) => {
    const keys = Object.keys(modifiers);
    return keys.reduce((result, modifierID, index) => {
      const {
        modifierKitchenChitName = "",
        modifierName = "",
        modifierPrice = 0,
      } = modifiers[modifierID];
      const price = _roundNumber(modifierPrice);
      const priceText = ` ($${price.toFixed(2)})`;
      return (
        result +
        `${modifierKitchenChitName || modifierName}${priceText}${
          index < keys.length - 1 ? ", " : ""
        }`
      );
    }, "");
  };

  renderModifierGroups = (modifierGroups) => {
    const keys = Object.keys(modifierGroups);
    return keys.reduce((result, modifierGroupID, index) => {
      const { modifiers = {} } = modifierGroups[modifierGroupID];
      return (
        result +
        `${this.renderModifiers(modifiers)}${
          index < keys.length - 1 ? ", " : ""
        }`
      );
    }, "");
  };

  render() {
    const { modifierGroups = {} } = this.props;
    return (
      Object.keys(modifierGroups).length > 0 && (
        <div
          style={{ display: "flex", flexWrap: "wrap", lineHeight: "1.2rem" }}
        >
          <div className={Style.itemAddition}>Item Addition:</div>
          {this.renderModifierGroups(modifierGroups)}
        </div>
      )
    );
  }
}

Modifiers.propTypes = {
  modifierGroups: PropTypes.object,
};

Modifiers.defaultProps = {
  modifierGroups: {},
};

export default Modifiers;
