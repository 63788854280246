// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Utils
import { _roundNumber } from "utils";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Chip } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

class Modifier extends Component {
  state = { showEditModifierModal: false };

  componentDidMount = () =>
    this.setState({ modifierInfo: this.props.modifierInfo });

  onHideEditModifierModal = () =>
    this.setState({ showEditModifierModal: false });

  onShowEditModifierModal = (e) => {
    e && e.preventDefault();
    this.setState({ showEditModifierModal: true });
  };

  onUpdateAfterModifierDeleted = () =>
    this.setState({ modifierInfo: null }, () =>
      this.props.onUpdateAllModifiers({
        modifierID: this.props.modifierID,
        modifierInfo: null,
      })
    );

  onUpdateAfterModifierSaved = ({ modifierInfo }) =>
    this.setState({ modifierInfo }, () =>
      this.props.onUpdateAllModifiers({
        modifierID: this.props.modifierID,
        modifierInfo,
      })
    );

  renderEditModal = () => (
    <Modals.ModifierModal
      isInEditMode={true}
      isReadOnly={this.props.isReadOnly}
      modifierID={this.props.modifierID}
      modifierInfo={this.state.modifierInfo}
      onCloseModal={this.onHideEditModifierModal}
      onUpdateAfterModifierDeleted={this.onUpdateAfterModifierDeleted}
      onUpdateAfterModifierSaved={this.onUpdateAfterModifierSaved}
    />
  );

  renderHelperButton = () => {
    const { modifierHelperButton } = this.props;
    if (!this.props.showHelperButton) return;
    let buttonContent;
    if (modifierHelperButton)
      buttonContent = modifierHelperButton(this.props.modifierID);
    else
      buttonContent = (
        <PencilIcon
          className={CommonStyle.pencilIcon}
          onClick={this.onShowEditModifierModal}
        />
      );
    return buttonContent;
  };

  renderModifier = () => {
    const { modifierInfo = {} } = this.state;
    const { modifierName = "", modifierPrice = 0 } = modifierInfo;
    return (
      <Chip
        label={
          <React.Fragment>
            <div className={CommonStyle.chipName}>{modifierName}</div>
            <div className={CommonStyle.price}>
              ${_roundNumber(modifierPrice).toFixed(2)}
            </div>
          </React.Fragment>
        }
        onClickLabel={this.onShowEditModifierModal}
        helperButtonContent={this.renderHelperButton()}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showEditModifierModal && this.renderEditModal()}
        {this.state.modifierInfo && this.renderModifier()}
      </React.Fragment>
    );
  }
}

Modifier.propTypes = {
  isInEditMode: PropTypes.bool,
  modifierHelperButton: PropTypes.func,
  modifierID: PropTypes.string.isRequired,
  modifierInfo: PropTypes.shape({ modifierName: PropTypes.string }).isRequired,
  onUpdateAllModifiers: PropTypes.func,
};

Modifier.defaultProps = {
  isInEditMode: false,
  onUpdateAllModifiers: () => {},
};

export default Modifier;
