// BUTI DINERS, INC. All right Reserved ©

// Assets
import Facebook_Logo from "assets/Images/facebook.png";
import Google_Logo from "assets/Images/google.png";
import Instagram_Logo from "assets/Images/instagram.png";
import TripAdvisor_Logo from "assets/Images/tripadvisor.png";
import Yelp_Logo from "assets/Images/yelp.png";

// Lib
import REGEX from "lib/Constants/RegEx";

const {
  FACEBOOK_LINK,
  GOOGLE_LINK,
  IG_LINK,
  TRIP_ADVISOR_LINK,
  YELP_LINK,
} = REGEX;

const SOCIAL_MEDIA_COMPANIES = {
  facebook: {
    logo: Facebook_Logo,
    name: "Facebook",
    placeholder: "https://www.facebook.com/your-restaurant",
    regEx: FACEBOOK_LINK,
  },
  google: {
    logo: Google_Logo,
    name: "Google",
    placeholder: "https://www.google.com/search?q=your+restaurant",
    regEx: GOOGLE_LINK,
  },
  instagram: {
    logo: Instagram_Logo,
    name: "Instagram",
    placeholder: "https://www.instagram.com/your-restaurant",
    regEx: IG_LINK,
  },
  tripadvisor: {
    logo: TripAdvisor_Logo,
    name: "TripAdvisor",
    placeholder: "https://www.tripadvisor.com/your-restaurant",
    regEx: TRIP_ADVISOR_LINK,
  },
  yelp: {
    logo: Yelp_Logo,
    name: "Yelp",
    placeholder: "https://www.yelp.com/writeareview/biz/your-restaurant",
    regEx: YELP_LINK,
  },
};

export { SOCIAL_MEDIA_COMPANIES };
