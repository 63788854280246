/* eslint-disable jsx-a11y/img-redundant-alt */
// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const placeholderImg = require("assets/Images/image_placeholder.png");

const Img = (props) => {
  const onError = (e) => {
    e.target.src = placeholderImg;
    props.onError();
  };

  return (
    <img
      alt={props.alt}
      onClick={props.onClick}
      onLoad={props.onLoad}
      className={cx(Style.image, props.className)}
      src={props.src || placeholderImg}
      style={props.style}
      onError={(e) => onError(e)}
    />
  );
};

Img.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  src: PropTypes.string,
};

Img.defaultProps = {
  alt: "Photo not found",
  className: "",
  onError: () => {},
  onLoad: () => {},
  src: "",
};

export default Img;
