// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import dayjs from "dayjs";

// Style
import Style from "./style.module.scss";

// Assets

import Rating_1 from "assets/Images/rating-1.png";
import Rating_2 from "assets/Images/rating-2.png";
import Rating_3 from "assets/Images/rating-3.png";
import Rating_4 from "assets/Images/rating-4.png";
import Rating_5 from "assets/Images/rating-5.png";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const _createColumnsForTable = () => [
  {
    accessor: "selected_rating",
    header: "Rating",
  },
  {
    accessor: "feedback_text",
    header: "Comment",
  },
  {
    accessor: "uuid",
    header: "Guest",
  },
  {
    accessor: "created_at",
    header: "Created At",
  },
];

const _convertDateFormat = ({ date }) => {
  const { day, month, year } = date;
  return `${year}-${_pad(month)}-${_pad(day)}`;
};
const _pad = (n) => (n < 10 ? "0" + n : n);

const _convertDateRangeToUtc = ({
  selectedDayRange,
  timeZone = "America/New_York",
}) => {
  const { from, to } = selectedDayRange;
  let from_utc, to_utc;
  if (from) {
    from_utc = dayjs
      .tz(_convertDateFormat({ date: from }), timeZone)
      .startOf("day")
      .utc()
      .format();
  }
  if (to) {
    to_utc = dayjs
      .tz(_convertDateFormat({ date: to }), timeZone)
      .endOf("day")
      .utc()
      .format();
  }
  return { from_utc, to_utc };
};

const _sanitizeDataForTable = ({
  guest_reviews = {},
  sorted_review_id_list,
  time_zone,
}) =>
  sorted_review_id_list.map((review_id) => {
    const {
      createdAt,
      feedback_text,
      selected_rating_id,
      uuid,
    } = guest_reviews[review_id];
    return {
      created_at: dayjs(createdAt)
        .tz(time_zone)
        .format("MMM-DD-YYYY hh:mm A"),
      feedback_text,
      review_id,
      selected_rating_id,
      selected_rating: (
        <img
          alt={selected_rating_id}
          className={Style.rating_img}
          src={_translateRating(selected_rating_id)}
        />
      ),
      uuid,
    };
  });

const _translateRating = (selected_rating_id) => {
  switch (selected_rating_id) {
    case "rating_1":
      return Rating_1;
    case "rating_2":
      return Rating_2;
    case "rating_3":
      return Rating_3;
    case "rating_4":
      return Rating_4;
    case "rating_5":
      return Rating_5;
    default:
      return;
  }
};

const _sortReviews = ({ guest_reviews, sort_rating, sort_date, sort_type_date, sort_type_rating, filter_rating }) =>
  Object.keys(guest_reviews)
    .filter(review_id => {
      if (!filter_rating) return true;
      const selectedRating = guest_reviews[review_id]?.selected_rating_id?.replace("rating_", "")
      return selectedRating === filter_rating;
    })
    .sort((review_a_id, review_b_id) => {
      const { createdAt: time_stamp_1 } = guest_reviews[review_a_id];
      const { createdAt: time_stamp_2 } = guest_reviews[review_b_id];

      if (sort_type_date === "date" && sort_type_rating === "rating") {
        const ratingIdA = parseInt(guest_reviews[review_a_id]?.selected_rating_id?.replace("rating_", ""),10);
        const ratingIdB = parseInt(guest_reviews[review_b_id]?.selected_rating_id?.replace("rating_", ""),10);

        if (ratingIdA !== ratingIdB) {
          return sort_rating === 'highest' ? ratingIdB - ratingIdA : ratingIdA - ratingIdB;
        } else {
          return sort_date === 'newest' ?
            dayjs(time_stamp_2).diff(dayjs(time_stamp_1)) :
            dayjs(time_stamp_1).diff(dayjs(time_stamp_2));
        }
      } else if (sort_type_date === "date") {
        return sort_date === 'newest' ?
          dayjs(time_stamp_2).diff(dayjs(time_stamp_1)) :
          dayjs(time_stamp_1).diff(dayjs(time_stamp_2));
      } else if (sort_type_rating === "rating") {
        const ratingIdA = parseInt(
          guest_reviews[review_a_id]?.selected_rating_id?.replace("rating_", ""),
          10
        );
        const ratingIdB = parseInt(
          guest_reviews[review_b_id]?.selected_rating_id?.replace("rating_", ""),
          10
        );

        return sort_rating === 'highest' ? ratingIdB - ratingIdA : ratingIdA - ratingIdB;
      }

      return 0;
    });


export {
  _createColumnsForTable,
  _sanitizeDataForTable,
  _sortReviews,
  _convertDateRangeToUtc,
};
