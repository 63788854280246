// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import {
  _convertDate,
  _createColumnsForTable,
  _renderPayoutStatus,
  _sanitizeDataForTable,
} from "./helperFunctions";

// Utils
import { _getDeviceDimension } from "utils";

// Style
import Style from "./style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { PayoutTransactions } from "components";

// Fields
import {
  Button,
  LoadingSpinner,
  NotViewableOnMobile,
  PageMsg,
  Table,
} from "fields";

// Assets
import { InfoIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";
import ExportPayoutModal from "components/Modals/ExportPayoutModal";

const MIN_WIDTH_TO_DISPLAY_PAYOUTS = 600;

const { ShowConfirmNotif } = Functions;
const { Merchants } = Services;

const { GetPayoutsForConnectedAccount } = Merchants.GetRequests;

class PayoutsForConnectedAccount extends React.Component {
  state = { payouts_info: {}, show_data: false, click_count: 0 };

  componentDidMount = async () => {
    const { width } = _getDeviceDimension();
    this.onSetStripeAccountId();
    if (width >= MIN_WIDTH_TO_DISPLAY_PAYOUTS) {
      this.setState({ isFindingPayouts: true });
      try {
        const payouts_info = await this.onGetPayouts();
        this.setState({ payouts_info });
      } catch {
        ShowConfirmNotif({
          message: "An error occured. Please try again later.",
          type: "error",
        });
      } finally {
        this.setState({ isFindingPayouts: false });
      }
    }
  };
  handlePayoutClick = () => {
    const { click_count } = this.state;
    this.setState({
      click_count: click_count < 6 ? click_count + 1 : 0,
      show_button: click_count === 5 ? true : false,
    });
  };

  onClickPayout = ({ dataObject }) => {
    const { id } = dataObject;
    this.setState({ selectedPayoutId: id });
  };

  handleCloseModal = () => {
    this.setState({
      show_button: false,
      click_count: 0,
      is_modal_open: false,
    });
  };

  onSetStripeAccountId = () => {
    const stripe_account_id = this.onGetStripeAccountId();
    this.setState({ stripe_account_id });
  };

  onGetPayouts = async ({ starting_after = "" } = {}) => {
    try {
      const { payouts_info = {} } = await GetPayoutsForConnectedAccount({
        starting_after,
        stripe_account_id: this.onGetStripeAccountId(),
      });
      return payouts_info;
    } catch (e) {
      throw e;
    }
  };

  onGetStripeAccountId = () =>
    _get(this.props, "context.shopBasicInfo.stripeConnectInfo.stripeAccountID");

  // onGetStripeAccountId = () => "acct_1EhmcFKsEylVTkPf";

  onGetShopTimeZone = () => _get(this.props, "context.shopBasicInfo.timeZone");

  onShowMoreData = async () => {
    this.setState({ isFindingMoreData: true });
    const { payouts_info = {} } = this.state;
    const { data = [] } = payouts_info;
    const oldestPayout = data[data.length - 1] || {};
    const { id } = oldestPayout;
    try {
      const more_payouts_info = await this.onGetPayouts({ starting_after: id });
      const { data: moreData = [], has_more } = more_payouts_info;
      this.setState({
        payouts_info: {
          ...payouts_info,
          data: data.concat(moreData),
          has_more,
        },
      });
    } catch {
    } finally {
      this.setState({ isFindingMoreData: false });
    }
  };

  renderNotice = () => (
    <div className={Style.notice}>
      <InfoIcon /> Some payments may take longer to process. Please check in
      next payout.
    </div>
  );

  renderHeader = () => {
    const { click_count, show_button } = this.state;
    return (
      <div className={Style.extraPaddingTop}>
        <h2
          className={Style.subNavbarLeftColumn}
          onClick={this.handlePayoutClick}
        >
          All Payouts
        </h2>
        <div className={Style.subNavbarRightColumn}>
          {click_count === 6 && show_button && (
            <Button
              className={Style.export_button}
              onClick={() => {
                this.setState({ is_modal_open: true });
              }}
            >
              Export
            </Button>
          )}
        </div>
      </div>
    );
  };

  renderPayoutTable = () => {
    const { payouts_info = {} } = this.state;
    const { data = [], has_more = false } = payouts_info;

    return (
      <Table
        columns={_createColumnsForTable()}
        data={_sanitizeDataForTable({
          data,
          timeZone: this.onGetShopTimeZone(),
        })}
        hasMoreData={has_more}
        isFindingMoreData={this.state.isFindingMoreData}
        noDataMessage="Payouts will be available soon"
        onClickRow={this.onClickPayout}
        onShowMoreData={this.onShowMoreData}
        showMoreDataMessage="Show More Payouts"
      />
    );
  };

  renderExportModal = () => {
    const { context } = this.props;
    const { shopBasicInfo } = context;
    const { is_modal_open, stripe_account_id } = this.state;
    const timeZone = this.onGetShopTimeZone();
    if (!is_modal_open) return null;
    return (
      <ExportPayoutModal
        onCloseModal={this.handleCloseModal}
        stripe_account_id={stripe_account_id}
        timeZone={timeZone}
        shopBasicInfo={shopBasicInfo}
      />
    );
  };

  renderPayouts = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderNotice()}
        {this.renderPayoutTable()}
        {this.renderExportModal()}
      </div>
    );
  };

  renderSelectedPayout = (selectedPayoutId) => {
    const { payouts_info = {} } = this.state;
    const { data = [] } = payouts_info;
    const payout = data.find(({ id }) => id === selectedPayoutId) || {};
    const { arrival_date, created, status } = payout;
    const timeZone = this.onGetShopTimeZone();

    return (
      <PayoutTransactions
        arrivalDate={_convertDate({ date: arrival_date, timeZone })}
        created={_convertDate({ date: created, timeZone })}
        onShowRecentPayouts={() => this.setState({ selectedPayoutId: "" })}
        status={_renderPayoutStatus(status)}
        stripeAccountID={this.onGetStripeAccountId()}
        payout={payout}
        timeZone={timeZone}
      />
    );
  };

  render() {
    const { width } = _getDeviceDimension();
    const { selectedPayoutId } = this.state;
    if (this.state.isFindingPayouts)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for payouts" />
        </PageMsg>
      );
    else if (width < MIN_WIDTH_TO_DISPLAY_PAYOUTS)
      return (
        <NotViewableOnMobile description=" Payouts currently can be viewed only on tablet and laptop" />
      );
    else if (selectedPayoutId)
      return this.renderSelectedPayout(selectedPayoutId);
    return this.renderPayouts();
  }
}

PayoutsForConnectedAccount.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      stripeConnectInfo: PropTypes.shape({
        stripeAccountID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(
  PayoutsForConnectedAccount
);
