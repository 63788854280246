// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import MenuFormFields from "./MenuFormFields";

// Style
import LargeCenterModalStyle from "components/Modals/style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { Functions, Services } from "lib";

class CreateOrEditMenuModal extends Component {
  confirmNotif = null;

  state = { showLoadingModal: false };

  componentWillUnmount = () => this.setState({ showLoadingModal: false });

  onCreateMenu = async (menuInfo) => {
    const { Merchants } = Services;
    const { CreateNewMenu } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const params = { menuInfo, shopID: this.props.context.shopID };
    const { menuID } = await CreateNewMenu(params);
    if (menuID) {
      this.props.onUpdateMenusAfterCreate({ menuID, menuInfo });
      return true;
    }
    return false;
  };

  onDeleteMenu = () => {
    const { Merchants } = Services;
    const { DeleteMenu } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { menuID } = this.props;
    DeleteMenu({ menuID, shopID: this.props.context.shopID })
      .then(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: `Removed ${this.props.menuInfo.menuName}`,
          type: "success",
        });
        this.props.onCloseModal();
        this.props.onUpdateAfterMenuDeleted();
      })
      .catch(() => {
        this.confirmNotif = Functions.ShowConfirmNotif({
          message: "An error has occured. Please try again.",
          type: "error",
        });
      });
  };

  onSubmitMenuInfo = async (menuInfo) => {
    const { ShowConfirmNotif } = Functions;
    const { isInEditMode } = this.props;
    const res = await (isInEditMode
      ? this.onSaveMenu(menuInfo)
      : this.onCreateMenu(menuInfo));
    if (res) {
      this.confirmNotif = ShowConfirmNotif({
        message: `${isInEditMode ? "Saved" : "Created"} ${menuInfo.menuName}`,
        type: "success",
      });
      this.props.onCloseModal();
    } else {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error has occured. Please try again.",
        type: "error",
      });
    }
  };

  onSaveMenu = async (menuInfo) => {
    const { Merchants } = Services;
    const { SaveChangedMenuInfo } = Merchants.PostRequests;
    this.setState({ showLoadingModal: true });
    const { menuID } = this.props;
    const params = { menuID, menuInfo, shopID: this.props.context.shopID };
    if (await SaveChangedMenuInfo(params)) {
      this.props.onUpdateAfterMenuSaved({ menuInfo });
      return true;
    }
    return false;
  };

  onHideConfirmDeleteModal = () =>
    this.setState({ showConfirmDeleteModal: false });

  onShowConfirmDeleteModal = () =>
    this.setState({ showConfirmDeleteModal: true });

  renderContent = () => {
    const { isInEditMode } = this.props;
    return (
      <React.Fragment>
        <MenuFormFields
          allowDrinkMenuCreation={this.props.allowDrinkMenuCreation}
          allowFoodMenuCreation={this.props.allowFoodMenuCreation}
          menuID={this.props.menuID}
          menuInfo={this.props.menuInfo}
          isInEditMode={isInEditMode}
          onSubmitMenuInfo={this.onSubmitMenuInfo}
          shopID={this.props.context.shopID}
        />
        {/* {isInEditMode && this.renderDeleteMenuForm()} */}
      </React.Fragment>
    );
  };

  renderDeleteMenuForm = () => (
    <div>
      <button
        className={LargeCenterModalStyle.deleteButton}
        name="Delete Menu"
        onClick={this.onShowConfirmDeleteModal}
        type="submit"
      >
        Archive
      </button>
    </div>
  );

  render() {
    const { isInEditMode, menuInfo, onCloseModal } = this.props;
    return (
      <ResponsiveModal
        classNames={{ modal: LargeCenterModalStyle.largeCenterModalBox }}
        contentLabel="Menu info"
        headerProps={{
          close_button_icon: "arrow",
          header: `${isInEditMode ? "Edit" : "New"} menu`,
        }}
        onCloseModal={onCloseModal}
      >
        {this.state.showConfirmDeleteModal && (
          <Modals.ConfirmDeleteMenuModal
            menuName={menuInfo.menuName}
            onCloseModal={this.onHideConfirmDeleteModal}
            onDeleteMenu={this.onDeleteMenu}
          />
        )}
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Processing..." />
        )}
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

CreateOrEditMenuModal.propTypes = {
  allowDrinkMenuCreation: PropTypes.bool,
  allowFoodMenuCreation: PropTypes.bool,
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }),
  menuID: PropTypes.string,
  menuInfo: PropTypes.object,
  isInEditMode: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onUpdateMenusAfterCreate: PropTypes.func,
  onUpdateAfterMenuDeleted: PropTypes.func,
  onUpdateAfterMenuSaved: PropTypes.func,
};

CreateOrEditMenuModal.defaultProps = {
  allowDrinkMenuCreation: true,
  allowFoodMenuCreation: true,
  menuInfo: {},
  isInEditMode: false,
};

export default withContext(MerchantInterfaceConsumer)(CreateOrEditMenuModal);
