// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import SubTotal from "./subTotal";

// Style
import Parent_Style from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { CartItem } from "components";

// Lib
import { Functions } from "lib";

const Items = ({ order_id, order_info = {},handleClick }) => {
  const { OrderMathFuncs } = Functions;
  const { _calcTotalItemsCount } = OrderMathFuncs;
  const { orderItems = {} } = order_info;
  const items_count = _calcTotalItemsCount({ orderItems });

  const renderItems = () =>
    Object.entries(orderItems).map((entry) => (
      <div key={entry[0]}>
        <CartItem detailsOfItemInCart={entry[1]} />
      </div>
    ));

  return (
    <div className={cx(Parent_Style.infoCol, Style.container)}>
      <h3 className={Style.items_count}>
        {items_count} Item{items_count === 1 ? "" : "s"}
      </h3>
      <div className={Style.order_id}  onClick={handleClick}>Order #{order_id}</div>
      {renderItems()}
      <SubTotal orderInfo={order_info} />
    </div>
  );
};

Items.propTypes = {
  order_id: PropTypes.string.isRequired,
  order_info: PropTypes.object.isRequired,
};

export default Items;
