// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Icons
import {
  DollarSymbolIcon,
  NavigationIcon,
  PrinterIcon,
  SpeechBubblesIcon,
} from "assets/Icons";

const _createActions = ({
  allowPrintingOrder = "false",
  onPrintOrder,
  onSendReminderToCustomer,
  onShowRefundModal,
  orderDeliveryTypeID,
}) => {
  return {
    printOrder: allowPrintingOrder === "true" && {
      action: onPrintOrder,
      icon: <PrinterIcon />,
      label: "Print order",
      loadingText: "Printing",
    },
    sendReminder: orderDeliveryTypeID !== "deliver" && {
      action: onSendReminderToCustomer,
      icon:
        orderDeliveryTypeID === "inStore" ? (
          <NavigationIcon />
        ) : (
          <SpeechBubblesIcon />
        ),
      label:
        orderDeliveryTypeID === "inStore" ? "Find customer" : "Remind customer",
      loadingText: "Sending",
    },
    refund: {
      action: onShowRefundModal,
      icon: <DollarSymbolIcon />,
      label: "Refund",
    },
  };
};

const _createContentForCustomerReminder = ({
  orderDeliveryTypeID = "inStore",
  shopName = "",
}) => {
  let message = "",
    notifMsg = "";
  if (shopName) {
    message =
      orderDeliveryTypeID === "inStore"
        ? `${shopName} staff is looking for you. Please come to the counter or ignore this text if you already received your order.`
        : `A friendly reminder from ${shopName} that your pickup order is ready. Ignore this text if you already got your order`;
    notifMsg =
      orderDeliveryTypeID === "inStore"
        ? "Invited customer to the counter"
        : "Sent reminder to customer";
    return { message, notifMsg };
  }
  return { message, notifMsg };
};

export { _createActions, _createContentForCustomerReminder };
