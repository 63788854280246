// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import cx from "classnames";
import _ismpty from "lodash.isempty";

import { DATA_TO_HIGHLIGHT, SECTIONS } from "./constants";
import {
  _convertRowValueToString,
  _createReport,
  _findtopItems,
  _getDataRowValue,
} from "./functions";

// Utils
import { _convertDateToLocalDate } from "utils";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

// Icons
import { ArrowIcon } from "assets/Icons";

class PastOrdersQuickReportModal extends React.Component {
  state = {};

  renderDateRange = () => {
    const timeZone = _get(this.props, "context.shopBasicInfo.timeZone");
    const { dateRange } = this.props;
    const { endAt, startAt } = dateRange;
    const localStartAt = _convertDateToLocalDate({ date: startAt, timeZone });
    const localEndAt = _convertDateToLocalDate({ date: endAt, timeZone });
    if (localStartAt === localEndAt)
      return (
        <div className={Style.dateRange}>
          <div className={Style.date}>{localStartAt}</div>
        </div>
      );
    return (
      <div className={Style.dateRange}>
        <div className={Style.date}>{localStartAt}</div>
        <div className={Style.arrow_icon}>
          <ArrowIcon />
        </div>
        <div className={Style.date}>{localEndAt}</div>
      </div>
    );
  };

  renderFields = ({ fields, report }) =>
    Object.keys(fields).map((field_id) => {
      const { label } = fields[field_id];
      const value = _getDataRowValue({ field_id, report });
      const { pastOrders = {} } = this.props;
      if (
        field_id === "net_total_plus_tips" &&
        _getDataRowValue({ field_id: "totalTipAmount", report }) === 0
      )
        return null;
      if (
        ["totalTipAmount", "totalDeliverFee"].includes(field_id) &&
        value === 0
      ) {
        return null;
      }
      if (field_id === "quantity") {
        return this.renderTopItems(_findtopItems({ pastOrders }));
      }
      return (
        <div
          className={cx(
            Style.data_row,
            DATA_TO_HIGHLIGHT.includes(field_id) && Style.highlight
          )}
          key={field_id}
        >
          <h3 className={Style.data_row_h3}>{label}</h3>
          <p className={Style.date_row_p}>
            {_convertRowValueToString({ field_id, value })}
          </p>
        </div>
      );
    });

  renderTopItems = (top_five_items = []) =>
    top_five_items.map((item) => {
      const { itemName, itemKitchenChitName, itemID, quantity } = item;
      return (
        <div className={Style.data_row} key={itemID}>
          <div>
            <h3 className={Style.data_row_h3}>{itemName}</h3>
            {itemKitchenChitName && (
              <h4 className={Style.data_row_h4}>
                Kitchen chit name: {itemKitchenChitName}
              </h4>
            )}
          </div>
          <p className={Style.date_row_p}>{quantity}</p>
        </div>
      );
    });

  renderReport = (report = {}) => {
    const { allOrders = {} } = report;
    return Object.keys(SECTIONS).map((section_id) => {
      const { fields = {}, label } = SECTIONS[section_id];
      return (
        !_ismpty(fields) && (
          <div className={Style.section}>
            <h2 className={Style.section_label}>{label}</h2>
            {this.renderFields({ fields, report: allOrders })}
          </div>
        )
      );
    });
  };

  render() {
    const { pastOrders = {} } = this.props;
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modalClassname }}
        contentLabel="sales report modal"
        contentContainerClassname={Style.contentContainer}
        headerProps={{ header: "Sales Report" }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderDateRange()}
        {this.renderReport(_createReport({ pastOrders }))}
      </ResponsiveModal>
    );
  }
}

PastOrdersQuickReportModal.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  dateRange: PropTypes.exact({
    endAt: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  pastOrders: PropTypes.object,
};

PastOrdersQuickReportModal.defaultProps = {
  pasOrders: {},
};

export default withContext(MerchantInterfaceConsumer)(
  PastOrdersQuickReportModal
);
