// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Chip } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

class Menu extends Component {
  state = {};

  componentDidMount = () => this.setState({ menuInfo: this.props.menuInfo });

  componentDidUpdate = (prevProps = {}) => {
    const { menuInfo = {} } = this.props;
    const { menuInfo: prevMenuInfo = {} } = prevProps;
    const { groups = {}, menuName = "" } = menuInfo;
    const {
      groups: prevGroups = {},
      menuName: prevMenuName = "",
    } = prevMenuInfo;
    if (
      menuName !== prevMenuName ||
      Object.keys(groups).length !== Object.keys(prevGroups).length
    )
      this.setState({ menuInfo });
  };

  onHideEditMenuModal = () => this.setState({ showEditMenuModal: false });

  onShowEditMenuModal = () => this.setState({ showEditMenuModal: true });

  onUpdateAfterMenuDeleted = () => this.setState({ menuInfo: null });

  onUpdateAfterMenuSaved = ({ menuInfo }) => this.setState({ menuInfo });

  renderChipLabel = () => {
    const { menuInfo } = this.state;
    const groupsCount = Object.keys(menuInfo.groups || {}).length;
    return (
      <React.Fragment>
        <div className={CommonStyle.chipName}>{menuInfo.menuName}</div>
        <div className={Style.chipDescription}>
          {groupsCount > 0 && (
            <div className={CommonStyle.elementsCounter}>
              {groupsCount} {groupsCount !== 1 ? "categories" : "category"}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderEditModal = () => (
    <Modals.MenuModal
      isInEditMode={true}
      menuID={this.props.menuID}
      menuInfo={this.state.menuInfo}
      onCloseModal={this.onHideEditMenuModal}
      onUpdateAfterMenuDeleted={this.onUpdateAfterMenuDeleted}
      onUpdateAfterMenuSaved={this.onUpdateAfterMenuSaved}
    />
  );

  renderHelperButton = () => {
    const { menuHelperButton } = this.props;
    if (menuHelperButton) return menuHelperButton(this.props.itemID);
    return (
      <PencilIcon
        className={CommonStyle.pencilIcon}
        onClick={this.onShowEditMenuModal}
      />
    );
  };

  renderMenu = () => (
    <Chip
      label={this.renderChipLabel()}
      labelClassname={Style.chipLabel}
      onClickLabel={this.onShowEditMenuModal}
      helperButtonContent={this.renderHelperButton()}
    />
  );

  render() {
    return (
      <React.Fragment>
        {this.state.showEditMenuModal && this.renderEditModal()}
        {this.state.menuInfo && this.renderMenu()}
      </React.Fragment>
    );
  }
}

Menu.propTypes = {
  isInEditMode: PropTypes.bool,
  menuHelperButton: PropTypes.func,
  menuID: PropTypes.string.isRequired,
  menuInfo: PropTypes.shape({ menuName: PropTypes.string }).isRequired,
};

Menu.defaultProps = { isInEditMode: true };

export default Menu;
