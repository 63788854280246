// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import CheckInConfirmModal from "../../CheckInConfirmModal";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input, Numberpad } from "fields";

// Lib
import { Constants, Services } from "lib";

// Asets
import { SkipliLogoWithTextWhiteIcon } from "assets/Icons";

class CheckInSection extends React.Component {
  state = { btnStatus: "inactive", phoneNumber: "" };

  onClickNumberpad = (phoneNumber) => {
    const { REGEX } = Constants;
    let val = phoneNumber.replace(/[^0-9]/g, "");
    if (val.length <= 10) {
      this.setState({
        btnStatus: REGEX.US_PHONE_NUMBER.test(val) ? "active" : "inactive",
        phoneNumber: val,
      });
    }
  };

  onSubmit = async () => {
    const { BUTI } = Services;
    const { SendTextMessage } = BUTI.PostRequests;
    const { customerCheckIn = {} } = this.props;
    const { after_checkin = {} } = customerCheckIn;
    const { automated_text_after_checkin = "" } = after_checkin;
    const { phoneNumber } = this.state;

    this.setState({ btnStatus: "loading" });
    try {
      if (automated_text_after_checkin) {
        await SendTextMessage({
          body: automated_text_after_checkin,
          to: phoneNumber,
        });
      }
    } catch {
    } finally {
      this.setState({ btnStatus: "inactive", showConfirmModal: true });
    }
  };

  renderPhoneNumberForm = () => {
    const { phoneNumber } = this.state;
    return (
      <div className={Style.phoneNumberForm}>
        <div className={Style.phoneNumberField}>
          <Input.TextInput
            fieldClassname={Style.field}
            inputClassname={Style.input}
            labelClassname={Style.label}
            placeholder="Your Mobile Number"
            name="customer phone number"
            onChange={() => {}}
            value={
              phoneNumber.length >= 10
                ? _formatPhoneNumber(phoneNumber)
                : phoneNumber
            }
            type="tel"
            readOnly
          />
        </div>
        <Numberpad
          keyClassname={Style.key}
          onChange={this.onClickNumberpad}
          value={this.state.phoneNumber}
        />
        <Button
          className={Style.submitBtn}
          loadingText="Sending"
          name="Submit Button"
          onClick={this.onSubmit}
          status={this.state.btnStatus}
          type="submit"
        >
          Check In
        </Button>
      </div>
    );
  };

  render() {
    const { customerCheckIn = {} } = this.props;
    const { after_checkin = {} } = customerCheckIn;
    return (
      <div>
        {this.state.showConfirmModal && (
          <CheckInConfirmModal
            after_checkin={after_checkin}
            onClearPhoneNumber={() => this.setState({ phoneNumber: "" })}
            onCloseModal={() => this.setState({ showConfirmModal: false })}
            onResendTextMsg={this.onSubmit}
            phoneNumber={this.state.phoneNumber}
          />
        )}
        <div className={Style.skipli_logo}>
          <SkipliLogoWithTextWhiteIcon />
        </div>
        {this.renderPhoneNumberForm()}
        <p className={Style.disclaimer}>
          By entering my phone number, I agree to receive SKIPLI notifications
          via autotext. Unsubscribe anytime and still participate in SKIPLI.
        </p>
      </div>
    );
  }
}

CheckInSection.propTypes = {
  customerCheckIn: PropTypes.shape({ instruction: PropTypes.string }),
};

export default CheckInSection;
