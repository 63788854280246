// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { RadioButton, Input } from "fields";

// Icons
import { CloseIcon, MagnifyingGlassIcon } from "assets/Icons";

class SearchBar extends Component {
  state = {};

  componentWillMount() {
    document.addEventListener("mousedown", this.onHandleClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.onHandleClick, false);
  }

  onClickCloseIcon = () => this.props.onCloseSearchBar();

  onChangeSearchText = text => this.props.onChangeSearchText(text);

  onCloseSearchBar = () =>
    !this.props.searchText
      ? this.props.onCloseSearchBar()
      : this.setState({ minimizeMode: true });

  onHandleClick = e => {
    if (this.node) {
      if (this.node.contains(e.target)) this.setState({ minimizeMode: false });
      else this.onCloseSearchBar();
    }
  };

  onGetInputValue = () => {
    const { searchText } = this.props;
    const { minimizeMode } = this.state;
    if (minimizeMode) {
      const { searchCategories, selectedSearchCategory } = this.props;
      const { label = "" } = searchCategories.find(
        ({ id }) => id === selectedSearchCategory
      );
      return (label ? `${label}: ` : "") + searchText;
    }
    return this.props.searchText;
  };

  onMinimizeSearchBar = () =>
    !this.props.searchText && this.props.onCloseSearchBar();

  renderClearButton = () => (
    <button
      className={cx(
        Style.closeIcon,
        this.state.minimizeMode && Style.minimized
      )}
      onClick={this.onClickCloseIcon}
    >
      <CloseIcon />
    </button>
  );

  renderSearchBar = (hasCategories = false) => {
    const { minimizeMode } = this.state;
    return (
      <div
        className={cx(
          Style.searchBar,
          hasCategories && Style.hasCategories,
          minimizeMode && Style.minimized
        )}
      >
        {minimizeMode && (
          <div
            className={cx(Style.searchIcon, minimizeMode && Style.minimized)}
          >
            <MagnifyingGlassIcon />
          </div>
        )}
        <Input.TextInput
          className={Style.inputContainer}
          fieldClassname={Style.field}
          inputClassname={cx(Style.input, minimizeMode && Style.minimized)}
          name="search input"
          onChange={this.onChangeSearchText}
          placeholder={this.props.inputPlaceholder}
          value={this.onGetInputValue()}
        />
        {this.props.searchText && !minimizeMode && this.renderClearButton()}
      </div>
    );
  };

  renderSearchCategories = (categories = []) =>
    categories.map((category = {}) => {
      const { id = "", label = "" } = category;
      return (
        id &&
        label && (
          <div className={Style.category} key={id}>
            <RadioButton
              checked={this.props.selectedSearchCategory === id}
              id={id}
              label={label}
              labelClassName={Style.categoryLabel}
              onClick={this.props.onSelectSearchCategory}
            />
          </div>
        )
      );
    });

  render() {
    const { searchCategories } = this.props;
    const { minimizeMode } = this.state;
    const hasCategories = searchCategories.length > 0;
    return (
      <div
        className={cx(Style.container, minimizeMode && Style.minimized)}
        ref={node => (this.node = node)}
      >
        <div className={cx(Style.frame, minimizeMode && Style.minimized)}>
          {this.renderSearchBar(hasCategories)}
          {hasCategories && !minimizeMode && (
            <div className={Style.searchCategories}>
              {this.renderSearchCategories(searchCategories)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  inputPlaceholder: PropTypes.string,
  onChangeSearchText: PropTypes.func,
  onCloseSearchBar: PropTypes.func,
  onSelectSearchCategory: PropTypes.func,
  searchCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  searchText: PropTypes.string,
  selectedSearchCategory: PropTypes.string
};

SearchBar.defaultProps = {
  inputPlaceholder: "What are you searching?",
  onChangeSearchText: () => {},
  onCloseSearchBar: () => {},
  onSelectSearchCategory: () => {},
  searchCategories: [],
  searchText: "",
  selectedSearchCategory: ""
};

export default SearchBar;
