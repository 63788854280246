// BUTI DINERS, INC. All right Reserved ©

import OrderingChannels from "./OrderingChannels";
import ShopAbout from "./ShopAbout";
import ContactUs from "./ContactUs";
import ShopHeader from "./ShopHeader";
import ShopHeroImage from "./ShopHeroImage";
import ShopMenu from "./ShopMenu";

export default {
  OrderingChannels,
  ShopAbout,
  ContactUs,
  ShopHeader,
  ShopHeroImage,
  ShopMenu,
};
