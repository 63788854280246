// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Style
import CommonNavbarStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { Button } from "fields";

class MerchantGlobalNavbar extends React.Component {
  renderNavItem = ({ id, label, icon }) => (
    <Button
      className={cx(
        Style.navbarItemContainer,
        id === this.props.activeNavbarItemId && Style.active
      )}
      name="label"
      onClick={() => this.props.onChangeGlobalNavItem(id)}
      key={id}
    >
      <div className={Style.iconContainer}>{icon}</div>
      <div className={Style.navbarItemLabel}>{label}</div>
      {this.renderNotificationBadge(id)}
    </Button>
  );

  renderNotificationBadge = (navItemID) => {
    const { context } = this.props;
    switch (navItemID) {
      case "orders":
        const { activeOrders = {} } = context;
        const ordersCount = Object.keys(activeOrders).length;
        return (
          ordersCount > 0 && (
            <div className={CommonNavbarStyle.badge}>{ordersCount}</div>
          )
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className={Style.navbarContainer}>
        {this.props.navItems.map(this.renderNavItem)}
      </div>
    );
  }
}

MerchantGlobalNavbar.propTypes = {
  activeNavbarItemId: PropTypes.string.isRequired,
  context: PropTypes.shape({
    activeOrders: PropTypes.object,
  }).isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.any,
    })
  ).isRequired,
  onChangeGlobalNavItem: PropTypes.func.isRequired,
  showNavItemLabel: PropTypes.bool,
};

MerchantGlobalNavbar.defaultProps = {
  context: {
    activeOrders: {},
  },
  showNavItemLabel: true,
};

export default withContext(MerchantInterfaceConsumer)(MerchantGlobalNavbar);
