// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { customAlphabet } from "nanoid";

// Style
import Style from "./style.module.scss";

// Components
import { ImageUpload, Modals } from "components";

// Fields
import {
  Button,
  Img,
  ImageUsageDisclaimer,
  ModalBackButton,
  ResponsiveModal,
} from "fields";

// Lib
import BUTI from "lib/Services/BUTI";

const nanoid = customAlphabet("1234567890", 18);

class ImageViewerModal extends React.Component {
  state = { imageURL: "" };

  componentDidMount = () => this.setState({ imageURL: this.props.imageURL });

  onChangeImageFile = (imageFile) => this.setState({ imageFile });

  onGetSaveBtnStatus = () => {
    if (!this.state.imageFile) return "inactive";
    else if (!this.state.termsAgreed) return "inactive";
    return "active";
  };

  onUploadImageToAWS = async () => {
    const { destinationFolderName = "" } = this.props;
    if (!destinationFolderName) return;
    this.setState({ showLoadingModal: true });
    const { imageURL = "" } = await BUTI.PostRequests.UploadImageToAws({
      folderName: this.props.destinationFolderName,
      imageFile: this.state.imageFile,
      imageName: nanoid(),
    });
    if (imageURL) this.props.onCompleteImageUpload(imageURL);
  };

  renderDisclaimer = () => {
    const { termsAgreed } = this.state;
    return (
      <ImageUsageDisclaimer
        onAgreeToTerms={() => this.setState({ termsAgreed: !termsAgreed })}
        termsAgreed={termsAgreed}
      />
    );
  };

  renderImage = () => (
    <div className={Style.imageContainer}>
      <Img className={Style.image} src={this.state.imageURL} />
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.state.showLoadingModal && (
          <Modals.LoadingModal message="Uploading..." />
        )}
        <ResponsiveModal
          closeModalButtonLabel={<ModalBackButton />}
          classNames={{ modal: Style.modalBoxClassname }}
          contentLabel={this.props.modalContentLabel}
          headerProps={{ header: this.props.modal_header }}
          onCloseModal={this.props.onCloseModal}
        >
          <h2>{this.props.heading}</h2>
          {this.renderImage()}
          <ImageUpload
            imageQuality={this.props.imageQuality}
            image_url={this.state.imageURL}
            onChangeImageFile={this.onChangeImageFile}
            onRemovePhoto={() => this.props.onCompleteImageUpload("")}
            uploadMaxHeight={this.props.uploadMaxHeight}
            uploadMaxWidth={this.props.uploadMaxWidth}
          />
          {this.renderDisclaimer()}
          <Button
            className={Style.saveButton}
            name={this.props.saveButtonName}
            onClick={this.onUploadImageToAWS}
            status={this.onGetSaveBtnStatus()}
          >
            Save
          </Button>
        </ResponsiveModal>
      </React.Fragment>
    );
  }
}

ImageViewerModal.propTypes = {
  destinationFolderName: PropTypes.string.isRequired,
  heading: PropTypes.string,
  imageURL: PropTypes.string,
  modalContentLabel: PropTypes.string.isRequired,
  modal_header: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onCompleteImageUpload: PropTypes.func.isRequired,
  uploadMaxHeight: PropTypes.number,
  uploadMaxWidth: PropTypes.number,
  saveButtonName: PropTypes.string.isRequired,
};

ImageViewerModal.defaultProps = {
  imageURL: "",
  modalContentLabel: "Image Viewer Modal",
  modal_header: "Image",
};

export default ImageViewerModal;
