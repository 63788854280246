// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import AddButton from "views/MenusManagement/AddButton";
import ExportButton from "views/MenusManagement/ExportButton";
// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { MenusManagement, Modals } from "components";

// Fields

import { LoadingSpinner, PageMsg } from "fields";

// Lib
import { Services } from "lib";

const MAX_CLICKS_COUNT = 5;

class AllMenus extends Component {
  state = {
    allMenus: {},
    show_export_modal: false,
  };

  componentDidMount = () =>
    this.setState({ isGettingMenus: true }, this.onGetAllMenus);

  componentDidUpdate = (prevProps = {}) => {
    const { newMenuId = "", newMenuInfo = {} } = this.props;
    if (newMenuId && newMenuId !== prevProps.newMenuId)
      this.setState({
        allMenus: set(this.state.allMenus, newMenuId, newMenuInfo),
      });
  };

  componentWillUnmount = () => this.setState({ isGettingMenus: false });

  onClickHeading = () => {
    const { clicksCount = 0 } = this.state;
    this.setState({ clicksCount: clicksCount + 1 }, () => {
      if (this.state.clicksCount - MAX_CLICKS_COUNT > -1)
        this.props.onShowImportMenuButton();
    });
  };

  onCountMenuType = () =>
    Object.values(this.state.allMenus).reduce(
      (result, menuInfo) => {
        const { menuType } = menuInfo;
        return set(result, menuType, result[menuType] + 1);
      },
      { beverage: 0, food: 0 }
    );

  onGetAllMenus = async () => {
    const { Merchants } = Services;
    const { GetShopAllMenus } = Merchants.GetRequests;
    const { allMenus } = await GetShopAllMenus({ shopID: this.props.shopID });
    this.setState({ allMenus, isGettingMenus: false });
  };

  onHideCreateMenuModal = () => this.setState({ showCreateMenuModal: false });

  onShowCreateMenuModal = () => this.setState({ showCreateMenuModal: true });

  onUpdateMenusAfterCreate = ({ menuID, menuInfo }) =>
    this.setState({
      allMenus: set(this.state.allMenus, menuID, menuInfo),
    });

  renderContent = () => {
    const { allMenus = {} } = this.state;

    const menuIDs = Object.keys(allMenus);
    const content =
      menuIDs.length === 0 ? (
        <PageMsg>No menus found.</PageMsg>
      ) : (
        <div className={Style.listContainer}>{this.renderMenus(allMenus)}</div>
      );
    return (
      <div>
        <div className={CommonStyle.headingContainer}>
          <h2 className={CommonStyle.heading} onClick={this.onClickHeading}>
            Menus (2 max)
          </h2>
          {menuIDs.length < 2 && (
            <AddButton onClick={this.onShowCreateMenuModal} />
          )}
          <ExportButton
            onClick={() => this.setState({ show_export_modal: true })}
          />
        </div>
        {this.state.showCreateMenuModal && this.renderCreateModal()}
        {this.state.show_export_modal && this.renderSelection()}

        <div>{content}</div>
      </div>
    );
  };

  renderSelection = () => {
    return (
      <Modals.ExportMenuModal
        shopID={this.props.shopID}
        onCloseModal={() => this.setState({ show_export_modal: false })}
      />
    );
  };

  renderCreateModal = () => {
    const { beverage = 0, food = 0 } = this.onCountMenuType();
    return (
      <Modals.MenuModal
        allowDrinkMenuCreation={beverage < 1}
        allowFoodMenuCreation={food < 1}
        onCloseModal={this.onHideCreateMenuModal}
        onUpdateMenusAfterCreate={this.onUpdateMenusAfterCreate}
      />
    );
  };

  renderMenus = (allMenus = {}) =>
    Object.keys(allMenus).map((menuID) => (
      <div className={Style.menuContainer} key={menuID}>
        <MenusManagement.Menu menuID={menuID} menuInfo={allMenus[menuID]} />
      </div>
    ));

  render() {
    if (this.state.isGettingMenus)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for menus" />
        </PageMsg>
      );
    return this.renderContent();
  }
}

AllMenus.propTypes = {
  newMenuId: PropTypes.string,
  newMenuInfo: PropTypes.shape({
    groups: PropTypes.object,
    isMenuForCatering: PropTypes.shape({
      true: PropTypes.string,
      false: PropTypes.string,
    }),
    menuName: PropTypes.string,
  }),
  onShowImportMenuButton: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default AllMenus;
