// BUTI DINERS, INC. All right Reserved ©

const _getSubmitBtnStatus = ({ modifierGroupInfo = {} }) => {
  const {
    modifierGroupAllowMultipleChoices = {},
    modifierGroupIsRequired = {},
    modifierGroupName,
  } = modifierGroupInfo;
  if (
    Object.keys(modifierGroupAllowMultipleChoices).length === 0 ||
    Object.keys(modifierGroupIsRequired).length === 0 ||
    !modifierGroupName
  )
    return "inactive";
  else if (modifierGroupAllowMultipleChoices.true) {
    const {
      modifierGroupMaxChoices = "",
      modifierGroupMinChoices = "",
    } = modifierGroupInfo;
    const maxChoices = modifierGroupMaxChoices || 0;
    const minChoices = modifierGroupMinChoices || 0;
    if (parseInt(maxChoices) < parseInt(minChoices)) return "inactive";
  }
  return "active";
};

export { _getSubmitBtnStatus };
