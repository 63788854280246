// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import ModuleStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Input, Link } from "fields";

// Icons
import { PhoneIcon } from "assets/Icons";

// Lib
import { Constants } from "lib";

class ShopBasicInfoModule extends React.Component {
  onInputChange = (fieldID, fieldNewValue) => {
    let fieldValue = fieldNewValue;
    if (fieldID === "salesTax") {
      const val = parseFloat(`${fieldValue}e-2`);
      fieldValue = isNaN(val) ? "" : val;
    }
    this.props.onInputChange(fieldID, String(fieldValue));
  };

  onGetModuleInfo = () => {
    const { SHOP_INFO_SECTIONS } = Constants;
    return SHOP_INFO_SECTIONS.find(({ id }) => id === "basicInfo");
  };

  renderField = (field, fieldData = "", fieldID) => {
    const { fieldKind } = field;
    let data = fieldData;
    if (fieldID === "salesTax") {
      const val = parseFloat(`${data}e2`);
      data = isNaN(val) ? 0 : val;
    } else if (fieldID === "shopId") {
      data = this.props.shopId;
    } else if (fieldID === "phoneNumber") {
      let val = fieldData.replace(/[^0-9]/g, "");
      data = val.length >= 10 ? _formatPhoneNumber(val) : val;
    }
    switch (fieldKind) {
      case "text":
        return (
          <div className={ModuleStyle.field} key={fieldID}>
            <Input.TextInput
              {...field}
              name={fieldID}
              onChange={(newValue) => this.onInputChange(fieldID, newValue)}
              value={data}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderFields = (fields = []) =>
    fields.map((field) => {
      const { id = "" } = field;
      return this.renderField(field, String(this.props.shopInfo[id] || ""), id);
    });

  renderTestCallBtn = () => {
    const { shopInfo = {} } = this.props;
    const { phoneNumber = "" } = shopInfo;
    return (
      phoneNumber && (
        <button>
          <Link
            className={Style.callShopButton}
            href={`tel:${phoneNumber}`}
            target="_self"
          >
            <div className={Style.phone_icon}>
              <PhoneIcon />
            </div>
            Test Call
          </Link>
        </button>
      )
    );
  };

  render() {
    const moduleInfo = this.onGetModuleInfo();
    return (
      <div>
        <div className={ModuleStyle.fieldsContainer}>
          {this.renderFields(moduleInfo.fields)}
        </div>
        {/* {this.renderTestCallBtn()} */}
      </div>
    );
  }
}

ShopBasicInfoModule.propTypes = {
  onInputChange: PropTypes.func,
  shopId: PropTypes.string.isRequired,
  shopInfo: PropTypes.object,
};

ShopBasicInfoModule.defaultProps = {
  onInputChange: () => {},
  shopInfo: {},
};

export default ShopBasicInfoModule;
