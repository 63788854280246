// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { push } from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Icons
import { CheckIcon, PrinterIcon } from "assets/Icons";

const PrinterLoadingNotification = props => {
  const onShowSettings = () => props.onChangeGlobalNavItem("settings");

  const goToSettingsBtn = <div className={Style.button}>Fix now</div>;

  const findDisconnectedPrinter = () => {
    const { addedPrinters = {} } = props;
    return Object.keys(addedPrinters).reduce(
      (result, printerName) => {
        const { isConnected = false } = addedPrinters[printerName];
        return isConnected
          ? push(result, "active", printerName)
          : push(result, "inactive", printerName);
      },
      { active: [], inactive: [] }
    );
  };

  const renderContent = () => {
    const printers = findDisconnectedPrinter();
    const activePrinterCount = printers.active.length;
    const inactivePrinterCount = printers.inactive.length;
    if (props.isConnectingToPrintServer)
      return {
        status: "loading",
        text: "Connecting to print server..."
      };
    else if (props.isFindingShopAddedPrinters)
      return {
        status: "loading",
        text: "Connecting to printers..."
      };
    else if (!props.isQZSocketConnected)
      return {
        button: goToSettingsBtn,
        status: "error",
        text: "Print server offline"
      };
    else if (inactivePrinterCount > 0) {
      let text;
      if (activePrinterCount === 0) {
        text = `${inactivePrinterCount} OFF`;
      } else {
        text = (
          <div>
            <span className={Style.on}>{activePrinterCount} ON</span>,{" "}
            <span className={Style.off}>{inactivePrinterCount} OFF</span>
          </div>
        );
      }
      return {
        button: goToSettingsBtn,
        status: activePrinterCount > 0 ? "loading" : "error",
        text
      };
    }
    return {
      status: "success",
      text: <CheckIcon className={Style.checkIcon} />
    };
  };

  const { button, status, text } = renderContent();
  return (
    <div className={Style.container}>
      <button
        className={cx(Style.notification, Style[status])}
        onClick={onShowSettings}
      >
        <div className={Style.iconCol}>
          <PrinterIcon />
        </div>
        <div className={Style.textCol}>
          {text}
          {button}
        </div>
      </button>
    </div>
  );
};

PrinterLoadingNotification.propTypes = {
  addedPrinters: PropTypes.object,
  isConnectingToPrintServer: PropTypes.bool,
  isFindingShopAddedPrinters: PropTypes.bool,
  isQZSocketConnected: PropTypes.bool,
  onChangeGlobalNavItem: PropTypes.func.isRequired
};

export default PrinterLoadingNotification;
