// BUTI DINERS, INC. All right Reserved ©

import BasicInfo from "./BasicInfo";
import ShopMembership from "./Membership";
import PhoneNumbersToReceiveOrders from "./PhoneNumbersToReceiveOrders";
import ShopLogo from "./ShopLogo";
import ShopThumbnailImg from "./ShopThumbnailImage";
import SocialMediaProfiles from "./SocialMediaProfiles";

const ShopInfoModules = {
  BasicInfo,
  PhoneNumbersToReceiveOrders,
  ShopLogo,
  ShopMembership,
  ShopThumbnailImg,
  SocialMediaProfiles,
};

export default ShopInfoModules;
