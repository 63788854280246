// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import MerchantViewStyle from "views/style.module.scss";

// Components
import { Navbars, PreparationSections } from "components";

// Fields
import { ComingSoonButton, PageMsg, SubNavbarContainer } from "fields";

// Lib
import { Constants } from "lib";

const { PREPARATION_SUBNAV_ITEMS } = Constants;

class Preparation extends React.Component {
  confirmNotif = null;
  state = { activeSubNavItemId: "inStore" };

  onChangeSubNavItemId = (itemId) =>
    this.setState({ activeSubNavItemId: itemId });

  renderContent = () => {
    const { activeSubNavItemId } = this.state;
    switch (activeSubNavItemId) {
      case "inStore":
        return <PreparationSections.InStore />;
      case "deliver":
        return <PreparationSections.Deliver />;
      case "pickUp":
        return <PreparationSections.PickUp />;
      default:
        return (
          <PageMsg>
            <ComingSoonButton />
          </PageMsg>
        );
    }
  };

  renderSubNavbar = () => (
    <SubNavbarContainer>
      <Navbars.SubNavbar
        activeNavItemId={this.state.activeSubNavItemId}
        navItems={PREPARATION_SUBNAV_ITEMS}
        onNavItemClick={this.onChangeSubNavItemId}
      />
    </SubNavbarContainer>
  );

  render() {
    return (
      <div>
        {this.renderSubNavbar()}
        <div className={MerchantViewStyle.extraPaddingTop}>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default Preparation;
