// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { MenusManagement } from "components";

// Fields
import { PageMsg } from "fields";

// Assets
import { MenusIcon } from "assets/Icons";

class ListOfModifierGroups extends Component {
  renderModifierGroups = (modifierGroups = {}) =>
    (this.props.sortedModifierGroupIDs || Object.keys(modifierGroups)).map(
      (modifierGroupID) => (
        <div className={CommonStyle.elementContainer} key={modifierGroupID}>
          <MenusManagement.ModifierGroup
            {...this.props.modifierGroupProps}
            modifierGroupHelperButton={this.props.modifierGroupHelperButton}
            modifierGroupID={modifierGroupID}
            modifierGroupInfo={modifierGroups[modifierGroupID]}
            onUpdateAllModifierGroups={this.props.onUpdateAllModifierGroups}
          />
        </div>
      )
    );

  render() {
    const { modifierGroups = {} } = this.props;
    if (Object.keys(modifierGroups).length === 0)
      return (
        <PageMsg>
          <div className={CommonStyle.menus_icon}>
            <MenusIcon />
          </div>
          {this.props.emptyListMessage}
        </PageMsg>
      );
    return (
      <div
        className={cx(
          CommonStyle.listContainer,
          this.props.listContainerClassname
        )}
      >
        {this.renderModifierGroups(modifierGroups)}
      </div>
    );
  }
}

ListOfModifierGroups.propTypes = {
  emptyListMessage: PropTypes.string,
  modifierGroupHelperButton: PropTypes.func,
  modifierGroupProps: PropTypes.object,
  modifierGroups: PropTypes.object,
  onUpdateAllModifierGroups: PropTypes.func,
  sortedModifierGroupIDs: PropTypes.array,
};

ListOfModifierGroups.defaultProps = {
  emptyListMessage: "No modifier groups found.",
  modifierGroupProps: {},
  modifierGroups: {},
  onUpdateAllModifierGroups: () => {},
  sortedModifierGroupIDs: null,
};

export default ListOfModifierGroups;
