// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _pick from "lodash.pick";
import { set } from "object-path-immutable";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, CenterModal, Checkbox, Input } from "fields";

// Lib
import { Constants, Functions, Services } from "lib";

const COMPANY_NAME_ENDING = ["llc", "inc", "co", "corp", "ltd"];

class ConfirmMerchantGetPaidModal extends React.Component {
  confirmNotif = null;
  state = { inputErrors: {} };

  onConfirmInfoReview = () =>
    this.setState({ isInfoReviewed: !this.state.isInfoReviewed });

  onInputChange = (fieldID, fieldValue = "") => {
    const { label } = Constants.MERCHANT_GET_PAID_FORM[fieldID];
    const { merchantGetPaidInfo } = this.props;
    const isMatched = merchantGetPaidInfo[fieldID] === fieldValue;
    this.setState({
      [fieldID]: fieldValue,
      inputErrors: set(
        this.state.inputErrors,
        fieldID,
        isMatched || !fieldValue ? "" : `${label} not matched`
      ),
    });
  };

  onGetSubmitButtonStatus = () => {
    const { merchantGetPaidInfo } = this.props;
    const {
      accountNumber,
      routingNumber,
      isInfoReviewed,
      isSubmitting,
    } = this.state;
    if (isSubmitting) return "loading";
    else if (!isInfoReviewed) return "inactive";
    else if (!accountNumber || !routingNumber) return "inactive";
    else if (accountNumber !== merchantGetPaidInfo["accountNumber"])
      return "inactive";
    else if (routingNumber !== merchantGetPaidInfo["routingNumber"])
      return "inactive";
    return "active";
  };

  onSaveMerchantGetPaidInfo = async () => {
    this.setState({ isSubmitting: true });
    const { ShowConfirmNotif } = Functions;
    const { BUTI, Merchants } = Services;
    const { SendEmail } = BUTI.PostRequests;
    const { SaveChangedMerchantGetPaidInfo } = Merchants.PostRequests;

    try {
      const { shopBasicInfo, shopID } = this.props.context;
      const { name } = shopBasicInfo;
      await SaveChangedMerchantGetPaidInfo({
        merchantGetPaidInfo: this.props.merchantGetPaidInfo,
        shopID,
      });
      this.confirmNotif = ShowConfirmNotif({
        message: "Updated your get paid info",
        type: "success",
      });
      await SendEmail({
        addresses: ["stripe@skiplinow.com"],
        body: `The new merchant get paid info is waiting for review: admin.skiplinow.com/${shopID}`,
        subject: `Merchant Get Paid Info changed by ${name}`,
      });
      this.setState({ isSubmitting: false }, this.props.onCloseModal);
    } catch {
      this.confirmNotif = ShowConfirmNotif({
        message: "An error occured. Please try again or refresh the page",
        type: "error",
      });
      this.setState({ isSubmitting: false });
    }
  };

  renderBusinessNameWarning = () => {
    const { businessName = "" } = this.props.merchantGetPaidInfo;
    const a = businessName.toLowerCase().split(" ");
    const shouldShowWarning = COMPANY_NAME_ENDING.reduce((result, ending) => {
      if (result) {
        if (a.includes(ending) || a.includes(`${ending}.`)) return false;
      }
      return result;
    }, true);
    return (
      shouldShowWarning && (
        <div className={Style.businessNameWarning}>
          Official business name should contain one of these:{" "}
          {COMPANY_NAME_ENDING.join(", ").toUpperCase()}
        </div>
      )
    );
  };

  renderButtons = () => (
    <div style={{ marginTop: "30px" }}>
      <Button
        className={Style.submitBtn}
        loadingText="Submitting"
        name="Submit merchant get paid info"
        onClick={this.onSaveMerchantGetPaidInfo}
        status={this.onGetSubmitButtonStatus()}
      >
        Submit
      </Button>
      <button
        onClick={() => {
          !this.state.isSubmitting && this.props.onCloseModal();
        }}
      >
        Cancel
      </button>
    </div>
  );

  renderCheckBox = () => (
    <Checkbox
      checked={this.state.isInfoReviewed}
      id="isInfoReviewed"
      label="I/We hereby confirm that the information provided is accurate, correct and complete."
      onClick={this.onConfirmInfoReview}
    />
  );

  renderFinalVerificationForm = () => {
    const { MERCHANT_GET_PAID_FORM } = Constants;
    const fields = _pick(MERCHANT_GET_PAID_FORM, [
      "accountNumber",
      "routingNumber",
    ]);
    return Object.keys(fields).map((fieldID) => (
      <div className={Style.input} key={fieldID}>
        <Input.TextInput
          {...fields[fieldID]}
          errortext={this.state.inputErrors[fieldID]}
          name={fieldID}
          onChange={(val) => this.onInputChange(fieldID, val)}
          showError
          value={this.state[fieldID] || ""}
        />
      </div>
    ));
  };

  renderForm = (fieldIDs) => {
    const { MERCHANT_GET_PAID_FORM } = Constants;
    const { merchantGetPaidInfo } = this.props;
    const fields = _pick(MERCHANT_GET_PAID_FORM, fieldIDs);
    return Object.keys(fields).map((fieldID) => (
      <div className={Style.input} key={fieldID}>
        <Input.TextInput
          {...fields[fieldID]}
          name={fieldID}
          readOnly
          value={merchantGetPaidInfo[fieldID] || ""}
        />
      </div>
    ));
  };

  render() {
    const { CHECKING_ACCOUNT_FIELDS, OWNER_INFO_FIELDS } = Constants;
    return (
      <CenterModal
        contentLabel="confirm merchant get info modal"
        onCloseModal={this.props.onCloseModal}
        modalBoxClassname={Style.modalBox}
        contentContainerClassname={Style.modalContainer}
        showCloseButton={false}
      >
        <h1 className={Style.modalTitle}>Review Important Information</h1>
        <p style={{ marginBottom: "30px" }}>
          Carefully review the provided information to prevent potential delay
          of payout
        </p>
        {this.renderBusinessNameWarning()}
        <h2 className={Style.sectionTitle}>Owner Information</h2>
        <div className={Style.fields}>{this.renderForm(OWNER_INFO_FIELDS)}</div>
        <h2 className={Style.sectionTitle}>Checking Account</h2>
        <div className={Style.fields}>
          {this.renderForm(CHECKING_ACCOUNT_FIELDS)}
        </div>
        <h2 className={Style.sectionTitle}>Final Verification</h2>
        <div className={Style.fields}>{this.renderFinalVerificationForm()}</div>
        {this.renderCheckBox()}
        {this.renderButtons()}
      </CenterModal>
    );
  }
}

ConfirmMerchantGetPaidModal.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  merchantGetPaidInfo: PropTypes.shape({
    businessName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    routingNumber: PropTypes.string.isRequired,
    ownerName: PropTypes.string.isRequired,
    lastFourDigitsOfSSN: PropTypes.string.isRequired,
    federalTaxID: PropTypes.string,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(
  ConfirmMerchantGetPaidModal
);
