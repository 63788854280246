// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import shortid from "shortid";

// Style
import Style from "./style.module.scss";

class Table extends React.Component {
  renderData = ({ columns = [], data = [] }) => {
    const { classNames = {} } = this.props;
    return data.map((dataObject = {}) => (
      <div
        className={Style.row}
        onClick={() => this.props.onClickRow({ dataObject })}
        key={shortid.generate()}
      >
        {columns.map(({ accessor }) => {
          const cellInfo = dataObject[accessor] || "";
          return (
            <div
              className={cx(Style.column, classNames[`${accessor}-column`])}
              key={shortid.generate()}
              onClick={() => this.props.onClickRowCell({ accessor, cellInfo })}
            >
              <div className={cx(Style.cell, classNames[`${accessor}-cell`])}>
                {cellInfo}
              </div>
            </div>
          );
        })}
      </div>
    ));
  };

  renderEmptyData = () => (
    <div className={cx(Style.row, Style.noData)}>
      {this.props.noDataMessage}
    </div>
  );

  renderHeaders = ({ columns = [] }) => {
    const { classNames = {} } = this.props;
    return columns.map(({ accessor, header }) => (
      <div
        className={cx(Style.column, classNames[`${accessor}-column`])}
        key={accessor}
        onClick={() => this.props.onClickColumnHeader({ accessor, header })}
      >
        <div className={cx(Style.cell, classNames[`${accessor}-cell`])}>
          {header}
        </div>
      </div>
    ));
  };

  renderShowMoreButton = () => {
    const { isFindingMoreData = false } = this.props;
    return (
      <div
        className={cx(
          Style.row,
          Style.showMore,
          isFindingMoreData && Style.loading
        )}
        onClick={!isFindingMoreData ? this.props.onShowMoreData : () => {}}
      >
        {isFindingMoreData ? "Loading..." : this.props.showMoreDataMessage}
      </div>
    );
  };

  render() {
    const {
      classNames = {},
      columns = [],
      data = [],
      hasMoreData,
    } = this.props;
    return (
      <div className={Style.container}>
        <div className={cx(Style.table, classNames.table)}>
          <div className={cx(Style.row, Style.header)}>
            {this.renderHeaders({ columns })}
          </div>
          {data.length === 0
            ? this.renderEmptyData()
            : this.renderData({ columns, data })}
          {hasMoreData && this.renderShowMoreButton()}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  classNames: PropTypes.shape({
    table: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired, // accessor is the "key" in the data
      header: PropTypes.string.isRequired, // name of the column
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.any, // the value of accessor can be anything
    })
  ).isRequired,
  hasMoreData: PropTypes.bool,
  isFindingMoreData: PropTypes.bool,
  noDataMessage: PropTypes.string,
  onClickColumnHeader: PropTypes.func,
  onClickRow: PropTypes.func,
  onClickRowCell: PropTypes.func,
  onShowMoreData: PropTypes.func,
  showMoreDataMessage: PropTypes.string,
};

Table.defaultProps = {
  hasMoreData: false,
  isFindingMoreData: false,
  noDataMessage: "No data found",
  onClickColumnHeader: () => {},
  onClickRow: () => {},
  onClickRowCell: () => {},
  onShowMoreData: () => {},
  showMoreDataMessage: "Show more data",
};

export default Table;
