// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, WebsiteEditOverlay } from "fields";

const { WebsiteBuilderEdit } = Modals;

class ShopAbout extends React.Component {
  state = {};

  onHideEditModal = () => this.setState({ showEditModal: false });

  onShowEditModal = () => this.setState({ showEditModal: true });

  renderEditModal = () => {
    const { aboutUs = {} } = this.props;
    return (
      <WebsiteBuilderEdit.ShopAbout
        aboutUs={aboutUs}
        onChange={this.props.onChange}
        onCloseModal={this.onHideEditModal}
      />
    );
  };

  render() {
    const { aboutUs = {}, themeColor } = this.props;

    const { imageURL = "", text = "", textPosition = "right" } = aboutUs;
    const OrderNowBtn = styled(Button)`
      background: ${themeColor};
    `;

    return (
      <React.Fragment>
        {this.state.showEditModal && this.renderEditModal()}
        <WebsiteEditOverlay onShowEditModal={this.onShowEditModal}>
          <div
            className={cx(
              Style.container,
              textPosition === "left" && Style.textOnLeft
            )}
          >
            <img alt="" className={Style.image} src={imageURL} />
            <div className={Style.textContainer}>
              <h2 className={Style.heading}>Our Story</h2>
              <p className={Style.description}>{text}</p>
              <OrderNowBtn
                className={Style.orderNow}
                hasShadow
                name="order now"
                onClick={this.props.onRedirectToOrderOnline}
              >
                Order Now
              </OrderNowBtn>
            </div>
          </div>
        </WebsiteEditOverlay>
      </React.Fragment>
    );
  }
}

ShopAbout.propTypes = {
  aboutUs: PropTypes.exact({
    imageURL: PropTypes.string,
    text: PropTypes.string,
    textPosition: PropTypes.oneOf(["left", "right"]),
  }),
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
};

export default ShopAbout;
