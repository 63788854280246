// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

const ONE_SECOND = 1000;

class CheckInConfirmModal extends React.Component {
  state = { seconds: ONE_SECOND * 20 };

  onClearPhoneNumber = () => {
    this.props.onClearPhoneNumber();
    this.props.onCloseModal();
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 0)
        this.setState(({ seconds }) => ({ seconds: seconds - ONE_SECOND }));
      else {
        clearInterval(this.myInterval);
        this.onClearPhoneNumber();
      }
    }, ONE_SECOND);
  }

  componentWillUnmount = () => {
    clearInterval(this.myInterval);
    this.setState({ seconds: ONE_SECOND * 15 });
  };

  onResend = () => {
    this.props.onCloseModal();
    this.props.onResendTextMsg();
  };

  renderPhoneNumberConfirmation = () => (
    <div className={Style.phoneNumberConfirmation}>
      <h3>Menu & rewards sent!</h3>
      <p>
        We’ve sent a link to{" "}
        <strong>{_formatPhoneNumber(this.props.phoneNumber)}</strong>*
      </p>
    </div>
  );

  renderResendTextMsg = () => (
    <div className={Style.resendTextMsg}>
      <p>*Didn't receive a text?</p>
      <button onClick={this.onResend}>
        RESEND TO {_formatPhoneNumber(this.props.phoneNumber)}
      </button>
      <p>
        or{" "}
        <strong onClick={this.onClearPhoneNumber}>Change Phone Number</strong>
      </p>
    </div>
  );

  render() {
    const { after_checkin = {} } = this.props;
    const { instruction_after_checkin } = after_checkin;
    const remaining_seconds = this.state.seconds / ONE_SECOND;

    return (
      <ResponsiveModal
        classNames={{
          contentContainer: Style.modalContentContainer,
          modal: Style.modalBoxClassname,
        }}
        contentDescriptionProps={{
          contentDescription: `This window will close in ${remaining_seconds} second${
            remaining_seconds > 1 ? "s" : ""
          }`,
          showContentDescription: true,
        }}
        contentLabel="Confirm customer check in"
        contentContainerClassname={Style.modalContent}
        onCloseModal={this.onClearPhoneNumber}
        showCloseButton={false}
      >
        {this.renderPhoneNumberConfirmation()}
        {instruction_after_checkin && (
          <div className={Style.storeMsg}>{instruction_after_checkin}</div>
        )}
        {this.renderResendTextMsg()}
      </ResponsiveModal>
    );
  }
}

CheckInConfirmModal.propTypes = {
  after_checkin: {
    automated_text_after_checkin: PropTypes.string.isRequired,
    instruction_after_checkin: PropTypes.string.isRequired,
  },
  onClearPhoneNumber: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onResendTextMsg: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

export default CheckInConfirmModal;
