// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import cx from "classnames";

import {
  LOCATION_INFO,
  OWNER_INFO_FIELDS,
} from "components/NewMerchantAccountModules/AgreementForm/HelperFunctions";

// Components
import { CheckBoxGroup } from "components";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, ResponsiveModal } from "fields";

const DATA_FIELD_TO_HIGHLIGHT = [
  "accountNumber",
  "nine_digits_of_us_ssn",
  "routingNumber",
  "taxId",
];

class ReviewButton extends React.Component {
  state = {};

  onApproveForm = async () => {
    try {
      await this.props.onSubmitForm({
        form: set(this.props.form, "approval.isApproved", true),
        isApprovingForm: true,
      });
      this.props.onHideReviewButton();
    } catch {}
  };

  onGetApproveButtonStatus = () =>
    this.state.confimedReview ? "active" : "inactive";

  renderConfirmCheckbox = () => {
    const { confimedReview = false } = this.state;
    return (
      <div className={Style.section}>
        <CheckBoxGroup
          heading="I have reviewed the merchant account application. I confirm that the
          information are correct and verified when applicable."
          onClick={() => this.setState({ confimedReview: !confimedReview })}
          options={{ agree: { label: "I agree" } }}
          headingClassname={Style.checkBoxHeading}
          required
          selectedOptionIds={confimedReview ? ["agree"] : []}
        />
      </div>
    );
  };

  renderInfoRow = ({ fieldId, fieldLabel, fieldValue }) => (
    <div className={Style.infoRow} key={fieldId}>
      <div className={Style.label}>{fieldLabel}</div>
      <div
        className={cx(
          Style.data,
          DATA_FIELD_TO_HIGHLIGHT.includes(fieldId) && Style.highlight
        )}
      >
        {fieldValue}
      </div>
    </div>
  );

  renderLocations = ({ locations = {} }) =>
    Object.keys(locations).map((locationId, index) => {
      return (
        <div className={Style.section} key={locationId}>
          <h2 className={Style.sectionHeading}>Restaurant #{index + 1}</h2>
          {Object.keys(locations[locationId]).map((fieldsGroupId) => {
            const { fields = {}, label = "" } =
              LOCATION_INFO[fieldsGroupId] || {};
            return (
              label && (
                <div key={fieldsGroupId}>
                  <h3 style={{ fontSize: "1rem" }}>{label}</h3>
                  {this.renderLocationSubsectionInfo({
                    fields,
                    locationSubsectionInfo:
                      locations[locationId][fieldsGroupId],
                  })}
                </div>
              )
            );
          })}
        </div>
      );
    });

  renderLocationSubsectionInfo = ({
    fields = {},
    locationSubsectionInfo = {},
  }) =>
    Object.keys(locationSubsectionInfo).map((fieldId) => {
      const { label } = fields[fieldId] || {};
      let data = locationSubsectionInfo[fieldId];
      if (fieldId === "businessStructure") {
        const { optionInfo } = data;
        const { label = "" } = optionInfo;
        data = label;
      }
      return this.renderInfoRow({
        fieldId,
        fieldLabel: label,
        fieldValue: data,
      });
    });

  renderOwnerInfo = ({ ownerInfo = {} }) => {
    const ownerInfoFields = OWNER_INFO_FIELDS({ ownerInfo });
    return Object.keys(ownerInfo).map((fieldId) => {
      const { label } = ownerInfoFields[fieldId];
      return this.renderInfoRow({
        fieldId,
        fieldLabel: label,
        fieldValue: ownerInfo[fieldId],
      });
    });
  };

  renderReviewModal = () => {
    const { form } = this.props;
    const { locations = {}, ownerInfo = {} } = form;
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentDescriptionProps={{
          contentDescription: (
            <div>
              The form can't be modified once approved.
              <br />
              Ensure important information, such as SSN and EIN, are correct.
            </div>
          ),
          showContentDescription: true,
          title: "Important Notice",
        }}
        contentLabel="Review merchant account application before approving"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Save & Approve",
            loadingText: "Approving",
            status: this.onGetApproveButtonStatus(),
          },
        }}
        headerProps={{ header: "Review Application" }}
        onCloseModal={() => this.setState({ showReviewModal: false })}
        onSubmit={this.onApproveForm}
      >
        <div className={Style.section}>
          <h2 className={Style.sectionHeading}>Business Representative</h2>
          {this.renderOwnerInfo({ ownerInfo })}
        </div>
        {this.renderLocations({ locations })}
        {this.renderConfirmCheckbox()}
      </ResponsiveModal>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showReviewModal && this.renderReviewModal()}
        <div className={Style.container}>
          <div className={Style.content}>
            <Button
              className={Style.cancelButton}
              color="white"
              name="cancel"
              onClick={this.props.onHideReviewButton}
            >
              Later
            </Button>
            <Button
              className={Style.reviewButton}
              hasShadow
              name="review form"
              onClick={() => this.setState({ showReviewModal: true })}
              status={this.props.submitButtonStatus}
            >
              Review
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ReviewButton.propTypes = {
  form: PropTypes.shape({}).isRequired,
  onHideReviewButton: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  submitButtonStatus: PropTypes.oneOf(["active", "inactive", "loading"])
    .isRequired,
};

export default ReviewButton;
