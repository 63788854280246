// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import ConfirmArchiveStyle from "components/Modals/style.module.scss";

// Fields
import { Input, ResponsiveModal } from "fields";

const { TextInput } = Input;

class ConfirmArchiveGroupModal extends Component {
  state = { groupName: "" };

  onInputChange = (groupName) => this.setState({ groupName });

  onGetSubmitBtnStatus = () =>
    this.state.groupName.toLowerCase() === this.props.groupName.toLowerCase()
      ? "active"
      : "inactive";

  onSubmit = (e) => {
    e && e.preventDefault();
    this.props.onCloseModal();
    this.props.onArchiveGroup();
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{
          footer_submit_button: ConfirmArchiveStyle.confirmArchiveButton,
          modal: ConfirmArchiveStyle.confirmArchiveModalBox,
        }}
        contentLabel="Confirm archive group modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Archive",
            status: this.onGetSubmitBtnStatus(),
          },
        }}
        headerProps={{
          close_button_icon: "arrow",
          header: "Archive category",
        }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.onSubmit}
      >
        <div className={ConfirmArchiveStyle.confirmArchiveTitle}>
          Type <strong>{this.props.groupName}</strong> to archive
        </div>
        <TextInput
          name="confirm archive group"
          value={this.state.groupName}
          onChange={this.onInputChange}
        />
      </ResponsiveModal>
    );
  }
}

ConfirmArchiveGroupModal.propTypes = {
  groupName: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onArchiveGroup: PropTypes.func.isRequired,
};

export default ConfirmArchiveGroupModal;
