// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";
import _get from "lodash.get";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Utils
import { _removeAccents } from "utils";

// Style
import LargeCenterModalStyle from "components/Modals/style.module.scss";

// Components
import { MenusManagementModules, Modals } from "components";

// Fields
import { Box, Button, Input } from "fields";

// Lib
import { Constants } from "lib";

const { RadioButtons, TextInput } = Input;

class MenuFormFields extends Component {
  state = { menuInfo: {} };

  componentDidMount = () => {
    let menuInfo = this.props.menuInfo;
    const { allowDrinkMenuCreation, allowFoodMenuCreation } = this.props;
    if (this.props.isInEditMode) this.setState({ menuInfo });
    else if (allowDrinkMenuCreation && allowFoodMenuCreation)
      this.setState({ menuInfo });
    else {
      if (!allowDrinkMenuCreation) {
        menuInfo = immutable.set(menuInfo, "menuType", "food");
      } else if (!allowFoodMenuCreation) {
        menuInfo = immutable.set(menuInfo, "menuType", "beverage");
      }
      this.setState({ menuInfo });
    }
  };

  onAddGroupToMenu = (groupID) =>
    this.setState({
      menuInfo: immutable.set(this.state.menuInfo, `groups.${groupID}`, "buti"),
    });

  onChangeMenuInfo = (fieldID, value) =>
    this.setState({
      menuInfo: immutable.set(
        this.state.menuInfo,
        fieldID,
        _removeAccents({ text: value })
      ),
    });

  onClickRadio = (fieldID, optionID) => {
    if (fieldID === "menuType") {
      if (!this.props.allowFoodMenuCreation && optionID === "food") return;
      else if (!this.props.allowDrinkMenuCreation && optionID === "beverage")
        return;
      this.onChangeMenuInfo(fieldID, optionID);
    } else
      this.setState({
        menuInfo: immutable.set(this.state.menuInfo, fieldID, {
          [optionID]: "checked",
        }),
      });
  };

  onGetFieldOptions = (fieldID, fieldOptions) => {
    const { menuInfo } = this.state;
    if (fieldID === "menuType") {
      const selectedMenuTypeID = menuInfo[fieldID];
      if (selectedMenuTypeID)
        return immutable.set(
          fieldOptions,
          `${selectedMenuTypeID}.checked`,
          true
        );
    }
    const selectedOptions = menuInfo[fieldID];
    return Object.keys(selectedOptions || {}).reduce(
      (result, optionID) => immutable.set(result, `${optionID}.checked`, true),
      fieldOptions
    );
  };

  onGetSubmitButtonStatus = () => {
    const { menuName } = this.state.menuInfo;
    return !menuName ? "inactive" : "active";
  };

  onHideAddGroupModal = () => this.setState({ showAddGroupModal: false });

  onRemoveGroup = (groupID) =>
    this.setState({
      menuInfo: immutable.del(this.state.menuInfo, `groups.${groupID}`),
    });

  onShowAddGroupModal = () => this.setState({ showAddGroupModal: true });

  onSubmitForm = () => this.props.onSubmitMenuInfo(this.state.menuInfo);

  renderField = (field = {}) => {
    const { fieldKind, id } = field;
    if (id === "isMenuForCatering") {
      const role = _get(this.props.context, "personnel.role");
      if (role !== "owner") return null;
    }
    switch (fieldKind) {
      case "radio":
        return (
          <div key={id}>
            <RadioButtons
              {...field}
              fieldID={id}
              onChange={({ optionID }) => this.onClickRadio(id, optionID)}
              options={this.onGetFieldOptions(id, field.options)}
              renderOption={({ label }) => <div>{label}</div>}
            />
          </div>
        );
      case "text":
        return (
          <div className={LargeCenterModalStyle.formField} key={id}>
            <TextInput
              {...field}
              name={id}
              onChange={(value) => this.onChangeMenuInfo(id, value)}
              value={this.state.menuInfo[id] || ""}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderSubmitButton = () => (
    <Button
      className={LargeCenterModalStyle.saveButton}
      color="info"
      name="Save"
      onClick={this.onSubmitForm}
      status={this.onGetSubmitButtonStatus()}
    >
      {this.props.isInEditMode ? "Save" : "Create"}
    </Button>
  );

  render() {
    const { NEW_MENU_FIELDS } = Constants;
    const { menuInfo } = this.state;
    return (
      <div>
        {this.state.showAddGroupModal && (
          <Modals.AddGroupModal
            addedGroups={menuInfo.groups}
            destinationName={menuInfo.menuName}
            onAddGroup={this.onAddGroupToMenu}
            onCloseModal={this.onHideAddGroupModal}
          />
        )}
        <Box header="Menu Info">{NEW_MENU_FIELDS.map(this.renderField)}</Box>
        <MenusManagementModules.AddedGroupsModule
          groups={menuInfo.groups}
          onClickAddButton={this.onShowAddGroupModal}
          onRemoveGroup={this.onRemoveGroup}
        />
        {this.renderSubmitButton()}
      </div>
    );
  }
}

MenuFormFields.propTypes = {
  allowDrinkMenuCreation: PropTypes.bool,
  allowFoodMenuCreation: PropTypes.bool,
  context: PropTypes.shape({ personnel: PropTypes.object.isRequired })
    .isRequired,
  menuID: PropTypes.string,
  menuInfo: PropTypes.object,
  isInEditMode: PropTypes.bool,
  onSubmitMenuInfo: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
};

MenuFormFields.defaultProps = {
  allowDrinkMenuCreation: true,
  allowFoodMenuCreation: true,
  menuID: "",
  menuInfo: {},
  isInEditMode: false,
};

export default withContext(MerchantInterfaceConsumer)(MenuFormFields);
