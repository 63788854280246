// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Input } from "fields";

// Lib
import { Constants, Functions, Services } from "lib";


class Personnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personnelPin: this.props.personnelPin,
      personnelInfo: this.props.personnelInfo,
    };
  }

  componentDidMount = () => {
    const { personnelPin = "" } = this.props;
    this.setState({ isPinLengthValid: personnelPin.length === 5 });
  };

  onChangePersonnelName = (newName) => {
    const { personnelInfo } = this.state;
    this.setState({
      personnelInfo: immutable.set(personnelInfo, "personnelName", newName),
    });
  };

  onChangeOwnerMobileNumber = (mobileNumber = "") => {
    const { REGEX } = Constants;
    this.setState({
      personnelInfo: immutable.set(
        this.state.personnelInfo,
        "mobileNumber",
        mobileNumber
      ),
      isOwnerMobileNumberValid: REGEX.US_PHONE_NUMBER.test(mobileNumber),
    });
  };

  onChangePersonnelPin = (newPin = "") => {
    const { personnelPin } = this.state;
    const finalPin = newPin.length < 6 ? newPin : personnelPin;
    this.setState({ personnelPin: finalPin }, () => {
      const isPinTaken =
        Object.keys(this.props.allPersonnels[finalPin] || {}).length > 0;
      const isPinLengthValid = finalPin.length === 5;
      this.setState({ isPinTaken, isPinLengthValid });
    });
  };

  onCreateNewPersonnel = async () => {
    const { Merchants } = Services;
    const { CreateNewShopPersonnel } = Merchants.PostRequests;
    this.setState({ isCreatingPersonnel: true });
    const { personnelInfo, personnelPin } = this.state;
    const { success } = await CreateNewShopPersonnel({
      personnelInfo,
      personnelPin,
      shopID: this.props.shopID,
    });
    this.setState({ isCreatingPersonnel: false }, () => {
      if (success) {
        this.props.onCancelCreateNewUser();
        this.props.onCreateUserSuccess({ personnelInfo, personnelPin });
      }
    });
  };

  onGetCreateBtnStatus = () => {
    const { isPinTaken, isPinLengthValid, personnelInfo = {} } = this.state;
    if (isPinTaken || !isPinLengthValid) return "inactive";
    else if (!personnelInfo.personnelName) return "inactive";
    else if (this.state.isCreatingPersonnel) return "loading";
    return "active";
  };

  onRemovePersonnel = async () => {
    const { Merchants } = Services;
    const { RemovePersonnel } = Merchants.PostRequests;
    this.setState({ isRemovingPersonnel: true });
    const { personnelPin } = this.state;
    const { success } = await RemovePersonnel({
      personnelPin,
      shopID: this.props.shopID,
    });
    this.setState({ isRemovingPersonnel: false }, () => {
      if (success) this.props.onRemoveUserSuccess({ personnelPin });
    });
  };

  onSavePersonnelInfo = async () => {
    const { ShowConfirmNotif } = Functions;
    const { Merchants } = Services;
    const { SavePersonnelInfo } = Merchants.PostRequests;
    const { allPersonnels = {}, shopID } = this.props;
    const { personnelInfo = {} } = this.state;
    const personnelPin = Object.keys(allPersonnels).find((pin) => {
      const { personnelID } = allPersonnels[pin];
      return personnelID === personnelInfo.personnelID;
    });
    const { success } = await SavePersonnelInfo({
      personnelPin,
      personnelInfo,
      shopID,
    });
    this.confirmNotif = ShowConfirmNotif({
      message: success
        ? "Updated your profile"
        : "Failed to update your profile. Try again or refresh page.",
      type: success ? "success" : "error",
    });
  };

  onValidatePin = () => {
    if (this.state.isPinTaken) return "PIN is taken";
    else if (!this.state.isPinLengthValid)
      return "PIN must be exactly 5 digits";
  };

  renderButtons = () => {
    const { personnelInfo } = this.state;
    const { role } = personnelInfo;
    if (this.props.isNewUser)
      return (
        <div className={Style.newUserButtons}>
          <Button
            className={Style.createUserBtn}
            loadingText="Creating"
            name="Create New User"
            onClick={this.onCreateNewPersonnel}
            status={this.onGetCreateBtnStatus()}
          >
            Create
          </Button>
          <Button
            className={Style.laterBtn}
            color="dark"
            name="Cancel create New User"
            onClick={this.props.onCancelCreateNewUser}
            status={this.state.isCreatingPersonnel ? "inactive" : "active"}
          >
            Later
          </Button>
        </div>
      );
    else if (this.props.showRemoveButton)
      return (
        <Button
          className={Style.removePersonnelBtn}
          color="danger"
          name="remove personnel"
          onClick={this.onRemovePersonnel}
        >
          {this.state.isRemovingPersonnel ? "Removing..." : "Remove"}
        </Button>
      );
    else if (role === "owner")
      return (
        <Button
          className={Style.savePersonnelInfoBtn}
          name="Save Personnel Info"
          onClick={this.onSavePersonnelInfo}
          status={this.state.isOwnerMobileNumberValid ? "active" : "inactive"}
        >
          Save New Info
        </Button>
      );
  };

  render() {
    const { REGEX } = Constants;
    const { isEditable, showPersonnelPin } = this.props;
    const { personnelInfo, personnelPin } = this.state;
    const { mobileNumber = "", personnelName = "", role } = personnelInfo;
    return (
      <div>
        <div className={isEditable ? Style.personnelInModal : Style.personnel}>
          {showPersonnelPin && (
            <div className={Style.inputContainer}>
              <Input.TextInput
                errortext={this.onValidatePin()}
                label="Pin"
                name="Personnel Pin"
                onChange={this.onChangePersonnelPin}
                value={personnelPin}
                readOnly={!isEditable}
                required
                showError
                type="number"
              />
            </div>
          )}
          <div className={Style.inputContainer}>
            <Input.TextInput
              label="Name"
              name="Personnel Name"
              onChange={this.onChangePersonnelName}
              value={personnelName}
              readOnly={!isEditable}
              required
            />
          </div>
          <div className={Style.inputContainer}>
            <Input.TextInput
              label="Role"
              name="Personnel Role"
              value={role}
              readOnly
            />
          </div>
          {role === "owner" && (
            <div className={Style.inputContainer}>
              <Input.TextInput
                errortext={
                  mobileNumber && !REGEX.US_PHONE_NUMBER.test(mobileNumber)
                    ? "Invalid Number"
                    : ""
                }
                label="Mobile Number"
                name="Mobile Number"
                onChange={this.onChangeOwnerMobileNumber}
                value={mobileNumber}
                showError
                type="number"
              />
            </div>
          )}
        </div>
        {this.renderButtons()}
      </div>
    );
  }
}

Personnel.propTypes = {
  allPersonnels: PropTypes.object,
  personnelInfo: PropTypes.shape({
    personnelID: PropTypes.string,
    personnelName: PropTypes.string,
    role: PropTypes.oneOf(["owner", "staff"]),
  }),
  isEditable: PropTypes.bool,
  isNewUser: PropTypes.bool,
  onCancelCreateNewUser: PropTypes.func,
  onCreateUserSuccess: PropTypes.func,
  onRemoveUserSuccess: PropTypes.func,
  personnelPin: PropTypes.string,
  shopID: PropTypes.string,
  showPersonnelPin: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
};

Personnel.defaultProps = {
  allPersonnels: {},
  isEditable: false,
  isNewUser: false,
  onCancelCreateNewUser: () => {},
  onCreateUserSuccess: () => {},
  onRemoveUserSuccess: () => {},
  personnelInfo: {},
  personnelPin: "",
  showPersonnelPin: false,
  showRemoveButton: true,
};

export default Personnel;
