// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import _isEmpty from "lodash.isempty";
import cx from "classnames";

import AddButton from "../AddButton";
import FilterButton from "../FilterButton";
import { _filter } from "../functions";

import {
  DEFAULT_FILTER_VALUES,
  FILTER_MODIFIER_GROUPS_CRITERIAS,
} from "./constants";

// Style
import Style from "../style.module.scss";

// Components
import { MenusListOfElements, Modals } from "components";

// Fields
import { LoadingSpinner, PageMsg } from "fields";

// Lib
import { Services } from "lib";

const { Merchants } = Services;
const { GetAllModifierGroups } = Merchants.GetRequests;

class AllModifierGroups extends Component {
  state = {
    allModifierGroups: {},
    selected_filter_values: DEFAULT_FILTER_VALUES,
  };

  componentDidMount = () => this.onGetAllModifierGroups();

  componentWillUnmount = () =>
    this.setState({ isGettingModifierGroups: false });

  onGetAllModifierGroups = async () => {
    this.setState({ isGettingModifierGroups: true });
    try {
      const { allModifierGroups = {} } = await GetAllModifierGroups({
        shopID: this.props.shopID,
      });
      this.setState({ allModifierGroups }, this.onUpdateFilterResults);
    } catch {
    } finally {
      this.setState({ isGettingModifierGroups: false });
    }
  };

  onUpdateFilterResults = () =>
    this.setState({
      filter_results: _filter({
        list: this.state.allModifierGroups,
        filter_criterias: FILTER_MODIFIER_GROUPS_CRITERIAS,
        selected_filter_values: this.state.selected_filter_values,
      }),
    });

  onUpdateAllModifierGroups = ({ modifierGroupID, modifierGroupInfo }) => {
    const { allModifierGroups = {} } = this.state;
    if (!modifierGroupID) return;
    else if (_isEmpty(modifierGroupInfo))
      this.setState(
        { allModifierGroups: del(allModifierGroups, modifierGroupID) },
        this.onUpdateFilterResults
      );
    else {
      this.setState(
        {
          allModifierGroups: set(
            allModifierGroups,
            modifierGroupID,
            modifierGroupInfo
          ),
        },
        this.onUpdateFilterResults
      );
    }
  };

  onUpdateSelectedFilterValues = (selected_filter_values) =>
    this.setState({ selected_filter_values }, this.onUpdateFilterResults);

  renderModifierGroups = () => {
    const { filter_results = {} } = this.state;
    return (
      <MenusListOfElements.ListOfModifierGroups
        modifierGroupProps={{ isInEditMode: true }}
        modifierGroups={filter_results}
        onUpdateAllModifierGroups={this.onUpdateAllModifierGroups}
      />
    );
  };

  renderCreateModal = () => (
    <Modals.ModifierGroupModal
      onCloseModal={() =>
        this.setState({ showCreateModifierGroupModal: false })
      }
      onUpdateModifierGroupsAfterCreate={this.onUpdateAllModifierGroups}
      shopID={this.props.shopID}
    />
  );

  renderContent = () => {
    const { filter_results = {} } = this.state;
    const { scroll_y } = this.props;
    const count = Object.keys(filter_results).length;
    return (
      <div>
        <div
          className={cx(Style.headingContainer, {
            [Style.headingContainerEnd]: scroll_y > 50,
          })}
        >
          <h2 className={Style.heading}>
            {count} {count > 1 ? "Modifier Groups" : "Modifier Group"}
          </h2>
          <div
            className={cx(Style.buttons_group, {
              [Style.buttons_group_update]: scroll_y > 50,
            })}
          >
            <AddButton
              onClick={() =>
                this.setState({ showCreateModifierGroupModal: true })
              }
            />
            <FilterButton
              filter_criterias={FILTER_MODIFIER_GROUPS_CRITERIAS}
              onUpdateSelectedFilterValues={this.onUpdateSelectedFilterValues}
              results_count={count}
              selected_filter_values={this.state.selected_filter_values}
            />
          </div>
        </div>
        {this.state.showCreateModifierGroupModal && this.renderCreateModal()}
        {this.renderModifierGroups()}
      </div>
    );
  };

  render() {
    if (this.state.isGettingModifierGroups)
      return (
        <PageMsg>
          <LoadingSpinner message="Searching for modifier groups" />
        </PageMsg>
      );
    return this.renderContent();
  }
}

AllModifierGroups.propTypes = {
  shopID: PropTypes.string.isRequired,
};

export default AllModifierGroups;
