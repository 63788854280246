// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { set } from "object-path-immutable";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Chip } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

class Group extends Component {
  state = {};

  componentDidMount = () => this.setState({ groupInfo: this.props.groupInfo });

  onGetGroupImage = () => _get(this.state.groupInfo, "groupImages.full") || {};

  onHideChangePhotoModal = () => this.setState({ showChangePhotoModal: false });

  onHideEditGroupModal = () => this.setState({ showEditGroupModal: false });

  onShowChangePhotoModal = () => this.setState({ showChangePhotoModal: true });

  onShowEditGroupModal = () => this.setState({ showEditGroupModal: true });

  onUpdateAfterGroupArchived = ({ shouldArchiveGroup = false }) => {
    const { groupInfo } = this.state;
    this.setState(
      {
        groupInfo: set(groupInfo, "groupIsArchived", shouldArchiveGroup),
      },
      () =>
        this.props.onUpdateAllCategories({
          category_id: this.props.groupID,
          category_info: this.state.groupInfo,
        })
    );
  };

  onUpdateAfterGroupSaved = ({ groupInfo }) =>
    this.setState({ groupInfo }, () =>
      this.props.onUpdateAllCategories({
        category_id: this.props.groupID,
        category_info: this.state.groupInfo,
      })
    );

  onUpdateGroupImageAfterSaved = (newImage = "") =>
    this.setState(
      {
        groupInfo: set(this.state.groupInfo, "groupImages.full", newImage),
      },
      () =>
        this.props.onUpdateAllCategories({
          category_id: this.props.groupID,
          category_info: this.state.groupInfo,
        })
    );

  renderChangePhotoModal = () => {
    const groupImage = this.onGetGroupImage();
    return (
      <Modals.ChangeGroupPhotoModal
        groupID={this.props.groupID}
        groupName={this.state.groupInfo.groupName}
        imageURL={groupImage.imageURL}
        imageStyle={groupImage.imageStyle}
        onCloseModal={this.onHideChangePhotoModal}
        onUpdateGroupImageAfterSaved={this.onUpdateGroupImageAfterSaved}
      />
    );
  };

  renderChipLabel = () => {
    const { groupInfo } = this.state;
    const itemsCount = Object.keys(groupInfo.items || {}).length;
    const modifierGroupsCount = Object.keys(groupInfo.modifierGroups || {})
      .length;
    return (
      <React.Fragment>
        <div className={CommonStyle.chipName}>{groupInfo.groupName}</div>
        <div className={Style.chipDescription}>
          {itemsCount > 0 && (
            <div className={CommonStyle.elementsCounter}>
              {itemsCount} item{itemsCount !== 1 ? "s" : ""}
            </div>
          )}
          {modifierGroupsCount > 0 && (
            <div className={CommonStyle.elementsCounter}>
              {modifierGroupsCount} mod. group
              {modifierGroupsCount !== 1 ? "s" : ""}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderEditModal = () => (
    <Modals.MenuGroupModal
      isInEditMode={this.props.isInEditMode}
      groupID={this.props.groupID}
      groupInfo={this.state.groupInfo}
      onCloseModal={this.onHideEditGroupModal}
      onUpdateAfterGroupArchived={this.onUpdateAfterGroupArchived}
      onUpdateAfterGroupSaved={this.onUpdateAfterGroupSaved}
    />
  );

  renderHelperButton = () => {
    const { groupHelperButton } = this.props;
    if (groupHelperButton) return groupHelperButton(this.props.groupID);
    return (
      <PencilIcon
        className={CommonStyle.pencilIcon}
        onClick={this.onShowEditGroupModal}
      />
    );
  };

  renderGroup = () => (
    <div className={CommonStyle.chipContainer}>
      <Chip
        chipClassname={CommonStyle.chip}
        helperButtonClassname={CommonStyle.chipHelperButton}
        helperButtonContent={this.renderHelperButton()}
        label={this.renderChipLabel()}
        onClickLabel={this.onShowEditGroupModal}
      />
      {this.renderSigns()}
    </div>
  );

  renderSigns = () => {
    const { groupIsArchived } = this.state.groupInfo;
    if (groupIsArchived) {
      return (
        <div className={CommonStyle.signsContainer}>
          <div className={CommonStyle.archivedSign}>Archived</div>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showChangePhotoModal && this.renderChangePhotoModal()}
        {this.state.showEditGroupModal && this.renderEditModal()}
        {this.state.groupInfo && this.renderGroup()}
      </React.Fragment>
    );
  }
}

Group.propTypes = {
  isInEditMode: PropTypes.bool,
  groupHelperButton: PropTypes.func,
  groupID: PropTypes.string.isRequired,
  groupInfo: PropTypes.shape({ groupName: PropTypes.string }).isRequired,
  onUpdateAllCategories: PropTypes.func,
};

Group.defaultProps = {
  isInEditMode: true,
  onUpdateAllCategories: () => {},
};

export default Group;
