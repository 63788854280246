// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Field
import { ExpansionPanel, Link } from "fields";

class Help extends React.Component {
  render() {
    return (
      <div>
        <ExpansionPanel
          moduleTitle="Skipli Support"
          moduleDescription="Our team is on call and proactively monitoring orders as they come in. Reach our Support any day of the week."
          showExpandableContent
        >
          <div style={{ lineHeight: "25px" }}>
            Email:{" "}
            <Link className={Style.link} href="mailto: support@skiplinow.com">
              support@skiplinow.com
            </Link>
          </div>
          <div style={{ lineHeight: "25px" }}>
            Hotline 1:{" "}
            <Link className={Style.link} href="tel:949-346-6346">
              (949) 346 6346‬
            </Link>
          </div>
          <div style={{ lineHeight: "25px" }}>
            Hotline 2:{" "}
            <Link className={Style.link} href="tel:714-598-5560">
              (714) 598 5560
            </Link>
          </div>
        </ExpansionPanel>
      </div>
    );
  }
}

export default Help;
