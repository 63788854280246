// BUTI DINERS, INC. All right Reserved ©

// Lib
import { Functions, Services } from "lib";

const WEEK_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const onLocalizeTime = async ({ timeZone = "America/New_York", utc_time }) => {
  if (!utc_time) return;
  const { DateTime } = Functions;
  const { _convertTime24to12 } = DateTime;
  const { BUTI } = Services;
  const { ConvertUTCToLocalTime } = BUTI.GetRequests;
  const { localTime } = await ConvertUTCToLocalTime({
    utc_time,
    timeZone,
  });
  return _convertTime24to12(localTime);
};

const _convertExtraHours = async ({ extraHours = {}, timeZone }) =>
  Object.keys(extraHours).reduce(async (previousPromise, hoursId) => {
    const result = await previousPromise;
    const { endAt, startAt } = extraHours[hoursId];
    const local_start_at = await onLocalizeTime({
      timeZone,
      utc_time: startAt,
    });
    const local_end_at = await onLocalizeTime({
      timeZone,
      utc_time: endAt,
    });
    return result.concat(`, ${local_start_at} - ${local_end_at}`);
  }, Promise.resolve(""));

const _convertWeekDays = async ({ openHours, timeZone }) =>
  await WEEK_DAYS.reduce(async (previousPromise, weekday) => {
    const result = await previousPromise;
    const {
      endAt = "",
      extraHours = {},
      isClosedAllDay = false,
      startAt = "",
    } = openHours[weekday];
    const day = weekday.charAt(0).toUpperCase() + weekday.slice(1);

    if (isClosedAllDay) return result.concat(`${day}: CLOSED\r\n\r\n`);
    const local_start_at = await onLocalizeTime({
      timeZone,
      utc_time: startAt,
    });
    const local_end_at = await onLocalizeTime({
      timeZone,
      utc_time: endAt,
    });
    const extraHoursInText = await _convertExtraHours({ extraHours, timeZone });
    return result.concat(
      `${day}: ${local_start_at} - ${local_end_at}${extraHoursInText}\r\n\r\n`
    );
  }, Promise.resolve(""));

const _createNotifyEmailToSkipliSupport = async ({
  openHours = {},
  shopID,
  timeZone,
}) => {
  const { FRONT_END_BASE_URL } = Services;
  const merchant_portal = `${FRONT_END_BASE_URL}/${shopID}`;
  const hoursInText = await _convertWeekDays({ openHours, timeZone });
  return `Hi,\r\n\r\nThe merchant updated the open hours.\r\n\r\n${hoursInText}See more details in the merchant portal:\r\n${merchant_portal}`;
};

// function isHoliday(date: Date) {
//   const holidays = {
//     MD: {
//       // Month, Day
//       '1/1': "New Year's Day",
//       '7/4': 'Independence Day',
//       '11/11': "Veteran's Day",
//       '12/25': 'Christmas Day',
//       '12/31': "New Year's Eve"
//     },
//     MODW: {
//       // Month, Occurence, Day of Week
//       '1/3/1': 'Martin Luther King Jr. Day',
//       '2/3/1': 'Presidents Day',
//       '5/L/1': 'Memorial Day',
//       '9/1/1': 'Labor Day',
//       '10/2/1': 'Columbus Day',
//       '11/4/4': 'Thanksgiving Day'
//     }
//   };
//   const dayOfTheMonth = date.getDate();
//   const month = date.getMonth() + 1;
//   const dayOfTheWeek = date.getDay(); // 0 - 6, Su -> Sa
//   const lastDayOfTheMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
//   const isLastOccurrence = dayOfTheMonth + 7 > lastDayOfTheMonth;
//   let currentOccurrenceDay = dayOfTheMonth,
//     occurrence = 0;
//   for (currentOccurrenceDay; currentOccurrenceDay > 0; currentOccurrenceDay -= 7) occurrence++;

//   return !!(
//     holidays.MD?.[`${month}/${dayOfTheMonth}`] ||
//     (isLastOccurrence && holidays.MODW?.[`${month}/L/${dayOfTheWeek}`]) ||
//     holidays.MODW?.[`${month}/${occurrence}/${dayOfTheWeek}`]
//   );
// }

export { _createNotifyEmailToSkipliSupport };
