// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import Style from "./style.module.scss";
import CommonStyle from "../style.module.scss";

// Fields
import { Box, Checkbox, Link } from "fields";

const CERTIFICATIONS = [
  "The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and",
  "I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and",
  "I am a U.S. citizen or other U.S. person",
  // "The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.",
];

const Agreement = (props) => {
  const { agreement = {}, readOnly = false } = props;
  const { hasAgreed = false } = agreement;

  const onClickCheckbox = () => {
    if (!readOnly) props.onChange(set(agreement, "hasAgreed", !hasAgreed));
  };

  const renderCertifications = () =>
    CERTIFICATIONS.map((text, index) => (
      <p className={Style.certification} key={index}>
        {index + 1}. {text}
      </p>
    ));

  return (
    <Box
      class_names={{ header: CommonStyle.box_header }}
      header="Certification"
    >
      <p>Under penalties of perjury, I certify that: </p>
      <div>{renderCertifications()}</div>
      <div className={Style.checkbox}>
        <Checkbox
          checked={hasAgreed}
          id="agree"
          label="Agree to certification"
          onClick={onClickCheckbox}
          readOnly={readOnly}
        />
      </div>
      <p className={Style.paragraph}>
        By selecting the 'Agree to certification' and submitting this Electronic
        Signature Form, I agree that my electronic signature is the legally
        binding equivalent to my handwritten signature. I have read{" "}
        <Link className={Style.termsLink} href="https://skiplinow.com/terms">
          this agreement
        </Link>
        , understand it, and agree to be bound by its terms and conditions.
      </p>
      {props.submitButton}
    </Box>
  );
};

Agreement.propTypes = {
  agreement: PropTypes.shape({
    hasAgreed: PropTypes.bool,
  }),
  onChange: PropTypes.func.isRequired,
  submitButton: PropTypes.object,
  readOnly: PropTypes.bool,
};

Agreement.defaultProps = {
  readOnly: false,
};

export default Agreement;
