// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Input, ResponsiveModal } from "fields";

const ONE_SECOND = 1000;

class ConfirmCloseCheckInModal extends React.Component {
  state = { errortext: "", seconds: ONE_SECOND * 10, value: "" };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;
      if (seconds > 0)
        this.setState(({ seconds }) => ({ seconds: seconds - ONE_SECOND }));
      if (seconds === 0) {
        clearInterval(this.myInterval);
        this.props.onHideConfirmCloseModal();
      }
    }, ONE_SECOND);
  }

  componentWillUnmount = () => {
    clearInterval(this.myInterval);
    this.setState({ seconds: ONE_SECOND * 10 });
  };

  onVerifyCode = () => {
    if (this.state.value === this.props.deactivateCheckInCode)
      this.props.onDeactivateCheckIn();
    else this.setState({ errortext: "Incorrect code" });
  };

  render() {
    const { seconds } = this.state;
    const remaining_seconds = seconds / ONE_SECOND;

    return (
      <ResponsiveModal
        closeModalAfterSuccessSubmit={false}
        contentDescriptionProps={{
          contentDescription: `This window will close in ${remaining_seconds} second${
            remaining_seconds > 1 ? "s" : ""
          }`,
          showContentDescription: true,
        }}
        contentLabel="close check in modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            status: this.state.value ? "active" : "inactive",
          },
        }}
        onCloseModal={this.props.onHideConfirmCloseModal}
        onSubmit={this.onVerifyCode}
      >
        <Input.TextInput
          label="Deactivation Code"
          errortext={this.state.errortext}
          fieldClassname={Style.deactivateCodeInput}
          max_character_count={5}
          name="enter confirm code to close check in modal"
          onChange={(value) => this.setState({ value })}
          value={this.state.value}
          showError
          type="number"
        />
      </ResponsiveModal>
    );
  }
}

ConfirmCloseCheckInModal.propTypes = {
  deactivateCheckInCode: PropTypes.string.isRequired,
  onDeactivateCheckIn: PropTypes.func.isRequired,
  onHideConfirmCloseModal: PropTypes.func.isRequired,
};

export default ConfirmCloseCheckInModal;
