// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const Button = (props) => {
  const { status } = props;

  const onClick = () => {
    const { onClick } = props;
    if (status === "active" && onClick) props.onClick();
  };

  return (
    <button
      className={cx(
        Style.button,
        Style[props.color],
        props.hasShadow && Style.hasShadow,
        props.className,
        (status === "inactive" || status === "loading") && Style.inactive
      )}
      disabled={props.disabled}
      formAction={props.formAction}
      name={props.name}
      onBlur={props.onBlur}
      onClick={() => status === "active" && onClick()}
      style={props.style}
      type={props.type}
      value={props.value}
    >
      {status === "loading" ? `${props.loadingText}...` : props.children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  color: PropTypes.oneOf(["danger", "dark", "info", "primary", "warning"]),
  disabled: PropTypes.bool,
  hasShadow: PropTypes.bool,
  loadingText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(["active", "inactive", "loading"]),
  type: PropTypes.oneOf(["button", "reset", "submit"]),
};

Button.defaultProps = {
  disabled: false,
  hasShadow: false,
  loadingText: "Loading",
  onClick: () => {},
  status: "active",
  type: "submit",
};

export default Button;
