// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { DISPLAY_OPTIONS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, DateRangePicker, ResponsiveModal, Tabs } from "fields";

// Fields
import { FilterIcon } from "assets/Icons";

class ReviewsToolbar extends React.Component {
  state = {};

  onSortChangeDate = (sort_date) => {
    const sortTypeDate = "date";
    const { sort_rating, sortTypeRating, filter_rating } = this.state;

    this.setState({
      selected_sort_date_option_id: sort_date,
      sort_date,
      sortTypeDate,
    });

    this.props.onSortChange(
      sort_date,
      sortTypeDate,
      sort_rating,
      sortTypeRating,
      filter_rating
    );
  };

  onSortChangeRating = (sort_rating) => {
    const sortTypeRating = "rating";
    const { sort_date, sortTypeDate, filter_rating } = this.state;
    this.setState({
      selected_sort_rating_option_id: sort_rating,
      sort_rating,
      sortTypeRating,
    });
    this.props.onSortChange(
      sort_date,
      sortTypeDate,
      sort_rating,
      sortTypeRating,
      filter_rating
    );
  };

  onSortChangeStar = (filter_rating) => {
    const { sort_date, sortTypeDate, sort_rating, sortTypeRating } = this.state;
    this.setState({
      selected_sort_star_option_id: filter_rating,
      filter_rating,
    });
    this.props.onSortChange(
      sort_date,
      sortTypeDate,
      sort_rating,
      sortTypeRating,
      filter_rating
    );
  };

  renderFilterModalContent = () => {
    return (
      <div>
        <div className={Style.label_for_tabs}>Sort by date</div>
        <Tabs
          classNames={{ container: Style.sort_date_tabs }}
          onClick={this.onSortChangeDate}
          options={{
            newest: { label: "Newest" },
            oldest: { label: "Oldest" },
          }}
          selectedOptionId={this.state.selected_sort_date_option_id}
        />
        <div className={Style.label_for_tabs}>Sort by rating</div>
        <Tabs
          classNames={{ container: Style.sort_date_tabs }}
          onClick={this.onSortChangeRating}
          options={{
            highest: { label: "Highest" },
            lowest: { label: "Lowest" },
          }}
          selectedOptionId={this.state.selected_sort_rating_option_id}
        />
        <div className={Style.label_for_tabs}>Sort by star</div>
        <Tabs
          classNames={{ container: Style.sort_date_tabs }}
          onClick={this.onSortChangeStar}
          options={{
            1: { label: "1 Star" },
            2: { label: "2 Star" },
            3: { label: "3 Star" },
            4: { label: "4 Star" },
            5: { label: "5 Star" },
          }}
          selectedOptionId={this.state.selected_sort_star_option_id}
        />
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.show_filter_modal && (
          <ResponsiveModal
            classNames={{ modal: Style.modal }}
            contentLabel="Filter reviews"
            headerProps={{ header: "Filter" }}
            onCloseModal={() => this.setState({ show_filter_modal: false })}
          >
            {this.renderFilterModalContent()}
          </ResponsiveModal>
        )}
        <div
          className={cx({
            [Style.fixed_position]:
              (this.props.scroll_y > 400 && window.innerWidth > 1600) ||
              (this.props.scroll_y > 800 && window.innerWidth <= 1600),
          })}
        >
          <div className={Style.content}>
            <div className={Style.element_container}>
              <Button
                className={Style.sort_button}
                name="sort ratings"
                onClick={() => this.setState({ show_filter_modal: true })}
              >
                <FilterIcon /> Filter
              </Button>
            </div>
            <div className={Style.element_container}>
              <DateRangePicker
                class_names={{ container: Style.date_range_picker }}
                onChange={this.props.onChangeDateRange}
                selectedDayRange={this.props.selectedDayRange}
              />
            </div>
            <div className={Style.element_container}>
              <Tabs
                classNames={{ selected: Style.selected, tab: Style.tab }}
                options={DISPLAY_OPTIONS}
                selectedOptionId={this.props.selected_display_option_id}
                onClick={this.props.onChangeDisplayOption}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ReviewsToolbar.propTypes = {
  onChangeDateRange: PropTypes.func.isRequired,
  onChangeDisplayOption: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  selectedDayRange: PropTypes.string,
  selected_display_option_id: PropTypes.oneOf(["list", "grid"]).isRequired,
  selected_timeRange_id: PropTypes.string,
  scroll_y: PropTypes.number,
};

ReviewsToolbar.defaultProps = {};

export default ReviewsToolbar;
