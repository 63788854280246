// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import WebsiteBuilderEditStyle from "../style.module.scss";

// Components
import { RadioGroup } from "components";

// Fields
import { Box, Button, Input, ResponsiveModal } from "fields";
import Modals from "components/Modals";

const TEXT_FIELDS = {
  heading: {
    label: "Heading",
  },
  message: {
    hasMultipleLines: true,
    label: "Message",
    rows: 3,
  },
};

const TEXT_POSITIONS = {
  center: {
    label: "Center",
  },
  left: {
    label: "Left",
  },
  right: {
    label: "Right",
  },
};

class ShopHeroImageModal extends React.Component {
  state = {};

  onChange = ({ fieldId = "", fieldValue }) => {
    if (!fieldId) return;
    const { heroImage = {} } = this.props;
    this.props.onChange(set(heroImage, fieldId, fieldValue));
  };

  renderImageViewerModal = () => {
    const { context = {}, heroImage = {} } = this.props;
    const { shopID = "" } = context;
    const { imageURL = "" } = heroImage;

    return (
      <Modals.ImageViewerModal
        destinationFolderName={`shops/${shopID}/website`}
        imageURL={imageURL}
        modalContentLabel="change shop hero image"
        onCloseModal={() => this.setState({ showChangeImageModal: false })}
        onCompleteImageUpload={(imageURL) => {
          this.setState({ showChangeImageModal: false });
          this.onChange({ fieldId: "imageURL", fieldValue: imageURL });
        }}
        uploadMaxHeight={940}
        uploadMaxWidth={1400}
        saveButtonName="save new hero image"
      />
    );
  };

  renderModalContent = () => {
    const { heroImage = {} } = this.props;
    const { imageURL = "" } = heroImage;
    return (
      <div className={WebsiteBuilderEditStyle.modalContent}>
        <Box header="Background Image">
          <div className={WebsiteBuilderEditStyle.fieldContainer}>
            <Input.TextInput
              label="Image URL"
              name="image url"
              readOnly
              rows={2}
              value={imageURL}
            />
          </div>
          <Button
            name="change image button"
            onClick={() => this.setState({ showChangeImageModal: true })}
          >
            Change
          </Button>
        </Box>
        <Box header="Text">
          {this.renderTextPosition()}
          {this.renderTextFields()}
        </Box>
      </div>
    );
  };

  renderTextFields = () =>
    Object.keys(TEXT_FIELDS).map((fieldId) => {
      const { heroImage = {} } = this.props;
      const { hasMultipleLines = false, label, rows } = TEXT_FIELDS[fieldId];
      return (
        <div className={WebsiteBuilderEditStyle.fieldContainer} key={fieldId}>
          <Input.TextInput
            label={label}
            hasMultipleLines={hasMultipleLines}
            name={label}
            onChange={(value) => this.onChange({ fieldId, fieldValue: value })}
            rows={rows}
            value={heroImage[fieldId]}
          />
        </div>
      );
    });

  renderTextPosition = () => {
    const { heroImage = {} } = this.props;
    const { textPosition = "left" } = heroImage;
    return (
      <div className={WebsiteBuilderEditStyle.fieldContainer}>
        <RadioGroup
          heading="Text Position"
          onClick={(optionId) =>
            this.onChange({ fieldId: "textPosition", fieldValue: optionId })
          }
          options={TEXT_POSITIONS}
          required
          selectedOptionId={textPosition}
        />
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showChangeImageModal && this.renderImageViewerModal()}
        <ResponsiveModal
          classNames={{ modal: WebsiteBuilderEditStyle.modal }}
          contentLabel="Edit website header modal"
          headerProps={{ header: "Hero Image" }}
          onCloseModal={this.props.onCloseModal}
        >
          {this.renderModalContent()}
        </ResponsiveModal>
      </React.Fragment>
    );
  }
}

ShopHeroImageModal.propTypes = {
  context: PropTypes.shape({
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  heroImage: PropTypes.exact({
    imageURL: PropTypes.string,
    heading: PropTypes.string,
    message: PropTypes.string,
    textPosition: PropTypes.oneOf(["center", "left", "right"]),
    showOrderNowButton: PropTypes.string,
    showSeeMenuButton: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(ShopHeroImageModal);
