// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { _getSectionNavItems } from "./HelperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import NavbarItemStyle from "components/Navbars/MerchantGlobalNavbar/style.module.scss";
import Style from "./style.module.scss";

// Components
import { Navbars } from "components";

// Fields
import { Link } from "fields";

// Icons
import { OrderFoodOnlineIcon, PhoneIcon, SendEmailIcon } from "assets/Icons";

const NAVIGATION_MENU_SECTIONS = {
  manageOrders: {
    label: "manage orders",
    navItemIds: ["orders", "pastOrders"],
  },
  manageStore: {
    label: "manage store",
    navItemIds: [
      "menusManagement",
      "preparation",
      "shopInfo",
      "paymentInfo",
      "payoutsForConnectedAccount",
      "personnelProfile",
    ],
  },
  expandBusiness: {
    label: "expand business",
    navItemIds: [
      "guest_reviews",
      "combineThirdPartyOrders",
      "customerSelfCheckIn",
      "marketing",
      "website",
    ],
  },
  settings: {
    label: "settings",
    navItemIds: ["settings"],
  },
};

class NavigationMenuContainer extends React.Component {
  renderGeneralSection = () => {
    const { shopID } = this.props.context;
    return (
      <div className={cx(Style.section, Style.generalSection)}>
        <h2 className={Style.heading}>General</h2>
        {/* <Button
          className={NavbarItemStyle.navbarItemContainer}
          name="get updates"
          onClick={() => window.location.reload()}
        >
          <div className={NavbarItemStyle.iconContainer}>
            <RefreshIcon />
          </div>
          <div className={NavbarItemStyle.navbarItemLabel}>Get Updates</div>
        </Button> */}
        <Link
          className={cx(NavbarItemStyle.navbarItemContainer, Style.link)}
          href={`https://www.skiplinow.com/shop/${shopID}`}
        >
          <div className={NavbarItemStyle.iconContainer}>
            <OrderFoodOnlineIcon />
          </div>
          <div className={NavbarItemStyle.navbarItemLabel}>Visit Live Menu</div>
        </Link>
      </div>
    );
  };

  renderSections = () => {
    return Object.keys(NAVIGATION_MENU_SECTIONS).map((sectionId) => {
      const { label, navItemIds } = NAVIGATION_MENU_SECTIONS[sectionId];
      const sectionNavItems = _getSectionNavItems({
        navItems: this.props.navItems,
        sectionNavItemIds: navItemIds,
      });
      return (
        sectionNavItems.length > 0 && (
          <div className={Style.section} key={sectionId}>
            <h2 className={Style.heading}>{label}</h2>
            <Navbars.MerchantGlobalNavbar
              activeNavbarItemId={this.props.activeNavbarItemId}
              navItems={sectionNavItems}
              onChangeGlobalNavItem={this.props.onChangeGlobalNavItem}
            />
          </div>
        )
      );
    });
  };

  renderSupport = () => (
    <div className={Style.support}>
      <div className={Style.needHelp} style={{ fontSize: "14px" }}>
        Need help? Contact us
      </div>
      <div className={Style.helpLinks}>
        <Link className={Style.needHelp} href={`tel:6789996082`} target="_self">
          <PhoneIcon className={Style.phoneIcon} /> (678) 999-6082
        </Link>
        <Link className={Style.needHelp} href="mailto: support@skiplinow.com">
          <SendEmailIcon className={Style.phoneIcon} /> support@skiplinow.com
        </Link>
      </div>
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.navigationOptionsContainer}>
          {this.renderGeneralSection()}
          {this.props.personnel && (
            <div className={Style.sectionsContainer}>
              {this.renderSections()}
              {/* <Navbars.MerchantGlobalNavbar
                activeNavbarItemId={this.props.activeNavbarItemId}
                navItems={this.props.navItems}
                onChangeGlobalNavItem={this.props.onChangeGlobalNavItem}
              /> */}
            </div>
          )}
        </div>
        <div className={Style.poweredBy}>{this.renderSupport()}</div>
      </div>
    );
  }
}

NavigationMenuContainer.propTypes = {
  activeNavbarItemId: PropTypes.string.isRequired,
  context: PropTypes.shape({
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  navItems: PropTypes.array.isRequired,
  onChangeGlobalNavItem: PropTypes.func.isRequired,
  personnel: PropTypes.object,
};

export default withContext(MerchantInterfaceConsumer)(NavigationMenuContainer);
