// BUTI Corp All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import _get from "lodash.get";
import { _createReceiptPDF } from "./createReceiptPDF";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Fields
import { Input, ResponsiveModal } from "fields";

// Lib
import { Functions, Services } from "lib";

class ReportModal extends React.Component {
  state = { email: "support@skiplinow.com" };

  onGetSubmitButtonStatus = () => {
    const { email = "" } = this.state;
    return email ? "active" : "inactive";
  };

  onConvertTimeStamp = (timeStamp) => {
    const { DateTime } = Functions;
    const { _convertUTCTimestampToLocalTime } = DateTime;
    const { local_date, local_time } = _convertUTCTimestampToLocalTime({
      localTimeZone: _get(this.props.context, "shopBasicInfo.timeZone"),
      timeStamp,
    });
    return `${local_date} ${local_time}`;
  };

  onGeneratePaymentInfo = async (paymentIntentID) => {
    const { Merchants } = Services;
    const { GetUniqueCustomerPayment } = Merchants.GetRequests;
    const data = await GetUniqueCustomerPayment({
      payment_id: paymentIntentID,
    });
    return data;
  };

  onEmailReceipt = async () => {
    const { BUTI } = Services;
    const { SendEmail } = BUTI.PostRequests;
    const { order_info, order_id, shop_name, shop_address } = this.props;
    const { customerName, timeStamp } = order_info;
    const { paymentIntentID } = order_info;
    const time_order = this.onConvertTimeStamp(timeStamp);
    const payment_info = await this.onGeneratePaymentInfo(paymentIntentID);
    const pdf_data = _createReceiptPDF({
      customerName,
      order_id,
      order_info,
      payment_info,
      shop_address,
      shop_name,
      time_order,
    });
    const email_list = this.state.email.split(",").map((email) => email.trim());

    for (const email of email_list) {
      await SendEmail({
        addresses: [email],
        attachments: [pdf_data],
        body: `Dear ${customerName},\r\n\r\nWe're pleased to provide you with the attached file containing the receipt for your order #${order_id} at ${shop_name} on ${time_order}. If you have any questions or need further assistance, please don't hesitate to reach out.\r\n\r\n${shop_name} locates at ${shop_address}\r\n\r\nThank you for choosing Skipli.\r\n\r\nBest regards`,
        from: "support@skiplinow.com",
        subject: `Receipt of Skipli order #${order_id} for ${customerName} - ${time_order}`,
      });
    }
  };

  render() {
    const { email } = this.state;

    return (
      <ResponsiveModal
        contentLabel="Export Report"
        headerProps={{ header: "Export Report" }}
        onCloseModal={this.props.onCloseModal}
        footerProps={{
          showFooter: true,
          submitButtonProps: { status: this.onGetSubmitButtonStatus() },
        }}
        onSubmit={this.onEmailReceipt}
      >
        <Input.TextInput
          name="Email"
          onChange={(email) => this.setState({ email })}
          placeholder="Email"
          required={true}
          value={email}
        />
      </ResponsiveModal>
    );
  }
}

ReportModal.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  order_info: PropTypes.shape({
    gift_card_ledger: PropTypes.object.isRequired,
    orderDeliveryTypeID: PropTypes.string.isRequired,
    orderItems: PropTypes.object.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    pickUpTime: PropTypes.string,
    tableNumber: PropTypes.string,
    tipAmount: PropTypes.any,
  }).isRequired,
  order_id: PropTypes.string.isRequired,
  shop_address: PropTypes.string.isRequired,
  shop_name: PropTypes.string.isRequired,
};

ReportModal.defaultProps = {
  onCloseModal: () => {},
};

export default withContext(MerchantInterfaceConsumer)(ReportModal);
