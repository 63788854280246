// BUTI DINERS, INC. All right Reserved ©

import REGEX from "lib/Constants/RegEx";
import shortid from "shortid";

const OWNER_INFO_FIELDS = ({ ownerInfo = {} }) => {
  const { lastFourDigitsOfSSN = "" } = ownerInfo;
  const fields = {
    name: {
      label: "First and Last name",
      placeholder: "First Name, Last Name",
      required: true,
    },
    email: {
      label: "Email Address",
      regEx: REGEX.EMAIL,
      required: true,
      type: "email",
    },
    phone: {
      label: "Phone #",
      regEx: REGEX.US_PHONE_NUMBER,
      required: true,
      type: "number",
    },
    dob: {
      label: "Date Of Birth",
      placeholder: "MM/DD/YYYY",
      regEx: REGEX.DATE_OF_BIRTH,
      required: true,
    },
  };
  return lastFourDigitsOfSSN
    ? {
        ...fields,
        lastFourDigitsOfSSN: {
          label: "Last 4 digits of SSN",
          placeholder: "0000",
          required: true,
          regEx: REGEX.LAST_FOUR_DIGITS_OF_SSN,
          type: "number",
        },
      }
    : {
        ...fields,
        nine_digits_of_us_ssn: {
          label: "Social Security Number (SSN)",
          placeholder: "XXXXXXXXX",
          required: true,
          regEx: REGEX.NINE_DIGITS_OF_US_SSN,
          type: "number",
        },
      };
};

const SAMPLE_NEW_FORM = {
  agreement: { hasAgreed: false },
  approval: { isApproved: false },
  ownerInfo: {
    email: "son.ton.tran@gmail.com",
    name: "John Ton",
    phone: "9493466346",
  },
  locations: {
    [shortid.generate()]: {
      basicInfo: {
        name: "Restaurant",
        address: "1003 Center Street NW, Unit A",
        city: "Atlanta",
        state: "GA",
        zip: "30318",
      },
      legalInfo: {
        legalName: "4CX Innovations LLC",
        taxId: "34567765",
      },
      billingInfo: {
        bankName: "Bank Of America",
        routingNumber: "34567567",
        accountNumber: "345678",
      },
    },
  },
  lastModifiedAt: "",
  submittedAt: "",
};

const LOCATION_INFO = {
  getPaidInfo: {
    label: "Bank Account Details",
    fields: {
      routingNumber: {
        label: "Routing Number",
        placeholder: "Bank Routing #",
        required: true,
      },
      accountNumber: {
        label: "Bank Account Number",
        placeholder: "Bank Account #",
        required: true,
      },
    },
  },
  legalInfo: {
    label: "Tax Information",
    fields: {
      legalName: {
        label: "Legal Business Name",
        placeholder: "Company Name",
        required: true,
      },
      taxId: {
        label: "Employer ID Number (EIN)",
        placeholder: "EIN #",
        required: true,
      },
      businessStructure: {
        label: "Select Your Tax Classification",
        options: {
          individual: { label: "Individual/Sole Proprietor" },
          llc: { label: "Limited liability company (LLC)" },
          corporation: { label: "Corporation (Inc, S-corp, C-corp)" },
          nonprofit: { label: "Nonprofit Organization" },
          partnership: { label: "Partnership" },
        },
        required: true,
        type: "radio",
      },
    },
  },
  basicInfo: {
    label: "Location Information",
    fields: {
      name: { label: "Name", required: true },
      address: { label: "Address", required: true },
      city: { label: "City", required: true },
      state: { label: "State", required: true },
      zip: { label: "Zip Code", regEx: REGEX.POSTAL_CODE, required: true },
      phone: {
        label: "Business Phone",
        regEx: REGEX.US_PHONE_NUMBER,
        type: "number",
      },
      website: { label: "Business Website", regEx: REGEX.WEBSITE },
    },
  },
};

const _getMerchantLocationsForEmail = ({ locations = {} }) =>
  Object.keys(locations).reduce((result, locationId, index) => {
    const { basicInfo = {}, legalInfo = {} } = locations[locationId];
    const { address, city, name, state, zip } = basicInfo;
    const { legalName } = legalInfo;
    return result.concat(
      `${index +
        1}. ${name}\r\nAddress: ${address}, ${city}, ${state} ${zip}\r\nLegal name: ${legalName}\r\n\r\n`
    );
  }, "\r\n\r\n");

const _createEmailToMerchant = ({
  formId = "",
  isApprovingForm = false,
  lastModifiedAt = "",
  locations = {},
  name = "",
}) => {
  if (isApprovingForm) {
    return {
      body: `Congratulations ${name}!\r\n\r\nSkipli Business approved your merchant account for: ${_getMerchantLocationsForEmail(
        { locations }
      )}You can still access your application by clicking the link below:\r\n\r\nadmin.skiplinow.com/new-merchant-account/${formId}\r\n\r\nOne of our team members will soon reach out to setup your online store & walk you through the on-boarding process.\r\n\r\nBest,\r\nSkipli For Business\r\nbusiness@skiplinow.com`,
      subject: "Your merchant account is approved",
    };
  } else if (lastModifiedAt) {
    return {
      body: `Dear ${name},\r\n\r\nYou successfully updated your merchant account application for: ${_getMerchantLocationsForEmail(
        { locations }
      )}Our team has been notified of the updates and will review them soon. You can still make changes to the form if neccesary before the application is approved.\r\n\r\nOnce your application is approved (soon), one of our team members will reach out to setup your online store & walk you through the on-boarding process.\r\n\r\nIn the mean time, your form can be found at: admin.skiplinow.com/new-merchant-account/${formId}\r\n\r\nYour application number: ${formId}\r\n\r\nBest,\r\nSkipli For Business\r\nbusiness@skiplinow.com`,
      subject: "Your merchant account application is updated",
    };
  }
  return {
    body: `Dear ${name},\r\n\r\nThank you for submitting a merchant account application for: ${_getMerchantLocationsForEmail(
      { locations }
    )}We have started reviewing your application. During the review process, we will reach out to you via the contact info on the form if there are information that need to be verified.\r\n\r\nOnce your application is approved (soon), one of our team members will reach out to setup your online store & walk you through the on-boarding process.\r\n\r\nIn the mean time, your form can be found at: admin.skiplinow.com/new-merchant-account/${formId}\r\n\r\nYour application number: ${formId}\r\n\r\nBest,\r\nSkipli For Business\r\nbusiness@skiplinow.com`,
    subject: "We received your merchant account application",
  };
};

const _createEmailToSkipli = ({
  formId = "",
  isApprovingForm,
  lastModifiedAt = "",
  locations = {},
}) => {
  if (isApprovingForm) {
    return {
      body: `It happened! We have just approved a merchant account application for ${_getMerchantLocationsForEmail(
        { locations }
      )}Click the link below to review the form:\r\n\r\nadmin.skiplinow.com/new-merchant-account/${formId}\r\n\r\nBest,\r\nSkipli For Business\r\nbusiness@skiplinow.com`,
      subject: "Merchant account application is approved",
    };
  } else if (lastModifiedAt) {
    return {
      body: `A merchant have updated their merchant account application for ${_getMerchantLocationsForEmail(
        { locations }
      )}Click the link below to review the form:\r\n\r\nadmin.skiplinow.com/new-merchant-account/${formId}\r\n\r\nBest,\r\nSkipli For Business\r\nbusiness@skiplinow.com`,
      subject: "A merchant account is updated",
    };
  }
  return {
    body: `We have just received a merchant account application for ${_getMerchantLocationsForEmail(
      { locations }
    )}Click the link below to review the form:\r\n\r\nadmin.skiplinow.com/new-merchant-account/${formId}\r\n\r\nBest,\r\nSkipli For Business\r\nbusiness@skiplinow.com`,
    subject: "New merchant account application",
  };
};

const _getSubmitBtnStatus = ({ form = {} }) => {
  const { agreement = {}, ownerInfo = {}, locations = {} } = form;
  const isAgreementCertified = _verifyAgreement(agreement);
  const isOwnerInfoDone = _verifyInputFields({
    fields: OWNER_INFO_FIELDS({ ownerInfo }),
    values: ownerInfo,
  });
  const areLocationsDone = _verifyLocations(locations);
  if (isAgreementCertified && isOwnerInfoDone && areLocationsDone)
    return "active";
  return "inactive";
};

const _verifyAgreement = (agreement = {}) => {
  const { hasAgreed = false } = agreement;
  return hasAgreed;
};

const _verifyLocations = (locations = {}) =>
  Object.keys(locations).reduce(
    (result, locationId) => _verifyLocation(locations[locationId]) && result,
    true
  );

const _verifyLocation = (locationInfo = {}) =>
  Object.keys(LOCATION_INFO).reduce((isSectionDone, sectionId) => {
    const { fields = {} } = LOCATION_INFO[sectionId];
    return (
      _verifyInputFields({ fields, values: locationInfo[sectionId] }) &&
      isSectionDone
    );
  }, true);

const _verifyInputFields = ({ fields = {}, values = {} }) =>
  Object.keys(fields).reduce((result, fieldId) => {
    const { regEx = /[^]*/, required = false } = fields[fieldId];
    const value = values[fieldId];
    let isValueValid = true;
    if (required && !value) isValueValid = false;
    else if (value && !regEx.test(value)) isValueValid = false;
    return result && isValueValid;
  }, true);

export {
  LOCATION_INFO,
  OWNER_INFO_FIELDS,
  SAMPLE_NEW_FORM,
  _createEmailToMerchant,
  _createEmailToSkipli,
  _getSubmitBtnStatus,
};
