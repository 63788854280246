// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Components
import { HomeScreenModules } from "components";

// Fields
import { Button, Input } from "fields";

// Icons
import { ArrowIcon, SkipliLogoWithTextIcon } from "assets/Icons";

const BKG_IMG_URL = "https://i.imgur.com/RotYBiS.png";

class Home extends React.Component {
  state = { shopName: "", showSearchResult: false };

  onChangeShopName = (shopName) => this.setState({ shopName });

  onRedirectToNewMerchantAccount = () =>
    (window.location.href = "/new-merchant-account");

  onSearchShop = () =>
    String(this.state.shopName).trim() &&
    this.setState({ showSearchResult: true });

  renderWelcomeForm = () => (
    <React.Fragment>
      <div className={Style.skipliLogo}>
        <SkipliLogoWithTextIcon />
      </div>
      <div>
        <Input.TextInput
          name="store name"
          onChange={this.onChangeShopName}
          placeholder="Restaurant Name"
          value={this.state.shopName}
        />
        <Button
          className={Style.accessStoreButton}
          color="info"
          name="search for restaurant"
          onClick={this.onSearchShop}
        >
          Access Your Store
          <div className={Style.arrowIcon}>
            <ArrowIcon />
          </div>
        </Button>
      </div>
      <div className={Style.newAccountContainer}>
        <p>New to Skipli For Business?</p>
        <Button
          className={Style.newMerchantAccountButton}
          name="create new merchant account"
          onClick={this.onRedirectToNewMerchantAccount}
        >
          New Account
        </Button>
      </div>
    </React.Fragment>
  );

  render() {
    const { showSearchResult } = this.state;
    return (
      <div>
        <div className={cx(Style.form, showSearchResult && Style.expanded)}>
          {showSearchResult ? (
            <HomeScreenModules.SearchResults
              onBackToMainScreen={() =>
                this.setState({ showSearchResult: false })
              }
              onChangeShopName={this.onChangeShopName}
              shopName={this.state.shopName}
            />
          ) : (
            this.renderWelcomeForm()
          )}
        </div>
        <img alt="" className={Style.backgroundImg} src={BKG_IMG_URL} />
      </div>
    );
  }
}

export default Home;
