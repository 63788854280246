// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

class BetaButton extends React.Component {
  render() {
    return <div className={Style.container}>BETA</div>;
  }
}

export default BetaButton;
