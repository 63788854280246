// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Modifiers from "./Modifiers";

// Style
import Style from "./style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Lib
import { Functions, Services } from "lib";

// Assets
import { PlusIcon } from "assets/Icons";

class CartItem extends Component {
  state = { showItemDetails: false };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.onGetItemInfo();
  }

  componentWillUnmount() {
    this.setState({ showItemDetails: false });
    this._isMounted = false;
  }

  onGetItemInfo = async () => {
    if (!this.state.itemInfo) {
      const { Merchants } = Services;
      const { GetMenuItemInformation } = Merchants.GetRequests;
      const { shopID = "" } = this.props.context;
      if (shopID) {
        const { itemID } = this.props.detailsOfItemInCart;
        const { itemInfo } = await GetMenuItemInformation({
          itemID,
          shopID,
        }).catch(() => this.setState({ itemInfo: null }));
        if (itemInfo && this._isMounted) this.setState({ itemInfo });
      }
    }
  };

  onHideItemDetails = () => this.setState({ showItemDetails: false });

  onShowItemDetails = () => this.setState({ showItemDetails: true });

  renderOnSaleAmount = (itemSaleRate) => (
    <div className={Style.discount_amount} style={{ marginBottom: "5px" }}>
      {itemSaleRate}% discount
    </div>
  );

  renderItemInfoColumn = () => {
    const { detailsOfItemInCart } = this.props;
    const {
      customerInstruction,
      itemSimpleDescription = {},
    } = detailsOfItemInCart;
    const { itemKitchenChitName = "", itemName = "" } = itemSimpleDescription;
    return (
      <div className={Style.itemInfoColumn}>
        <div className={Style.itemDetailsColumn}>
          <div className={Style.itemName}>
            {itemKitchenChitName || itemName}
          </div>
          <div className={Style.itemExtraInfo}>
            <Modifiers modifierGroups={detailsOfItemInCart.modifierGroups} />
          </div>
          {customerInstruction && (
            <div className={cx(Style.itemExtraInfo, Style.customerInstruction)}>
              <div style={{ marginBottom: "0.6rem" }}>Note from guest:</div>
              <mark style={{ lineHeight: "1.2rem" }}>
                {customerInstruction}
              </mark>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderPriceColumn = () => {
    const { OrderMathFuncs } = Functions;
    const { _calcTotalPriceBeforeTaxForItem } = OrderMathFuncs;
    const { detailsOfItemInCart = {} } = this.props;
    const { itemIsOnSale = false, itemSaleRate = 0 } = detailsOfItemInCart;
    const { totalPriceBeforeTax } = _calcTotalPriceBeforeTaxForItem({
      detailsOfItemInCart,
    });
    return (
      <div className={Style.priceCol}>
        <div>${totalPriceBeforeTax.toFixed(2)}</div>
        {itemIsOnSale && this.renderOnSaleAmount(itemSaleRate)}
      </div>
    );
  };

  render() {
    const { detailsOfItemInCart } = this.props;
    const { quantity } = detailsOfItemInCart;
    return (
      <div className={Style.container}>
        <div className={Style.itemCount}>
          {quantity}
          <PlusIcon />
        </div>
        <div className={Style.itemInCart}>
          {this.renderItemInfoColumn()}
          {this.renderPriceColumn()}
        </div>
      </div>
    );
  }
}

CartItem.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string }),
  detailsOfItemInCart: PropTypes.shape({
    customerInstruction: PropTypes.string,
    itemID: PropTypes.string.isRequired,
    itemSimpleDescription: PropTypes.shape({
      itemKitchenChitName: PropTypes.string,
      itemName: PropTypes.string.isRequired,
      itemPrice: PropTypes.any,
    }),
    quantity: PropTypes.number.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(CartItem);
