// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Fields
import { Box, ComingSoonButton, ExpansionPanel } from "fields";

class Analytics extends React.Component {
  render() {
    return (
      <div>
        <Box>
          <ExpansionPanel moduleTitle="Sales" showExpandableContent>
            <ComingSoonButton />
          </ExpansionPanel>
        </Box>
        <Box>
          <ExpansionPanel moduleTitle="Service Quality" showExpandableContent>
            <ComingSoonButton />
          </ExpansionPanel>
        </Box>
        <Box>
          <ExpansionPanel
            moduleTitle="Customer Satisfaction"
            showExpandableContent
          >
            <ComingSoonButton />
          </ExpansionPanel>
        </Box>
      </div>
    );
  }
}

export default Analytics;
