// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Checkbox } from "fields";

const ImageUsageDisclaimer = ({ onAgreeToTerms, termsAgreed }) => (
  <div>
    <p className={Style.disclaimer}>
      I certify that the image I chose to display is not subject to any
      copyrights or intellectual property infringement. I agree to waive SKIPLI
      of any responsibility to ensure the image copyright and I will be
      responsible for any infringement penalties should they arise.
    </p>
    <Checkbox
      checked={termsAgreed}
      id="i-agree"
      label="I agree"
      onClick={onAgreeToTerms}
    />
  </div>
);

ImageUsageDisclaimer.propTypes = {
  onAgreeToTerms: PropTypes.func.isRequired,
  termsAgreed: PropTypes.bool,
};

ImageUsageDisclaimer.defaultProps = {
  termsAgreed: false,
};

export default ImageUsageDisclaimer;
