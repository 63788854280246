// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const Box = ({ class_names = {}, children, description, header }) => (
  <div className={cx(Style.container, class_names.container)}>
    {header && (
      <div className={cx(Style.header_container, class_names.header_container)}>
        <h2 className={cx(Style.header, class_names.header)}>{header}</h2>
        {description && (
          <p className={cx(Style.description, class_names.description)}>
            {description}
          </p>
        )}
      </div>
    )}
    {children && (
      <div
        className={cx(Style.content_container, class_names.content_container)}
      >
        {children}
      </div>
    )}
  </div>
);

Box.propTypes = {
  children: PropTypes.any,
  class_names: PropTypes.shape({
    container: PropTypes.string,
    content_container: PropTypes.string,
    description: PropTypes.string,
    header_container: PropTypes.string,
    header: PropTypes.string,
  }),
  description: PropTypes.string,
  header: PropTypes.any,
};

Box.defaultProps = {
  children: null,
  class_names: {},
};

export default Box;
