// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Assets
import { CheckIcon, MagnifyingGlassIcon } from "assets/Icons";

class Description extends React.Component {
  render() {
    const { form = {} } = this.props;
    const { approval = {}, lastModifiedAt = "" } = form;
    const { isApproved = false } = approval;
    if (isApproved)
      return (
        <div className={Style.approved}>
          <div className={Style.checkIcon}>
            <CheckIcon />
          </div>
          <div className={Style.description}>
            Congratulations! Your merchant account is approved
          </div>
        </div>
      );
    else if (lastModifiedAt)
      return (
        <div className={Style.reviewing}>
          <div className={Style.glassIcon}>
            <MagnifyingGlassIcon />
          </div>
          <div className={Style.description}>
            Your merchant account is being reviewed by our staff
          </div>
        </div>
      );
    return null;
  }
}

Description.propTypes = {
  form: PropTypes.shape({
    approval: PropTypes.shape({
      approvedAt: PropTypes.string,
      isApproved: PropTypes.bool,
    }),
    lastModifiedAt: PropTypes.string,
  }).isRequired,
};

export default Description;
