// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _orderBy from "lodash.orderby";

// Style
import Style from "../style.module.scss";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Components
import { Printer } from "components";

class AddedPrinters extends React.Component {
  onSortAddedPrinters = (addedPrinters = {}) =>
    _orderBy(
      Object.keys(addedPrinters),
      [
        (printerName) => addedPrinters[printerName].isConnected,
        (printerName) => addedPrinters[printerName].isPrimary,
      ],
      ["asc", "asc"]
    );

  renderAddedPrinters = () => {
    const { addedPrinters = {} } = this.props.context;
    const sortedPrinterNames = this.onSortAddedPrinters(addedPrinters);
    return sortedPrinterNames.map((printerName) => {
      const { isConnected, isPrimary, printerBrand } = addedPrinters[
        printerName
      ];
      return (
        <div className={Style.printerContainer} key={printerName}>
          <Printer
            isAddedToShop
            isConnected={isConnected}
            isPrimary={isPrimary}
            onChangePrinterBrand={this.props.onChangePrinterBrand}
            onRemovePrinter={this.props.onRemovePrinter}
            onTestPrinterConnection={this.props.onTestPrinterConnection}
            printerBrand={printerBrand}
            printerName={printerName}
          />
        </div>
      );
    });
  };

  render() {
    const {
      addedPrinters = {},
      isFindingShopAddedPrinters,
    } = this.props.context;
    if (isFindingShopAddedPrinters) return <p>Finding available printers...</p>;
    return Object.keys(addedPrinters).length === 0 ? (
      <p>No printer added.</p>
    ) : (
      <div className={Style.printers}>{this.renderAddedPrinters()}</div>
    );
  }
}

AddedPrinters.propTypes = {
  context: PropTypes.shape({
    addedPrinters: PropTypes.object,
  }).isRequired,
  isFindingShopAddedPrinters: PropTypes.bool,
  onChangePrinterBrand: PropTypes.func.isRequired,
  onRemovePrinter: PropTypes.func.isRequired,
  onTestPrinterConnection: PropTypes.func.isRequired,
};

export default withContext(MerchantInterfaceConsumer)(AddedPrinters);
