// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import ModuleStyle from "../style.module.scss";

// Fields
import { ExpansionPanel, Input } from "fields";

class CouponInfoModule extends React.Component {
  render() {
    const { couponInfo = {} } = this.props;
    const { description = "", name = "" } = couponInfo;
    return (
      <ExpansionPanel moduleTitle="Coupon Information" showExpandableContent>
        <div className={ModuleStyle.fieldsContainer}>
          <div className={ModuleStyle.field}>
            <Input.TextInput
              name="Coupon Name"
              onChange={newValue =>
                this.props.onChangeCouponInfo("name", newValue)
              }
              placeholder="Name"
              required
              value={name}
            />
          </div>
          <div className={ModuleStyle.field}>
            <Input.TextInput
              name="Coupon Description"
              onChange={newValue =>
                this.props.onChangeCouponInfo("description", newValue)
              }
              placeholder="Description"
              value={description}
            />
          </div>
        </div>
      </ExpansionPanel>
    );
  }
}

CouponInfoModule.propTypes = {
  couponInfo: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string
  }),
  onChangeCouponInfo: PropTypes.func.isRequired
};

CouponInfoModule.defaultProps = {
  couponInfo: { description: "", name: "" }
};

export default CouponInfoModule;
