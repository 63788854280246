// BUTI DINERS, INC. All right Reserved ©

// Utils
import { _isTrue } from "utils";
import _isEmpty from "lodash.isempty";

// Style
import Style from "./style.module.scss";

const DEFAULT_FILTER_VALUES = { modifierGroupIsArchived: "false" };

const FILTER_MODIFIER_GROUPS_CRITERIAS = {
  modifierGroupIsArchived: {
    class_names: { option: Style.radio_group_option },
    comparator: (a, b) => _isTrue(a) === _isTrue(b),
    input: {
      header: "Select status",
      options: {
        false: { label: "Active" },
        true: { label: "Archived" },
      },
      type: "radio",
    },
    required: true,
  },
  modifierGroupName: {
    comparator: (a = "", b = "") => {
      if (!_isEmpty(b))
        return String(a)
          .toLowerCase()
          .includes(String(b).toLowerCase());
      return true;
    },
    input: {
      label: "Modifier Group Name",
      type: "text",
    },
    required: false,
  },
};

export { DEFAULT_FILTER_VALUES, FILTER_MODIFIER_GROUPS_CRITERIAS };
