// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { del, set } from "object-path-immutable";
import _get from "lodash.get";

import Buttons from "./Buttons";
import PreviewInfo from "./PreviewInfo";
import OrderDetails from "./OrderDetails";

import {
  _createConfirmMsgForCustomer,
  _createOrderReadyMsgForCustomer,
  formatOrderTime,
} from "./helperFunctions";

// Utils
import { _isTrue } from "utils";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";
import Filter from "bad-words";
// Lib
import { Functions, Services } from "lib";

class ShopOrderPill extends React.Component {
  confirmNotif = null;
  state = { showExpandedInfo: false, is_loading: false, isLoading: false };
  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    this.handleCloseAllOrder();
  };

  componentWillUnmount() {
    this.setState({ isLoading: false, showExpandedInfo: false });
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.showExpandedInfo !== prevProps.showExpandedInfo)
      this.setState({ showExpandedInfo: this.props.showExpandedInfo });

    if (this.props.is_close_all_order !== prevProps.is_close_all_order) {
      this.handleCloseAllOrder();
      // this.setState({ handle: true });
    }
  }

  handleCloseAllOrder = () => {
    const { orderInfo = {}, is_close_all_order } = this.props;
    const { status } = orderInfo;
    if (is_close_all_order && status === "confirmed") {
      this.onCloseActiveOrder();
      // this.setState({ handle: false });
    }
  };

  renderBody = async ({ preparationTime, send_notify }) => {
    const { context, orderInfo = {} } = this.props;
    const { shopBasicInfo = {} } = context;
    const {
      customerName,
      deliveryDetails,
      orderDeliveryTypeID,
      phoneNumber,
      timeStamp,
    } = orderInfo;
    const { timeZone } = this.props.context.shopBasicInfo;
    const filter = new Filter();
    const time_order = formatOrderTime(timeStamp, timeZone);
    if (send_notify && phoneNumber) {
      let bodyMessage = _createConfirmMsgForCustomer({
        customerName,
        deliveryDetails,
        orderDeliveryTypeID,
        preparationTime,
        shopBasicInfo,
        time_order,
      });
      bodyMessage = filter.clean(bodyMessage).replace(/\*+/g, "");
      this.setState({ bodyMessage, showExpandedInfo: true });
    }
  };
  
  renderBodyAccept = async () => {
    const { orderInfo = {} } = this.props;
    const { deliveryDetails, orderDeliveryTypeID, timeStamp } = orderInfo;
    const { timeZone } = this.props.context.shopBasicInfo;
    const time_order = formatOrderTime(timeStamp, timeZone);
    const body_message_accept = _createOrderReadyMsgForCustomer({
      deliveryDetails,
      orderDeliveryTypeID,
      shopName: _get(this.props.context, "shopBasicInfo.name") || "",
      time_order,
    });
    this.setState({ body_message_accept, showExpandedInfo: true });
  };

  onConfirmActiveOrder = async ({
    nextStatus,
    preparationTime,
    send_notify,
  }) => {
    const { ChangeStatusOfActiveOrder } = Services.Merchants.PostRequests;
    this.setState({ isLoading: true });
    const { context, orderID, orderInfo } = this.props;
    const { shopBasicInfo, shopID } = context;

    const params = { nextStatus, orderID, shopID };
    const { success } = await ChangeStatusOfActiveOrder(params);
    const { allowPrintingOrder = "false" } = shopBasicInfo;

    if (this._isMounted) this.setState({ isLoading: false });
    if (!success) {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: "Failed to confirm an order. Please try again.",
        type: "warning",
      });
      return;
    }
    if (_isTrue(allowPrintingOrder)) this.onPrintOrder();
    const {
      customerName,
      deliveryDetails,
      orderDeliveryTypeID,
      phoneNumber,
      timeStamp,
    } = orderInfo;
    const { timeZone } = this.props.context.shopBasicInfo;

    const time_order = formatOrderTime(timeStamp, timeZone);
    if (send_notify && phoneNumber) {
      const filter = new Filter();

      let bodyMessage = _createConfirmMsgForCustomer({
        customerName,
        deliveryDetails,
        orderDeliveryTypeID,
        preparationTime,
        shopBasicInfo,
        time_order,
      });

      bodyMessage = filter.clean(bodyMessage).replace(/\*+/g, "");

      const { SendTextMessage } = Services.BUTI.PostRequests;
      await SendTextMessage({
        body: bodyMessage,
        to: phoneNumber,
      });
    }
    this.setState({ showExpandedInfo: true }, () =>
      context.onChangeActiveOrders(
        set(context.activeOrders, `${orderID}.status`, nextStatus)
      )
    );
  };

  handleOrderClose = async ({
    orderID = "",
    params,
    processed_count = 0,
    sendAllOrderReadyText,
    is_close_all_order,
  }) => {
    const { is_loading } = this.state;
    if (is_loading) {
      return;
    }
    this.setState({ is_loading: true });

    const { MoveActiveOrderToPastOrders } = Services.Merchants.PostRequests;
    const { SendTextMessage } = Services.BUTI.PostRequests;
    const { context, orderInfo, ordersList = {} } = this.props;
    const { timeZone } = this.props.context.shopBasicInfo;
    const {
      deliveryDetails,
      orderDeliveryTypeID,
      phoneNumber,
      timeStamp,
    } = orderInfo;

    const time_order = formatOrderTime(timeStamp, timeZone);

    const { success } = await MoveActiveOrderToPastOrders({
      orderID,
      shopID: context.shopID,
    });

    if (success) {
      delete ordersList[orderID];
      const { sendOrderReadyText = true } = params;

      if (
        phoneNumber &&
        (is_close_all_order ? sendAllOrderReadyText : sendOrderReadyText)
      ) {
        await SendTextMessage({
          body: _createOrderReadyMsgForCustomer({
            deliveryDetails,
            orderDeliveryTypeID,
            shopName: _get(this.props.context, "shopBasicInfo.name") || "",
            time_order,
          }),
          to: phoneNumber,
        });
      }
      this.props.onChangeCloseAllActiveOrder();
    } else {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: "Failed to close an order. Please try again.",
        type: "warning",
      });
    }
    this.props.handleSendAllOrderReadyTextChange(false);
    this.setState({ is_loading: false });
    return processed_count;
  };

  onCloseActiveOrder = async (params = {}) => {
    const {
      context,
      is_close_all_order,
      orderID = "",
      sendAllOrderReadyText,
    } = this.props;

    let processed_count = 0;

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      try {
        if (is_close_all_order) {
          processed_count = await this.handleOrderClose({
            orderID,
            params,
            processed_count,
            sendAllOrderReadyText,
            is_close_all_order,
          });
          if (processed_count === false) return;
        } else {
          processed_count = await this.handleOrderClose({
            orderID,
            params,
            processed_count,
            is_close_all_order,
          });
          if (processed_count === false) return;

          context.onChangeActiveOrders(del(context.activeOrders, orderID));
        }
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  onPrintOrder = () => {
    const { context, orderID, orderInfo } = this.props;
    const { OrdersManagement, ShowConfirmNotif } = Functions;
    const { _printOrder } = OrdersManagement;
    const {
      addedPrinters,
      onConnectToPrintServer = () => {},
      onUpdateAddedPrinters = () => {},
      printerQZConfigs = {},
      shopBasicInfo,
    } = context;
    const { timeZone } = shopBasicInfo;
    Object.keys(addedPrinters).forEach((printerName) => {
      const { printerBrand } = addedPrinters[printerName];
      try {
        _printOrder({
          qzConfigForPrinter: printerQZConfigs[printerName],
          orderID,
          orderInfo,
          printerBrand,
          printerName,
          shopTimeZone: timeZone,
        });
      } catch (error) {
        let message = error;
        if (error === "printServerNotConnected") {
          message = "Print server is not connected";
          onConnectToPrintServer();
        } else if (error === "printerNotConnected") {
          message = `${printerName} printer is not connected`;
          onUpdateAddedPrinters(
            set(addedPrinters, `${printerName}.isConnected`, false)
          );
        }
        this.confirmNotif = ShowConfirmNotif({ message, type: "error" });
      }
    });
  };

  onRefundRequestSuccess = ({ refund_request_id, request_details }) =>
    this.setState({ showRefundModal: false }, () =>
      this.props.onAddRefundRequestToPastOrder({
        orderId: this.props.orderID,
        refund_request_id,
        request_details,
      })
    );

  renderContent = () => {
    const { showExpandedInfo } = this.state;
    const { context, orderID, orderInfo = {}, orderType } = this.props;
    const { status } = orderInfo;
    const { shopID: shop_id } = context;
    return (
      <div className={Style.orderContainer}>
        <div
          className={cx(
            Style.orderPreviewBox,
            Style[status || "active"],
            showExpandedInfo && Style.showExpandedInfo,
            this.state.isLoading && Style.isLoading
          )}
        >
          {this.renderPreviewInfo(orderInfo)}
          <div>
            <Buttons
              isLoading={this.state.isLoading}
              onCloseActiveOrder={this.onCloseActiveOrder}
              onPrintOrder={this.onPrintOrder}
              onShowActionsModal={() =>
                this.setState({ showActionsModal: true })
              }
              onShowConfirmCloseOrder={() =>
                this.setState({ showConfirmCloseOrder: true })
              }
              onShowEstimatePrepTimeModal={() =>
                this.setState({ showEstimatePrepTimeModal: true })
              }
              onShowExpandedInfo={() =>
                this.setState({
                  showExpandedInfo: !this.state.showExpandedInfo,
                })
              }
              onShowRefundModal={() => this.setState({ showRefundModal: true })}
              orderInfo={orderInfo}
              orderType={orderType}
              showExpandedInfo={showExpandedInfo}
            />
          </div>
        </div>
        {showExpandedInfo && (
          <OrderDetails
            orderID={orderID}
            orderInfo={orderInfo}
            orderType={orderType}
            onShowRefundModal={() => this.setState({ showRefundModal: true })}
            shop_id={shop_id}
          />
        )}
      </div>
    );
  };

  renderPreviewInfo = (orderInfo = {}) => (
    <PreviewInfo
      onArrowBtnClick={() =>
        this.setState({ showExpandedInfo: !this.state.showExpandedInfo })
      }
      orderInfo={orderInfo}
      orderType={this.props.orderType}
      showExpandedInfo={this.state.showExpandedInfo}
    />
  );

  render() {
    const {
      showConfirmCloseOrder,
      showEstimatePrepTimeModal,
      showRefundModal,
    } = this.state;
    const { orderInfo } = this.props;
    const { orderDeliveryTypeID, pickUpTime = "", refundRequests } = orderInfo;
    return (
      <React.Fragment>
        {showEstimatePrepTimeModal && (
          <Modals.SelectEstimatePrepTimeModal
            onAcceptOrder={({ preparationTime, send_notify }) =>
              this.onConfirmActiveOrder({
                nextStatus: "confirmed",
                preparationTime,
                send_notify,
              })
            }
            onRenderBody={({ preparationTime, send_notify }) =>
              this.renderBody({
                preparationTime,
                send_notify,
              })
            }
            bodyMessage={this.state.bodyMessage}
            onCloseModal={() =>
              this.setState({ showEstimatePrepTimeModal: false })
            }
            orderInfo={orderInfo}
          />
        )}
        {showConfirmCloseOrder && (
          <Modals.ConfirmCloseOrderModal
            onCloseModal={() => this.setState({ showConfirmCloseOrder: false })}
            onClosePickupOrder={this.onCloseActiveOrder}
            orderDeliveryTypeID={orderDeliveryTypeID}
            pickUpTime={pickUpTime}
            body_message_accept={this.state.body_message_accept}
            renderBodyAccept={this.renderBodyAccept}
          />
        )}
        {showRefundModal && (
          <Modals.ConfirmRefundOrderModal
            onCloseModal={() => this.setState({ showRefundModal: false })}
            onRefundRequestSuccess={this.onRefundRequestSuccess}
            orderID={this.props.orderID}
            orderInfo={orderInfo}
            refundRequests={refundRequests}
          />
        )}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

ShopOrderPill.propTypes = {
  context: PropTypes.shape({
    activeOrders: PropTypes.object,
    addedPrinters: PropTypes.object,
    onChangeActiveOrders: PropTypes.func.isRequired,
    printerQZConfigs: PropTypes.object,
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  is_close_all_order: PropTypes.bool,
  onAddRefundRequestToPastOrder: PropTypes.func.isRequired,
  onChangeCloseAllActiveOrder: PropTypes.func,
  orderID: PropTypes.string.isRequired,
  ordersList: PropTypes.object,
  orderInfo: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    orderDeliveryTypeID: PropTypes.oneOf(["deliver", "inStore", "pickUp"])
      .isRequired,
    orderItems: PropTypes.object.isRequired,
    paymentIntentID: PropTypes.string,
    phoneNumber: PropTypes.string,
    status: PropTypes.string.isRequired,
    tableNumber: PropTypes.string,
    timeStamp: PropTypes.string.isRequired,
    tipAmount: PropTypes.any,
    totalPriceAfterTax: PropTypes.any.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  orderType: PropTypes.oneOf(["active", "past"]).isRequired,
  showExpandedInfo: PropTypes.bool,
};

ShopOrderPill.defaultProps = {
  onChangeOrderStatus: () => {},
  showExpandedInfo: false,
  ordersList: {},
};

export default withContext(MerchantInterfaceConsumer)(ShopOrderPill);
