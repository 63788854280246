// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Parent_Style from "../style.module.scss";
import Style from "./style.module.scss";

// Icons
import { PhoneIcon, UserIcon } from "assets/Icons";

const CustomerInfo = ({ customer_name, phone_number }) => {
  return (
    <div className={Parent_Style.infoCol}>
      <div className={Parent_Style.detailHeading}>customer info</div>
      <div className={Parent_Style.info_cell}>
        <div className={Parent_Style.icon}>
          <UserIcon />
        </div>
        <strong>{customer_name}</strong>
      </div>
      <div className={Parent_Style.info_cell}>
        <div className={Parent_Style.icon}>
          <PhoneIcon />
        </div>
        <div className={Parent_Style.flex_wrap}>
          <strong> {_formatPhoneNumber(phone_number)}</strong>
          <button
            className={Style.call_customer_button}
            onClick={() => window.open(`tel:${phone_number}`)}
          >
            Call
          </button>
        </div>
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  customer_name: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
};

export default CustomerInfo;
