// BUTI DINERS, INC. All right Reserved ©

import { del, set } from "object-path-immutable";

import { _roundNumber } from "utils";

const _getSubmitBtnStatus = ({ inputErrors = {}, modifierInfo = {} }) => {
  const { modifierName, modifierPrice = 0 } = modifierInfo;
  if (
    !modifierName ||
    isNaN(modifierPrice) ||
    parseFloat(modifierPrice) < 0 ||
    Object.keys(inputErrors).length > 0
  )
    return "inactive";
  return "active";
};

const _validateInputValue = ({ inputErrors = {}, fieldID, value }) => {
  switch (fieldID) {
    case "modifierPrice": {
      if (isNaN(value))
        return set(inputErrors, "modifierPrice", "Price is invalid");
      else if (parseFloat(value) < 0)
        return set(inputErrors, "modifierPrice", "Price must be at least 0");
      return del(inputErrors, "modifierPrice");
    }
    default:
      return inputErrors;
  }
};

const _vetModifierInfoBeforeSubmit = ({ modifierInfo }) => {
  const { modifierPrice = 0 } = modifierInfo;
  let price = 0;
  if (isNaN(modifierPrice)) price = 0;
  else if (parseFloat(modifierPrice) > 0)
    price = parseFloat(_roundNumber(modifierPrice));
  return set(modifierInfo, "modifierPrice", price);
};

export {
  _getSubmitBtnStatus,
  _validateInputValue,
  _vetModifierInfoBeforeSubmit,
};
