// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { set } from "object-path-immutable";
import _get from "lodash.get";
import _orderBy from "lodash.orderby";

// Utils
import { _roundNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Icons
import { DeliverIcon, DineInIcon, LunchBagIcon } from "assets/Icons";

// Lib
import { Constants, Functions } from "lib";

const DELIVERY_TYPES = {
  inStore: {
    label: (
      <div className={Style.tab}>
        <DineInIcon className={Style.dineInIcon} /> Dine In
      </div>
    ),
  },
  pickUp: {
    label: (
      <div className={Style.tab}>
        <LunchBagIcon className={Style.lunchBagIcon} />
        Take Out
      </div>
    ),
  },
  deliver: {
    label: (
      <div className={Style.tab}>
        <DeliverIcon className={Style.dineInIcon} />
        Delivery
      </div>
    ),
  },
};

const _addAllOrders = ({ byDeliveryType = {} }) =>
  Object.keys(byDeliveryType).reduce(
    (result, deliveryTypeId) => {
      const {
        subTotal = 0,
        subTotalPlusTax = 0,
        totalDeliverFee = 0,
        totalItemsCount = 0,
        totalOrdersCount = 0,
        totalTipAmount = 0,
        totalMerchantFee = 0,
      } = byDeliveryType[deliveryTypeId];
      return {
        subTotal: result.subTotal + subTotal,
        subTotalPlusTax: result.subTotalPlusTax + subTotalPlusTax,
        totalDeliverFee: result.totalDeliverFee + totalDeliverFee,
        totalItemsCount: result.totalItemsCount + totalItemsCount,
        totalMerchantFee: result.totalMerchantFee + totalMerchantFee,
        totalOrdersCount: result.totalOrdersCount + totalOrdersCount,
        totalTipAmount: result.totalTipAmount + totalTipAmount,
      };
    },
    {
      subTotal: 0,
      subTotalPlusTax: 0,
      totalDeliverFee: 0,
      totalItemsCount: 0,
      totalMerchantFee: 0,
      totalOrdersCount: 0,
      totalTipAmount: 0,
    }
  );

const _createReport = ({ pastOrders = {} }) => {
  const { OrderMathFuncs } = Functions;
  const {
    _calSubTotalOfOrder,
    _calcSubTotalPlusTax,
    _calcTotalItemsCount,
  } = OrderMathFuncs;
  const { QUICK_REPORT_TEMPLATE } = Constants;
  const byDeliveryType = Object.values(pastOrders).reduce(
    (report, orderDetails) => {
      const {
        merchantFees = {},
        orderDeliveryTypeID = "inStore",
        orderItems = {},
        tipAmount = 0,
        totalDeliverFee: deliverFeeAmount = 0,
      } = orderDetails;
      const {
        subTotal = 0,
        subTotalPlusTax = 0,
        totalDeliverFee = 0,
        totalItemsCount = 0,
        totalMerchantFee = 0,
        totalOrdersCount = 0,
        totalTipAmount = 0,
      } = report[orderDeliveryTypeID];

      const { totalFee = 0 } = merchantFees;

      return set(report, orderDeliveryTypeID, {
        subTotal: subTotal + _calSubTotalOfOrder({ orderItems }),
        subTotalPlusTax:
          subTotalPlusTax + _calcSubTotalPlusTax({ orderDetails }),
        totalDeliverFee: (totalDeliverFee || 0) + deliverFeeAmount,
        totalItemsCount: totalItemsCount + _calcTotalItemsCount({ orderItems }),
        totalOrdersCount: totalOrdersCount + 1,
        totalTipAmount: totalTipAmount + tipAmount,
        totalMerchantFee: totalMerchantFee + totalFee,
      });
    },
    QUICK_REPORT_TEMPLATE
  );
  return {
    allOrders: _addAllOrders({ byDeliveryType }),
    byDeliveryType,
  };
};

const _convertRowValueToString = ({ field_id, value }) => {
  switch (field_id) {
    case "net_sales":
    case "net_total":
    case "net_total_plus_tips":
    case "salesTax":
    case "subTotal":
    case "totalDeliverFee":
    case "totalTipAmount":
      return `$${_roundNumber(value).toFixed(2)}`;
    case "merchant_fee":
      return `($${_roundNumber(value).toFixed(2)})`;
    default:
      return value;
  }
};

const _getDataRowValue = ({ field_id, report }) => {
  switch (field_id) {
    case "merchant_fee": {
      const { totalMerchantFee = 0 } = report;
      return parseFloat(totalMerchantFee);
    }
    case "net_sales": {
      const { subTotal, totalMerchantFee = 0 } = report;
      return parseFloat(subTotal) - parseFloat(totalMerchantFee);
    }
    case "net_total": {
      return (
        _getDataRowValue({ field_id: "subTotal", report }) +
        _getDataRowValue({ field_id: "salesTax", report }) -
        _getDataRowValue({ field_id: "merchant_fee", report })
      );
    }
    case "net_total_plus_tips": {
      return (
        _getDataRowValue({ field_id: "net_total", report }) +
        _getDataRowValue({ field_id: "totalTipAmount", report })
      );
    }
    case "salesTax": {
      const { subTotal, subTotalPlusTax } = report;
      return parseFloat(subTotalPlusTax) - parseFloat(subTotal);
    }
    case "subTotal": {
      const { subTotal } = report;
      return parseFloat(subTotal);
    }
    case "totalDeliverFee":
    case "totalTipAmount": {
      const result = _get(report, field_id) || 0;
      if (parseFloat(result) === 0 || isNaN(result)) return 0;
      return result;
    }
    default:
      return _get(report, field_id) || 0;
  }
};

// --------------------------------------------------
// Find top 5 selling items in past orders

const _findtopItems = ({ pastOrders = {} }) => {
  const order_items = Object.values(pastOrders).reduce((items, order) => {
    const { orderItems = {} } = order;
    Object.values(orderItems).forEach((item) => {
      const {
        itemID,
        quantity,
        itemSimpleDescription: { itemKitchenChitName, itemName },
      } = item;
      items[itemID]
        ? (items[itemID].quantity += quantity)
        : (items[itemID] = { itemID, itemKitchenChitName, quantity, itemName });
    });
    return items;
  }, {});
  return _orderBy(Object.values(order_items), "quantity", "desc").slice(0, 5);
};

export {
  DELIVERY_TYPES,
  _convertRowValueToString,
  _createReport,
  _findtopItems,
  _getDataRowValue,
};
