// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
// import ModuleStyle from "../style.module.scss";

import BuyOneGetOne from "./BuyOneGetOne";
import DiscountOnAboveX from "./DiscountOnAboveX";

// Fields
import { ExpansionPanel } from "fields";

class CouponSelections extends React.Component {
  renderCouponTypeSelections = () => {
    switch (this.props.couponType) {
      case "BUY_ONE_GET_ONE":
        return <BuyOneGetOne {...this.props} />;
      case "DISCOUNT_ON_ORDERS_ABOVE_X":
        return <DiscountOnAboveX {...this.props} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <ExpansionPanel moduleTitle="Make Selections" showExpandableContent>
        {this.renderCouponTypeSelections()}
      </ExpansionPanel>
    );
  }
}

CouponSelections.propTypes = {
  couponType: PropTypes.any.isRequired,
  onSetCouponSelections: PropTypes.func.isRequired,
  selections: PropTypes.object,
  shopID: PropTypes.string.isRequired
};

CouponSelections.defaultProps = {
  selections: {}
};

export default CouponSelections;
