// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Components
import { SAMPLE_PICKUP_ORDER } from "components/SettingsModules/Printers/HelperFunctions";

// Lib
import { Functions, Services } from "lib";

const { ShowConfirmNotif } = Functions;
const { BUTI, Customers } = Services;

const { GetCurrentUTCTimestamp } = BUTI.GetRequests;
const { AddOrderToShopActiveOrders } = Customers.PostRequests;

class SkipliDev extends React.Component {
  confirmNotif = null;

  onSubmitTestOrder = async () => {
    const { currentTimestamp } = await GetCurrentUTCTimestamp();
    const { success } = await AddOrderToShopActiveOrders({
      orderDetails: set(SAMPLE_PICKUP_ORDER, "timeStamp", currentTimestamp),
      shopID: this.props.shopID,
    });
    this.confirmNotif = ShowConfirmNotif({
      message: success ? "Submitted the order" : "Please try again",
      type: success ? "success" : "error",
    });
  };

  render() {
    return (
      <div>
        <h1>Skipli Development Only</h1>
        <button onClick={this.onSubmitTestOrder}>Submit Test Order</button>
      </div>
    );
  }
}

SkipliDev.propTypes = {
  shopID: PropTypes.string.isRequired,
};

export default SkipliDev;
