import React from "react";
import PropTypes from "prop-types";
import * as immutable from "object-path-immutable";

// Fields
import { Input } from "fields";

// Style
import ModuleStyle from "../style.module.scss";

class DiscountOnAboveX extends React.Component {
  state = { selections: {} };

  onInputChange = (fieldID, val) =>
    this.props.onSetCouponSelections(
      immutable.set(this.props.selections, fieldID, val)
    );

  render() {
    const { selections } = this.props;
    const { amountOff = "", minimumTotal = "" } = selections;
    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          <strong>E.g. Get $4 off on total above $20</strong>
        </div>
        <div className={ModuleStyle.fieldsContainer}>
          <div className={ModuleStyle.field}>
            <Input.TextInput
              id="minimumTotal"
              label="Minimum Total ($)"
              name="minimumTotal"
              onChange={val => this.onInputChange("minimumTotal", val)}
              required
              type="number"
              value={minimumTotal}
            />
          </div>
          <div className={ModuleStyle.field}>
            <Input.TextInput
              id="amountOff"
              label="Amount Off ($)"
              name="amountOff"
              onChange={val => this.onInputChange("amountOff", val)}
              required
              type="number"
              value={amountOff}
            />
          </div>
        </div>
      </div>
    );
  }
}

DiscountOnAboveX.propTypes = {
  onSetCouponSelections: PropTypes.func,
  selections: PropTypes.shape({
    amountOff: PropTypes.string,
    minimumTotal: PropTypes.string
  })
};

export default DiscountOnAboveX;
