// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import shortid from "shortid";

import { SHOP_INFO_MODULES } from "./constants";

// Utils
import { _scrollTo } from "utils";

// Style
import MerchantViewStyle from "views/style.module.scss";
import SubNavbarStyle from "views/CustomerSelfCheckIn/style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals, ShopInfoModules } from "components";

// Components
import { Box, Button, Dropdown, SubNavbarContainer } from "fields";

// Assets
import { ArrowIcon, SaveIcon } from "assets/Icons";

// Lib
import { Functions, Services } from "lib";

const { ShowConfirmNotif } = Functions;
const { Merchants } = Services;
const { SaveChangedShopInfo } = Merchants.PostRequests;

class ShopInfo extends Component {
  refs = {};
  state = { shopInfo: this.props.shopInfo };

  onAddPhoneNumber = () =>
    this.setState({
      shopInfo: set(
        this.state.shopInfo,
        `phoneNumbersToReceiveOrders.${shortid.generate()}`,
        null
      ),
    });

  onChangePhoneNumber = (id, phoneNumber) =>
    this.setState({
      shopInfo: set(
        this.state.shopInfo,
        `phoneNumbersToReceiveOrders.${id}`,
        phoneNumber
      ),
    });

  onChangeStoreHours = (openHours) =>
    this.setState({
      shopInfo: set(this.state.shopInfo, `openHours`, openHours),
    });

  onFieldValueChange = (fieldKey, fieldNewValue) => {
    const { shopInfo } = this.state;
    this.setState({
      shopInfo: set(shopInfo, fieldKey, fieldNewValue),
    });
  };

  onSaveShopInfo = async () => {
    this.setState({ isLoading: true });
    const params = { shopID: this.props.shopID, shopInfo: this.state.shopInfo };
    const { success } = await SaveChangedShopInfo(params).finally(() =>
      this.setState({ isLoading: false })
    );
    ShowConfirmNotif({
      message: success
        ? "Updated shop info"
        : "An error occured. Please try again or refresh the page",
      type: success ? "success" : "error",
    });
    if (success) this.props.onUpdateShopBasicInfo(this.state.shopInfo);
  };

  onSelectDropdownOption = ({ id }) => _scrollTo({ ref: this.refs[id] });

  onUpdateRefs = ({ ref_id = "", ref }) => {
    if (ref_id) {
      this.refs = set(this.refs, ref_id, ref);
    }
  };

  renderDropdownOption = ({ name }) => (
    <div className={Style.dropdown_option}>
      <div>{name}</div>
      <div className={Style.option_arrow_icon}>
        <ArrowIcon />
      </div>
    </div>
  );

  renderSubNavbar = () => (
    <SubNavbarContainer>
      <div className={SubNavbarStyle.subNavbarLeftColumn}>
        <Dropdown
          class_names={{ placeholder: Style.dropdown_placeholder }}
          modal_props={{
            headerProps: { header: "Your Store" },
          }}
          onSelectOption={this.onSelectDropdownOption}
          options={SHOP_INFO_MODULES}
          placeholder="Your Store"
          renderOption={this.renderDropdownOption}
        />
      </div>
      <div className={SubNavbarStyle.subNavbarRightColumn}>
        <Button
          className={SubNavbarStyle.saveBtn}
          color="info"
          name="save shop info"
          onClick={this.onSaveShopInfo}
        >
          <div className={SubNavbarStyle.save_icon}>
            <SaveIcon />
          </div>
          Save
        </Button>
      </div>
    </SubNavbarContainer>
  );

  render() {
    const { shopID } = this.props;
    const { shopInfo = {} } = this.state;
    const { social_media_profiles = {} } = shopInfo;
    return (
      <div>
        {this.state.isLoading && (
          <Modals.LoadingModal message="Saving new info..." />
        )}
        {this.renderSubNavbar()}
        <div className={MerchantViewStyle.extraPaddingTop}>
          <div ref={(ref) => this.onUpdateRefs({ ref_id: "basic_info", ref })}>
            <Box
              description="Please email accounts@skiplinow.com for any changes to the basic information"
              header="Basic Information"
            >
              <ShopInfoModules.BasicInfo
                onInputChange={this.onFieldValueChange}
                shopId={shopID}
                shopInfo={shopInfo}
              />
            </Box>
          </div>
          <div
            ref={(ref) =>
              this.onUpdateRefs({ ref_id: "social_media_profiles", ref })
            }
          >
            <Box
              description="Make use of social media to connect with your guests and collect feedback via SKIPLI reviews"
              header="Social Media Profiles"
            >
              <ShopInfoModules.SocialMediaProfiles
                onInputChange={this.onFieldValueChange}
                social_media_profiles={social_media_profiles}
              />
            </Box>
          </div>
          <div
            ref={(ref) =>
              this.onUpdateRefs({ ref_id: "new_orders_notification", ref })
            }
          >
            <Box
              description="New orders will be texted to these phone numbers"
              header="New Orders Notification"
            >
              <ShopInfoModules.PhoneNumbersToReceiveOrders
                onAddPhoneNumber={this.onAddPhoneNumber}
                onChangePhoneNumber={this.onChangePhoneNumber}
                phoneNumbers={shopInfo.phoneNumbersToReceiveOrders}
              />
            </Box>
          </div>
          <div ref={(ref) => this.onUpdateRefs({ ref_id: "shop_logo", ref })}>
            <Box header="Store Logo">
              <ShopInfoModules.ShopLogo
                logoURL={shopInfo.logoURL}
                onUpdateShopLogoAfterSaved={(logoURL) =>
                  this.onFieldValueChange("logoURL", logoURL)
                }
                shopID={shopID}
              />
            </Box>
          </div>
          <div
            ref={(ref) => this.onUpdateRefs({ ref_id: "shop_thumbnail", ref })}
          >
            <Box header="Cover Photo">
              <ShopInfoModules.ShopThumbnailImg
                onUpdateShopThumbnailImgAfterSaved={(thumbnailImg) =>
                  this.onFieldValueChange("thumbnailImg", thumbnailImg)
                }
                shopID={shopID}
                thumbnailImg={shopInfo.thumbnailImg || ""}
              />
            </Box>
          </div>
          <div ref={(ref) => this.onUpdateRefs({ ref_id: "membership", ref })}>
            <Box header="Membership">
              <ShopInfoModules.ShopMembership
                membership={shopInfo.membership}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

ShopInfo.propTypes = {
  onUpdateShopBasicInfo: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
  shopInfo: PropTypes.object.isRequired,
};

export default ShopInfo;
